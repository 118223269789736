'use strict';
var config = require('../config.js');

var jobCtrl = function ($scope, railApi, $state, $stateParams, Upload, $q, deviceDetector, $location, $rootScope, $filter, $modal, jobService) {

    var preferred_language = $rootScope.preferred_language;
    $scope.apiUrl = config.apiIP;
    $scope.docFiles = [];
    $scope.addFile = [];
    $scope.attachFiles = [{id: 'attachFiles1'}];
    $scope.interviewFiles = [];
    $scope.attachInterviewFiles = [{id: 'attachInterviewFiles1'}];
    $scope.attachDocsId = [];
    $scope.attachDocsInfo = [];
    $scope.deviceInfo = deviceDetector;
    $scope.job_template = {};
    $scope.validEmail = true;
    $scope.emailExists = true;
    // $scope.post_reason = railApi.index('post_reasons/').data;

    $scope.candidateSources = railApi.index('candidate_sources', {
        // query: { isViewable: true },
        // fields: 'id name order',
        // limit: 50
    }).data;

    railApi.get('organizations/1').promise.then(function(data) {
        $scope.isitorganization = data;

        if ($scope.isitorganization.mandatory_cover_letter) {
            $scope.hgForm.cover_letter_options  = 'CoverLetterOptionMandatory';
        }
        if ($scope.isitorganization.optional_cover_letter) {
            $scope.hgForm.cover_letter_options = 'CoverLetterOptionOptional';
        }
        if ($scope.isitorganization.not_accepted_cover_letter) {
            $scope.hgForm.cover_letter_options = 'CoverLetterOptionDoNotAccept';
        }
    });

    // Used in add/assignment-information so we can display both recruiters and recruiter admins for recruiter dropdown
    $scope.recruiters = railApi.index('employees').data;
    $scope.aasm_state_filter = function (recruiters) {
        if (recruiters.aasm_state === 'recruiter' || recruiters.aasm_state === 'recruiter_admin'){
            return recruiters.aasm_state;
        }
    };
    if (-1 !== _.findIndex($rootScope.hgUserData.expiry_values, (e) => {return e.job_id == $stateParams.id;}, 0)) {
      $scope.expiryNotificationFrequency = $rootScope.hgUserData.expiry_values[_.findIndex($rootScope.hgUserData.expiry_values, (e) => {return e.job_id == $stateParams.id;}, 0)].frequency;
    }
    else if (-1 !== _.findIndex($rootScope.hgUserData.expiry_notifications, (e) => {return e.job_id == $stateParams.id;}, 0)) {
        $scope.expiryNotificationFrequency = $rootScope.hgUserData.expiry_notifications[_.findIndex($rootScope.hgUserData.expiry_notifications, (e) => {return e.job_id == $stateParams.id;}, 0)].frequency;
    }
    else{
      $scope.expiryNotificationFrequency = 'none';
    }



    $scope.resetForm = function () {
        $scope.hgForm = {
          name: {},
          openings: 1,
          salaryType: 'pre',
          industry_id: '',
          decision_maker_ids: [],
          hiring_manager_ids: [],
          recruiter_ids: [],
          range_percents_termmap: 50,
          range_percents_questionnaire: 50,
          required_languages: [preferred_language],
          not_add_job_board: false,
          general_application: false,
          is_auto_close: false,
          display_closing_date: true,
          display_type: true,
          requested_documents: false,
          requisition_number: '',
          report_category_ids_concat: [],
          division_category: [],
          display_category: [],
          form_ids: [],
          custom_field_input_en: '',
          custom_field_input_fr: '',
          custom_field_input_es: '',
          custom_field_input_2_en: '',
          custom_field_input_2_fr: '',
          custom_field_input_2_es: '',
          custom_field_input_3_en: '',
          custom_field_input_3_fr: '',
          custom_field_input_3_es: '',
          alternate_apply_method_input: '',
          remote_indication: '',
          uploaded_files_attributes: []
        };

        // ,
        // post_reason: [$scope.post_reason]

        $scope.fileError = false;
    };

    if (!$stateParams.id) {
      $scope.resetForm();
    }

    $scope.applyTemplate = function (template_id) {
        if (template_id && template_id != '000000000000000000defa17') {
          $scope.job_template = railApi.get('job_templates/' + template_id).data;
          $scope.$watch('job_template.id', function (newVal, oldVal) {
                if (newVal != oldVal) {
                    if (!newVal.pending) { // and no error
                      var report_category_ids = _.map($scope.job_template.report_categories, 'id');
                      $scope.hgForm.division_category.push($scope.job_template.division_category[0]);
                      $scope.hgForm.display_category.push($scope.job_template.display_category[0]);
                      $scope.hgForm.name = $scope.job_template.name;
                      $scope.hgForm.description = $scope.job_template.description;
                      $scope.hgForm.required_languages = $scope.job_template.required_languages;
                      $scope.hgForm.industry = $scope.job_template.industry;
                      $scope.hgForm.job_type = $scope.job_template.job_type;
                      $scope.hgForm.range_percents_termmap = $scope.job_template.range_percents_termmap;
                      $scope.hgForm.range_percents_questionnaire = $scope.job_template.range_percents_questionnaire;
                      $scope.hgForm.report_category_ids_concat = report_category_ids;
                      $scope.hgForm.job_application_method_en = $scope.job_template.job_application_method_en;
                      $scope.hgForm.form_ids = $scope.job_template.question_section_ids;
                      $scope.hgForm.alternate_apply_method_input = $scope.job_template.alternate_apply_method_input;
                      $scope.hgForm.alternate_apply_method_input_link = $scope.job_template.alternate_apply_method_input_link;
                      $scope.hgForm.summary = $scope.job_template.summary;
                      $scope.hgForm.cover_letter_options = $scope.job_template.cover_letter_options;
                    }
                }
            }, true)
        }
    };



    $scope.refreshFileList = function() {
        
        
        railApi.get('jobs/' + $stateParams.id, { 'page': 'edit_job' }).promise.then(function(data) {
            $scope.viewFiles = _.filter(data.uploaded_files, { 'file_type': 'job_attachment' });
            $scope.viewInterviewFiles = _.filter(data.uploaded_files, { 'file_type': 'interview_attachment' });
        });
    }

    if ($stateParams.id) {
        $scope.hgForm = railApi.get('jobs/' + $stateParams.id, { 'page': 'edit_job' }).data;
        $scope.job = $scope.hgForm;
        railApi.get('jobs/' + $stateParams.id, { 'page': 'edit_job' }).promise.then(function(data) {
            $scope.viewFiles = _.filter(data.uploaded_files, { 'file_type': 'job_attachment' });
            $scope.viewInterviewFiles = _.filter(data.uploaded_files, { 'file_type': 'interview_attachment' });
        });
        $scope.$watch('hgForm.id', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $scope.docFiles = _.filter($scope.hgForm.uploaded_files, { 'file_type': 'job_attachment' });
                    $scope.attachFiles = _.filter($scope.hgForm.uploaded_files, { 'file_type': 'job_attachment' });
                    $scope.interviewFiles = _.filter($scope.hgForm.uploaded_files, { 'file_type': 'interview_attachment' });
                    $scope.attachInterviewFiles = _.filter($scope.hgForm.uploaded_files, { 'file_type': 'interview_attachment' });
                    if ($scope.attachFiles.length === 0) {
                      $scope.attachFiles = [{id: 'attachFiles1'}];
                    }
                    if ($scope.attachInterviewFiles.length === 0) {
                      $scope.attachInterviewFiles = [{id: 'attachInterviewFiles1'}];
                    }
                }
            }
        }, true)
    };

    $scope.closeJob = function () {
      var path = 'jobs/' + $scope.hgForm.id + '/close/' + $scope.hgForm.close_reason_id;
      $scope.result = railApi.put(path, {id: $scope.hgForm.id, close_reason_id: $scope.hgForm.close_reason_id}, 'job');
      $scope.$watch('result', function (newVal, oldVal) {
          if (newVal != oldVal) {
              if (!newVal.pending) { // and no error
                  $state.go('jobList', {status: 'closed'});
              }
          }
      }, true)
    };

    $scope.assignJobExpiryNotifications = function (email_frequency, job_id) {
        var path = 'employees/' + $rootScope.hgUserData.id + '/assign_expiry_notification';
        $scope.result = railApi.put(path, {id: $rootScope.hgUserData.id, expiry_notification_email_frequency: email_frequency, job_id: job_id}, 'employee');
        $scope.expiryNotificationFrequency = email_frequency;
    };

    $scope.adjustCloseReaonForJob = function () {
        var path = 'jobs/' + $scope.hgForm.id + '/adjust_close_reason';
        $scope.result = railApi.put(path, {id: $scope.hgForm.id, close_reason_id: $scope.hgForm.close_reason_id}, 'job');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('jobList', {status: 'closed'});
                }
            }
        }, true)
    };

    $scope.filterCloseReason = function (closeReason) {
        return closeReason.name.en !== 'Closed Pending Job' || closeReason.is_system_default === false
    }

    $scope.RemoveCloseReason = function () {
        var path = 'jobs/' + $scope.hgForm.id + '/active_job';
        $scope.result = railApi.put(path, {id: $scope.hgForm.id, close_reason_id: '', closed_date: ''}, 'job');
    };

    $scope.closePendingJobInformation = function () {
        var path = 'jobs/' + $scope.hgForm.id + '/close_pending';
        $scope.result = railApi.put(path, {id: $scope.hgForm.id}, 'job');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('jobList', {status: 'closed'});
                }
            }
        }, true)
    };

    $scope.archiveJob = function () {
        var path = 'jobs/' + $scope.hgForm.id + '/archive';
        $scope.result = railApi.put(path, {id: $scope.hgForm.id}, 'job');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('jobList', {status: 'archived'});
                }
            }
        }, true)
    };

    $scope.expireJob = function () {
        var path = 'jobs/' + $scope.hgForm.id + '/expire';
        $scope.result = railApi.put(path, {id: $scope.hgForm.id}, 'job');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('jobList', {status: 'expired'});
                }
            }
        }, true)
    };

    $scope.extendJob = function () {
        var path = 'jobs/' + $scope.hgForm.id + '/extend_end_date';
        var endDate = $scope.hgForm.end_date;
        var internalEndDate = $scope.hgForm.internal_end_date;
        $scope.result = railApi.put(path, {id: $scope.hgForm.id, end_date: endDate, internal_end_date: internalEndDate}, 'job');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('jobList', {status: 'active'});
                }
            }
        }, true)
    };

    $scope.fillJob = function () {
        var path = 'jobs/' + $scope.hgForm.id + '/fill';
        $scope.result = railApi.put(path, {id: $scope.hgForm.id}, 'job');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('jobList', {status: 'filled'});
                }
            }
        }, true)
    };

    $scope.templateJob = function () {
        var path = 'jobs/' + $scope.hgForm.id + '/template_from_job';
        $scope.result = railApi.post(path, {id: $scope.hgForm.id}, 'job_template');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('jobTemplateList');
                }
            }
        }, true)
    };

    $scope.cloneJob = function () {
        var path = 'jobs/' + $scope.hgForm.id + '/repost';
        var startDate = $scope.hgForm.start_date;
        var endDate = $scope.hgForm.end_date;
        var internalEndDate = $scope.hgForm.internal_end_date;
        var locationId = $scope.hgForm.location_id;
        var displayClosingDate = $scope.hgForm.display_closing_date;
        var postReasonId = $scope.hgForm.post_reason_id;
        $scope.result = railApi.post(path, {id: $scope.hgForm.id, start_date: startDate, end_date: endDate, internal_end_date: internalEndDate, location_id: locationId, display_closing_date: displayClosingDate, post_reason_id: postReasonId}, 'job');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('jobEdit', {id: newVal["data"].id});
                }
            }
        }, true)
    };

    $scope.httpsurl = function(url){
        if(url.includes("https://")){
            return url
        }else{
            return "https://"+url
        }
    }

    $scope.cloneWithApplicantsJob = function () {
        var path = 'jobs/' + $scope.hgForm.id + '/repost_with_applicants';
        var startDate = $scope.hgForm.start_date;
        var endDate = $scope.hgForm.end_date;
        var internalEndDate = $scope.hgForm.internal_end_date;
        var locationId = $scope.hgForm.location_id;
        var displayClosingDate = $scope.hgForm.display_closing_date;
        var postReasonId = $scope.hgForm.post_reason_id;
        $scope.result = railApi.post(path, {id: $scope.hgForm.id, start_date: startDate, end_date: endDate, internal_end_date: internalEndDate, location_id: locationId, display_closing_date: displayClosingDate, post_reason_id: postReasonId}, 'job');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('jobEdit', {id: newVal["data"].id});
                }
            }
        }, true)
    };

    $scope.indeedRemoveSponsor = function () {
        $scope.hgForm.sponsored = false;
        railApi.put('jobs/' + $scope.hgForm.id, $scope.hgForm, 'job');
    };

    $scope.resetForm = function () {
        $scope.fileError = true;
        $scope.errorFiles = true;
    };

    $scope.downloadFile = function downloadFile(file_id, file_name, mimetype) {
        $scope.downloadedFile = railApi.get('uploaded_files/' + file_id + '/download')
                                   .promise
                                   .then(function(data) {
                                     var blob = new Blob([data], {type: mimetype});
                                     saveAs(blob, file_name);
                                   });
    };




    $scope.removeFile = function (index, file_id, file_type, file_name) {
        
        if('job_attachment' === file_type) {
            var fileIndex = _.findIndex($scope.viewFiles, function(chr) {
                return (chr.name || chr.original_file_name) == file_name;
            });
            $scope.viewFiles.splice(fileIndex,1);
        }

        if('interview_attachment' === file_type) {
            var fileIndex = _.findIndex($scope.viewInterviewFiles, function(chr) {
                return (chr.name || chr.original_file_name) == file_name;
            });
            $scope.viewInterviewFiles.splice(fileIndex,1);
        }
        if ($scope.hgForm.uploaded_files_attributes) {
          var uploadFileIndex = _.findIndex($scope.hgForm.uploaded_files_attributes, function(chr) {
              return chr.original_file_name == file_name;
          });
          $scope.hgForm.uploaded_files_attributes.splice(uploadFileIndex,1);
        }
        if (file_id) {
            var remove_url = 'jobs/remove_file/' + file_id;
            railApi.put(remove_url, {file_id: file_id}, 'job');
          }
        
    }



    $scope.addFileToJob = function (file_type, file_index) {
        
        var files = [];

        
            _.map($scope.addFile, function (value, index) {
                if (-1 === _.findIndex(
                    $scope.hgForm.uploaded_files, { 'true_original_name': (value.name || value.original_file_name) })
                ) {
                    return files.push(value);
                }
            });
        
        
        if (files.length) {
            $scope.upload(files, file_type, file_index);

            return true;
        } else {

            return false;
        }

    }




    $scope.addNewFile = function () {
        var newItemNo = $scope.attachFiles.length + 1;
        $scope.attachFiles.push({'id' : 'attachFiles' + newItemNo});
    };


    $scope.addNewInterviewFile = function () {
        var newItemNo = $scope.attachInterviewFiles.length + 1;
        $scope.attachInterviewFiles.push({'id' : 'attachInterviewFiles' + newItemNo});
    };


    $scope.removeNewJobFile = function (index, file_id, file_type, file_name) {

        if ('job_attachment' === file_type) {
            var fileIndex = _.findIndex($scope.docFiles, function(chr) {
                return (chr.name || chr.original_file_name) == file_name;
            });
            $scope.docFiles.splice(fileIndex,1);
            $scope.attachFiles.splice(index,1);
            if ($scope.hgForm.uploaded_files_attributes) {
              var uploadFileIndex = _.findIndex($scope.hgForm.uploaded_files_attributes, function(chr) {
                  return chr.original_file_name == file_name;
              });
              $scope.hgForm.uploaded_files_attributes.splice(uploadFileIndex,1);
            }
        }

        if ('interview_attachment' === file_type) {
            var fileIndex = _.findIndex($scope.interviewFiles, function(chr) {
                return (chr.name || chr.original_file_name) == file_name;
            });
            $scope.interviewFiles.splice(fileIndex,1);
            $scope.attachInterviewFiles.splice(index,1);
            if ($scope.hgForm.uploaded_files_attributes) {
              var uploadFileIndex = _.findIndex($scope.hgForm.uploaded_files_attributes, function(chr) {
                  return chr.original_file_name == file_name;
              });
              $scope.hgForm.uploaded_files_attributes.splice(uploadFileIndex,1);
            }
        }

        if (file_id) {
          var remove_url = 'jobs/remove_file/' + file_id;
          railApi.put(remove_url, {file_id: file_id}, 'job');
        }

        var attachDocsInfoIndex = _.findIndex($scope.attachDocsInfo, function(chr) {
            return chr.fileName == fileName;
        });

        if (attachDocsInfoIndex > -1) {
            var attachId = $scope.attachDocsInfo[attachDocsInfoIndex].id;
            var attachDocsIdIndex = $scope.attachDocsId.indexOf(attachId);
            if (attachDocsIdIndex > -1) {
                $scope.attachDocsId.splice(attachDocsIdIndex, 1);
            }
            $scope.attachDocsInfo.splice(attachDocsInfoIndex,1);
        } else {
            // if this wrong file type then reset error message
            $scope.errorFile = false;
            $scope.errorInterviewFile = false;
        }

        if (0 === $scope.attachFiles.length) {
            this.addNewFile();
        }

        if (0 === $scope.attachInterviewFiles.length) {
            this.addNewInterviewFile();
        }
    }; //removeFile


    $scope.getDocsIdAndUpload = function (file_type, file_index) {
        var files = [];


        if ('job_attachment' === file_type && !$stateParams.id) {
            _.map($scope.docFiles, function (value, index) {
                if (-1 === _.findIndex(
                    $scope.hgForm.uploaded_files_attributes, { 'true_original_name': (value.name || value.original_file_name) })
                ) {
                    return files.push(value);
                }
            });
        }

        if ('interview_attachment' === file_type && !$stateParams.id) {
            _.map($scope.interviewFiles, function (value, index) {
                if (-1 === _.findIndex(
                    $scope.hgForm.uploaded_files_attributes, { 'true_original_name': (value.name || value.original_file_name) })
                ) {
                    return files.push(value);
                }
            });
        }


        if (files.length) {
            $scope.upload(files, file_type, file_index);

            return true;
        } else {

            return false;
        }

    }; //getDocsIdAndUpload


 


   

    $scope.showModalEmailJobPosting = function () {
        if($rootScope.currentLang == 'en') {
            $scope.emailBody = '<strong>Job ID#</strong> ' + $scope.hgForm.id +'<br><strong>Job Title:</strong> ' +$scope.hgForm.name.en+ '<br><strong>Date Posted:</strong> ' +$scope.hgForm.start_date+'<br><strong>Closing Date:</strong> ' + $scope.hgForm.end_date +'<br><strong>Job Type:</strong> '+$scope.hgForm.job_type.name.en+'<br><strong>Display Category:</strong> ' + $scope.hgForm.display_category[0].name.en +'<br><br><strong>Job Description:</strong><br> <br>' +$scope.hgForm.description.en

        }
        if($rootScope.currentLang == 'fr') {
            $scope.emailBody = '<strong>Identifiant du poste#</strong> ' + $scope.hgForm.id +'<br><strong>Titre du poste:</strong> ' +$scope.hgForm.name.fr+ '<br><strong>Date de parution:</strong> ' +$scope.hgForm.start_date+'<br><strong>Date de clôture: </strong> ' + $scope.hgForm.end_date +'<br><strong>Type d’emploi:</strong> '+$scope.hgForm.job_type.name.fr+'<br><strong>Affichez la catégorie:</strong> ' + $scope.hgForm.display_category[0].name.fr +'<br><br><strong>Description de la position: </strong><br><br>' +$scope.hgForm.description.fr

        }
        if($rootScope.currentLang == 'es') {
            $scope.emailBody = '<strong>Identificación del trabajo#</strong> ' + $scope.hgForm.id +'<br><strong>Título del trabajo:</strong> ' +$scope.hgForm.name.es+ '<br><strong>Fecha de publicación:</strong> ' +$scope.hgForm.start_date+'<br><strong>Fecha de cierre:</strong> ' + $scope.hgForm.end_date +'<br><strong>Tipo de trabajo:</strong> '+$scope.hgForm.job_type.name.es+'<br><strong>Categoría de visualización:</strong> ' + $scope.hgForm.display_category[0].name.es +'<br><br><strong>Descripción de trabajo:</strong><br><br>' +$scope.hgForm.description.es

        }
            $scope.employeeList = railApi.index('employees', {}, 'employee').data;
            $scope.emailJobPostingModal = $scope.modal.open({
                templateUrl: 'emailJobPostingModal.ejs',
                scope: $scope,
                size: 'lg'
            });
    };

    $scope.showModalJobExpiryNotifications = function () {
        $scope.assignJobExpiryNotificationsModal = $scope.modal.open({
            templateUrl: 'assignJobExpiryNotificationsModal.ejs',
            scope: $scope,
            size: 'lg'
        });
    };

    $scope.validateEmail = function(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    $scope.emailJobPosting = function (emailTemplate) {
        $scope.validEmail = true;
        if(emailTemplate.externalUsers){
            //strip out spaces and change into string array
            emailTemplate.externalUsers = emailTemplate.externalUsers.split(' ').join('');
            var externalEmails = emailTemplate.externalUsers.split(',');
            externalEmails.forEach(function(value, key) {
                if(!$scope.validateEmail(value)) {
                    $scope.validEmail = false;
                };
            });
        }


        if(emailTemplate.externalUsers && !emailTemplate.systemUsers){
            emailTemplate.systemUsers = externalEmails;
        }
        else if(emailTemplate.externalUsers && emailTemplate.systemUsers){
            emailTemplate.systemUsers = externalEmails.concat(emailTemplate.systemUsers);
        }
        if(!emailTemplate.externalUsers && !emailTemplate.systemUsers){
            $scope.emailExists = false;
        }

        if($scope.validEmail && $scope.emailExists) {
             $rootScope.sendEmailJobPosting = railApi.post('send_hgemail/email_job_posting', {
                t_uid: emailTemplate.systemUsers,
                f_uid: $rootScope.hgUserData.email,
                subject: emailTemplate.subject[$rootScope.currentLang],
                message: emailTemplate.body,
                employee_id: $rootScope.hgUserData.id,
                email_template_id: emailTemplate.id,
                file_upload: $scope.file_upload
        }, "send_hgemail");
            $scope.emailJobPostingModal.dismiss();
        }
    }

    $scope.upload = function (files, file_type, file_index, error) {
        if (files && files.length && (undefined === error)) {
            $scope.errorFile = false;
            $scope.errorInterviewFile = false;

            if ($stateParams.id) {
                var upload_url = config.apiIP + 'jobs/' + $stateParams.id + '/upload_file';
            }
            else {
                var upload_url = config.apiIP + 'jobs/upload_file';
            }

            for (var i = 0; i < files.length; i+=1) {
                var file = files[i];
                var data = {
                    file_type: file_type
                };

                if ('job_attachment' === file_type) {
                  if(file.dropBox) {
                      if(5000000 < file.bytes) {
                          $scope.errorFile = true;
                          return false;
                      }
                      data.dropBoxFile = file;
                      file = [];
                  } else {
                    if(5000000 < file.bytes) {
                        $scope.errorFile = true;
                        return false;
                    }
                  }
                }

                if ('interview_attachment' === file_type) {
                  if(file.dropBox) {
                      if(5000000 < file.bytes) {
                          $scope.errorInterviewFile = true;
                          return false;
                      }
                      data.dropBoxFile = file;
                      file = [];
                  } else {
                    if(5000000 < file.bytes) {
                        $scope.errorInterviewFile = true;
                        return false;
                    }
                  }
                }

                Upload.upload({
                    url: upload_url,
                    file: file,
                    fields: data
                }).progress(function (evt) {
                      $scope.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                }).success(function (data, status, headers, config) {
                  if (('job_attachment' === file_type || 'interview_attachment' === file_type) && !$stateParams.id) {
                      $scope.hgForm.uploaded_files_attributes.push(data.file_info);


                  }
                  if ('job_attachment' === file_type && $stateParams.id) {
                   
                    $scope.viewFiles.push($scope.addFile)
                    $scope.refreshFileList();

                  }
                  if ('interview_attachment' === file_type && $stateParams.id) {
                    $scope.viewInterviewFiles.push($scope.addFile)
                    $scope.refreshFileList();

                  }            


                }).error(function(data, status, headers, config) {
                  if ('job_attachment' === file_type) {
                    $scope.fileError = data.error;
                    $scope.errorFile = true;
                  }
                  else {
                    $scope.fileError = data.error;
                    $scope.errorInterviewFile = true;
                  }
                });
                // $scope.viewFiles.push($scope.docFiles[$scope.docFiles.length - 1])
            }
        } else {
            return false;
        }
       
    }; //upload["8", "25", 28, 27, 29, 30, "32"]

    $scope.q_list = []
    $scope.hgForm.question_ids = []

    $scope.setSessionVariables = function() {
        sessionStorage.setItem("jobCreated", true);
    }

    $scope.checkSessionVariables = function() {
        if(sessionStorage.getItem("jobCreated")) {
            sessionStorage.removeItem("jobCreated");
            $scope.jobPostingSuccessModal = $modal.open({
                templateUrl: 'jobPostingSuccessModal.ejs',
                scope: $scope,
                size: 'lg'
            });
        }
    
    }



    $scope.checkQuestionsLength = function(questionArray){
        var qItemsLength = _.filter(questionArray, function(data){
            return data != null
        }).length
        if(qItemsLength < 3){
            $scope.lessThanThree = true
        }else{
            $scope.lessThanThree = false
        }
        if(qItemsLength < 1){
            $scope.questionEmpty = true
        }else{
            $scope.questionEmpty = false
        }
    }
    $scope.formsPreview = function(formIds){
        railApi.index('forms', {'archived': false}).promise.then(function(data){
            var currForms = data.forms
            if(!formIds){
                formIds = []
            }
            var newFormList = []
            currForms.forEach(function(form){
                formIds.forEach(function(id){
                    if(form.id == id){
                        var formz = form
                        newFormList.push(formz)
                    }
                })
            })
            $scope.newFormList = newFormList
        })


        if($scope.hgForm.question_ids){
            $scope.hgForm.question_ids.forEach(function(data){
                if(data != null){
                    $scope.q_list[data.q_index] = data
                }
            })
        }

        $scope.checkQuestionsLength($scope.q_list);

    }

    $scope.parJson = function (json) {
        return JSON.parse(json);
    }

    $scope.removeEntry = function(formId, index){
        var question_index = index.toString();
        if($scope.hgForm.question_ids[question_index].question_id == false){
            delete $scope.hgForm.question_ids[question_index]
        }else{
            $scope.hgForm.question_ids[question_index].q_index = index;
            $scope.hgForm.question_ids[question_index].form_id = formId;
        }

        $scope.checkQuestionsLength($scope.hgForm.question_ids);
    }

    $scope.removeEntryEdit = function(formId, index){
        if(_.find($scope.q_list, {question_id: false})){
            $scope.q_list[_.findIndex($scope.q_list, {question_id: false})] = null
        }else{
            $scope.q_list[index].q_index = index;
            $scope.q_list[index].form_id = formId;
        }

        $scope.checkQuestionsLength($scope.q_list);
    }


    // $scope.initCoverLetterDefault = function() {

    //     if ($rootScope.clientSettings.mandatory_cover_letter) {
    //         $scope.hgForm.cover_letter_options  = 'CoverLetterOptionMandatory';
    //     }
    //     if ($rootScope.clientSettings.optional_cover_letter) {
    //         $scope.hgForm.cover_letter_options = 'CoverLetterOptionOptional';
    //     }
    //     if ($rootScope.clientSettings.not_accepted_cover_letter) {
    //         $scope.hgForm.cover_letter_options = 'CoverLetterOptionDoNotAccept';
    //     }

    // }

    $scope.initNotes = function() {

       railApi.index('forms', {'archived': false}).promise.then(function(data) {
            data.forms.forEach(function(form) {
                if(form.form_notes) {
                    form.form_notes = " - (" + form.form_notes + ")";
                    // console.log(form);
                }
            })
            $scope.questionSections = data;
        });

    }

    $scope.saveQuestions = function(selected){
        $scope.hgForm.question_ids = selected
        railApi.put('jobs/' + $stateParams.id, $scope.hgForm, 'job');
    }

    $scope.clearQEditList = function(){
        $scope.q_list = [];
    }


};

module.exports = jobCtrl;
