'use strict';

var Service = function () {

    this.update = function (selectedSource, candidateSources, selected) {
        selected.sourceIds = [];
        _.forEach(selectedSource, function (value) {
            var id = _.findIndex(candidateSources, {id: value});
            selected.sourceIds.push(candidateSources[id]);
        });

    };
};

Service.$inject = [];

module.exports = Service;