'use strict';
var config = require('../config.js');

var applyCtrl = function ($scope, Upload, deviceDetector, $location, railApi, $stateParams, $state, $rootScope) {
    $scope.applicantProfiles = {}
    $scope.dropBoxFile = [];
    $scope.coverLetterDropBoxFile = [];
    $scope.docFiles = [];
    $scope.attachFiles = [{id: 'attachFiles1'}];
    $scope.attachDocsId = [];
    $scope.attachDocsInfo = [];
    $scope.deviceInfo = deviceDetector;
    $scope.isDisabled = false;
    $scope.applied_by_recruiter = $state.current.data.appliedByRecruiter;
    $scope.resumeUploaded = false;
    $scope.answered = {};
    $scope.isInternal= false;
    $scope.CurrentDate = new Date();
    $scope.mandatoryCoverLetter;
    $scope.method = 'form';
    $scope.referralId;
    // if recruiter applied a candidate to job then terms and condition by default checked.
    if ($scope.applied_by_recruiter) {
        $scope.termsAndConditions = true;
    }
    

    railApi.get('organizations/1').promise.then(function(data) {
        if(!data.enable_terms_and_conditions) {
            $scope.termsAndConditions = true;
        }
     });
    
     if($rootScope.consider_for_other_jobs_feature_active == true) {
        var consideration = true;
     }
     else{
        var consideration = null;
     };
    

    $scope.resetForm = function () {
        $scope.resumeUploaded = false;
        $scope.hgForm = {
            applicant_profiles_attributes: [{
                job_id: $stateParams.id,
                work_experiences_attributes: [{}],
                educations_attributes: [{}],
                uploaded_files_attributes: [],
                applied_by_recruiter: $scope.applied_by_recruiter,
                preferred_language: $rootScope.currentLang,
                consider_for_other_jobs: consideration,
                answer_ids: [],
                text_answers_attributes: []
            }],
            job_id: $stateParams.id,
            current_employee: false,
            previous_employee: false,
            address_attributes: {},
            questions: {}
            
        };
        $scope.fileError = false;
    };

    $scope.emailFormat = /^[A-Za-z0-9]+[A-Za-z0-9._-]+@[A-Za-z0-9_-]+\.[A-Za-z0-9._-]{2,250}$/;

    $scope.addTextAnswer = function(id) {
        if(_.findIndex($scope.hgForm.applicant_profiles_attributes[0].text_answers_attributes, {question_id: id}) == -1 ){
            $scope.hgForm.applicant_profiles_attributes[0].text_answers_attributes.push({'question_id': id, 'answer_text': ''})
        }
    }

    $rootScope.$watch("currentLang", function(newVal, oldVal){
        if(newVal !== oldVal) {
            $scope.hgForm.applicant_profiles_attributes[0].preferred_language = newVal;
        }
    });

    $scope.mandatoryCoverLetterCheck = function() {

        if($scope.job.cover_letter_options == 'CoverLetterOptionMandatory'){
            if(($scope.doesFileExist == null || $scope.doesFileExist == 'empty') && $scope.applied_by_recruiter == false){
                $scope.invalidCoverLetter = true;
            }else{
                $scope.invalidCoverLetter = false;
            }
        }else{
            $scope.invalidCoverLetter = false;
        }
        if($scope.job.job_application_method_en == 'formsOnly'){
            $scope.step = 1 
            $scope.method = 'form_only' 
            $scope.pageView = 'confirmForm'
            $scope.form_only = true
        }else{
            console.log($rootScope.clientSettings.show_manual_apply_boolean)
            $scope.step = 0;
        }
    }

    


    $scope.resetForm();

    //will reload 'recruiterApplyJob' state
    $scope.reloadState = function(){
        $state.reload('recruiterApplyJob');
    };

    $scope.printPage = function() {

        var divToPrint = document.getElementById('applicationConfirm');
        var newWin = window.open('', 'Print-Window');
        newWin.document.open();
        newWin.document.write('<html><head>' + document.head.innerHTML + '</head><body onload="setTimeout(function(){window.print();}, 500);"><style>@media print { @page { margin: 0; } body{ margin: 1.6cm; font: 300  13px/16px "Open Sans", Helvetica, Arial, sans-serif; .alert { padding: 15px; margin-bottom: 20px; border: 1px solid transparent; border-radius: 2px; } .alert h4 { margin-top: 0; color: inherit;} .alert .alert-link { font-weight: bold; } .alert > p, .alert > ul { margin-bottom: 0; } .alert > p + p { margin-top: 5px; } .alert-dismissable, .alert-dismissible { padding-right: 35px; } .alert-dismissable .close, .alert-dismissible .close { position: relative; top: -2px; right: -21px; color: inherit; } .alert-success { background-color: #dff0d8; border-color: #468847; color: #468847; } .alert-success hr { border-top-color: #3d773e; } .alert-success .alert-link { color: #356635; } .alert-info { background-color: #D6DDE7; border-color: #9cb4c5; color: #346597; } .alert-info hr { border-top-color: #8ca8bc;} .alert-info .alert-link { color: #274c71; } .alert-warning { background-color: #fcf8e3; border-color: #DBAB57; color: #c09853; } .alert-warning hr { border-top-color: #d6a042; } .alert-warning .alert-link { color: #a47e3c; } .alert-danger { background-color: #f2dede; border-color: #b94a48; color: #b94a48; } .alert-danger hr { border-top-color: #a74240;} .alert-danger .alert-link { color: #953b39;}}}</style>' + divToPrint.innerHTML + '</body></html>'); 
        newWin.document.close();
    }


    $scope.getInternalApply = function(internal_id, job_id){
        $state.transitionTo('internalCareerInfo', {internal_id: internal_id, page: 'internal', id: job_id});
    };

    $scope.getInternalList = function(internal_id){
        $state.transitionTo('internalApplyList', {internal_id: $stateParams.internal_id});
    };

    
    
    var parts = $location.host().split('.');
    $scope.subdomain = parts[0];
    if ($stateParams.internal_id) {
      $scope.jobResult = railApi.get('jobs/apply/'+ $stateParams.id, { page: 'apply', internal_id: $stateParams.internal_id }).promise.then(function(data){
        $scope.job = data;
        data.candidate_sources.forEach(function(source){
            if(source.name.en.includes("Referral") && source.is_system_default == true){
                $scope.referralId = source.id
            }
        })
        $scope.mandatoryCoverLetterCheck();
        if('internal' == $stateParams.page){
            $scope.hgForm.applicant_profiles_attributes[0]['candidate_source_id']=_.find($scope.job.candidate_sources, function(source) { if(source.name['en'] == 'Internal'){return true;} }).id;
        };
      });
    } else {
      $scope.jobResult = railApi.get('jobs/apply/'+ $stateParams.id, { page: 'apply' }).promise.then(function(data){
        $scope.job = data;
        data.candidate_sources.forEach(function(source){
            if(source.name.en.includes("Referral") && source.is_system_default == true){
                $scope.referralId = source.id
            }
        })
        $scope.mandatoryCoverLetterCheck();
        if($scope.applied_by_recruiter == true){
            $scope.hgForm.applicant_profiles_attributes[0]['candidate_source_id']=_.find($scope.job.candidate_sources, function(source) { if(source.name['en'] == 'Added by Recruiter'){return true;} }).id;
        };
      });
    }

    


    $scope.$watch("doesFileExist", function (newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
            $scope.mandatoryCoverLetterCheck();
        }
    }, true);


    $scope.disableClick = function() {
        $scope.isDisabled = true;
    };

    $scope.addNewFile = function () {
        var newItemNo = $scope.attachFiles.length + 1;
        $scope.attachFiles.push({'id' : 'attachFiles' + newItemNo});
    };

    $scope.removeCoverLetter = function () {
        $scope.coverLetterModel = {};
        $scope.parsedCoverLetter = '';
        $scope.isDisabled = false;
        $scope.doesFileExist = 'empty';
    };

    $scope.removeFile = function (index, fileName) {

        var fileIndex = _.findIndex($scope.docFiles, function(chr) {
            return chr.name == fileName;
        });
        $scope.docFiles.splice(fileIndex,1);
        $scope.attachFiles.splice(index,1);

        var attachDocsInfoIndex = _.findIndex($scope.attachDocsInfo, function(chr) {
            return chr.fileName == fileName;
        });

        if (attachDocsInfoIndex > -1) {
            var attachId = $scope.attachDocsInfo[attachDocsInfoIndex].id;
            var attachDocsIdIndex = $scope.attachDocsId.indexOf(attachId);
            if (attachDocsIdIndex > -1) {
                $scope.attachDocsId.splice(attachDocsIdIndex, 1);
            }
            $scope.attachDocsInfo.splice(attachDocsInfoIndex,1);
        } else {
            // if this wrong file type then reset error message
            $scope.errorFile = false;
        }

        if (0 === $scope.attachFiles.length) {
            this.addNewFile();
        }
    }; //removeFile

    $scope.checkInternalJob = function () {
        var url = $location.absUrl();
             if(url.indexOf('internal') !== -1){
                $scope.isInternal = true;
        }
    }

    $scope.getDocsIdAndUpload = function () {
        var files = [];

        _.map($scope.docFiles, function (value, index) {
            if (-1 === _.findIndex(
                    $scope.hgForm.applicant_profiles_attributes[0].uploaded_files_attributes, { 'true_original_name': value.name })
            ) {
                return files.push(value);
            }
        });

        if (files.length) {
            $scope.upload(files, 'attachment');

            return true;
        } else {

            return false
        }

    }; //getDocsIdAndUpload

    var mapLinguaxResponse = function (data, fileType) {
        $scope.fileModel = data.file_info;
        if ('resume' === fileType) {
            $scope.hgForm.applicant_profiles_attributes[0].educations_attributes = data.parsed_file.educations_attributes;
            $scope.hgForm.applicant_profiles_attributes[0].objective = data.parsed_file.objective;
            $scope.hgForm.applicant_profiles_attributes[0].summary = data.parsed_file.summary;
            $scope.hgForm.applicant_profiles_attributes[0].resume = data.parsed_file.rawText;
            $scope.hgForm.applicant_profiles_attributes[0].work_experiences_attributes = data.parsed_file.work_experiences_attributes;
            $scope.hgForm.applicant_profiles_attributes[0].uploaded_files_attributes = [data.file_info];
            $scope.hgForm.address_attributes = data.parsed_file.address_attributes;
            if (data.parsed_file.hasOwnProperty('contact_attributes')) {
              $scope.hgForm.first_name = data.parsed_file.contact_attributes.first_name;
              $scope.hgForm.last_name = data.parsed_file.contact_attributes.last_name;
              $scope.hgForm.work_phone = data.parsed_file.contact_attributes.work_phone;
              $scope.hgForm.home_phone = data.parsed_file.contact_attributes.home_phone;
              $scope.hgForm.email = data.parsed_file.contact_attributes.email;
            }
            else{

            }
            $scope.resumeUploaded = true;
        }

        if ('attachment' === fileType) {
            $scope.hgForm.applicant_profiles_attributes[0].uploaded_files_attributes.push(data.file_info);
        }

        if ('cover_letter' === fileType) {
            $scope.hgForm.applicant_profiles_attributes[0].cover_letter = data.parsed_file.text;
            $scope.hgForm.applicant_profiles_attributes[0].uploaded_files_attributes.push(data.file_info);
        }

        if($scope.applied_by_recruiter == true){
            $scope.hgForm.applicant_profiles_attributes[0]['candidate_source_id']=_.find($scope.job.candidate_sources, function(source) { if(source.name['en'] == 'Added by Recruiter'){return true;} }).id;
        };

        if('internal' == $stateParams.page){
            $scope.hgForm.applicant_profiles_attributes[0]['candidate_source_id']=_.find($scope.job.candidate_sources, function(source) { if(source.name['en'] == 'Internal'){return true;} }).id;
        };
    };

    $scope.dropBoxScript = function(){
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = "https://www.dropbox.com/static/api/2/dropins.js";
        script.id = "dropboxjs"
        script.setAttribute("data-app-key", "vuwgctpnzo40wc8")
        script.setAttribute("defer","")
        var dropBoxScript = document.getElementById("dropboxjs");
        if(!dropBoxScript){
            document.body.appendChild(script)
        }
    }

    $scope.upload = function (files, fileType, error) {
        if (files && files.length && (undefined === error)) {
            $scope.errorResume = false;
            var jobDescription = $scope.job.description[$scope.$parent.currentLang] || '';
            
            if(fileType == 'cover_letter' ) {
                $scope.doesFileExist = files[0].lastModified ? files[0].lastModified.toString() : files[0].lastModifiedDate;
                $scope.mandatoryCoverLetterCheck();
            }
            
            for (var i = 0; i < files.length; i+=1) {
                var file = files[i];
                var data = {
                    file_type: fileType,
                    jobDesc: jobDescription
                };

                if(file.dropBox) {
                    if(5242880 < file.bytes) {
                        $scope.errorResume = true;
                        return false;
                    }
                    data.dropBoxFile = file;
                    file = [];
                }

                Upload.upload({
                    url: config.apiIP + 'applicant_profiles/upload_file',
                    file: file,
                    fields: data
                }).progress(function (evt) {
                    $scope.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                }).success(function (data, status, headers, config) {
                    // completed, data contains the parsed resume and fileModel
                    mapLinguaxResponse(data, fileType);
                }).error(function(data, status, headers, config) {
                    $scope.fileError = data.error;
                    if (config.fields.type === 'resume') {
                        $scope.errorResume = true;
                    }
                    else {
                        $scope.errorFile = true;
                    }
                });
            }
        } else {
            return false;
        }
    }; //upload["8", "25", 28, 27, 29, 30, "32"]

    $scope.$watch('answered', function (newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
            $scope.hgForm.applicant_profiles_attributes[0].answer_ids = _.flatten(Object.keys(newValue).map(function(e) {
                return newValue[e]
            }))
        }
    }, true);


    $scope.connectToLinkedIn = function () {
        localStorage.setItem("id", $stateParams.id)
        var redirect_uri = $location.protocol() +"://"+ $location.host() + "/view/client/redirect.html"
        window.location = "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=78udshneqyjg2z&redirect_uri=" + redirect_uri + "&state=LN9DGl7U9XK5lEZMlfnv&scope=r_basicprofile,r_emailaddress"
        $scope.isConnectedToLinkedIn = true;
    };

    $scope.getLinkedInAuthCode = function () {
        if ($location.search().code != null) {
            var redirect_uri = $location.protocol() +"://"+ $location.host() + "/view/client/redirect.html"
            var url = "https://www.linkedin.com/oauth/v2/accessToken"

            var headers = { "Content-Type": "application/x-www-form-urlencoded" }





            var auth_data = "grant_type=authorization_code&code=" + $location.search().code + "&redirect_uri=" + redirect_uri + "&client_id=78udshneqyjg2z&client_secret=A0gaHnhAKofaJIQt"
            //var auth_data = { client_id: "78udshneqyjg2z", grant_type: "authorization_code", code: $location.search().code.toString(), redirect_uri: "http://localhost:9000/view/client/redirect.html", client_secret: "A0gaHnhAKofaJIQt" }
            var data = {
                "url": url, "data": auth_data, "method": "POST", "headers": headers
            }
            $scope.authResponse = railApi.post('integrations', data, 'integration')

        }
    }

    $scope.getUserData = function () {
        var access_token = sessionStorage.getItem("access_token");

        var data = {
            method: "GET",
            url: "https://api.linkedin.com/v1/people/~:(first-name,last-name,headline,location,summary,positions,email-address)?format=json",
            headers: { "Content-Type": "application/json", "Authorization": "Bearer " + access_token, "x-li-format": "json" }
        }
        $scope.userDataResponse = railApi.post('integrations', data, 'integration').data

    }



    $scope.$watch('authResponse', function (newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
            if (newValue.errors.error) {
                $scope.errorMessage = newValue.errors.error_description
            } else {
                if (newValue.data.access_token != null) {
                    sessionStorage.setItem("access_token", newValue.data.access_token)
                    $scope.step = 1;
                    $scope.getUserData();


                }
            }
        }
    }, true);

    $scope.$watch('userDataResponse', function (newValue, oldValue) {
        if (newValue && newValue !== oldValue) {

            $scope.hgForm.first_name = newValue.firstName
            $scope.hgForm.last_name = newValue.lastName
            $scope.hgForm.email = newValue.emailAddress
            if (newValue.summary != null) {
                $scope.hgForm.applicant_profiles_attributes[0].summary = newValue.summary
            }
            if (newValue.location != null && newValue.location.name != null) {
                $scope.hgForm.address_attributes.city = newValue.location.name
            }
            if (newValue.positions != null && newValue.positions._total > 0) {
                $scope.hgForm.applicant_profiles_attributes[0].work_experiences_attributes[0].position = newValue.positions.values[0].title
                $scope.hgForm.applicant_profiles_attributes[0].work_experiences_attributes[0].company = newValue.positions.values[0].company.name
                $scope.hgForm.applicant_profiles_attributes[0].work_experiences_attributes[0].description = newValue.positions.values[0].summary
                var startDate = new Date(newValue.positions.values[0].startDate.year, newValue.positions.values[0].startDate.month - 1, 1);
                $scope.hgForm.applicant_profiles_attributes[0].work_experiences_attributes[0].start_date = startDate
                $scope.hgForm.applicant_profiles_attributes[0].work_experiences_attributes[0].present_date = true
            }

        }
    }, true);
    $scope.form_only = false
    $scope.checkFormOnly = function(){
        
        
    }

};

module.exports = applyCtrl;
