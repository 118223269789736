var Filter = function() {
    // app.filter('searchFor', function(){

    // All filters must return a function. The first parameter
    // is the data that is to be filtered, and the second is an
    // argument that may be passed with a colon (searchFor:searchString)
    //alert('hello')

    return function (arr, searchString, searchArea) {
        var result = [];
        if (searchString != null) {
            searchString2 = searchString.toLowerCase();
            // Using the forEach helper method to loop through the array
            //searchArray = ['item'];
            
            if (searchArea=='all') {
                angular.forEach(arr, function (applicant_list) {
                    var clientName ='', clientResume ='', clientNotes ='', clientCoverLetter ='';
                    var add_to_list = false;
                    if (applicant_list.applicant.name != null) {
                        clientName = applicant_list.applicant.name.toLowerCase();
                        if (clientName.search(searchString2) != -1) add_to_list = true;
                        
                    };
                    
                    if (applicant_list.resume != null) {
                        clientResume = applicant_list.resume.toLowerCase();
                        if (clientResume.search(searchString2) != -1) add_to_list = true;
                        
                    };  
                    if (applicant_list.coverletter !=null){
                        clientNotes = applicant_list.cover_letter.toLowerCase();
                        if (clientCoverLetter.search(searchString2) != -1 ) add_to_list = true;
                    };
                    
                    for (x in applicant_list.notes){
                        clientNotes = applicant_list.notes[x].body.toLowerCase();
                        if (clientNotes.search(searchString2) != -1 ) {
                            add_to_list = true;
                            break;
                        };
                    };
                    
                    //clientName = applicant_list.applicant.name.toLowerCase();
                    //clientResume = applicant_list.resume.toLowerCase();
                    if ( add_to_list ) {
                        result.push(applicant_list);

                    };
                });
            }
            else if (searchArea=='name') {
                angular.forEach(arr, function (applicant_list) {
                    clientName = applicant_list.applicant.name.toLowerCase();
                    if (clientName.search(searchString2) != -1 ) {
                        result.push(applicant_list);
                    };

                });
            }
            else if (searchArea=='notes') {
                angular.forEach(arr, function (applicant_list) {
                    var clientNotes ='';
                    for (x in applicant_list.notes){
                        clientNotes = applicant_list.notes[x].body.toLowerCase();
                        console.log(clientNotes);
                    
                        if (clientNotes.search(searchString2) != -1 ) {
                            result.push(applicant_list);
                            break;
                        };
                    };
                });
            }
            return result;
        } else {
            return arr;
        }
    };
};

Filter.$inject = [];

module.exports = Filter;
