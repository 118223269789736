'use strict';

// Removed Twitter from line 7 as a workaround to get post a job and job template functionality to work. add back in when working on twitter and fix issues that arise.
var Service = function ($rootScope, $stateParams, $state, railApi) {
    this.getClientData = function () {
        if (!$rootScope.client) {
            $rootScope.client = railApi.get('organizations').data;
        }
    };
};

Service.$inject = ['$rootScope', '$stateParams', '$state', 'railApi'];

module.exports = Service;