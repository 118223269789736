'use strict';
var config = require('../config.js');
const jsSHA = require("jssha");

var informationRequestAnswerCtrl = function ($scope, Jobs, railApi, $translate, Upload, $stateParams, $rootScope, $state, $filter) {

    
    $scope.uploaded_files_attributes = [];
    $scope.answered = {}
    $scope.text_answers_attributes = []
    $scope.providedDocs = [];

    $scope.initFormData = function(){
        var hash = $stateParams.hash
        $scope.organization = $rootScope.clientSettings
        setTimeout(function() {
            railApi.post('approval_emails/check_hash', {hash: hash}, 'approval_emails').promise.then(function(data){
                if(data.email_type =='info_request'){
                    $scope.requestForm = data
                    $scope.section = data.forms[0]
                    var val = [{label: 'user.name.first', value: data.applicant_info.first_name},
                         {label: 'user.name.last', value: data.applicant_info.last_name},
                         {label: 'user.email', value: data.applicant_info.email},
                         {label: 'jobName', value: data.job_info.name.en},
                         {label: 'jobNameFr', value: data.job_info.name.fr},
                         {label: 'jobNameEs', value: data.job_info.name.es},
                         {label: 'organization.name', value: $scope.organization.name},
                         {label: 'contactEmail', value: $scope.organization.email},
                         {label: 'contactPhone', value: $scope.organization.phone},
                         {label: 'global.url.hostname', value: $scope.organization.url},
                         {label: 'clientName', value: $scope.organization.name},
                         {label: 'job.name', value: data.job_info.name.en},
                         {label: 'job.name_fr', value: data.job_info.name.fr},
                         {label: 'job.name_es', value: data.job_info.name.es},
                         {label: 'global.url.protocol', value: $scope.organization.url}]
                         
                    var emailString = data.message
                    val.forEach( function(value){
                        emailString = emailString.replaceAll('{{'+value.label+'}}', !_.isEmpty(value.value) ? value.value : '______')
                    })
        
                    $scope.offerPrev = emailString
                }else{
                    window.location.href="/expiredLink.html";
                }
                
            })
          }, 1000);
    }

    $scope.addTextAnswer = function(id) {
        $scope.text_answers_attributes.push({'question_id': id, 'answer_text': ''})
    }

    $scope.beforeInfoFileSubmit = function(files){
        
        $scope.submittedFiles = files
        $scope.infoResponseConfirmationModal = $scope.modal.open({
            templateUrl: 'infoResponseConfirmationModal.ejs',
            scope: $scope,
            size: 'md'
        });
    }

    $scope.sendFormResponse = function(answers){
        railApi.post('approval_emails/answer_forms', {hash: $stateParams.hash, answers: answers, text_answers: $scope.text_answers_attributes}, 'approval_emails').promise.then(function(data){
            $scope.responseSubmittedModal = $scope.modal.open({
                templateUrl: 'responseSubmittedModal.ejs',
                scope: $scope,
                size: 'md'
            });
        })
    }

    $scope.submitFileResponse = function(files, note){
        railApi.post('approval_emails/info_req_file_submit', {hash: $stateParams.hash, files: files, note: note}).promise.then(function(data){
            $scope.responseSubmittedModal = $scope.modal.open({
                templateUrl: 'responseSubmittedModal.ejs',
                scope: $scope,
                size: 'md'
            });
        })
    }

    $scope.getDocsIdAndUpload = function (file_req_id, file_upload) {
        if(file_upload){
            var files = [];

            files.push({
                id: file_req_id,
                file: file_upload
            });

            if (files.length) {
                $scope.upload(files, 'info_attachment');

                return true;
            } else {

                return false
            }
        }

        
    };

    $scope.clearProvidedDoc = function(index, file_and_link_id){
        $scope.uploaded_files_attributes = $scope.uploaded_files_attributes.filter(function(item) {
            return item.file_and_link_id != file_and_link_id;
        });
        $scope.providedDocs[index] = null
    }

    $scope.upload = function (files, file_type, attachment, error) {
        if (files && files.length && (undefined === error)) {
            $scope.errorFile = false;
            $scope.errorInterviewFile = false;

            if ($scope.requestForm) {
                var upload_url = config.apiIP + 'approval_emails/' + $scope.requestForm + '/upload_file';
            }
            else {
                var upload_url = config.apiIP + 'approval_emails/upload_file';
            }

            for (var i = 0; i < files.length; i+=1) {

                if ('info_attachment' === file_type) {
                    if(5000000 < files[0].bytes) {
                        $scope.errorFile = true;
                        return false;
                    }
                  }

                var data = {
                    file_and_link_id: files[i].id,
                    direction: 'inbound',
                    file_type: 'info_attachment'
                };

                Upload.upload({
                    url: upload_url,
                    file: files[i].file,
                    fields: data
                }).progress(function (evt) {
                      $scope.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                }).success(function (data, status, headers, config) {
                    $scope.uploaded_files_attributes.push({file: data.file_info, file_and_link_id: data.file_and_link_id})
                }).error(function(data, status, headers, config) {
                  if ('info_attachment' === file_type) {
                    $scope.fileError = data.error;
                    $scope.errorFile = true;
                  }
                  else {
                    $scope.fileError = data.error;
                    $scope.errorInterviewFile = true;
                  }
                });
                
            }
        } else {
            return false;
        }
    }

}

module.exports = informationRequestAnswerCtrl;