'use strict';

var routeConfig = require('./route.js');
var home = global.mainPageDir;

var config = function ($provide,
                       // $location,
                       $stateProvider,
                       $urlRouterProvider,
                       $translateProvider,
                       $httpProvider, RestangularProvider, $compileProvider, DropBoxSettingsProvider, ENV,
                       CacheFactoryProvider) {

    $httpProvider.interceptors.push('tokenInterceptorFactory');
    $urlRouterProvider.otherwise('/' + home);
    if (('startdate.ca' === window.location.host || 'www.startdate.ca' === window.location.host) && '/' === window.location.pathname) {
        window.location = 'applicant-tracking.html#/';
    }
    routeConfig($stateProvider);

    // all Restangular requests will be prefixed with the following string
    RestangularProvider.setBaseUrl(config.origin + '/api/v1/');

    // Add the X-API-KEY to the headers of all Restangular requests
    // RestangularProvider.setDefaultHeaders({'X-API-KEY': 'VhLuP-v23Twnp-o6ONBg-snFcCNO5-Ajqi3b'});

    $translateProvider.useStaticFilesLoader({
        prefix: 'translation/',
        suffix: '.json'
    });

    // var analyticsAccount = window.location.host.indexOf("test") == -1 ? 'UA-123661751-1' : 'UA-123661751-3';
      
    // //console.log(googleAccount);
    // AnalyticsProvider
    //     .logAllCalls(true)
    //     .trackPages(true)
    //     .delayScriptTag(false)
    //     .setAccount(analyticsAccount)
    //     .ignoreFirstPageLoad(true);

    // Override the default regular expression that blocks mailto link (used to add "unsafe" in front of mailto:a@b.com
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|mailto):/);

    // Configure the options
    DropBoxSettingsProvider.configure({
        linkType: 'direct',
        multiselect: false,
        extensions: ['.pdf', '.doc', '.docx', '.rtf', '.txt','.odf', '.jpg', '.bmp', '.gif', '.tif']
    });
    angular.extend(CacheFactoryProvider.defaults, { maxAge: 60 * 1000 });
 
    config.origin = location.protocol + '//' + location.hostname;

    config.apiIP = ENV.APIIP;

    // the following chunk may not be needed if the client name is already set somewhere (HOT FIX)
    // this is used in the routes for client feeds
    config.client = location.hostname;
    config.client = config.client.split(".");
    config.client = config.client[0];


    // config.globalUrlVariable = location.$$absUrl;

    config.domain = ENV.DOMAIN;

    // o365 connections
    /*
    adalAuthenticationServiceProvider.init(
    {
        instance: 'https://login.microsoftonline.com/',
        tenant: 'startdate.onmicrosoft.com',
        clientId: '7dde16b1-e277-4436-9d3f-f63ce4b86b40',
        redirectUri: 'http://localhost:9000/ats.html',
        extraQueryParameter: 'nux=1',
        cacheLocation: 'localStorage',
        popUp:"true"
    },);*/

};

config.$inject = [
    '$provide',
    '$stateProvider',
    '$urlRouterProvider',
    '$translateProvider',
    '$httpProvider',
    'RestangularProvider',
    '$compileProvider',
    'DropBoxSettingsProvider',
    'ENV',
    'CacheFactoryProvider'
];



//var apiIP = "http://192.168.1.100:8080/";
// This is set in the grunt scripts/ngconstant.js file for the environment


// Dev linux setup: "http://0.0.0.0:8080/"
// Staging setup: "http://192.168.1.199:8080/"
// Windows setup: "http://192.168.1.102:8080/"
// Production setup: "unknown"


module.exports = config;

//$compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|file):/);
