/**
 * Created by dylan on 27/01/15.
 */

module.exports = function (app) {
    _.forOwn(_, function (method, name) {
        app.filter(name, function () {
            return function () {
                return method.apply(_, arguments);
            }
        })
    })
};