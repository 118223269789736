/**
 * Created by dylan on 18/12/14.
 */


var config = require('../../config.js');

var Directive = function ($translate) {
    return {
        restrict: 'AE',
        require: '?^form',
        replace: true,
        template: "<ul class=\"header-dropdown-list\" data-ng-show=\"1 < _langCtx.available_languages.length\" style=\"padding-left: 10px !important;\">\n    <li>\n        <a href=\"\" class=\"dropdown-toggle\" data-toggle=\"dropdown\">\n            <img data-ng-if=\"showFlags\" alt=\"\" class=\"flag flag-{{ _langCtx.currentLang }}\" src=\"img/blank.gif\">\n            <span> {{ 'LanguageMenu.' + _langCtx.currentLang | hgTranslate }} </span>\n            <i class=\"fa fa-angle-down\"></i>\n        </a>\n        <ul class=\"dropdown-menu pull-right\">\n            <li\n                data-ng-class=\"{active: lang === _langCtx.currentLang}\"\n                data-ng-repeat=\"lang in _langCtx.available_languages\"\n                data-ng-hide=\"-1 === _.indexOf(viewableLanguages, lang)\"\n                    >\n                <a href=\"\" data-ng-click=\"_langCtx.setLang(lang)\" >\n                    <img data-ng-if=\"showFlags\" class=\"flag flag-{{ lang }}\" src=\"img/blank.gif\" />\n                    {{ 'LanguageMenu.' + lang | hgTranslate }}\n                </a>\n            </li>\n        </ul>\n    </li>\n</ul>\n",
        link: function (scope, elem, attr, form) {

            scope.$watch('preferred_language', function(val) {
                if (null != val) {
                    if (null != form) {
                        form.currentLang = scope.preferred_language;
                    } else {
                        scope.currentLang = scope.preferred_language;
                    }
                }
            }, true);
            
            scope.$watch('available_languages', function(val) {
                if (null != val) {
                    if (null != form) {

                        form.available_languages = val;
                        form.setLang = function (lang) {
                            if (-1 !== val.indexOf(lang)) {
                                form.currentLang = lang;
                            }
                        };
                    } else {
                        scope.available_languages = val;
                        scope.viewableLanguages = val;
                        scope.setLang = function (lang) {
                            if (-1 !== val.indexOf(lang)) {
                                scope.currentLang = lang;
                                $translate.use(lang);
                            }
                        };
                    }
                }
            }, true);

            if (null != form) {
                scope._langCtx = form;

                scope.$watch('hgForm', function (newVal) {
                    if (null != newVal &&
                        !_.isEmpty(newVal) &&
                        (null != newVal.required_languages ||
                        null != newVal.available_languages) &&
                        (!_.isEmpty(newVal.required_languages) || !_.isEmpty(newVal.available_languages))) {

                        if (null != newVal.required_languages) {
                            scope.viewableLanguages = newVal.required_languages;

                            if (-1 === newVal.required_languages.indexOf(form.currentLang)) {
                                form.currentLang = newVal.required_languages[0];
                            }
                        } else {
                            scope.viewableLanguages = newVal.available_languages;
                            if (-1 === newVal.available_languages.indexOf(form.currentLang)) {
                                form.currentLang = newVal.available_languages[0];
                            }
                        }
                    }
                }, true);

            } else {
                scope._langCtx = scope;
            }

        }
    };
};

Directive.$inject = ['$translate'];

module.exports = Directive;
