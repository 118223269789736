/**
 * Created by dylan on 11/12/14.
 */
require('./bootstrap.js');
require('./dropbox-picker.min.js');
require('angular-addtocalendar');
var app = angular.module('sd', [
    'ngSanitize',
    'ui.router',
    'ui.bootstrap',
    'restangular',
    'pascalprecht.translate',
    'checklist-model',
    'ngFileUpload',
    'angular-flot',
    'ngRoute',
    'angular-google-analytics',
    'dropbox-picker',
    'ng.deviceDetector',
    'angular-loading-bar',
    'angular-cache',
    'smoothScroll',
    'ngAnimate',
    'angular-atc'
])
    .config(require('./ng/config.js'))
    .run(require('./ng/run.js'))
    .constant('hgInformation',require('./ng/constant.js'))
    .constant('primaryColor',require('./ng/primaryColorConstant.js'));

require('./ng/constants.js');
require('./ng/controllers')(app);
require('./ng/services')(app);
require('./ng/directives')(app);
require('./ng/filters')(app);
require('./ng/resources')(app);
require('./ng/hgInject')(app,
// Injectable values (Note: all services and factories are added by default).
    [
        '$rootScope',
        '$stateParams',
        '$state',
        'hgInformation',
        'primaryColor'

    ]
);

angular.bootstrap(document, ['sd']);
