/**
 * Created by joseph on 22/02/17.
 */

var serialize = require('../serialize.js');
var config = require('../config.js');
/**
 * REST methods for the hg api.
 *
 * Methods are the same as the serverside api:
 *
 *      options, head, index, get, post, put, delete
 *
 * Each method will return a promise.
 * Once the promise completes it will attach some of the response data to the returned promise.
 *
 * Populated fields:
 *
 *      data: The response body.
 *      headers: The function to access the response headers.
 *      query: The query params sent in with the request.
 */
var Factory = function ($http, $q, conversions) {
    var baseUrl = config.apiIP;

    return {
        /**
         * Performs an 'OPTIONS' http request.
         *
         * @param path the path (from the base url) to request.
         * @param query an optional query object (converted to query string).
         *
         * @returns Promise{ data, headers, query }
         */
        options: function (path, query) {
            var deferred = $q.defer();
            path = baseUrl + '/' + path;
            deferred.query = query;
            deferred.errors = {};

            $http.head(path + '?' + serialize(query || {}))
                .success(function (data, status, headers, config) {
                    deferred.headers = headers;
                    deferred.resolve(data, status, headers, config);
                })
                .error(function (data, status, headers, config) {
                    _.merge(deferred.errors, data);
                    deferred.headers = headers;
                    deferred.reject(data, status, headers, config);
                });

            return deferred;
        },

        /**
         * Performs an 'Head' http request.
         *
         * @param path the path (from the base url) to request.
         * @param query an optional query object (converted to query string).
         *
         * @returns Promise{ data, headers, query }
         */
        head: function (path, query) {
            var deferred = $q.defer();
            path = baseUrl + '/' + path;
            deferred.query = query;
            deferred.errors = {};

            $http.head(path + '?' + serialize(query || {}))
                .success(function (data, status, headers, config) {
                    deferred.headers = headers;
                    deferred.resolve(data, status, headers, config);
                })
                .error(function (data, status, headers, config) {
                    _.merge(deferred.errors, data);
                    deferred.headers = headers;
                    deferred.reject(data, status, headers, config);
                });

            return deferred;
        },

        /**
         * Performs an 'INDEX(GET)' http request.
         *
         * @param path the path (from the base url) to request.
         * @param query an optional query object (converted to query string).
         *
         * @returns Promise{ data, headers, query }
         */
        index: function (path, query) {
            var deferred = $q.defer();
            path = baseUrl + '/' + path;
            deferred.data = [];
            deferred.query = query;
            deferred.errors = {};
            deferred.pending = true;

            $http.get(path + '?' + serialize(query || {}))
                .success(function (data, status, headers, config) {
                    _.merge(deferred.data, data);
                    deferred.headers = headers;
                    deferred.pending = false;
                    deferred.resolve(data, status, headers, config);
                })
                .error(function (data, status, headers, config) {
                    _.merge(deferred.errors, data);
                    deferred.headers = headers;
                    deferred.pending = false;
                    deferred.reject(data, status, headers, config);
                });

            return deferred;
        },

        /**
         * Performs an 'GET(/:id)' http request.
         *
         * @param path the path (from the base url) to request.
         * @param query an optional query object (converted to query string).
         *
         * @returns Promise{ data, headers, query }
         */
        get: function (path, query, bulk) {
            var deferred = $q.defer();
            bulk? (path = baseUrl + path): (path = baseUrl + '/' + path);
            deferred.data = {};
            deferred.query = query;
            deferred.errors = {};
            deferred.pending = true;

            if(bulk) {
                // console.log(Object.keys(query)[0]);
                $http.get(path + '[' + Object.values(query)[0] + ']' || {})
                    .success(function (data, status, headers, config) {
                        _.merge(deferred.data, data);
                        deferred.headers = headers;
                        deferred.pending = false;
                        deferred.resolve(data, status, headers, config);
                    })
                    .error(function (data, status, headers, config) {
                        _.merge(deferred.errors, data);
                        deferred.headers = headers;
                        deferred.pending = false;
                        deferred.reject(data, status, headers, config);
                    });
            } else {
                $http.get(path + '?' + serialize(query || {}))
                    .success(function (data, status, headers, config) {
                        _.merge(deferred.data, data);
                        deferred.headers = headers;
                        deferred.pending = false;
                        deferred.resolve(data, status, headers, config);
                    })
                    .error(function (data, status, headers, config) {
                        _.merge(deferred.errors, data);
                        deferred.headers = headers;
                        deferred.pending = false;
                        deferred.reject(data, status, headers, config);
                    });
            }

            return deferred;
        },

        /**
         * Performs an 'PUT' http request.
         *
         * @param path the path (from the base url) to request.
         * @param changes the body to send in the query.
         * @param wrapper the wrapping container needed on the back end
         *
         * @returns Promise{ data, headers, query }
         */
        put: function (path, changes, wrapper) {

            var deferred = $q.defer();
            path = baseUrl + '/' + path;
            deferred.data = {};
            deferred.errors = {};
            deferred.pending = true;

            var convertedChanges = conversions.convertData(changes);
            var wrappedChanges = {};
            wrappedChanges[wrapper] = convertedChanges;

            // console.log(wrappedChanges);

            $http.put(path, wrappedChanges)
                .success(function (data, status, headers, config) {
                    // console.log(data);
                    _.merge(deferred.data, data);
                    deferred.headers = headers;
                    deferred.pending = false;
                    deferred.resolve(data, status, headers, config);
                })
                .error(function (data, status, headers, config) {
                    _.merge(deferred.errors, data);
                    deferred.headers = headers;
                    deferred.pending = false;
                    deferred.reject(data, status, headers, config);
                });

            return deferred;
        },

        /**
         * Performs an 'POST' http request.
         *
         * @param path the path (from the base url) to request.
         * @param changes the record to post.
         * @param wrapper the required container for backed API
         *
         * @returns Promise{ data, headers, query }
         */
        post: function (path, changes, wrapper) {
            var deferred = $q.defer();
            path = baseUrl + '/' + path;
            deferred.data = {};
            deferred.errors = {};
            deferred.pending = true;

            var convertedChanges = conversions.convertData(changes);
            var wrappedChanges = {};
            if (wrapper) {
                wrappedChanges[wrapper] = convertedChanges;
            } else {
                wrappedChanges = convertedChanges;
            }

            $http.post(path, wrappedChanges)
                .success(function (data, status, headers, config) {
                    _.merge(deferred.data, data);
                    deferred.headers = headers;
                    deferred.pending = false;
                    deferred.resolve(data, status, headers, config);
                })
                .error(function (data, status, headers, config) {
                    _.merge(deferred.errors, data);
                    deferred.headers = headers;
                    deferred.pending = false;
                    deferred.reject(data, status, headers, config);
                });

            return deferred;
        },

        /**
         * Performs an 'DELETE' http request.
         *
         * @param path the path (from the base url) to request.
         *
         * @returns Promise{ data, headers, query }
         */
        delete: function (path) {
            var deferred = $q.defer();
            path = baseUrl + '/' + path;
            deferred.data = {};
            deferred.errors = {};

            $http.delete(path)
                .success(function (data, status, headers, config) {
                    _.merge(deferred.data, data);
                    deferred.headers = headers;
                    deferred.resolve(data, status, headers, config);
                })
                .error(function (data, status, headers, config) {
                    _.merge(deferred.errors, data);
                    deferred.headers = headers;
                    deferred.reject(data, status, headers, config);
                });

            return deferred;
        }
    };
};
Factory.$inject = ['$http', '$q', 'conversions'];

module.exports = Factory;