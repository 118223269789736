'use strict';

var Directive = function() {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            scope.$watch(attrs.hgDirtyData, function(newVal, oldVal){
                scope.dirtyData = false;

                if (!_.isEmpty(newVal) &&
                    !_.isEmpty(oldVal) &&
                    !_.isEqual(oldVal, newVal)
                ) {
                    scope.dirtyData = true;
                }
            }, true);
        }
    };
};

Directive.$inject = [];

module.exports = Directive;