'use strict';

var stagesCtrl = function ($scope, railApi, $stateParams) {


    $scope.hgForm = {
        name: {en:'', fr:'', es:''},
        dm_notification: false,
        dm_stage: false,
        dm_move_and_remove: false,
        interview: false,
        order: '',
        stage_type: '',
        workflow_id: $stateParams.id
    };

    $scope.workflow = railApi.get('workflows/' + $stateParams.id, { fields: 'required_languages' }).data;
    $scope.$watch('workflow', function (newVal, oldVal) {
        if (newVal!=oldVal) {
            var req_lang = $scope.workflow.required_languages;
            var stageList = _.sortBy(_.reject(_.reject($scope.workflow.stages_attributes, { 'stage_type': 'rejected' }), {'stage_type': 'hired'}), 'order');
            var stageOrder = _.last(stageList).order + 1;
            $scope.hgForm.order = stageOrder;
        }
    }, true);

    $scope.error = [];

    $scope.formSubmit = function () {
        $scope.errors = [];
        req_lang.forEach(function (elem, index, array) {
            if ($scope.hgForm.name[elem] == '') {
                $scope.errors.push("Name should have the following languages: "+elem);
                $scope.stageError = true;
            }
            else if($scope.stageError == true) {
                $scope.result = railApi.post('stages', $scope.hgForm, 'stages_attributes')
            }
        });
    };

    $scope.$watch('hgForm', function (newVal, oldVal) {
        if (newVal!=oldVal) {

        }
    }, true);
};

module.exports = stagesCtrl;
