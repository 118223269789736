/**
 * Created by dylan on 08/01/15.
 */
'use strict';

var hgFilter = require('hg-filters');

var Directive = function ($parse) {
    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            /**
             * Function that runs hgFilter schema and updates the scope.
             */
            var filter = function () {
                var options = $parse(attr.hgFilter)(scope, { hgFilter: hgFilter });
                scope.data = hgFilter(options).run(scope.data) || {};
            };

            // Watch form data, validate after `delay` ms.
            scope.$watch('data', filter, true);
        }
    };
};
Directive.$inject = ['$parse'];

module.exports = Directive;