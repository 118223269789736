'use strict';
var config = require('../config.js');

var indeedSponsorCtrl = function ($scope, railApi, $window, $state, $stateParams) {

  var something = '';
  if ($scope.hgForm === undefined || $scope.hgForm.id === undefined) {
    // $scope.hgForm = $scope.job;
    var something = 'job';
  }
  else {
    // $scope.hgForm = $scope.hgForm;
    var something = 'hgForm';
  };

    $scope.indeedUpdateSponsor = function () {

        console.log($scope.sponsored_date);
        // $scope.hgForm.sponsored = true;
        $scope[something].sponsored = true;
        // $scope.hgForm.sponsored_date = $scope.sponsored_date;
        $scope[something].sponsored_date = $scope.sponsored_date;
        // railApi.put('jobs/' + $scope.hgForm.id, $scope.hgForm, 'job');
        railApi.put('jobs/' + $scope[something].id, $scope[something], 'job');

        // "2018-02-17T13:15:00.000Z"
    };

    $scope.indeedContactSponsor = function () {
        // var employee_id = $scope.hgForm.added_by_id;
        var employee_id = $scope[something].added_by_id;
        // would need to convert countries to country codes (ie canada => CA) ∴ hardcoded
        var organization_name = $scope.clientSettings.name;
        var organization_country = $scope.clientSettings.location.country;
        if (organization_country == 'Canada'){
            organization_country = 'CA';
        };

        railApi.get('employees/' + employee_id).promise.then(function(data) {
            var first_name = '';
            var last_name = '';
            var email = '';
            var phone = '';
            first_name = data.first_name;
            last_name = data.last_name;
            email = data.email;
            if (data.work_phone != '' && data.work_phone != null){
                phone = data.work_phone;
            } else {
                phone = data.home_phone;
            }
            phone = phone.replace(/[-\s\.]/g, "");

            var indeedUrlBuild = {
                'email': email,
                'firstName': first_name,
                'lastName': last_name,
                'phone': phone,
                'company': organization_name,
                'country': organization_country
            };

            // building url:
            var contactUrl = 'https://www.indeed.com/hire/contact?mini=true';
            for (var key in indeedUrlBuild) {
                contactUrl += '&' + key + '=' + indeedUrlBuild[key]
            }

            contactUrl = encodeURI(contactUrl);


            $window.location.href = contactUrl;

        });






        // railApi.put('jobs/' + $scope.hgForm.id, $scope.hgForm, 'job');
        console.log('fired indeedContactSponsor');
    };

};

module.exports = indeedSponsorCtrl;
