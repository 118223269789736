var config = require('../config.js');

var Filter = function ($interpolate) {

    var interpolateString = function (input, objectParams) {

        if(input) {
            var msg = $interpolate(input);
            return msg(objectParams);
        }
    };

    return interpolateString;
};

Filter.$inject = ['$interpolate'];

module.exports = Filter;

