'use strict';

var selector = 'hgForm';

var getPathExpr = function (path) {
    // Regex to extract out dynamic variables.
    path = path.split(/\[([^\]]*)]/);

    // First path is a string, so we wrap it in quotes.
    path[0] = '\'' + path[0] + '\'';

    // Every other path is dynamic, so we concat it to the string.
    for (var i = 1; i < path.length; i += 2) {
        path[i] = ' + \'.\' + ' + path[i];
    }

    return path.join('');
};

var Directive = function($compile) {
    return {
        require: '?ngModel',
        restrict: 'EA',
        compile: function (tElem, tAttr) {
            tElem = $(tElem);
            var selectRegex = RegExp('^' + selector + '\\.');
            var errorPath = '';
            var elemModel;

            tElem.find('hg-edit').each(function(i, elem) {
                elemModel = $(elem).find('[ng-model^="' + selector + '"]').attr('ng-model');

                if (null == elemModel) {
                    elemModel = $(elem).find('[checklist-model^="' + selector + '"]').attr('checklist-model');
                }

                if(null != elemModel) {
                    errorPath = getPathExpr(elemModel.replace(selectRegex, ''));

                    $(elem).parent().after(
                        angular.element('<div/>')
                            .attr({
                                name: '{{' + errorPath + '}}'
                            })
                            .attr({
                                class: 'col-md-12 note note-error',
                                'ng-repeat':
                                'message in editableUpdateResult.errors.errors[' + errorPath + '].message'
                            }).text('{{message}}')
                    );
                }
            });

            return function (scope, element, attrs, model) {
                var editable = true;

                if(null != attrs.editableUpdate) {
                    if (null != attrs.editable) {
                        scope.$watch(attrs.editable, function(newVal) {
                            if (null != newVal) {
                                editable = newVal;
                            }
                        });
                    }

                    if(null != attrs.ngModel){
                        // make a change every time the model changed
                        scope.$watch(function () {
                            return model.$modelValue;
                        }, function (newValue, oldValue) {
                            if (null != oldValue &&
                                null != newValue &&
                                !_.isEqual(newValue, oldValue) &&
                                editable
                            ) {
                                scope.editableUpdateResult = scope.$eval(attrs.editableUpdate);
                            }
                        }, true);
                    } else {
                        // if it is an "editable" view do it only when we're going from "hg-edit" to 'hg-text' view
                        // if we want edit only an hg-editable we can use editable-update attribute
                        scope.$watch(function () {
                            return scope.hgEditableToggle;
                        }, function (newValue, oldValue) {
                            if (null != oldValue &&
                                null != newValue &&
                                !_.isEqual(newValue, oldValue) &&
                                element.find('hg-editable').length &&
                                editable &&
                                null != scope.hgEditableToggle &&
                                null == _.findKey(scope.hgEditableToggle, function(value) { if (true === value) { return value;}}) &&
                                'false' === element.attr('init-load')
                            ) {
                                scope.editableUpdateResult = scope.$eval(attrs.editableUpdate);
                            }
                        }, true);
                    }
                }
            };

        }
    };
};

Directive.$inject = ['$compile'];

module.exports = Directive;