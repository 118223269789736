/**
 * Created by dylan on 17/02/15.
 */
'use strict';

var Directive = function ($parse) {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function (scope, elem, attr, model) {
            model.$parsers.unshift(function (val) {
                if (null == val || '' === val) {
                    return null;
                }

                return val;
            });
        }
    };
};

module.exports = Directive;