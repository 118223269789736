/**
 * Created by Neo on 2019-01
 */
'use restrict';

var reportCandidateSourceCtrl = function ($scope, railApi, $stateParams, $timeout) {

    $scope.total = 0;
    $scope.results = {};
    $scope.hgForm = null;
    //$scope.candidateSourceData = [];
    $scope.jobReportCategoryData = [];

    $scope.calculateTotal = function(result){

        var myTotal = 0;

        for(var i = 0; i < result.length; i++){
            var jobsNum = result[i][1];
            myTotal = myTotal + jobsNum;
        }

        $scope.total = myTotal;

    };

    // for job posting report
    $scope.getCandidateSourceReportData = function(hgForm, railApiName) {

        railApi.get('candidate_sources/', {'is_viewable': true}).promise.then(function(myData){
            $scope.candidateSourceData = myData;
            $scope.candidateSourceNumber = myData.length;
        });

        if (hgForm.job_id > 0) {
            railApi.get('jobs/'+railApiName+'/', {'job_id': hgForm.job_id}).promise.then(function(myData) {
                $scope.resourceNumbers = myData;
            });
        }else{
            railApi.get('jobs/'+railApiName+'/', {'job_start_date': hgForm.start_date, 'job_end_date': hgForm.end_date}).promise.then(function(myData) {
         
                $scope.resourceNumbers = myData;
    
            });
        }
        
    }

    $scope.getCandidateContacData = function() {
        railApi.get('applicants/export_contacts.csv', {'is_viewable': true}).promise.then(function(myData){
            var blob = new Blob([myData], {type: "text/plain; charset=utf-8"});
            saveAs(blob, "report.csv");
        });
    }
};

module.exports = reportCandidateSourceCtrl;