'use strict';
var config = require('../config.js');

var userManagementEditCtrl = function ($scope, $rootScope, $stateParams, RailRestangular, railApi, $state, $window) {

    var userId = $stateParams.id;
    var userRestApi = RailRestangular.service('employees');
    var licenseRestApi = RailRestangular.service('licenses');

    /**
     * Getting the list of Users (excluding hg users)
     */
    var getUserList = function() {
        /**
         * Get List of Users
         */
        userRestApi.getList().then(function (users) {
            $scope.userList = users;
            $scope.usersCount = users.length;
        }, function(errorResponse) {
            $scope.showErrorLabel = true;
            $scope.errorMessage = errorResponse;
        });
    };

    /**
     * Getting license information for client
     */
    var getLicenseList = function () {
        /**
         * Get List of Users
         */
        licenseRestApi.getList().then(function (licenses) {
            $scope.licenseList = {};
            $scope.licenseList.super_admin = {};
            $scope.licenseList.recruiter_admin = {};
            $scope.licenseList.recruiter = {};
            $scope.licenseList.hiring_manager = {};
            $scope.licenseList.decision_maker = {};
            $scope.licenseList.observer = {};
            $scope.licenseList.pending = {};
            $scope.licenseList.archived = {};

            angular.forEach(licenses, function (val, key) {
                $scope.licenseList[val.key] = val;
            });
        }, function (errorResponse) {
            $scope.showErrorLabel = true;
            $scope.errorMessage = errorResponse;
        });
    };

    getUserList();
    getLicenseList();


    $scope.showDeleteUserAccountConfirm = false;
    $scope.showDeleteUserAccountSuccess = false;
    $scope.showEditUserAccountSuccess = false;
    $scope.showPasswordResetSuccess = false;
    $scope.showError = false;
    $scope.errorMessage = '';

    RailRestangular.one('employees', userId).get().then(function (data) {
        $scope.hgForm = data;
    });

    $scope.saveUserAccount = function () {
        $scope.hgServerErrors = {};
        $scope.hgForm.put().then(function (data) {
            $scope.showError = false;
            $scope.showEditUserAccountSuccess = true;
        }, function (errorResponse) {
            $scope.showEditUserAccountSuccess = false;
            $scope.showError = true;

            // User Already Exists
            if (500 === errorResponse.status &&
                errorResponse.data &&
                errorResponse.data.errors &&
                errorResponse.data.errors.email &&
                errorResponse.data.errors.email.message &&
                'Duplicate e-mail address. This user is already in the system.' === errorResponse.data.errors.email.message[0]) {

                // user already exists in database
                $scope.errorMessage = 'E-mail already in use';

            } else if (500 === errorResponse.status &&
                errorResponse.data &&
                errorResponse.data.errors &&
                errorResponse.data.errors.aclRoles &&
                errorResponse.data.errors.aclRoles.message &&
                'Not enough licenses to fill user role' === errorResponse.data.errors.aclRoles.message[0]) {

                // user already exists in database
                $scope.errorMessage = 'Not enough licenses';

            } else {
                if (errorResponse.data.errors) {
                    $scope.hgServerErrors = errorResponse.data.errors;
                    $scope.showError = false;
                } else {
                    $scope.errorMessage = 'Unknown Error';
                }
            }
        });
    };

    $scope.deleteUserAccountConfirm = function () {
        $scope.showDeleteUserAccountConfirm = true;
    };

    $scope.deleteUserAccountCancel = function () {
        $scope.showDeleteUserAccountConfirm = false;
    };

    $scope.deleteUserAccount = function () {
        $scope.hgForm.remove();
        $scope.showDeleteUserAccountSuccess = true;
    };

    $scope.createUserChangeLog = function (change_requested) {
        $scope.change_requested = change_requested
        railApi.post('user_change_logs/create_log', {
            first_name: $scope.hgForm.first_name,
            last_name: $scope.hgForm.last_name,
            email: $scope.hgForm.email,
            aasm_state: $scope.hgForm.aasm_state,
            change_made: $scope.change_requested, 
            change_made_by: $rootScope.hgUserData.email,
        });
    }

    $scope.createUserInfoChangeLog = function () {
        $scope.change_requested = 'User information updated'
        railApi.post('user_change_logs/create_log', {
            first_name: $scope.hgForm.first_name,
            last_name: $scope.hgForm.last_name,
            email: $scope.hgForm.email,
            aasm_state: $scope.hgForm.aasm_state,
            change_made: $scope.change_requested, 
            change_made_by: $rootScope.hgUserData.email,
        });
    }

    $scope.resetPassword = function () {
        var payload = {};
        var user_email = $window.localStorage.getItem('uid');
        payload.email = $scope.hgForm.email;
        $window.localStorage.setItem('uid', $scope.hgForm.email);
        payload.redirect_url = config.apiIP + '/password-reset/';
        RailRestangular.all('auth/password').post(payload).then(function (data) {
            $scope.showPasswordResetSuccess = true;
            $scope.showPasswordResetMessage = 'Email Sent';
            $window.localStorage.setItem('uid', user_email);
        }, function () {
            $scope.showPasswordResetFail = true;
            $scope.showPasswordResetMessage = 'Unable to reset password';
            $window.localStorage.setItem('uid', user_email);
        });
    };

    $scope.deactivateAccount = function () {
        var path = 'employees/' + $scope.hgForm.id + '/deactivate';
        $scope.result = railApi.put(path, {id: $scope.hgForm.id}, 'employee');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('userManagement')
                }
            }
        }, true)
        $scope.newRole = 'Account Deactivated' 
        $scope.createUserChangeLog($scope.newRole)
    };

    $scope.archiveAccount = function () {
        var path = 'employees/' + $scope.hgForm.id + '/archive_license';
        $scope.result = railApi.put(path, {id: $scope.hgForm.id}, 'employee');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('userManagement')
                }
            }
        }, true)
        $scope.newRole = 'Account Archived' 
        $scope.createUserChangeLog($scope.newRole)
    };

    $scope.makeObserver = function () {
        var path = 'employees/' + $scope.hgForm.id + '/make_observer';
        $scope.result = railApi.put(path, {id: $scope.hgForm.id}, 'employee');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('userManagement')
                }
            }
        }, true)
        $scope.newRole = 'User Role changed to Observer' 
        $scope.createUserChangeLog($scope.newRole)
    };

    $scope.makeDecisionMaker = function () {
        var path = 'employees/' + $scope.hgForm.id + '/make_decision_maker';
        $scope.result = railApi.put(path, {id: $scope.hgForm.id}, 'employee');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('userManagement')
                }
            }
        }, true)
        $scope.newRole = 'User Role changed to Decision Maker' 
        $scope.createUserChangeLog($scope.newRole)
    };

    $scope.makeHiringManager = function () {
        var path = 'employees/' + $scope.hgForm.id + '/make_hiring_manager';
        $scope.result = railApi.put(path, {id: $scope.hgForm.id}, 'employee');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('userManagement')
                }
            }
        }, true)
        $scope.newRole = 'User Role changed to Hiring Manager' 
        $scope.createUserChangeLog($scope.newRole)
    };

    $scope.makeRecruiter = function () {
        var path = 'employees/' + $scope.hgForm.id + '/make_recruiter';
        $scope.result = railApi.put(path, {id: $scope.hgForm.id}, 'employee');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('userManagement')
                }
            }
        }, true)
        $scope.newRole = 'User Role changed to Recruiter' 
        $scope.createUserChangeLog($scope.newRole)
    };

    $scope.makeRecruiterAdmin = function () {
        railApi.put('employees/' + $scope.hgForm.id, {mailing_list: true}, 'employee'); // add employee to mailing list
        var path = 'employees/' + $scope.hgForm.id + '/make_recruiter_admin';
        $scope.result = railApi.put(path, {id: $scope.hgForm.id}, 'employee');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('userManagement')
                }
            }
        }, true)
        $scope.newRole = 'User Role changed to Recruiter Admin' 
        $scope.createUserChangeLog($scope.newRole)
    };

    $scope.makeSuperAdmin = function () {
        var path = 'employees/' + $scope.hgForm.id + '/make_super_admin';
        $scope.result = railApi.put(path, {id: $scope.hgForm.id}, 'employee');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('userManagement')
                }
            }
        }, true)
    };

    $scope.unlockUser = function () {
        var path = 'employees/' + $scope.hgForm.id;
        $scope.result = railApi.put(path, {failed_attempts: 0, locked_at: 'null'}, 'employee');

        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('userManagement')
                }
            }
        }, true)
        $scope.newRole = 'Account Unlocked' 
        $scope.createUserChangeLog($scope.newRole)
    }

    $scope.aclRoleList = {
        archived: 'Archived',
        pending: 'Inactive',
        observer: 'Observer',
        decision_maker: 'Decision Maker',
        recruiter: 'Recruiter',
        hiring_manager: 'Hiring Manager',
        recruiter_admin: 'Recruiter Admin',
        super_admin: 'Super Admin'
    };
};

module.exports = userManagementEditCtrl;