'use strict';



var Directive = function(railApi) {
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        template: "<span class=\"select\">\n    <hg-select placeholder=\"{{ 'CloseReason.selectCloseReason' | hgTranslate }}\"\n               data-ng-model=\"hgForm.close_reason.id\"\n               options=\"{ maxItems: 1, returnInt: true}\">\n        <hg-option ng-repeat=\"close_reason in close_reasons\" value=\"{{close_reason.id}}\">\n            {{close_reason.name | hgGetLang: currentLang}}\n        </hg-option>\n        <hg-option selected ng-if=\"hgForm.close_reason[0].id\" value=\"{{hgForm.close_reason[0].id}}\">\n            {{hgForm.close_reason[0].name | hgGetLang: currentLang}}\n        </hg-option>\n    </hg-select>\n</span>\n",

        link: function(scope, elem, attrs) {
            scope.close_reasons = railApi.index('job_categories', {'query': {'category_type': 'close_reason'}}).data
        }
    };
};

Directive.$inject = ['railApi'];

module.exports = Directive;
