/**
 * Created by dylan on 05/01/15.
 */
'use strict';

var selector = 'hgForm';
var delay = 800;
var hgValidate = require('hg-validators');

var Directive = function ($parse) {
    return {
        require: '^form',
        restrict: 'A',
        compile: function (tElem, tAttr) {
            tElem = $(tElem);

            if (!tElem.is('form')) {
                throw new Error('hgValidate: Can only be used on form elements.');
            }


            // Attach error messages (ng-repeat) to each input for this form.
            angular.forEach(inputs, function (input) {

            });

            return true;
        }
    };
};
Directive.$inject = ['$parse'];

module.exports = Directive;