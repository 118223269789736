  'use strict';
var config = require('../config.js');
const jsSHA = require("jssha");

// var icsLib = require('../../../../bower_components/ics.js/ics.js');

var applicantCtrl = function ($scope, $rootScope, $stateParams, $state,jobService, railApi, getClientDataService, $location, Upload, $q, deviceDetector,$sce,$http, $filter, FileSaver, $timeout) {

    $scope.apiUrl = config.apiIP;
    // Sets default candidate sorting as candidate rank
    // $rootScope.clientSettings.candidate_default_sort = 'candidate_rank';
    // console.log($rootScope.clientSettings.candidate_default_sort);
    $scope.tagIt = false;

    // event calendar variables
    $scope.applicantProfileUrl = $rootScope.clientSettings.url;
    $scope.todayDate = new Date();
    $scope.tomorrowDate = new Date();
    $scope.tomorrowDate.setDate($scope.todayDate.getDate() + 1);
    $scope.eventStartDate = $filter('date')($scope.tomorrowDate, 'yyyyMMdd') + 'T110000';
    $scope.eventEndDate = $filter('date')($scope.tomorrowDate, 'yyyyMMdd') + 'T113000';
    $scope.neoData = [];
    $scope.CurrentDate = $filter('date')(new Date(),'yyyy-MM-dd');
    $scope.employeeStatusString;
    $scope.validEmail = true;
    $scope.emailExists = true;
    $scope.providedDocs = [];
    $scope.referralId;

    $scope.candidateSources = railApi.index('candidate_sources').promise.then(function(data){
        data.forEach(function(source){
            if(source.name.en.includes("Referral") && source.is_system_default == true){
                $scope.referralId = source.id
            }
        })
    
    });


    // ics.js plugin
    $scope.createiCalendarEvent = function(){
        console.log(" start to create event .....");
    };
    $scope.userRole = $rootScope.hgUserData.role
    getClientDataService.getClientData();
    $scope.applications = null;
    $scope.applicantIds = [];
    if ($rootScope.hgUserData.watched_workload_ids[$stateParams.job_id]) {
      $scope.watchWorkloadIds = $rootScope.hgUserData.watched_workload_ids[$stateParams.job_id].watched_ids;
      $scope.watchWorkloadFrequency = $rootScope.hgUserData.watched_workload_ids[$stateParams.job_id].email_notification_frequency;
    }else{
      $scope.watchWorkloadIds = [];
      $scope.watchWorkloadFrequency = '';
    }
    if (-1 !== _.findIndex($rootScope.hgUserData.expiry_values, (e) => {return e.job_id == $stateParams.job_id;}, 0)) {
      $scope.expiryNotificationFrequency = $rootScope.hgUserData.expiry_values[_.findIndex($rootScope.hgUserData.expiry_values, (e) => {return e.job_id == $stateParams.job_id;}, 0)].frequency;
    }
    else if (-1 !== _.findIndex($rootScope.hgUserData.expiry_notifications, (e) => {return e.job_id == $stateParams.job_id;}, 0)) {
        $scope.expiryNotificationFrequency = $rootScope.hgUserData.expiry_notifications[_.findIndex($rootScope.hgUserData.expiry_notifications, (e) => {return e.job_id == $stateParams.job_id;}, 0)].frequency;
    }
    else{
      $scope.expiryNotificationFrequency = 'none';
    }
    $scope.checkApplicant = [];
    $scope.setAllIdsInStage = {};
    $scope.defaultValueSelect = 'none';
    $scope.closedUpdate = {};
    $scope.profileAccordion = {};
    $scope.totalApplications = [];
    // $scope.showTabs = true;
    if($state.current.name == "jobApplicationProfile") {
           $scope.showTabs = false;
    }else{
            $scope.showTabs = true;
    }
    // if($stateParams.applicant_profile_id) $scope.showTabs = (!$stateParams.applicant_profile_id.length);

    $scope.viewResume = $rootScope.clientSettings.view_resume;

    if($rootScope.clientSettings && $rootScope.clientSettings.candidate_default_sort) {
            if($rootScope.clientSettings.candidate_default_sort == 'date' || $rootScope.clientSettings.candidate_default_sort == 'date.applied') {
                    $scope.sortBy = 'created_at DESC';
            }
            if($rootScope.clientSettings.candidate_default_sort == 'candidate_rank'){
                $scope.sortBy = 'candidate_rank';
            }
            if($rootScope.clientSettings.candidate_default_sort == 'name' || $rootScope.clientSettings.candidate_default_sort == 'applicants.last_name') {
                $scope.sortBy = 'applicants.last_name';
            }
            if($rootScope.clientSettings.candidate_default_sort == 'answer_score') {
                $scope.sortBy = 'answer_score';
            }
            if($rootScope.clientSettings.candidate_default_sort == 'ai_recomendations'){
                $scope.sortBy = 'ai_recomendations.tag';
            }


    }else if($rootScope.clientSettings.candidate_default_sort == '') {
        $scope.sortBy = 'ranking';
    }



    $scope.showAddNote = false;
    $scope.showStage = $scope.showStage || false;
    $scope.limit = 20;
    $scope.offset = 0;
    $scope.applicantSearch = false;
    $scope.maxResults = 0;
    $scope.stageChange = false;
    $scope.idPreviusApplicantProfile = 0;
    $scope.initializingQuery = true;

    $scope.showFilter = false;
    $scope.applications = [];
    $scope.tags = [];
    $scope.applicant_profile =[];
    $scope.applicationsQuery = {};
    $scope.applicationsNextQuery = {};
    $scope.applicationsNext = [];
    $scope.resultJobApplication = {};
    $scope.replyEmail = {};
    $scope.applicationsPage = 1;
    $scope.initialLoad = true;
    $scope.showFormattedView = true;
    $scope.showFormattedCoverLetterView = true;
    $scope.numUploadedFiles = false;
    $scope.uploadedCoverLetterExists = false;
    $scope.formattedCL = false;
    $scope.hasCoverLetter = false;
    $scope.exportUrl = config.apiIP + "/applicant_profiles/export_to_pdf?ids=";
    $scope.lastStageViewed = 0;
    $scope.employeeHash = "";

    $scope.isCategoryChanged = false;
    $scope.allTemplates = [];
    $scope.generalTemplates = [];
    $scope.systemTemplates = [];
    $scope.offerTemplates = [];
    $scope.regretTemplates = [];
    $scope.infoReqTemplates = [];
    $scope.selectedTemplates = [];
    $scope.generalCount;
    $scope.offerCount;
    $scope.regretCount;
    $scope.archivedCount;

    $scope.checkPlaceholders = true;


    $scope.regretEmails = railApi.index('template_emails', 'template_email').data;


    railApi.index('template_emails', {is_deleted: true}).promise.then(function(data){$scope.archivedTemplates = data; $scope.archivedCount = data.length;});
    railApi.index('template_emails', {is_deleted: false}).promise.then(function(data){
        data.forEach(function(template){
            if (template.email_type == "general") {
                $scope.generalTemplates.push(template)
            }
            if (template.email_type == "offer") {
                $scope.offerTemplates.push(template)
            }
            if (template.email_type == "RejectionEmailtype") {
                $scope.regretTemplates.push(template)
            }
            if (template.email_type == "informationRequest") {
                $scope.infoReqTemplates.push(template)
            }
            if (template.is_system_default) {
                $scope.systemTemplates.push(template);
            }
            $scope.allTemplates.push(template)
        })
        $scope.offerCount = $scope.offerTemplates.length;
        $scope.regretCount = $scope.regretTemplates.length;
        $scope.generalCount = $scope.generalTemplates.length;
        $scope.selectedTemplates = $scope.generalTemplates;

    })


    $scope.getCandidateHistory = function getCandidateHistory (applicant_id, applicant_profile) {
        if (applicant_profile.is_deleted != true) {

            $scope.candidate = railApi.get('applicants/' + applicant_id)
            .promise
            .then(function(data) {
                $scope.getTotalNotes(data, applicant_profile);
                $scope.getTotalEmails(data, applicant_profile);
                $scope.getTotalOfferLetters(data)
                $scope.totalAppliendJobs = data.applicant_profiles.length
            });

            $scope.currentTags = applicant_profile.applicant.tags;
        }
     };

     $scope.getTotalNotes = function(candidate, applicant_profile) {
        if (applicant_profile.is_deleted != true) {
            $scope.totalNotes = 0;
            $scope.candidateNotes = _.map(candidate.applicant_profiles, 'notes');

            // Add length of all note lists to the total, excluding notes from current job
            for (var i = 0; i < $scope.candidateNotes.length; i++) {
                $scope.totalNotes += $scope.candidateNotes[i].length;
            }

            $scope.totalNotes -= applicant_profile.notes.length;
            $scope.getDeletedForms(applicant_profile)
        }
    };

    $scope.getDeletedForms = function(applicant_profile){
         var appl_question_ids = []
         var form_question_ids = []
         $scope.jobForms = _.filter($scope.job.forms,  function(e){ return e.privacy_status != 'Private'; }); // this resets the jobForms from default before adding the missing ones that are removed
         railApi.get('applicant_profiles/' + applicant_profile.id).promise.then(function(data){
             data.answers.forEach(function(e){ //this code extracts all the question IDs that exist on an applicant's profile
                 appl_question_ids.push(e.question_id)
             })
             data.text_answers.forEach(function(z){
                 appl_question_ids.push(z.question_id)
             })

             $scope.jobForms.forEach(function(y){ // this code extraacts all the question IDs on the currect forms that the job has
                 y.questions.forEach(function(a){
                     form_question_ids.push(a.id)
                 })
             })

             let difference = _.difference(appl_question_ids, form_question_ids) // this code removes all the similar IDs on both arrays and only leaves the different ones.

             difference.forEach(function(y){ //this code adds the removed jobForms that are previously filled by the applicant.
                 railApi.get('questions/' + y).promise.then(function(data){
                     railApi.get('forms/' + data.form_id, {privacy_status: "Public"}).promise.then(function(data){
                         if($scope.jobForms.some(function(e){return e.id === data.id})){
                             //id exist in jobForms so do not add
                         }else{
                             //id doesnt exist in jobForms
                             data.is_deleted = true;
                             $scope.jobForms.push(data)
                             $scope.jobForms = _.filter($scope.jobForms, function(e){ return e.privacy_status != 'Private'; })
                         }
                     })
                 })
             })
         })
    };

   $scope.getTotalEmails = function(candidate, applicant_profile) {
       $scope.totalEmails = 0;
       $scope.candidateEmails = _.map(candidate.applicant_profiles, 'hgemails');

       // Add length of all note lists to the total, excluding notes from current job
       for (var i = 0; i < $scope.candidateEmails.length; i++) {
           $scope.totalEmails += $scope.candidateEmails[i].length;
       }

       $scope.totalEmails -= applicant_profile.hgemails.length;
   };

   $scope.goToCandidateHistory = function goToCandidateHistory () {
       location.href = "#/applicant/" + $scope.applicant_profile.applicant.id;
   };

   $scope.getTotalOfferLetters = function(candidate){
        $scope.totalOffer = 0
        $scope.offerLettersList = _.map(candidate.applicant_profiles, 'offer_letters')

        for (var i = 0; i < $scope.offerLettersList.length; i++) {
           $scope.totalOffer += $scope.offerLettersList[i].length;
       }
   }


$scope.searchInJobForm = {
    "keywords": {
        "query_must": "",
    },
    "mark_future_consideration": false,
    "recruiter_apply": false,
    "black_flag": false,
    "is_internal": false,
    "job_id": $stateParams.job_id,
    "limit": 20,
    "offset": 0,
    "stage_id": 1,
    "referral": false,
    "applicationDate": {
    "start": null,
    "end": null
    },
    "milestones": {
    "offered": false,
    "presented": false,
    "hired": false,
    "interviewed": false,
    "regretted": false
    },
    "current_employee": false,
    "previous_employee": false,
    "blackFlag": false,
    "tags": []

}

    $scope.searchToggles = {
        "field": "all",
        "anyTags": "all",
        "anyStage": "any",
        "selectKeywords": "all"

    }


    $scope.searchFormClone = {
        "keywords": {
            "query_must": "",
        },
        "mark_future_consideration": false,
        "recruiter_apply": false,
        "black_flag": false,
        "is_internal": false,
        "job_id": $stateParams.job_id,
        "limit": 20,
        "offset": 0,
        "stage_id": 1,
        "referral": false,
        "applicationDate": {
            "start": null,
            "end": null
        },
        "milestones": {
            "offered": false,
            "presented": false,
            "hired": false,
            "interviewed": false,
            "regretted": false
        },
        "current_employee": false,
        "previous_employee": false,
        "blackFlag": false,
        "tags": []
        }
    // searchInJobForm.anyTags = 'all'; searchInJobForm.anyStage = 'any'; searchInJobForm.keywords.field = 'all';
    /**
     * Notes editing
     *
     */
    var noteEditListener = $rootScope.$on('notesCtrl.note_edited', function(event, data){
        console.log("Note edited:"+data.note.id+","+data.applicant.id);
    });
    $scope.$on('$destroy', noteEditListener);

    // needs to be implemented for export to PDF
    $scope.ipAddress = config.apiIP;

    $scope.attachFilesIds = [];
    $scope.deviceInfo = deviceDetector;

    $scope.getAttachFileIds = function (files) {
        $scope.numUploadedFiles = false;
        $scope.attachFilesIds = _.map(files, function(n){
            return n.id;
        });
    };

    $scope.setLastStageViewed = function (stage) {
        $scope.lastStageViewed = stage;
    }

    var locationPath = $location.path();

    $scope.windowHeight = $(document).height();
    $scope.divCss = {width:'100%',height:'1200px',padding: '0 0 50px 0'};

    $scope.$watch($scope.job, function (newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
            $scope.job = newValue;
            $scope.allIds = railApi.index('applicant_profiles', {
                job_id: $scope.job.id,
                limit: 5000
            }).data;
        }
    }, true);

    // # https://stackoverflow.com/questions/21292114/external-resource-not-being-loaded-by-angularjs
    $scope.formattedViewURL = function (files) {
        var resumeFile = _.find(files, function(file){
            if(file.file_type == "formatted_resume" || (file.file_type == "resume" && file.original_file_name.includes(".pdf"))){
                return true;
            }
        });
        if (resumeFile === undefined) return false;
        var url = $scope.apiUrl + "/applicant_profiles/" + $scope.applicant_profile.id + "/display_formatted_resume/"+ $scope.applicant_profile.applicant.name +"#zoom=90&view=Fit";
        return $sce.trustAsResourceUrl(url)
    };

    $scope.formattedCoverLetterViewURL = function (files) {
        var resumeFile = _.find(files, function(file){

            if(file.file_type == "formatted_cover_letter" || (file.file_type == "cover_letter" && file.original_file_name.includes(".pdf"))){
                return true
            }
        });
        if (resumeFile === undefined) return false;
        var url = $scope.apiUrl + "/applicant_profiles/" + $scope.applicant_profile.id + "/display_formatted_cover_letter#zoom=90&view=Fit";
        //var data = downloadPDF( )
        return $sce.trustAsResourceUrl(url)
    };
    /*
    $scope.formattedCoverLetterViewURL = function (files) {
        var coverLetterFile = _.find(files, {file_type: "formatted_cover_letter"});
        if (coverLetterFile === undefined) return false;
        return $sce.trustAsResourceUrl($scope.apiUrl + "/applicant_profiles/" + $scope.applicant_profile.id + "/display_formatted_cover_letter")
    };*/

    $scope.ChangeFormatView = function ChangeFormatView (val) {
        $scope.showFormattedView = val;
    };

    $scope.checkCoverLetter = function(inst) {
        if (inst){
                if (inst['file_type'] == 'cover_letter') {
                $scope.hasCoverLetter = true;
            }
        }else{
            $scope.hasCoverLetter = false;
        }
    };

    $scope.coverLetterExists = function coverLetter(applicantId) {
        $scope.hasCoverLetter = false;
        $scope.coverLetters = function () {
            $scope.something = '';
            var deferred = $q.defer();
            $scope.something = railApi.get('applicant_profiles/'+ applicantId, {file_type : 'cover_letter' }).data;
            deferred.resolve($scope.something);
            return deferred.promise;
        };
    };

    $scope.ChangeFormatCoverLetterView = function ChangeFormatCoverLetterView (val) {
        $scope.showFormattedCoverLetterView = val;
    };

    $scope.parseQuestionTitle = function(questionObj){
        return JSON.parse(questionObj)
    }

    $scope.generateLogs = function(applicant_profile){
        $scope.allLogs = [];
        $scope.allLogs = $scope.allLogs.concat(applicant_profile.applicant_profile_logs);
        $scope.allLogs = $scope.allLogs.concat(applicant_profile.applicant.applicant_logs);
        if($scope.allLogs[$scope.allLogs.length - 1] == undefined){
            $scope.allLogs.pop();
        }
    }


    $scope.rejectionReasons = railApi.index('rejection_reasons', {is_deleted: 'false'}).data;
    $scope.bulkTags = railApi.index('tags', {is_viewable: 'true'}).data;

    // railApi.index('tags', {is_viewable: 'true'}).promise.then(function(tagData) {
    //     $scope.bulkTags = tagData;
    // });
    $scope.$watch('applicant_profile', function (newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          if ($scope.applicant_profile['id'] && $scope.tagIt == false) {

            $scope.generateLogs($scope.applicant_profile);

            $scope.checkCoverLetter(_.find($scope.applicant_profile.uploaded_files, { file_type: 'formatted_cover_letter' }) || _.find($scope.applicant_profile.uploaded_files, { file_type: 'cover_letter', mimetype: 'application/pdf' }))
            $scope.ChangeFormatCoverLetterView($scope.hasCoverLetter)

            railApi.index('tags', {is_viewable: 'true'}).promise.then(function(tagData) {

                $scope.bulkTags = tagData;
                $scope.availableTags = tagData;

                $scope.currentTags = $scope.applicant_profile.applicant.tags

                $scope.currentTags.forEach(function(tag) {
                    $scope.availableTags.forEach(function(availableTag){
                        if (_.isEqual(tag['id'], availableTag['id'])) {
                            var index = _.findIndex($scope.availableTags, {id: tag['id']});
                            $scope.availableTags.splice(index, 1);
                        }
                    });
                });
            // $scope.availableTags = $scope.bulkTags;
            // $scope.applicant_profile.applicant.tags.forEach(function(tag) {
            //   $scope.availableTags.forEach(function(availableTag){
            //     if (_.isEqual(tag['id'], availableTag['id'])) {
            //       var index = _.findIndex($scope.availableTags, {id: tag['id']});
            //       $scope.availableTags = _.without($scope.availableTags, tag);
            //       $scope.availableTags.splice(index, 1);
            //     }
            //   });
            });





            // $scope.availableTags = $scope.bulkTags;
            // $scope.applicant_profile.applicant.tags.forEach(function(tag) {
            //   $scope.availableTags.forEach(function(availableTag){
            //     if (_.isEqual(tag['id'], availableTag['id'])) {
            //       var index = _.findIndex($scope.availableTags, {id: tag['id']});
            //       // $scope.availableTags = _.without($scope.availableTags, tag);
            //       $scope.availableTags.splice(index, 1);
            //     }
            //   });
            // });
            // alert("bulk: " + $scope.bulkTags.length + " candidate: " + $scope.applicant_profile['id'] + " have avail tags: " + $scope.availableTags.length);
          }
            if($scope.applicant_profile.applicant == undefined){
                $scope.employeeStatusString = null;
            }else{
                if($scope.applicant_profile.applicant.current_employee) {
                    $scope.employeeStatusString = 'current';
                }
                else if ($scope.applicant_profile.applicant.previous_employee) {
                $scope.employeeStatusString = 'previous'
                }
                else {
                $scope.employeeStatusString = 'none'
                }
            }
        }
    }, true);

    jobService.getJob($stateParams.job_id, {page: 'application-management'})
        .then(function(job){
            // Neo filter job with only public privacy forms. Old: $scope.job = job;
            $scope.job = job;
            $scope.jobForms = _.filter(job.forms,  function(e){ return e.privacy_status != 'Private'; });
            $scope.workflow = job.workflow;
            $scope.docFiles = _.filter(job.uploaded_files, { 'file_type': 'job_attachment' });
            $scope.interviewFiles = _.filter(job.uploaded_files, { 'file_type': 'interview_attachment' });
        })
        .catch(function(error){
            console.log("Unable to get job, details:"+error);
        });



    $scope.$watch('job', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            if($scope.initialLoad) {
                $scope.initialLoad = false;
                $scope.workflow = $scope.job.workflow;
                $scope.showStage = ( ($rootScope.hgUserAuth.isInRoles(['observer', 'hiring_manager', 'recruiter', 'recruiter_admin', 'super_admin']) ) || $rootScope.hgUserAuth.isInRoles(['decision_maker']) );

                _.forEach($scope.workflow.stages, function (stage, key) {
                    if ($scope.showStage) {
                        var findStageId = _($scope.job.workloads).find({stage_id: stage.id});
                        $scope.total = (findStageId) ? findStageId.applicants_count : 0;
                    } else {
                        $scope.total = 0;
                    }
                    $scope.totalApplications[key] = {id: stage.id, total: $scope.total};
                });

                if ($scope.job.id) {
                    $scope.job.workflow.stages = _.sortByAll($scope.job.workflow.stages, 'order');
                    $scope.workflow = $scope.job.workflow;
                    $scope.selectedTab = $scope.getSelectedTab($scope.applicant_profile, $scope.job, $scope.workflow.stages);
                }

                if (null != $scope.job.end_date && ($scope.job.end_date > moment())) {
                    $scope.allowArchive = false;
                } else {
                    (null != $scope.job.internal_end_date && $scope.job.internal_end_date > moment())?
                        $scope.allowArchive = false:
                        $scope.allowArchive = true
                }

                _.forEach($scope.workflow.stages, function(tab) {
                    tab.disabled = false;
                    if ($rootScope.hgUserData.role === "decision_maker" && tab.dm_stage === false) {
                        tab.disabled = true;
                    }
                });

            }
        }
    }, true); // end $watch

    $scope.changeShowTabs = function(val){
        // if (val == false) {
            railApi.index('tags', {is_viewable: 'true'}).promise.then(function(tagData) {

                $scope.bulkTags = tagData;
                $scope.availableTags = tagData;

                // $scope.currentTags = $scope.applicant_profile.applicant.tags
                // $scope.currentTags.forEach(function(tag) {
                //     $scope.availableTags.forEach(function(availableTag){
                //         if (_.isEqual(tag['id'], availableTag['id'])) {
                //             var index = _.findIndex($scope.availableTags, {id: tag['id']});
                //             $scope.availableTags.splice(index, 1);
                //         }
                //     });
                // });
            });




        // }
        $scope.showTabs = val;
    };

    // watch applications
    if ('applicant/jobProfile' != locationPath.substring(1, 21)){
        $scope.$watch('applications', function (newValue, oldValue) {
            if (newValue !== oldValue) {
               if(!$scope.showTabs){ // setFirstApplicant
                   if (false === $scope.stageChange &&
                       null != $scope.applications.applicant_profiles &&
                       null != $scope.applications.applicant_profiles[0] &&
                       (null === $stateParams.applicant_profile ||
                       -1 === _.findIndex($scope.applications.applicant_profiles, {id: $stateParams.applicant_profile_id}))
                   ) {
                       $state.transitionTo('jobApplication.profile', {job_id: $stateParams.job_id, applicant_profile_id: $scope.applications.applicant_profiles[0].id});
                   }
               }
           }
        }, true);
    }


    $scope.$watch('applicant_profile', function(newValue, oldValue) {
        if(newValue !== oldValue) {
            if (null !== $rootScope.clientSettings && null !== $rootScope.clientSettings.view_resume && $scope.applicant_profile && _.find($scope.applicant_profile.uploaded_files, { file_type: 'resume' })) {
                $scope.viewResume = $rootScope.clientSettings.view_resume;
            } else {
                $scope.viewResume = false;
            }
        }

    }, true);


    $scope.getSelectedTab = function (applicant_profile, job, stages) {
        var selectedTab;
        var stagesWorkload;
        var order = -1;

        /*if ($stateParams.stageId != "" && $stateParams.jobId != "") {
            selectedTab = $stateParams.stageId;

            return selectedTab;
        }*/

        if (applicant_profile && applicant_profile[0]) {
            selectedTab = applicant_profile[0].stage_id;
        } else {
            stagesWorkload = _(job.workloads).filter('applicants_count').sortBy('workload_order');
            if (stagesWorkload.first()) {
                selectedTab = stagesWorkload.first().stage_id;

                if (!(($rootScope.hgUserAuth.isInRoles(['observer', 'hiring_manager', 'recruiter', 'recruiter_admin', 'super_admin'])) || (true === _.find(stages, {id: selectedTab}).dm_stage && $rootScope.hgUserAuth.isInRoles(['decision_maker'])))) {
                    selectedTab = '';

                    job.workloads.forEach(function (workload) {
                        if (0 < workload.applicants_count &&
                            (order > workload.stage.order || -1 === order) &&
                            true === _.find(stages, {id: workload.stage_id}).dm_stage
                        ) {
                            order = workload.stage.order;
                            selectedTab = workload.stage_id;
                        }
                    });
                }

            }

        }

        return selectedTab;
    };

    // watch selectedTab
    $scope.$watch('selectedTab', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            if ($scope.initializingQuery &&
                ($rootScope.hgUserAuth.isInRoles(['observer', 'hiring_manager', 'recruiter', 'recruiter_admin', 'super_admin']) ||
                true === _.find($scope.workflow.stages, {id: $scope.selectedTab}).dm_stage &&
                $rootScope.hgUserAuth.isInRoles(['decision_maker']))) {

                $scope.initializingQuery = false;
                $scope.loadStageApplicationsFx($scope.selectedTab);
                $scope.applicationsQuery = railApi.index('applicant_profiles', {
                //    fields: 'id profileId userId applicationMethodId isInternal log regretLetterReceived rejectionId',
                    order: $scope.sortBy,
                    limit: $scope.limit,
                    job_id: $stateParams.job_id,
                    stage_id: $scope.selectedTab
                //    populate: {
                //        userId: 'name blackFlag',
                //        applicationMethodId: 'name',
                //        rejectionId: true
                //    }
                });
                $scope.applicationsNextQuery = railApi.index('applicant_profiles', {
                //    fields: 'id profileId userId applicationMethodId isInternal log regretLetterReceived rejectionId',
                    order: $scope.sortBy,
                    limit: $scope.limit,
                    offset: $scope.limit,
                    job_id: $stateParams.job_id,
                    stage_id: $scope.selectedTab

                //    populate: {
                //        userId: 'name',
                //        applicationMethodId: 'name',
                //        rejectionId: true
                //    }
                });
                //
                $scope.applications = $scope.applicationsQuery.data;
                $scope.applicationsNext = $scope.applicationsNextQuery.data;
                //$scope.getApplicantProfiles();
            }
        }
    }, true);

    // watch closedUpdate
    $scope.$watch('closedUpdate', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            if (null != $scope.closedUpdate.data &&
                null != $scope.closedUpdate.data.name &&
                true === $scope.job.closed &&
                (null != $scope.job.emailTemplateId && '000000000000000000defa17' !== $scope.job.emailTemplateId) &&
                ((hgUserAuth.isInRoles(['hiring_manager', 'recruiter', 'recruiter_admin', 'super_admin'])) || (true === _.find($scope.workflow.stages, {id: $scope.selectedTab}).dm_stage && hgUserAuth.isInRoles(['decision_maker'])))) {

                $scope.applicationsQuery = railApi.index('applicant_profiles', {
                    order: 'ranking',
                    limit: $scope.limit,
                    offset: ($scope.applicationsPage - 1) * $scope.limit,
                    job_id: $stateParams.job_id,
                    stage_id: $scope.selectedTab
                });
                $scope.applications = $scope.applicationsQuery.data;
                $scope.applicationsNextQuery = railApi.index('applicant_profiles', {
                    order: 'ranking',
                    limit: $scope.limit,
                    offset: $scope.applicationsPage * $scope.limit,
                    job_id: $stateParams.job_id,
                    stage_id: $scope.selectedTab
                });
                $scope.applicationsNext = applicationsNextQuery.data;
            }
        }
    });


    $scope.loadStageApplicationsFx = function (stage_id) {
        $scope.applicationsPage = 1;

        // Neo: Update current stageId to URL parameters. Sukhjeet: this line of code removed because of task id: 7338
        //$stateParams['stageId'] = stageId;

        if ($scope.showStage) {
            $scope.selectedTab = stage_id;
            $scope.applicationsQuery = railApi.index('applicant_profiles', {
                limit: $scope.limit,
                order: $scope.sortBy,
                job_id: $stateParams.job_id,
                stage_id: stage_id
            });

            $scope.applicationsNextQuery = railApi.index('applicant_profiles', {
                limit: $scope.limit,
                offset: $scope.limit,
                order: $scope.sortBy,
                job_id: $stateParams.job_id,
                stage_id: stage_id
            });
            $scope.applications = $scope.applicationsQuery.data;
            $scope.applicationsNext = $scope.applicationsNextQuery.data;
        }
    };

    $scope.retrieveBlackFlagId = function (){
        _.forEach($scope.rejectionReasons, function(reason) {
            if(reason.name.en == 'Do-Not-Hire') {
                return reason.id;
            }
        });
    }

    $scope.moveApplicantFx = function (applicant_profile, stage_id, rejection_reason_id, black_flag) {
        var prevStageId = applicant_profile.stage_id;
        var idPreviusApplicantProfile;
        var indexStage;
        var showStage = ($rootScope.hgUserAuth.isInRoles(['hiring_manager', 'recruiter', 'recruiter_admin', 'super_admin'])) || (true === _.find($scope.workflow.stages, {id: stage_id}).dm_stage && $rootScope.hgUserAuth.isInRoles(['decision_maker']));

        $scope.maxResults -= 1;
        idPreviusApplicantProfile = $scope.idPreviusApplicantProfile;

        applicant_profile.stage_id = stage_id;
        $scope.movedToStageId = stage_id;

        if (null == rejection_reason_id) {
            $scope.result = railApi.put('applicant_profiles/' + applicant_profile.id + '/move_to_stage/' + stage_id, {id: applicant_profile.id, stage_id: stage_id}, 'applicant_profile');
        }else if(black_flag == true){
            var path = 'applicants/' + $scope.applicant_profile.applicant.id + '/black_flag';
            $scope.result = railApi.put(path, {id: $scope.applicant_profile.applicant.id}, 'applicant');
        } else {
            applicant_profile.rejection_reason_id = rejection_reason_id;
            $scope.result = railApi.put('applicant_profiles/' + applicant_profile.id + '/reject/' + rejection_reason_id, {id: applicant_profile.id, rejection_reason_id: rejection_reason_id}, 'applicant_profile');
        }
        var index = _($scope.applications.applicant_profiles).findIndex({id: applicant_profile.id});
        $scope.applicantProfileIndex = index;
        if (null != $scope.applicationsNext.applicant_profiles[0]) {
            if (idPreviusApplicantProfile !== $scope.applicationsNext.applicant_profiles[0].id) {
                $scope.applications.applicant_profiles.push($scope.applicationsNext.applicant_profiles[0]);
            }
            $scope.applicationsNext.applicant_profiles.splice(0, 1);
        }

        $scope.idPreviusApplicantProfile = applicant_profile.id;

        var prevStageIdIndex = _($scope.totalApplications).findIndex({id: prevStageId});
        $scope.totalApplications[prevStageIdIndex].total = $scope.totalApplications[prevStageIdIndex].total - 1;
        if (showStage) {
            indexStage = _($scope.totalApplications).findIndex({id: stage_id});
            if (null != $scope.totalApplications[indexStage] &&
                null != $scope.totalApplications[indexStage].total
            ) {
                $scope.totalApplications[indexStage].total = $scope.totalApplications[indexStage].total + 1;
            } else {
                if (null == $scope.totalApplications[indexStage]) {
                    $scope.totalApplications[indexStage] = {};
                }

                $scope.totalApplications[indexStage].total = 1;
            }
        }

        $scope.applications.applicant_profiles.splice(index, 1);

        if (null == $scope.applicationsNext.applicant_profiles[0]) {
            $scope.applicationsNextQuery.query.offset = ($scope.applicationsPage + 1) * $scope.applicationsNextQuery.query.limit;
            $scope.applicationsNext = railApi.index('applicant_profiles', $scope.applicationsNextQuery.query).data;
        }
    }; // Move applicantFx end

    $scope.reloadThisPage = function(){
        window.location.reload();
    }

    $scope.setInitialStage = function () {
        var isActive = true;
        if ($rootScope.hgUserData.role != "decision_maker") {
            _.forEach($scope.workflow.stages, function(tab) {
                if (isActive) {
                    if (tab.totalPerStage > 0) {
                        tab.active = true;
                        isActive = false;
                    }
                }
            });
        }
        else {
            _.forEach($scope.workflow.stages, function(tab) {
                if (isActive) {
                    if (tab.totalPerStage > 0 && tab.dm_stage === true) {
                        tab.active = true;
                        isActive = false;
                    }
                }
            });
        }
        if (isActive === true) {
            _.forEach($scope.workflow.stages, function(tab) {
                tab.active = false;
            });
        }
    }

    $scope.$watch('result', function(newVal, oldVal){
        if(newVal !== oldVal && newVal.pending === false) {
            if (0 === $scope.maxResults) {
                $state.go('jobApplication', {job_id: $stateParams.job_id});
                $scope.showTabs = true;
                if ($scope.showStage) {
                    // $scope.selectedTab = $scope.movedToStageId;
                    $scope.initializingQuery = true;
                    //this.loadStageApplications(scope, parent, stageId, showStage);
                } else {
                    $scope.selectedTab = null;
                }
            } else {
                if (null != $scope.applications.applicant_profiles[$scope.applicantProfileIndex]) {
                    $state.go('jobApplication.profile', {
                        job_id: $stateParams.job_id,
                        stage_id: $stateParams.stage_id,
                        applicant_profile_id: $scope.applications.applicant_profiles[$scope.applicantProfileIndex].id
                    });
                } else {
                    if (null != $scope.applications.applicant_profiles[$scope.applicantProfileIndex - 1] && null != $scope.applications.applicant_profiles[$scope.applicantProfileIndex - 1].id) {
                        $state.go('jobApplication.profile', {
                            job_id: $stateParams.job_id,
                            stage_id: $stateParams.stage_id,
                            applicant_profile_id: $scope.applications.applicant_profiles[$scope.applicantProfileIndex - 1].id
                        });
                    } else {
                        $scope.stageChange = false;
                    }
                }
            }
        }
    }, true);

    $scope.blackFlag = function (applicant_id) {
        var path = 'applicants/' + applicant_id + '/black_flag';
        $scope.result = railApi.put(path, {id: applicant_id}, 'applicant');
        $scope.$watch('result', function (newVal, oldVal) {
            if(newVal !== oldVal && newVal.pending === false) {
                    $state.reload('jobApplication', {
                        job_id: $scope.job.id
                    });
                }
        }, true)
    };

    $scope.sendRegretLetterFx = function (applicant_profile, emailTemplate, replyable) {
      if(emailTemplate.files) {
        $scope.file_upload = [];
        var promises = [];
        var files = emailTemplate.files;
        var fileCount = 0;
        // $scope.errFiles = error;
        $scope.errorFile = false;
        var data = {
            file_type: 'attachment',
            employee_id: $rootScope.hgUserData.id
        };

        for (var i = 0; i < files.length; i+=1) {
            var file = files[i];
            if(!file.$error) {
                var response = Upload.upload({
                    url: config.apiIP + 'applicant_profiles/upload_file',
                    file: file,
                    fields: data
                }).progress(function (evt) {
                    file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                }).success(function (data, status, headers, config) {
                    // completed, data contains the parsed resume and fileModel
                    data.file_info['employee'] = $rootScope.hgUserData;
                    $scope.fileModel = data.file_info;
                    $scope.attachFilesIds.push($scope.fileModel.id);

                    $scope.file_upload.push(data.file_info);

                }).error(function(data, status, headers, config) {
                    $scope.errorMsg = status + ': ' + data.errors[0].message;
                    $scope.errorFile = true;
                }).then(function (success, error) {
                  if(success) {
                    fileCount = fileCount + 1;
                    if (fileCount === files.length) {
                        $rootScope.regretLetterReceived = railApi.post('send_hgemail/send_emails', {
                            t_uid: applicant_profile.applicant.email,
                            f_uid: $rootScope.hgUserData.email,
                            subject: emailTemplate.subject[$rootScope.currentLang],
                            message: emailTemplate.body[$rootScope.currentLang],
                            applicant_profile_id: applicant_profile.id,
                            employee_id: $rootScope.hgUserData.id,
                            cc: emailTemplate.cc,
                            email_template_id: emailTemplate.id,
                            file_upload: $scope.file_upload,
                            replyable: replyable
                        }, 'send_hgemail');
                        $state.reload('jobApplication', {
                            job_id: $scope.job.id
                        });
                    }
                    else {
                        
                    }
                  }
                  else {

                  }
                });
                promises.push(response);
            }
          }
      }
      else {

        $rootScope.regretLetterReceived = railApi.post('send_hgemail/send_emails', {
            t_uid: applicant_profile.applicant.email,
            f_uid: $rootScope.hgUserData.email,
            subject: emailTemplate.subject[$rootScope.currentLang],
            message: emailTemplate.body[$rootScope.currentLang],
            applicant_profile_id: applicant_profile.id,
            employee_id: $rootScope.hgUserData.id,
            cc: emailTemplate.cc,
            email_template_id: emailTemplate.id,
            replyable: replyable
        }, 'send_hgemail');
        $state.reload('jobApplication', {
            job_id: $scope.job.id
          });
        }
    };

    $scope.sendActualRegretLetter = function (applicant_profile, emailTemplate, replyable) {
      if(emailTemplate.files) {
        $scope.file_upload = [];
        var promises = [];
        var files = emailTemplate.files;
        var fileCount = 0;
        // $scope.errFiles = error;
        $scope.errorFile = false;
        var data = {
            file_type: 'attachment',
            employee_id: $rootScope.hgUserData.id
        };

        for (var i = 0; i < files.length; i+=1) {
            var file = files[i];
            if(!file.$error) {
                var response = Upload.upload({
                    url: config.apiIP + 'applicant_profiles/upload_file',
                    file: file,
                    fields: data
                }).progress(function (evt) {
                    file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                }).success(function (data, status, headers, config) {
                    // completed, data contains the parsed resume and fileModel
                    data.file_info['employee'] = $rootScope.hgUserData;
                    $scope.fileModel = data.file_info;
                    $scope.attachFilesIds.push($scope.fileModel.id);

                    $scope.file_upload.push(data.file_info);

                }).error(function(data, status, headers, config) {
                    $scope.errorMsg = status + ': ' + data.errors[0].message;
                    $scope.errorFile = true;
                }).then(function (success, error) {
                  if(success) {
                    fileCount = fileCount + 1;
                    if (fileCount === files.length) {
                        $rootScope.regretLetterReceived = railApi.post('send_hgemail/send_regret_emails', {
                            t_uid: applicant_profile.applicant.email,
                            f_uid: $rootScope.hgUserData.email,
                            subject: emailTemplate.subject[$rootScope.currentLang],
                            message: emailTemplate.body[$rootScope.currentLang],
                            applicant_profile_id: applicant_profile.id,
                            employee_id: $rootScope.hgUserData.id,
                            cc: emailTemplate.cc,
                            email_template_id: emailTemplate.id,
                            file_upload: $scope.file_upload,
                            replyable: replyable
                        }, 'send_hgemail');
                        $state.reload('jobApplication', {
                            job_id: $scope.job.id
                        });
                    }
                    else {

                    }
                  }
                  else {

                  }
                });
                promises.push(response);
            }
          }
      }
      else {

        $rootScope.regretLetterReceived = railApi.post('send_hgemail/send_regret_emails', {
            t_uid: applicant_profile.applicant.email,
            f_uid: $rootScope.hgUserData.email,
            subject: emailTemplate.subject[$rootScope.currentLang],
            message: emailTemplate.body[$rootScope.currentLang],
            applicant_profile_id: applicant_profile.id,
            employee_id: $rootScope.hgUserData.id,
            cc: emailTemplate.cc,
            email_template_id: emailTemplate.id,
            replyable: replyable
        }, 'send_hgemail');
        $state.reload('jobApplication', {
            job_id: $scope.job.id
          });
        }
    };

    $scope.showModalReplyEmail = function (email) {
        $scope.regretEmails = railApi.index('template_emails', {email_type: 'RejectionEmailtype'}, 'template_email').data;
        $scope.employeeList = railApi.index('employees', {}, 'employee').data;
        $scope.ReplyEmail = railApi.get('hgemails/' + email.id).promise.then(function(data) {
          $scope.replyEmail = data;
          var somethinghere = '<br><br>On ' + email.created_at + ', <span dir="ltr"> <<a href="mailto: ' + email.f_uid + '" target="_blank" title="Link: mailto: ' + email.f_uid + '">' + email.f_uid + '</a>> wrote: <br></span>';
          $scope.replyEmail.message = somethinghere + data.message;
          var replySubject = 'RE: ';
          $scope.replyEmail.subject = replySubject + data.subject;
        });
        $scope.replyEmailModal = $scope.modal.open({
            templateUrl: 'replyEmailModal.ejs',
            scope: $scope,
            windowClass: 'modal-xl',
            backdrop: 'static'
        });
    };

    $scope.showModalAssignWatchStages = function () {
        $scope.assignWatchStagesModal = $scope.modal.open({
            templateUrl: 'assignWatchStagesModal.ejs',
            scope: $scope,
            size: 'lg'
        });
    };

    $scope.showModalJobExpiryNotifications = function () {
        $scope.assignJobExpiryNotificationsModal = $scope.modal.open({
            templateUrl: 'assignJobExpiryNotificationsModal.ejs',
            scope: $scope,
            size: 'lg'
        });
    };

    $scope.showModalRankCandidate = function (applicant_profile, ranking) {
        $scope.applicant_profile = applicant_profile;
        $scope.ranking = ranking;
        $scope.rankCandidateModal = $scope.modal.open({
            templateUrl: 'rankCandidateModal.ejs',
            scope: $scope,
            size: 'sm'
        });
    };

    $scope.showModalEmailJobPosting = function () {
        if($rootScope.currentLang == 'en') {
            $scope.emailBody = '<strong>Job ID#</strong> ' + $scope.job.id + ' <br><strong>Job Title:</strong> ' + $scope.job.name.en + '<br><strong>Date Posted:</strong> ' + $scope.job.start_date+ '<br><strong>Closing Date:</strong> ' + $scope.job.end_date + '<br><strong>Job Type:</strong> ' + $scope.job.job_type.name_en + '<br><strong>Display Category:</strong> ' + $scope.job.display_category[0].name.en + '<br><br><strong>Job Description:</strong> <br><br>' + $scope.job.description.en

        }
        if($rootScope.currentLang == 'fr') {
            $scope.emailBody = '<strong>Identifiant du poste#</strong> ' + $scope.job.id + ' <br><strong>Titre du poste:</strong> ' + $scope.job.name.fr + '<br><strong>Date de parution: ' + $scope.job.start_date+ '</strong> <br><strong>Date de clôture: </strong> ' + $scope.job.end_date + '<br><strong>Type d’emploi:</strong> ' + $scope.job.job_type.name_fr + '<br><strong>Affichez la catégorie:</strong> ' + $scope.job.display_category[0].name.fr + '<br><br><strong>Description de la position: </strong><br><br>' + $scope.job.description.fr

        }
        if($rootScope.currentLang == 'es') {
            $scope.emailBody = '<strong>Identificación del trabajo#</strong> ' + $scope.job.id + ' <br><strong>Título del trabajo:</strong> ' + $scope.job.name.es + '<br><strong>Fecha de publicación:</strong> ' + $scope.job.start_date+ '<br><strong>Fecha de cierre:</strong> ' + $scope.job.end_date + '<br><strong>Tipo de trabajo:</strong> ' + $scope.job.job_type.name_es + '<br><strong>Categoría de visualización:</strong> ' + $scope.job.display_category[0].name.es + '<br><br><strong>Descripción de trabajo:</strong><br><br>' + $scope.job.description.es

        }
            $scope.employeeList = railApi.index('employees', {}, 'employee').data;
            $scope.emailJobPostingModal = $scope.modal.open({
                templateUrl: 'emailJobPostingModal.ejs',
                scope: $scope,
                size: 'lg'
            });
    };


    $scope.validateEmail = function(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    $scope.emailJobPosting = function (emailTemplate) {
        $scope.validEmail = true;
        if(emailTemplate.externalUsers){
            //strip out spaces and change into string array
            emailTemplate.externalUsers = emailTemplate.externalUsers.split(' ').join('');
            var externalEmails = emailTemplate.externalUsers.split(',');
            externalEmails.forEach(function(value, key) {
                if(!$scope.validateEmail(value)) {
                    $scope.validEmail = false;
                };
            });
        }


        if(emailTemplate.externalUsers && !emailTemplate.systemUsers){
            emailTemplate.systemUsers = externalEmails;
        }
        else if(emailTemplate.externalUsers && emailTemplate.systemUsers){
            emailTemplate.systemUsers = externalEmails.concat(emailTemplate.systemUsers);
        }
        if(!emailTemplate.externalUsers && !emailTemplate.systemUsers){
            $scope.emailExists = false;
        }

        if($scope.validEmail && $scope.emailExists) {
             $rootScope.sendEmailJobPosting = railApi.post('send_hgemail/email_job_posting', {
                t_uid: emailTemplate.systemUsers,
                f_uid: $rootScope.hgUserData.email,
                subject: emailTemplate.subject[$rootScope.currentLang],
                message: emailTemplate.body,
                employee_id: $rootScope.hgUserData.id,
                email_template_id: emailTemplate.id,
                file_upload: $scope.file_upload
        }, "send_hgemail");
            $scope.emailJobPostingModal.dismiss();
        }
    }

    $scope.parseEmailToObject = function(emailString) {
        if (typeof emailString === 'string' || emailString instanceof String) {
            $scope.sendEmail = JSON.parse(emailString)

      
        }
        else {
            $scope.sendEmail = emailString

        }
        if( $scope.sendEmail){
            if( $scope.sendEmail.custom_placeholder.length > 0) {
                $scope.loadCustomPlaceholderForm($scope.sendEmail, 'new', 'load');

            }
        }

    }


    //////////////////// SEND EMAIL SEARCH ///////////////////////////////////

    $scope.showModalSendEmail = function () {
        // NOTE : need to check if there is a template selected
        // if (scope.templateToShow) {
            $scope.regretEmails = railApi.index('template_emails', 'template_email').data;
            $scope.applicant_profiles = railApi.index('applicant_profiles/bulk_candidates/' + $scope.job.id, {job_id: $scope.job.id}, 'applicant_profile').data;
            $scope.employeeList = railApi.index('employees', {}, 'employee').data;
            $scope.sendEmailModal = $scope.modal.open({
                templateUrl: 'sendBulkEmailModal.ejs',
                scope: $scope,
                windowClass: 'modal-xl',
                backdrop: 'static'
            });
        // }
    };


    $scope.confirmedSearchTemplates = function (selectedResult) {
        $scope.sendEmail = selectedResult;

        if (typeof $scope.sendEmail === 'string' || $scope.sendEmail instanceof String) {
            $scope.sendEmail = JSON.parse($scope.sendEmail)
           
        }
        $scope.searchResult = null;
        $scope.modalSearchTemplates = null;
        $scope.employeeList = railApi.index('employees', {}, 'employee').data;
        $scope.sendEmailModal = $scope.modal.open({
            templateUrl: 'sendEmailModal.ejs',
            scope: $scope,
            windowClass: 'modal-xl',
            backdrop: 'static',
            keyboard: false
          });
    };


    $scope.backToSendEmailModal = function () {
        $scope.searchResult = null;
        $scope.modalSearchTemplates = null;
        $scope.employeeList = railApi.index('employees', {}, 'employee').data;
        $scope.sendEmailModal = $scope.modal.open({
            templateUrl: 'sendEmailModal.ejs',
            scope: $scope,
            windowClass: 'modal-xl',
            backdrop: 'static',
            keyboard: false
          });

    }


    //////////////////// SEND BULK EMAIL SEARCH ///////////////////////////////////




    $scope.confirmedSearchBulkTemplates = function (selectedResult) {
        $scope.sendEmail = selectedResult;

        if (typeof $scope.sendEmail === 'string' || $scope.sendEmail instanceof String) {
            $scope.sendEmail = JSON.parse($scope.sendEmail)
           
        }
        $scope.searchResult = null;
        $scope.modalSearchTemplates = null;
        $scope.employeeList = railApi.index('employees', {}, 'employee').data;
        $scope.sendBulkEmailModal = $scope.modal.open({
            templateUrl: 'sendBulkEmailModal.ejs',
            scope: $scope,
            windowClass: 'modal-xl',
            backdrop: 'static',
            keyboard: false
          });
    };


    $scope.backToSendBulkEmailModal = function () {
        $scope.searchResult = null;
        $scope.modalSearchTemplates = null;
        $scope.employeeList = railApi.index('employees', {}, 'employee').data;
        $scope.sendBulkEmailModal = $scope.modal.open({
            templateUrl: 'sendBulkEmailModal.ejs',
            scope: $scope,
            windowClass: 'modal-xl',
            backdrop: 'static',
            keyboard: false
          });

    }


	
	
    $scope.showModalSearchBulkTemplates = function () {
            $scope.searchResult;
            railApi.index('template_emails', {is_deleted: false}).promise.then(function(data){
                $scope.modalSearchTemplates = data; $scope.modalSearchTemplatesCount = data.length;
            });
            $scope.searchBulkEmailTemplatesModal = $scope.modal.open({
                templateUrl: 'searchBulkEmailTemplatesModal.ejs',
                scope: $scope,
                windowClass: 'modal-xl'
            });
    };


    $scope.showModalBulkSendEmail = function () {
        // NOTE : need to check if there is a template selected
        // if (scope.templateToShow) {
            $scope.regretEmails = railApi.index('template_emails', 'template_email').data;
            $scope.applicant_profiles = railApi.index('applicant_profiles/bulk_candidates/' + $scope.job.id, {job_id: $scope.job.id}, 'applicant_profile').data;
            $scope.employeeList = railApi.index('employees', {}, 'employee').data;
            $scope.sendBulkEmailModal = $scope.modal.open({
                templateUrl: 'sendBulkEmailModal.ejs',
                scope: $scope,
                windowClass: 'modal-xl',
                backdrop: 'static'
            });
        // }
    };



    /////////////////////// END //////////////////////////////






    //////////////////// REGRET EMAIL SEARCH ///////////////////////////////////

    $scope.showModalSendRegretEmail = function () {
        // NOTE : need to check if there is a template selected
        // if (scope.templateToShow) {
            $scope.regretEmails = railApi.index('template_emails', 'template_email').data;
            $scope.applicant_profiles = railApi.index('applicant_profiles/bulk_candidates/' + $scope.job.id, {job_id: $scope.job.id}, 'applicant_profile').data;
            $scope.employeeList = railApi.index('employees', {}, 'employee').data;
            $scope.sendRegretEmailModal = $scope.modal.open({
                templateUrl: 'sendRegretEmailModal.ejs',
                scope: $scope,
                windowClass: 'modal-xl',
                backdrop: 'static'
            });
        // }
    };


    $scope.confirmedSearchRegretTemplates = function (selectedResult) {
        $scope.sendEmail = selectedResult;

        if (typeof $scope.sendEmail === 'string' || $scope.sendEmail instanceof String) {
            $scope.sendEmail = JSON.parse($scope.sendEmail)
           
        }
        $scope.searchResult = null;
        $scope.modalSearchTemplates = null;
        $scope.employeeList = railApi.index('employees', {}, 'employee').data;
        console.log($scope.sendEmail)
        $scope.sendRegretEmailModal = $scope.modal.open({
            templateUrl: 'sendRegretEmailModal.ejs',
            scope: $scope,
            windowClass: 'modal-xl',
            backdrop: 'static'
        });
    };


    $scope.backToSendRegretEmailModal = function () {
        $scope.searchResult = null;
        $scope.modalSearchTemplates = null;
        $scope.employeeList = railApi.index('employees', {}, 'employee').data;
        $scope.sendRegretEmailModal = $scope.modal.open({
            templateUrl: 'sendRegretEmailModal.ejs',
            scope: $scope,
            windowClass: 'modal-xl',
            backdrop: 'static'
        });

    }


    $scope.showModalSearchRegretTemplates = function () {
        $scope.searchResult;

        $scope.modalSearchTemplates = $scope.regretTemplates
        $scope.searchRegretEmailTemplatesModal = $scope.modal.open({
            templateUrl: 'searchRegretEmailTemplatesModal.ejs',
            scope: $scope,
            windowClass: 'modal-xl'
        });
    };

    /////////////////////// END //////////////////////////////


    //////////////////// BULK REGRET EMAIL SEARCH ///////////////////////////////////

    $scope.showModalSendBulkRegretEmail = function () {
        // NOTE : need to check if there is a template selected
        // if (scope.templateToShow) {
            $scope.regretEmails = railApi.index('template_emails', 'template_email').data;
            $scope.applicant_profiles = railApi.index('applicant_profiles/bulk_candidates_by_id/' + $scope.applicantIds, {ids: $scope.applicantIds}, 'applicant_profile').data;
            $scope.employeeList = railApi.index('employees', {}, 'employee').data;
            $scope.sendBulkRegretEmailModal = $scope.modal.open({
                templateUrl: 'sendBulkRegretEmailModal.ejs',
                scope: $scope,
                windowClass: 'modal-xl',
                backdrop: 'static'
            });
        // }
    };


    $scope.confirmedBulkRegretSearchTemplates = function (selectedResult) {
        $scope.sendEmail = selectedResult;

        if (typeof $scope.sendEmail === 'string' || $scope.sendEmail instanceof String) {
            $scope.sendEmail = JSON.parse($scope.sendEmail)
           
        }
        $scope.searchResult = null;
        $scope.modalSearchTemplates = null;
        $scope.employeeList = railApi.index('employees', {}, 'employee').data;
        $scope.sendBulkRegretEmailModal = $scope.modal.open({
            templateUrl: 'sendBulkRegretEmailModal.ejs',
            scope: $scope,
            windowClass: 'modal-xl',
            backdrop: 'static'
        });
    };


    $scope.backToSendBulkRegretEmailModal = function () {
        $scope.searchResult = null;
        $scope.modalSearchTemplates = null;
        $scope.employeeList = railApi.index('employees', {}, 'employee').data;
        $scope.sendBulkRegretEmailModal = $scope.modal.open({
            templateUrl: 'sendBulkRegretEmailModal.ejs',
            scope: $scope,
            windowClass: 'modal-xl',
            backdrop: 'static'
        });

    }


    $scope.showModalSearchBulkRegretTemplates = function () {
        $scope.searchResult;

        $scope.modalSearchTemplates = $scope.regretTemplates

        $scope.searchBulkRegretEmailTemplatesModal = $scope.modal.open({
            templateUrl: 'searchBulkRegretEmailTemplatesModal.ejs',
            scope: $scope,
            windowClass: 'modal-xl'
        });
    };

    /////////////////////// END //////////////////////////////

    $scope.loadCustomPlaceholderForm = function(sendEmail, type, from){
        $scope.sendEmail = sendEmail
        if (typeof sendEmail === 'string' || sendEmail instanceof String) {
            $scope.sendEmail = JSON.parse(sendEmail)  
        }
        else {
            $scope.sendEmail = sendEmail

        }
        
        $scope.customPlaceholders = sendEmail.custom_placeholder
        $scope.emailBody = sendEmail.body
        $scope.templateId = sendEmail.id
        $scope.sendEmail.approvers = []
        $scope.sendEmail.viewers = []

        if(from == 'modal') {
            $scope.checkPlaceholders = true;
        }


        if(type == 'new'){
            $scope.placeHolderVal = []
        }

        $scope.type = type
        if($scope.customPlaceholders){
            if($scope.customPlaceholders.length > 0  && $scope.checkPlaceholders){
                $scope.checkPlaceholders = false;

                $scope.customPlaceHolderModal = $scope.modal.open({
                    templateUrl: 'customPlaceHolderModal.ejs',
                    scope: $scope,
                    size: 'lg',
                    backdrop: 'static'
                });
            }
        }

    }

    //////////////////// SEND OFFER LETTER SEARCH ///////////////////////////////////

    $scope.showModalCreateOfferLetter = function () {

        $scope.employeeList = railApi.index('employees', {}, 'employee').data;
        $scope.regretEmails = railApi.index('template_emails', 'template_email').data;
        $scope.createOfferLetterModal = modal.open({
            templateUrl: 'createOfferLetterModal.ejs',
            scope: this,
            windowClass: 'modal-xl',
            backdrop: 'static'
        });

    };


    $scope.confirmedSearchOfferLetterTemplates = function (selectedResult) {
        $scope.sendEmail = selectedResult;

        if (typeof $scope.sendEmail === 'string' || $scope.sendEmail instanceof String) {
            $scope.sendEmail = JSON.parse($scope.sendEmail)
           
        }
        $scope.searchResult = null;
        $scope.modalSearchTemplates = null;
        $scope.employeeList = railApi.index('employees', {}, 'employee').data;
        $scope.createOfferLetterModal = $scope.modal.open({
            templateUrl: 'createOfferLetterModal.ejs',
            scope: $scope,
            windowClass: 'modal-xl',
            backdrop: 'static',
            keyboard: false
          });
    };


    $scope.backToCreateOfferLetterModal = function () {
        $scope.searchResult = null;
        $scope.modalSearchTemplates = null;
        $scope.employeeList = railApi.index('employees', {}, 'employee').data;
        $scope.createOfferLetterModal = $scope.modal.open({
            templateUrl: 'createOfferLetterModal.ejs',
            scope: $scope,
            windowClass: 'modal-xl',
            backdrop: 'static',
            keyboard: false
          });

    }
	
	
	   $scope.showModalSearchOfferLetterTemplates = function () {
        $scope.searchResult;
        railApi.index('template_emails', {is_deleted: false}).promise.then(function(data){
            $scope.modalSearchTemplates = data; $scope.modalSearchTemplatesCount = data.length;
        });
        $scope.searchOfferLetterTemplatesModal = $scope.modal.open({
            templateUrl: 'searchOfferLetterTemplatesModal.ejs',
            scope: $scope,
            windowClass: 'modal-xl'
        });
    };

    /////////////////////// END //////////////////////////////
    
    $scope.showModalSearchTemplates = function () {
        $scope.searchResult;
        railApi.index('template_emails', {is_deleted: false}).promise.then(function(data){
            $scope.modalSearchTemplates = data; $scope.modalSearchTemplatesCount = data.length;
        });
        $scope.searchEmailTemplatesModal = $scope.modal.open({
            templateUrl: 'searchEmailTemplatesModal.ejs',
            scope: $scope,
            windowClass: 'modal-xl'
        });
    };



    // $scope.showModalSendBulkRegretEmail = function () {
    //     // NOTE : need to check if there is a template selected
    //     // if (scope.templateToShow) {
    //         $scope.regretEmails = railApi.index('template_emails', 'template_email').data;
    //         $scope.applicant_profiles = railApi.index('applicant_profiles/bulk_candidates_by_id/' + $scope.applicantIds, {ids: $scope.applicantIds}, 'applicant_profile').data;
    //         $scope.employeeList = railApi.index('employees', {}, 'employee').data;
    //         $scope.sendBulkRegretEmailModal = $scope.modal.open({
    //             templateUrl: 'sendBulkRegretEmailModal.ejs',
    //             scope: $scope,
    //             windowClass: 'modal-xl',
    //             backdrop: 'static'
    //         });
    //     // }
    // };

    // $scope.showModalSendRegretEmail = function (applicant_profile) {
    //     // NOTE : need to check if there is a template selected
    //     // if (scope.templateToShow) {
    //         $scope.applicant_profile = applicant_profile;
    //         $scope.regretEmails = railApi.index('template_emails', 'template_email').data;
    //         $scope.employeeList = railApi.index('employees', {}, 'employee').data;
    //         $scope.sendRegretEmailModal = $scope.modal.open({
    //             templateUrl: 'sendRegretEmailModal.ejs',
    //             scope: $scope,
    //             windowClass: 'modal-xl',
    //             backdrop: 'static'
    //         });
    //     // }
    // };

    $scope.showModalRegretConfirmation = function (applicant_profile, sendEmail, replyable) {
      $scope.applicant_profile = applicant_profile;
      $scope.sendEmail = sendEmail;
      $scope.replyable = replyable;
            $scope.regretConfirmationModal = $scope.modal.open({
                templateUrl: 'regretConfirmationModal.ejs',
                scope: $scope,
                size: 'lg'
            });
        // }
    };

    $scope.showModalBulkRegretConfirmation = function (applicant_profiles, sendEmail, replyable) {
      $scope.applicant_profiles = applicant_profiles;
      $scope.sendEmail = sendEmail;
      $scope.replyable = replyable;
      $scope.recruiterAddedCandidates = _.filter(_.filter($scope.applicant_profiles, { 'regretted_date': null }), { 'applied_by_recruiter': true });
      $scope.normalCandidates = _.filter(_.filter($scope.applicant_profiles, { 'regretted_date': null }), { 'applied_by_recruiter': false });
      $scope.regrettedCandidates = _.filter(_.reject($scope.applicant_profiles, { 'regretted_date': null }), { 'applied_by_recruiter': false });
      $scope.regrettedAndRecruiterAddedCandidates = _.filter(_.reject($scope.applicant_profiles, { 'regretted_date': null }), { 'applied_by_recruiter': true });
            $scope.bulkRegretConfirmationModal = $scope.modal.open({
                templateUrl: 'regretBulkConfirmationModal.ejs',
                scope: $scope,
                size: 'lg'
            });
        // }
    };

    $scope.showModalRegretOnRejectionSingle = function (regretCandidate) {
      $scope.regretCandidate = regretCandidate;
            $scope.regretOnRejectionSingleModal = $scope.modal.open({
                templateUrl: 'regretOnRejectionSingleModal.ejs',
                scope: $scope,
                size: 'lg'
            });
        // }
    };

    $scope.showModalRegretOnRejectionMultiple = function () {
            $scope.regretOnRejectionMultipleModal = $scope.modal.open({
                templateUrl: 'regretOnRejectionMultipleModal.ejs',
                scope: $scope,
                size: 'lg'
            });
        // }
    };

    $scope.showModalCopyCandidates = function () {
        $scope.availableJobs = railApi.index('jobs/available_jobs_multiple_any/' + $scope.applicantIds, '', 'job').data;
        $scope.applicant_profiles = railApi.index('applicant_profiles/bulk_candidates/' + $scope.job.id, {job_id: $scope.job.id}, 'applicant_profile').data;
        $scope.copyToJobModal = $scope.modal.open({
            templateUrl: 'copyMultipleToJobModal.ejs',
            scope: $scope,
            size: 'lg'
        });
    };

    $scope.showModalCopyCandidatesConfirm = function (data, job, applicantIds) {
        $scope.duplicateApplicants = data;
        $scope.duplicateApplicantIds = [];
        $scope.copiedApplicantProfiles;
        $scope.duplicateApplicants.forEach(function(e){
            $scope.duplicateApplicantIds.push(e.id)
        })
        $scope.copiedApplicants = _.difference( applicantIds, $scope.duplicateApplicantIds);
        $scope.copiedToJob = job;

        if($scope.copiedApplicants.length) {
            $scope.copiedApplicantProfiles = railApi.index('applicant_profiles/bulk_candidates_by_id/' +  $scope.copiedApplicants, 'applicant_profile').data;
        }


        $scope.copyToJobModalConfirm = $scope.modal.open({
            templateUrl: 'copyMultipleToJobConfirmModal.ejs',
            scope: $scope,
            size: 'lg'
        });
    };


    $scope.showModalMoveCandidates = function () {
        $scope.applicant_profiles = railApi.index('applicant_profiles/bulk_candidates/' + $scope.job.id, {job_id: $scope.job.id}, 'applicant_profile').data;
        $scope.moveToStageModal = $scope.modal.open({
            templateUrl: 'moveToStageModal.ejs',
            scope: $scope,
            size: 'lg'
        });
    };

    $scope.showModalRejectCandidates = function () {
        $scope.organization = $rootScope.client[0]
        $scope.applicant_profiles = railApi.index('applicant_profiles/bulk_candidates/' + $scope.job.id, {job_id: $scope.job.id}, 'applicant_profile').data;
        $scope.rejection_reasons = railApi.index('rejection_reasons', {}, 'rejection_reason').data;
        $scope.rejectModal = $scope.modal.open({
            templateUrl: 'rejectModal.ejs',
            scope: $scope,
            size: 'lg'
        });
    };

    $scope.showModalWriteNotes = function () {
        $scope.applicant_profiles = railApi.index('applicant_profiles/bulk_candidates/' + $scope.job.id, {job_id: $scope.job.id}, 'applicant_profile').data;
        $scope.writeNoteModal = $scope.modal.open({
            templateUrl: 'writeNoteModal.ejs',
            scope: $scope,
            size: 'lg'
        });
    };

    $scope.showModalMilestoneBulk = function () {
        $scope.applicant_profiles = railApi.index('applicant_profiles/bulk_candidates/' + $scope.job.id, {job_id: $scope.job.id}, 'applicant_profile').data;
        $scope.milestoneBulkModal = $scope.modal.open({
            templateUrl: 'milestoneBulkModal.ejs',
            scope: $scope,
            size:'lg'
        })
    }

    $scope.showModalAdjustPresentedDate = function () {
        $scope.adjustPresentedDateModal = $scope.modal.open({
            templateUrl: 'adjustPresentedDateModal.ejs',
            scope: $scope,
            size: 'lg',
            backdrop: 'static'
        })
    }
    $scope.showModalAdjustInterviewedDate = function () {
        $scope.adjustInterviewedDateModal = $scope.modal.open({
            templateUrl: 'adjustInterviewedDateModal.ejs',
            scope: $scope,
            size: 'lg',
            backdrop: 'static'
        })
    }
    $scope.showModalAdjustOfferedDate = function () {
        $scope.adjustOfferedDateModal = $scope.modal.open({
            templateUrl: 'adjustOfferedDateModal.ejs',
            scope: $scope,
            size: 'lg',
            backdrop: 'static'
        })
    }
    $scope.showModalAdjustHiredDate = function () {
        $scope.adjustHiredDateModal = $scope.modal.open({
            templateUrl: 'adjustHiredDateModal.ejs',
            scope: $scope,
            size: 'lg',
            backdrop: 'static'
        })
    }
    $scope.showModalAdjustRegrettedDate = function () {
        $scope.adjustRegrettedDateModal = $scope.modal.open({
            templateUrl: 'adjustRegrettedDateModal.ejs',
            scope: $scope,
            size: 'lg',
            backdrop: 'static'
        })
    }


    $scope.showModalMarkFutureConsideration = function () {
        $scope.applicant_profiles = railApi.index('applicant_profiles/bulk_candidates/' + $scope.job.id, {job_id: $scope.job.id}, 'applicant_profile').data;
        $scope.markFutureConsiderationModal = $scope.modal.open({
            templateUrl: 'markFutureConsiderationModal.ejs',
            scope: $scope,
            size: 'lg'
        });
    };

    $scope.showModalExport = function () {
        $scope.applicant_profiles = railApi.index('applicant_profiles/bulk_candidates/' + $scope.job.id, {job_id: $scope.job.id}, 'applicant_profile').data;
        $scope.exportModal = $scope.modal.open({
            templateUrl: 'exportModal.ejs',
            scope: $scope,
            size: 'lg'
        });
    };

    $scope.showModalMoveCandidatesToStage = function (stage) {
        $scope.applicant_profiles = railApi.index('applicant_profiles/bulk_candidates/' + $scope.job.id, {job_id: $scope.job.id}, 'applicant_profile').data;
        $scope.stage = stage
        $scope.moveToSpecificStageModal = $scope.modal.open({
            templateUrl: 'moveToSpecificStageModal.ejs',
            scope: $scope,
            size: 'lg'
        });
    };

    $scope.showModalTagCandidates = function () {
        $scope.applicant_profiles = railApi.index('applicant_profiles/bulk_candidates/' + $scope.job.id, {job_id: $scope.job.id}, 'applicant_profile').data;
        $scope.tags = railApi.index('tags', {}, 'tag').data;
        $scope.tagCandidatesModal = $scope.modal.open({
            templateUrl: 'tagCandidatesModal.ejs',
            scope: $scope,
            size: 'lg'
        });
    };

    $scope.sendReplyEmail = function (applicant_profile, replyEmail) {
      if(replyEmail.files) {
        $scope.file_upload = [];
        var promises = [];
        var files = replyEmail.files;
        var fileCount = 0;
        // $scope.errFiles = error;
        $scope.errorFile = false;
        var data = {
            file_type: 'attachment',
            employee_id: $rootScope.hgUserData.id
        };

        for (var i = 0; i < files.length; i+=1) {
            var file = files[i];
            if(!file.$error) {
                var response = Upload.upload({
                    url: config.apiIP + 'applicant_profiles/upload_file',
                    file: file,
                    fields: data
                }).progress(function (evt) {
                    file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                }).success(function (data, status, headers, config) {
                    // completed, data contains the parsed resume and fileModel
                    data.file_info['employee'] = $rootScope.hgUserData;
                    $scope.fileModel = data.file_info;
                    $scope.attachFilesIds.push($scope.fileModel.id);

                    $scope.file_upload.push(data.file_info);

                }).error(function(data, status, headers, config) {
                    $scope.errorMsg = status + ': ' + data.errors[0].message;
                    $scope.errorFile = true;
                }).then(function (success, error) {
                  if(success) {
                    fileCount = fileCount + 1;
                    if (fileCount === files.length) {
                        $rootScope.replyEmailReceived = railApi.post('send_hgemail/send_reply_email', {
                            id: replyEmail.id,
                            t_uid: applicant_profile.applicant.email,
                            f_uid: $rootScope.hgUserData.email,
                            subject: replyEmail.subject,
                            message: replyEmail.message,
                            applicant_profile_id: applicant_profile.id,
                            employee_id: $rootScope.hgUserData.id,
                            cc: replyEmail.cc,
                            file_upload: $scope.file_upload,
                            replyable: true
                        }, 'email');
                        $state.reload('jobApplication', {
                            job_id: $scope.job.id
                        });
                    }
                    else {

                    }
                  }
                  else {

                  }
                });
                promises.push(response);
            }
          }
      }
      else {

        $rootScope.replyEmailReceived = railApi.post('send_hgemail/send_reply_email', {
            id: replyEmail.id,
            t_uid: applicant_profile.applicant.email,
            f_uid: $rootScope.hgUserData.email,
            subject: replyEmail.subject,
            message: replyEmail.message,
            applicant_profile_id: applicant_profile.id,
            employee_id: $rootScope.hgUserData.id,
            cc: replyEmail.cc,
            replyable: true
        }, 'email');
        $state.reload('jobApplication', {
            job_id: $scope.job.id
          });
        }
    };

    $scope.setIds = function (applicant_id) {
        var index = $scope.applicantIds.indexOf(applicant_id);
        if (-1 === index) {
            $scope.applicantIds.push(applicant_id);
        } else {
            $scope.applicantIds.splice(index, 1);
        }
    };

    $scope.setWatchWorkloadIds = function (watch_stage_id) {
        var index = $scope.watchWorkloadIds.indexOf(watch_stage_id);
        if (-1 === index) {
            $scope.watchWorkloadIds.push(watch_stage_id);
        } else {
            $scope.watchWorkloadIds.splice(index, 1);
        }
    };

    $scope.setStageIds = function (applications) {

      var stageApplicants = _.map(
          _.filter(applications.applicant_profiles, function (applicant) {
              if(!applicant.is_deleted){
                return $scope.selectedTab === applicant.stage_id;
              }
          }), 'id');

        $scope.applicantIds = _.uniq(_.union($scope.applicantIds, stageApplicants));

    };

    $scope.removeStageIds = function (applications) {
      var stageApplicants = _.map(
          _.filter(applications.applicant_profiles, function (applicant) {
              return $scope.selectedTab === applicant.stage_id;
          }), 'id');

      $scope.applicantIds = _.difference($scope.applicantIds, stageApplicants);
    };

    $scope.currDate = new Date(moment()._d)

    $scope.sendBulkEmails = function (emailTemplate, replyable) {
      emailTemplate.email_lang = $rootScope.currentLang;
      if(emailTemplate.files) {
        $scope.file_upload = [];
        var promises = [];
        var files = emailTemplate.files;
        var fileCount = 0;
        // scope.errFiles = error;
        $scope.errorFile = false;
        var data = {
            file_type: 'attachment',
            employee_id: $scope.hgUserData.id
        };

        for (var i = 0; i < files.length; i+=1) {
            var file = files[i];
            if(!file.$error) {
                var response = Upload.upload({
                    url: config.apiIP + 'applicant_profiles/upload_file',
                    file: file,
                    fields: data
                }).progress(function (evt) {
                    file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                }).success(function (data, status, headers, config) {
                    // completed, data contains the parsed resume and fileModel
                    data.file_info['employee'] = $scope.hgUserData;
                    $scope.fileModel = data.file_info;
                    $scope.attachFilesIds.push($scope.fileModel.id);

                    $scope.file_upload.push(data.file_info);

                }).error(function(data, status, headers, config) {
                    $scope.errorMsg = status + ': ' + data.errors[0].message;
                    $scope.errorFile = true;
                }).then(function (success, error) {
                  if(success) {
                    fileCount = fileCount + 1;
                    if (fileCount === files.length) {
                        $rootScope.regretLetterReceived = railApi.post('send_hgemail/send_bulk_emails', {
                            jobId: $scope.job.id,
                            applicantProfileIds: $scope.applicantIds,
                            emailTemplate: emailTemplate,
                            type: $scope.bulkType,
                            employee_id: $scope.hgUserData.id,
                            cc: emailTemplate.cc,
                            file_upload: $scope.file_upload,
                            replyable: replyable
                        }, "send_hgemail");
                    }
                    else {

                    }
                  }
                  else {

                  }
                });
                promises.push(response);
            }
          }
      }
      else {
        console.log("sending bulk emails");
        $rootScope.regretLetterReceived = railApi.post('send_hgemail/send_bulk_emails', {
            jobId: $scope.job.id,
            applicantProfileIds: $scope.applicantIds,
            emailTemplate: emailTemplate,
            type: $scope.bulkType,
            employee_id: $scope.hgUserData.id,
            cc: emailTemplate.cc,
            replyable: replyable
        }, "send_hgemail");
      }
    };

    $scope.sendBulkRegretEmails = function (emailTemplate, replyable) {
      emailTemplate.email_lang = $rootScope.currentLang;
      if(emailTemplate.files) {
        $scope.file_upload = [];
        var promises = [];
        var files = emailTemplate.files;
        var fileCount = 0;
        // scope.errFiles = error;
        $scope.errorFile = false;
        var data = {
            file_type: 'attachment',
            employee_id: $scope.hgUserData.id
        };

        for (var i = 0; i < files.length; i+=1) {
            var file = files[i];
            if(!file.$error) {
                var response = Upload.upload({
                    url: config.apiIP + 'applicant_profiles/upload_file',
                    file: file,
                    fields: data
                }).progress(function (evt) {
                    file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                }).success(function (data, status, headers, config) {
                    // completed, data contains the parsed resume and fileModel
                    data.file_info['employee'] = $scope.hgUserData;
                    $scope.fileModel = data.file_info;
                    $scope.attachFilesIds.push($scope.fileModel.id);

                    $scope.file_upload.push(data.file_info);

                }).error(function(data, status, headers, config) {
                    $scope.errorMsg = status + ': ' + data.errors[0].message;
                    $scope.errorFile = true;
                }).then(function (success, error) {
                  if(success) {
                    fileCount = fileCount + 1;
                    if (fileCount === files.length) {
                        $rootScope.regretLetterReceived = railApi.post('send_hgemail/send_bulk_regret_emails', {
                            jobId: $scope.job.id,
                            applicantProfileIds: $scope.applicantIds,
                            emailTemplate: emailTemplate,
                            type: $scope.bulkType,
                            employee_id: $scope.hgUserData.id,
                            cc: emailTemplate.cc,
                            file_upload: $scope.file_upload,
                            replyable: replyable
                        }, "send_hgemail");
                    }
                    else {

                    }
                  }
                  else {

                  }
                });
                promises.push(response);
            }
          }
      }
      else {
        console.log("sending bulk emails");
        $rootScope.regretLetterReceived = railApi.post('send_hgemail/send_bulk_regret_emails', {
            jobId: $scope.job.id,
            applicantProfileIds: $scope.applicantIds,
            emailTemplate: emailTemplate,
            type: $scope.bulkType,
            employee_id: $scope.hgUserData.id,
            cc: emailTemplate.cc,
            replyable: replyable
        }, "send_hgemail");
      }
    };

    $scope.beforeSaveEmailDraft = function(emailData, redirect, offerTotal){
        
        if(offerTotal > 0){
            $scope.sendOfferConfirmationModal = $scope.modal.open({
                templateUrl: 'sendOfferConfirmationModal.ejs',
                scope: $scope,
                size: 'lg'
            });
        }else{
            $scope.saveEmailDraft(emailData, redirect)
        }

    }

    $scope.saveEmailDraft = function(emailData, redirect){

        var viewerApprovers = emailData.approvers.concat(emailData.viewers)

        railApi.post('approval_emails',{message: emailData.body[$rootScope.currentLang],
                               job_id: $scope.job.id,
                               template_email_id: emailData.id,
                               employee_id: $rootScope.hgUserData.id,
                               status: 'draft',
                               approvers: viewerApprovers,
                               applicant_profile_id: $scope.applicant_profile.id,
                               email_type: 'offer_letter',
                               author_signature: emailData.signature_type == 'author_signature' ? true : false,
                               recruitement_signature: emailData.signature_type == 'recruitement_signature' ? true : false
        }).promise.then(function(data){
            if(redirect == true){
                $state.go('offerLetterView', {id: data.id});
            }else{
                $scope.offerLetterData = data
                $scope.offerLetterCreatedModal = $scope.modal.open({
                    templateUrl: 'offerLetterCreatedModal.ejs',
                    scope: $scope,
                    size: 'md'
                });
            }
        })
    }

    $scope.redirectToInfoReqView = function(id){
        $state.go('informationRequestView', {id: id});
    }

    $scope.step1 = true;
    $scope.currStep = 1
    $scope.nextPrev = function(step){
        for(let stepz = 1; stepz < 4; stepz++){
            if(step == stepz){
                $scope['step' + stepz] = true
                $scope.currStep = stepz
            }else{
                $scope['step' + stepz] = false
            }
        }
    }

    $scope.sendEmail = null
    $scope.addApprover = function(sendEmail, count){
        sendEmail.approvers.push({
            name: '',
            email: '',
            type: 'approver',
            order: (0 + count)
        })
        console.log(sendEmail.approvers)
    }

    $scope.reOrderApprovers = function (order1, order2, sendEmail) {


        var index1 = _.findIndex(sendEmail.approvers, { order: order1 })
        var index2 = _.findIndex(sendEmail.approvers, { order: order2 })

        //var tempWeight = $scope.weighting[order1]
        //$scope.weighting[order1] = $scope.weighting[order2]
        //$scope.weighting[order2] = tempWeight

        sendEmail.approvers[index1].order = order2;
        sendEmail.approvers[index2].order = order1;
    }

    $scope.removeApprover = function (sendEmail, order) {
        //$scope.weighting.splice(order, 1);
        sendEmail.approvers = sendEmail.approvers.filter(function (approver) { return approver.order != order });
        sendEmail.approvers.sort(function (a, b) {
            return a.order - b.order;
        })
        _.forEach(sendEmail.approvers, function (approver, index) {

            approver.order = index;
        });
    }

    $scope.addViewer = function(sendEmail, count){
        sendEmail.viewers.push({
            name: '',
            email: '',
            type: 'view',
            order: (0 + count)
        })
        console.log(sendEmail.viewers)
    }

    $scope.removeViewer = function (sendEmail, order) {
        //$scope.weighting.splice(order, 1);
        sendEmail.viewers = sendEmail.viewers.filter(function (viewer) { return viewer.order != order });
        sendEmail.viewers.sort(function (a, b) {
            return a.order - b.order;
        })
        _.forEach(sendEmail.viewers, function (viewer, index) {

            viewer.order = index;
        });
    }

    $scope.dateToday = function(){
        $scope.dateNowIso = new Date();
    }

    $scope.getAvailableForms = function() {

       railApi.index('forms', {'archived': false}).promise.then(function(data) {
            $scope.questionSections = data;
        });

    }

    $scope.random_id_generator = function(){
        return (Math.random() + 1).toString(36).substring(7);
    }

    $scope.sendInfoReq = function(emailData, redirect, recipient, form_id, due_date){

        railApi.post('approval_emails',{
            message: emailData.body[$rootScope.currentLang],
            subject: emailData.subject[$rootScope.currentLang],
            job_id: $scope.job.id,
            template_email_id: emailData.id,
            employee_id: $rootScope.hgUserData.id,
            status: 'req_out',
            applicant_profile_id: $scope.applicant_profile.id,
            email_type: 'info_request',
            form_id: form_id,
            info_req_attachments: $scope.informationRequestFiles,
            receiver: recipient.name,
            receiver_email: recipient.email,
            due_date: due_date, 
            date_today: moment(Date.now()).format('LL')
        }).promise.then(function(data){

            data.forms_list.forEach(function(form){
                $scope.applicant_profile.forms_list.push(form)
            })

            $scope.infoRequestData = data;
            $scope.confirmSentInformationRequestModal = $scope.modal.open({
                templateUrl: 'confirmSentInformationRequestModal.ejs',
                scope: $scope,
                size: 'md'
            });
            // if(redirect == true){
            //     $state.go('offerLetterView', {id: data.id});
            // }else{
            //     $scope.offerLetterData = data
            //     $scope.offerLetterCreatedModal = $scope.modal.open({
            //         templateUrl: 'offerLetterCreatedModal.ejs',
            //         scope: $scope,
            //         size: 'md'
            //     });
            // }
        })
        
    }
    $scope.informationRequestFiles = []
    $scope.addFiletoList = function(file, type, info){
        info.direction = 'outbound'
        if(file){
            info.file = file.file_info
            info.random_id = $scope.random_id_generator()
            $scope.informationRequestFiles.push(info)
            $scope.info_file_data = null
        }else{
            info.random_id = $scope.random_id_generator()
            $scope.informationRequestFiles.push(info)
        }
    }

    $scope.clearProvidedDoc = function(index){
        $scope.providedDocs.splice(index, 1);
    }
    $scope.providedDocs = []


    $scope.getDocsIdAndUpload = function () {
        var files = [];

        _.map($scope.providedDocs, function (value, index) {
            if (-1 === _.findIndex(
                    $scope.uploaded_files_attributes, { 'true_original_name': value.name })
            ) {
                return files.push(value);
            }
        });

        if (files.length) {
            $scope.upload_info_req_files(files, 'info_attachment');

            return true;
        } else {

            return false
        }

    }; //getDocsIdAndUpload

    $scope.saveFileRequest = function(data){
        $scope.informationRequestFiles.push({
            name: data.title,
            description: data.instructions,
            mandatory: data.mandatory,
            type: 'info_req_request',
            direction: 'inbound',
            random_id: $scope.random_id_generator()
        })
    }

    $scope.removeDocuments = function(random_id) {
        
        $scope.informationRequestFiles.splice(_.indexOf($scope.informationRequestFiles, _.findWhere($scope.informationRequestFiles, { random_id : random_id})), 1);

    }

    $scope.parJson = function (json) {
        return JSON.parse(json);
    }

    $scope.emptyFiles = function(){
        $scope.informationRequestFiles = []
    }

    $scope.selectFormPreview = function(form_id, form_list){
        $scope.formPrev = Object.values(form_list).filter(function(form){
            return form.id == form_id
        })
    }

    $scope.uploadFiles = function (files, file_type) {
        var promises = [];
        $scope.files = files;
        // $scope.errFiles = error;
        $scope.errorFile = false;
        var data = {
            file_type: file_type,
            employee_id: $rootScope.hgUserData.id,
            applicant_profile_id: $scope.applicant_profile.id
        };

        for (var i = 0; i < files.length; i+=1) {
            var file = files[i];
            if(!file.$error) {
                var response = Upload.upload({
                    url: config.apiIP + 'applicant_profiles/upload_file',
                    file: file,
                    fields: data
                }).progress(function (evt) {
                    file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                }).success(function (data, status, headers, config) {
                    // completed, data contains the parsed resume and fileModel
                    data.file_info['employee'] = $rootScope.hgUserData;
                    $scope.fileModel = data.file_info;
                    $scope.attachFilesIds.push($scope.fileModel.id);

                    if(!$scope.applicant_profile.hasOwnProperty('uploaded_files')) {
                        $scope.applicant_profile.uploaded_files = [];
                    }
                    $scope.applicant_profile.uploaded_files.push(data.file_info);

                }).error(function(data, status, headers, config) {
                    $scope.errorMsg = status + ': ' + data.errors[0].message;
                    $scope.errorFile = true;
                });

                promises.push(response);
            }
        }

        return $q.all(promises);

    }; //upload

    $scope.upload_info_req_files = function (files, file_type, error) {
        if (files && files.length && (undefined === error)) {
            $scope.errorResume = false;
            
            for (var i = 0; i < files.length; i+=1) {
                var file = files[i];
                var data = {
                    file_type: file_type
                };

                Upload.upload({
                    url: config.apiIP + 'applicant_profiles/upload_file',
                    file: file,
                    fields: data
                }).progress(function (evt) {
                    $scope.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                }).success(function (data, status, headers, config) {
                    // completed, data contains the parsed resume and fileModel
                    $scope.info_file_data = data
                }).error(function(data, status, headers, config) {
                    $scope.fileError = data.error;
                    $scope.errorFile = true;
                });
            }
        } else {
            return false;
        }

    }; //upload


    $scope.uploadDocs = function upload (files, fileType, error) {
        if (files && files.length && (undefined === error)) {

            var result = $scope.uploadFiles(files, fileType);
            result.then(function (data) {
                if(data.length) {
                    $scope.numUploadedFiles = data.length;
                }

            })
        } else {
            return false;
        }

    };

    $scope.removeFile = function removeFile(index) {
        this.$parent.newAttachFiles.splice(index,1);
        return;
    };

    $scope.removeEmailFile = function removeEmailFile(index) {
        this.$parent.sendEmail.files.splice(index,1);
        return;
    };

    $scope.removeReplyEmailFile = function removeReplyEmailFile(index) {
        this.$parent.replyEmail.files.splice(index,1);
        return;
    };

    $scope.closeJob = function () {
        var path = 'jobs/' + $scope.job.id + '/close/' + $scope.job.close_reason_id;
        $scope.result = railApi.put(path, {id: $scope.job.id, close_reason_id: $scope.job.close_reason_id}, 'job');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('jobList', {status: 'closed'});
                }
            }
        }, true)
    };

    $scope.assignWatchStages = function (workload_ids, email_frequency, job_id) {
        var path = 'employees/' + $rootScope.hgUserData.id + '/assign_watch_stages';
        $scope.result = railApi.put(path, {id: $rootScope.hgUserData.id, workload_ids: workload_ids, watch_stage_email_frequency: email_frequency, job_id: job_id}, 'employee');
        $scope.watchWorkloadFrequency = email_frequency;
    };

    $scope.assignJobExpiryNotifications = function (email_frequency, job_id) {
        var path = 'employees/' + $rootScope.hgUserData.id + '/assign_expiry_notification';
        $scope.result = railApi.put(path, {id: $rootScope.hgUserData.id, expiry_notification_email_frequency: email_frequency, job_id: job_id}, 'employee');
        $scope.expiryNotificationFrequency = email_frequency;
    };

    $scope.adjustCloseReaonForJob = function () {
        var path = 'jobs/' + $scope.job.id + '/adjust_close_reason';
        $scope.result = railApi.put(path, {id: $scope.job.id, close_reason_id: $scope.job.close_reason_id}, 'job');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('jobList', {status: 'closed'});
                }
            }
        }, true)
    };

    $scope.filterCloseReason = function (closeReason) {
        return closeReason.name.en !== 'Closed Pending Job' || closeReason.is_system_default === false
    };

    $scope.RemoveCloseReason = function () {
        var path = 'jobs/' + $scope.job.id + '/active_job';
        $scope.result = railApi.put(path, {id: $scope.job.id, close_reason_id: '', closed_date: ''}, 'job');
    };

    $scope.closePendingJobInformation = function () {
        var path = 'jobs/' + $scope.job.id + '/close_pending/';
        console.log('Inside close pending job');
        console.log($scope.job.id);
        $scope.result = railApi.put(path, {id: $scope.job.id}, 'job');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('jobList', {status: 'closed'});
                }
            }
        }, true)
    };

    $scope.openNotesModal = function () {
        railApi.get('applicants/' +  $scope.applicant_profile.applicant_id).promise.then(function(myData){
            $scope.userProfileQuery = myData;
            $scope.applicantNotes = $scope.userProfileQuery;
            $scope.applicantProfileNotes = $scope.applicantNotes.applicant_profiles;
            $scope.allNotesModal = $scope.modal.open({
                templateUrl: 'allNotesModal.ejs',
                scope: $scope,
                size: 'lg'
            });

        });

    }

    $scope.openEmailsModal = function () {
        railApi.get('applicants/' +  $scope.applicant_profile.applicant_id).promise.then(function(myData){
            $scope.userProfileQuery = myData;
            $scope.applicantEmails = $scope.userProfileQuery;
            $scope.applicantProfileEmails = $scope.applicantEmails.applicant_profiles;
            $scope.allEmailsModal = $scope.modal.open({
                templateUrl: 'allEmailsModal.ejs',
                scope: $scope,
                size: 'lg'
            });

        });

    }

    $scope.openOffersModal = function(){
        $scope.allOfferModal = $scope.modal.open({
            templateUrl: 'allOfferModal.ejs',
            scope: $scope,
            size: 'lg'
        });
    }

    $scope.openOfferLetterView = function openJobWindow (offerletter_id) {
        var url = "#/offer-letter/view/" + offerletter_id;
        window.open(url, '_blank');
    }


    $scope.closePendingJob = function () {
        var path = 'jobs/' + $scope.job.id + '/close_pending/';
        $scope.result = railApi.put(path, {id: $scope.job.id}, 'job');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('jobList', {status: 'closed'});
                }
            }
        }, true)
    };

    $scope.archiveJob = function () {
        var path = 'jobs/' + $scope.job.id + '/archive';
        $scope.result = railApi.put(path, {id: $scope.job.id}, 'job');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('jobList', {status: 'archived'});
                }
            }
        }, true)
    };

    $scope.expireJob = function () {
        var path = 'jobs/' + $scope.job.id + '/expire';
        $scope.result = railApi.put(path, {id: $scope.job.id}, 'job');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('jobList', {status: 'expired'});
                }
            }
        }, true)
    };

    $scope.employeeStatusChange = function (status) {
      var path = 'applicants/' + $scope.applicant_profile.applicant.id;
        if(status == 'current') {
            var current_employee_status = true;
            var previous_employee_status = false;
        }
        if(status == 'previous') {
            var current_employee_status = false;
            var previous_employee_status = true;
        }
        if(status == 'none') {
            var current_employee_status = false;
            var previous_employee_status = false;
        }
    //   var current_employee_status = $scope.applicant_profile.applicant.current_employee;
    //   var previous_employee_status = $scope.applicant_profile.applicant.previous_employee;
      $scope.result = railApi.put(path, {current_employee: current_employee_status, previous_employee: previous_employee_status}, 'applicant');
      $scope.$watch('result', function (newVal, oldVal) {
            if(newVal !== oldVal && newVal.pending === false) {
                    $state.reload('jobApplication', {
                        job_id: $scope.job.id
                    });
                }
        }, true)
    };

    $scope.updateCandidateStatus = function (sourceID, refName) {
        var path = 'applicant_profiles/' + $scope.applicant_profile.id + '/update_candidate_source/'+ sourceID;
        var path2 = 'applicants/' + $scope.applicant_profile.applicant.id + '/update_referral_name/'+ refName;
        if(sourceID !== $scope.referralId){
            $scope.result1 = railApi.put(path, {}, 'applicant_profile'); //this will set the candidate source id
            $scope.result2 = {};
            if(refName !== null){
                $scope.result2 = railApi.put('applicants/' + $scope.applicant_profile.applicant.id + '/update_referral_name/', {}, 'applicant'); //if you came from a reference and your reference has a name, this will clear it.
            }
        }else{
            if((refName !== $scope.applicant_profile.applicant.referral_name) && (sourceID !== $scope.applicant_profile.candidate_source_id)){ //if your candidate source is not reference, and you change it to reference with name, this will change both candidate source id to 3 and set refname
                $scope.result1 = railApi.put(path, {}, 'applicant_profile');
                $scope.result2 = railApi.put(path2, {}, 'applicant');
            }else if((refName !== $scope.applicant_profile.applicant.referral_name) && (sourceID == $scope.applicant_profile.candidate_source_id)){ //this one is when you are already in reference and just changing the reference name.
                $scope.result2 = railApi.put(path2, {}, 'applicant');
                $scope.result1 = {};
            }else if((refName == null) && (sourceID !== $scope.applicant_profile.candidate_source_id)){ //this one is when you change to reference with a blank reference name
                $scope.result1 = railApi.put(path, {}, 'applicant_profile');
                $scope.result2 = {};
            }
        }
        $scope.results = [];
        $scope.results = $scope.results.concat($scope.result1.data);
        $scope.results = $scope.results.concat($scope.result2.data);

        $scope.$watch('results', function (newVal, oldVal) {
            if(newVal !== oldVal) {
                    $state.reload('jobApplication', {
                        job_id: $scope.job.id
                    });
                }
        }, true)
    }

    $scope.referralChange = function () {
      var path = 'applicants/' + $scope.applicant_profile.applicant.id;
      var current_referral_name = $scope.applicant_profile.applicant.referral_name;
      // var app_candidate_source = $scope.applicant_profile.candidate_source_id;
      $scope.result = railApi.put(path, {referral_name: current_referral_name}, 'applicant');
      $scope.$watch('result', function (newVal, oldVal) {
            if(newVal !== oldVal && newVal.pending === false) {
                    $state.reload('jobApplication', {
                        job_id: $scope.job.id
                    });
                }
        }, true)
    };

    $scope.extendJob = function () {
        var path = 'jobs/' + $scope.job.id + '/extend_end_date';
        var endDate = $scope.job.end_date;
        var internalEndDate = $scope.job.internal_end_date;
        $scope.result = railApi.put(path, {id: $scope.job.id, end_date: endDate, internal_end_date: internalEndDate}, 'job');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('jobList', {status: 'active'});
                }
            }
        }, true)
    };

    $scope.fillJob = function () {
        var path = 'jobs/' + $scope.job.id + '/fill';
        $scope.result = railApi.put(path, {id: $scope.job.id}, 'job');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('jobList', {status: 'filled'});
                }
            }
        }, true)
    };

    $scope.writeNote = function (noteBody, applicantProfile, employee) {
        var path = 'notes/' ;
        $scope.result = railApi.post(path, {
            body: noteBody,
            stage_id: applicantProfile.stage_id,
            applicant_profile_id: applicantProfile.id,
            employee_id: employee.id,
            job_id: applicantProfile.job_id
        }, 'note');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.reload('jobApplication.profile', {job_id: applicantProfile.job_id, applicant_profile_id: applicantProfile.id});
                    // getSelectedTab(applicantProfile, applicantProfile.job, applicantProfile.job.workflow.stages);
                    // $scope.changeShowTabs(applicantProfile.stage_id);
                    // $scope.selectedTab = applicantProfile.stage_id;
                    // $scope.loadStageApplicationsFx(applicantProfile.stage_id);
                }
            }
        }, true)
    };

    $scope.templateJob = function () {
        var path = 'jobs/' + $scope.job.id + '/template_from_job';
        $scope.result = railApi.post(path, {id: $scope.job.id}, 'job_template');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('jobTemplateList');
                }
            }
        }, true)
    };

    $scope.cloneJob = function () {
        var path = 'jobs/' + $scope.job.id + '/repost';
        var startDate = $scope.job.start_date;
        var endDate = $scope.job.end_date;
        var internalEndDate = $scope.job.internal_end_date;
        var locationId = $scope.job.location_id;
        var displayClosingDate = $scope.job.display_closing_date;
        var postReasonId = $scope.job.post_reason_id;
        $scope.result = railApi.post(path, {id: $scope.job.id, start_date: startDate, end_date: endDate, internal_end_date: internalEndDate, location_id: locationId, display_closing_date: displayClosingDate, post_reason_id: postReasonId}, 'job');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('jobEdit', {id: newVal["data"].id});
                }
            }
        }, true)
    };


    $scope.setSessionVariables = function() {
        sessionStorage.setItem("jobCreated", true);
    }

    $scope.cloneWithApplicantsJob = function () {
        var path = 'jobs/' + $scope.job.id + '/repost_with_applicants';
        var startDate = $scope.job.start_date;
        var endDate = $scope.job.end_date;
        var internalEndDate = $scope.job.internal_end_date;
        var locationId = $scope.job.location_id;
        var displayClosingDate = $scope.job.display_closing_date;
        var postReasonId = $scope.job.post_reason_id;
        $scope.result = railApi.post(path, {id: $scope.job.id, start_date: startDate, end_date: endDate, internal_end_date: internalEndDate, location_id: locationId, display_closing_date: displayClosingDate, post_reason_id: postReasonId}, 'job');
        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('jobEdit', {id: newVal["data"].id});
                }
            }
        }, true)
    };

    $scope.getPdf = function(applicantIds) {
        $scope.applicationReport = railApi.get('applicant_profiles/export_to_pdf?ids=', { ids: applicantIds }, 'applicant_profile');
        console.log($scope.applicationReport);
    };

    $scope.availableJobs = function(applicantIds) {
        $scope.availableJobsList = railApi.get('jobs/available_jobs_multiple/' + applicantIds);
    };

    $scope.copyCandidatesToJob = function(applicantIds, job) {
        $scope.copyToJob = railApi.post('applicant_profiles/' + applicantIds + '/return_duplicates_copy/' + job.id, {}, 'applicant_profile').promise.then(function(data) {
            // $scope.copyToJobModal.dismiss();
            $scope.showModalCopyCandidatesConfirm(data, job, applicantIds);
        });
    };

    $scope.moveProfilesToStage = function(applicantIds, stage) {
        $scope.moveToStage = railApi.put('applicant_profiles/' + applicantIds + '/move_profiles_to_stage/' + stage.id, {}, 'applicant_profile')
                                    .promise
                                    .then(function(data) {
                                      window.location.reload();
                                    });
    };

    $scope.rejectCandidates = function(applicantIds, rejection_reason_id) {
        $scope.reject = railApi.put('applicant_profiles/' + applicantIds + '/reject_profiles/' + rejection_reason_id.id, {}, 'applicant_profile')
                               .promise
                               .then(function(data) {
                                 window.location.reload();
                               });
    };

    $scope.rejectCandidatesWithoutReload = function(applicantIds, rejection_reason_id) {
        $scope.reject = railApi.put('applicant_profiles/' + applicantIds + '/reject_profiles/' + rejection_reason_id.id, {}, 'applicant_profile');
    };

    $scope.writeNotes = function(applicantIds, body, employee) {
        $scope.writeMultipleNotes = railApi.post('notes/write_notes/' + applicantIds, { body: body, employee_id: employee.id }, 'note')
                                           .promise
                                           .then(function(data) {
                                             window.location.reload();
                                           });
    };

    $scope.markFutureConsideration = function(applicantIds) {
        $scope.markForFutureConsideration = railApi.put('/applicant_profiles/' + applicantIds + '/mark_candidates_for_future_consideration', {}, 'applicant_profile')
                                                   .promise
                                                   .then(function(data) {
                                                     window.location.reload();
                                                   });
    };

    $scope.tagCandidate = function(applicant_profile, tag) {
        $scope.tagIt = true;
        railApi.put('applicant_profiles/' + applicant_profile.id + '/tag_candidate/' + tag.id, {id: applicant_profile.id, tag_id: tag.id}, 'applicant_profile')
                            .promise
                            .then(function(data) {

                                $scope.tag = data;

                                railApi.index('applicant_profiles', {
                                    job_id: $stateParams.job_id,
                                    applicant_id: applicant_profile.applicant_id
                                }).promise.then(function(applicantData) {
                                    $scope.applications = applicantData;
                                    $scope.neoData = applicantData;
                                    $scope.currentTags = applicantData.applicant_profiles[0].applicant.tags

                                    $scope.currentTags.forEach(function(tag) {
                                        $scope.availableTags.forEach(function(availableTag){
                                            if (_.isEqual(tag['id'], availableTag['id'])) {
                                                var index = _.findIndex($scope.availableTags, {id: tag['id']});
                                                $scope.availableTags.splice(index, 1);
                                            }
                                        });
                                    });

                                    // get original application data, or will missing other candidates
                                    $scope.applicationsQuery = railApi.index('applicant_profiles', {
                                        order: $scope.sortBy,
                                        limit: $scope.limit,
                                        offset: ($scope.applicationsPage - 1) * $scope.limit,
                                        job_id: $stateParams.job_id,
                                        stage_id: $scope.selectedTab
                                    });
                                    $scope.applications = $scope.applicationsQuery.data;
                                });
                                railApi.index('tags', {is_viewable: 'true'}).promise.then(function(bulkTagData) {
                                    $scope.bulkTags = bulkTagData;
                                    // $scope.availableTags = $scope.bulkTags;
                                });
                            });
    };

    $scope.tagCandidates = function(applicantIds, tag) {
        $scope.bulkTag = railApi.put('applicant_profiles/' + applicantIds + '/tag_candidates/' + tag.id, {ids: applicantIds, tag_id: tag.id}, 'applicant_profile')
                                .promise
                                .then(function(data) {
                                    window.location.reload();
                                });
    };

    $scope.removeTag = function(applicant_profile, tag) {
        if (tag) {
            // alert("JobID: "  + $stateParams.job_id + " applicant_profile.id: " + applicant_profile.id + " applicantID: " + applicant_profile.applicant_id + "  fullName: " + applicant_profile.applicant.name);
            $scope.tagRemoval = railApi.put('applicant_profiles/' + applicant_profile.id + '/remove_tag/' + tag.id, {id: applicant_profile.id, tag_id: tag.id}, 'applicant_profile')
                                    .promise
                                    .then(function(data) {

                                            $scope.tag = data;

                                            railApi.index('applicant_profiles', {
                                                job_id: $stateParams.job_id,
                                                applicant_id: applicant_profile.applicant_id
                                            }).promise.then(function(applicantData) {
                                                $scope.applications = applicantData;
                                                $scope.neoData = applicantData;
                                                $scope.currentTags = applicantData.applicant_profiles[0].applicant.tags

                                                $scope.availableTags.push(tag)

                                                // get original application data, or will missing other candidates
                                                $scope.applicationsQuery = railApi.index('applicant_profiles', {
                                                    order: $scope.sortBy,
                                                    limit: $scope.limit,
                                                    offset: ($scope.applicationsPage - 1) * $scope.limit,
                                                    job_id: $stateParams.job_id,
                                                    stage_id: $scope.selectedTab
                                                });
                                                $scope.applications = $scope.applicationsQuery.data;
                                            });

                                            railApi.index('tags', {is_viewable: 'true'}).promise.then(function(bulkTagData) {
                                                $scope.bulkTags = bulkTagData;
                                                // $scope.availableTags = $scope.bulkTags;
                                            });
                                    });

        }
    };

    $scope.showFilterForm = function() {
        $scope.showFilter = !$scope.showFilter;
    }




    $scope.changeCategory = function(category){
        $scope.isCategoryChanged = true;

        if (category == 'offer') {
            $scope.selectedTemplates = $scope.offerTemplates;

        }
        if (category == 'regret') {
            $scope.selectedTemplates = $scope.regretTemplates;

        }
        if (category == 'general') {
            $scope.selectedTemplates = $scope.generalTemplates;

        }
        if (category == 'informationRequest') {
            $scope.selectedTemplates = $scope.infoReqTemplates;

        }
        if (category == 'system') {
            $scope.selectedTemplates = $scope.systemTemplates;

        }

     }

    $scope.exportProfiles = function(applicantIds) {
        $scope.employeeEncryption();
        window.location.href=$scope.exportUrl + '[' + applicantIds + ']' + '&employeeid='+$rootScope.hgUserData.id + '&hash=' + $scope.employeeHash;
    };

    $scope.sortQuery = function (sortBy) {
        if (sortBy === 'created_at') {
            $scope.applicationsQuery.query.order = ( $scope.applicationsQuery.query.order =='created_at ASC') ? 'created_at DESC' : 'created_at ASC';
            $scope.applicationsNextQuery.query.order = ( $scope.applicationsNextQuery.query.order =='created_at ASC') ? 'created_at DESC' : 'created_at ASC';
        } else if (sortBy === 'ranking'){
            $scope.applicationsQuery.query.order = ( $scope.applicationsQuery.query.order =='ranking ASC') ? 'ranking DESC' : 'ranking ASC';
            $scope.applicationsNextQuery.query.order = ( $scope.applicationsNextQuery.query.order =='ranking ASC') ? 'ranking DESC' : 'ranking ASC';
        }
        else if (sortBy === 'candidate_rank'){
            $scope.applicationsQuery.query.order = ( $scope.applicationsQuery.query.order =='candidate_rank ASC') ? 'candidate_rank DESC' : 'candidate_rank ASC';
            $scope.applicationsNextQuery.query.order = ( $scope.applicationsNextQuery.query.order =='candidate_rank ASC') ? 'candidate_rank DESC' : 'candidate_rank ASC';
        }
        else if (sortBy === 'name'){
            $scope.applicationsQuery.query.order = ( $scope.applicationsQuery.query.order =='name ASC') ? 'name DESC' : 'name ASC';
            $scope.applicationsNextQuery.query.order = ( $scope.applicationsQuery.query.order =='name ASC') ? 'name DESC' : 'name ASC';
        }
        else if (sortBy === 'answer_score'){
            $scope.applicationsQuery.query.order = ( $scope.applicationsQuery.query.order =='answer_score DESC') ? 'answer_score ASC' : 'answer_score DESC';
            $scope.applicationsNextQuery.query.order = ( $scope.applicationsQuery.query.order =='answer_score DESC') ? 'answer_score ASC' : 'answer_score DESC';
        }
        $scope.applications = railApi.index('applicant_profiles', $scope.applicationsQuery.query).data;
        // @TODO may be we dom't need applicationsNext query. need investigation.
        $scope.applicationsNext = railApi.index('applicant_profiles', $scope.applicationsNextQuery.query).data;
        $scope.sortBy = $scope.applicationsQuery.query.order
        $scope.sortBy = $scope.applicationsNextQuery.query.order
    };

    $scope.backToStages = function () {
        $scope.applicantSearch = false;
        $scope.loadStageApplicationsFx($scope.lastStageViewed);

    };



    $scope.resetSearchField = function () {
        $scope.searchInJobForm = {
            "keywords": {
                "query_must": "",
            },
            "mark_future_consideration": false,
            "recruiter_apply": false,
            "black_flag": false,
            "is_internal": false,
            "job_id": $stateParams.job_id,
            "limit": 20,
            "offset": 0,
            "stage_id": 1,
            "referral": false,
            "applicationDate": {
            "start": null,
            "end": null
            },
            "milestones": {
            "offered": false,
            "presented": false,
            "hired": false,
            "interviewed": false,
            "regretted": false
            },
            "current_employee": false,
            "previous_employee": false,
            "blackFlag": false,
            "tags": []

        }

            $scope.searchToggles = {
                "field": "all",
                "anyTags": "all",
                "anyStage": "any",
                "selectKeywords": "all"

            }


            $scope.searchFormClone = {
                "keywords": {
                    "query_must": "",
                },
                "mark_future_consideration": false,
                "recruiter_apply": false,
                "black_flag": false,
                "is_internal": false,
                "job_id": $stateParams.job_id,
                "limit": 20,
                "offset": 0,
                "stage_id": 1,
                "referral": false,
                "applicationDate": {
                    "start": null,
                    "end": null
                },
                "milestones": {
                    "offered": false,
                    "presented": false,
                    "hired": false,
                    "interviewed": false,
                    "regretted": false
                },
                "current_employee": false,
                "previous_employee": false,
                "blackFlag": false,
                "tags": []
                }

    }


    $scope.getApplicantProfiles = function () {
        $scope.applicantSearch = true;

        $scope.unmodified = angular.equals($scope.searchInJobForm, $scope.searchFormClone);


        if ($scope.unmodified) {
            $scope.searchInJobForm.unmodified = true;
            $scope.searchFormClone.unmodified = true;
        }
        else {
            $scope.searchInJobForm.unmodified = false;
            $scope.searchFormClone.unmodified = false;
        }


        $scope.searchInJobForm.stage_id = $scope.selectedTab;
        $scope.searchInJobForm.anyTags = $scope.searchToggles.anyTags;
        $scope.searchInJobForm.keywords.field = $scope.searchToggles.selectKeywords;
        $scope.searchInJobForm.anyStage = $scope.searchToggles.anyStage
        $scope.searchInJobForm.field = $scope.searchToggles.field;


        $scope.searchFormClone.stage_id = $scope.selectedTab;
        $scope.searchFormClone.anyTags = $scope.searchToggles.anyTags;
        $scope.searchFormClone.keywords.field = $scope.searchToggles.selectKeywords;
        $scope.searchFormClone.anyStage = $scope.searchToggles.anyStage
        $scope.searchFormClone.field = $scope.searchToggles.field;

        $scope.applicationsQuery = railApi.post('applicant_profiles/search', $scope.searchInJobForm);
        $scope.applications = railApi.post('applicant_profiles/search', $scope.searchInJobForm).data;
        $scope.changeShowTabs(true);
        };
    $scope.updatePaginationQuery = function() {

        if ($scope.applicantSearch == false)
        {
            $scope.applicationsQuery.query.offset = ($scope.applicationsPage - 1) * $scope.applicationsQuery.query.limit;
            $scope.applicationsNextQuery.query.offset = $scope.applicationsPage * $scope.applicationsNextQuery.query.limit;
            //alert($scope.applicationsPage);
            $scope.applications = railApi.index('applicant_profiles', $scope.applicationsQuery.query).data;
            //alert($scope.applicationsPage);
            $scope.applicationsNext = railApi.index('applicant_profiles', $scope.applicationsNextQuery.query).data;
        }
        else
        {
            $scope.searchInJobForm.offset = ($scope.applicationsPage - 1) * $scope.searchInJobForm.limit;
            //alert($scope.applicationsPage);
            $scope.applications = railApi.post('applicant_profiles/search', $scope.searchInJobForm).data;
            $scope.searchInJobForm.offset = $scope.applicationsPage * $scope.applicationsNextQuery.query.limit;
            //alert($scope.applicationsPage);
            $scope.applicationsNext = railApi.post('applicant_profiles/search', $scope.searchInJobForm).data;

        }
    };

    function str2bytes (str) {
        var bytes = new Uint8Array(str.length);
        for (var i=0; i<str.length; i++) {

            bytes[i] = str.charCodeAt(i);

        }
        return bytes;
    }

    $scope.downloadResumeFiles = function() {
        railApi.get('uploaded_files/download_orig_resumes/', {'applicant_profile_ids': $scope.applicantIds}).promise.then(function(myData){
            var downloadURL = $scope.apiUrl + myData.zip_url;

            window.open(downloadURL);
        });
    };
    $scope.exportContactInfo = function() {
        railApi.get('jobs/export_bulk_contacts_by_job/', {'applicant_profile_ids': $scope.applicantIds, 'format': 'csv'}).promise.then(function(myData){
            var blob = new Blob([myData], {type: "text/plain; charset=utf-8"});
            saveAs(blob, "report.csv");
        });
    }
    $scope.removeRejectStage = function(stage){
        return stage.stage_type != 'rejected';
    }

    $scope.rankCandidate = function (applicant_profile, rank) {
        railApi.put('applicant_profiles/' + applicant_profile.id + '/rank_candidate/', {id: applicant_profile.id, candidate_rank: rank}, 'applicant_profile').promise.then(function(data) {
            $scope.loadStageApplicationsFx(applicant_profile.stage.id)
            $scope.generateLogs(data)
        });
    }

    $scope.aggregateRankCandidate = function (applicant_profile, rank) {
        railApi.put('applicant_profiles/' + applicant_profile.id + '/aggregate_rank_candidate/', {id: applicant_profile.id, candidate_rank: rank}, 'applicant_profile').promise.then(function(data) {
            $scope.loadStageApplicationsFx(applicant_profile.stage.id)
            $scope.generateLogs(data)
        });
    }

    $scope.confirmationPrompt = false; // show milestone bulk prompt
    $scope.markMilestoneDates = function(applicantIds, milestoneDateToAdd, milestoneToUpdate) {
        milestoneToUpdate = milestoneToUpdate.toLowerCase();
        railApi.put('applicant_profiles/' + applicantIds + '/mark_milestones/', {ids: applicantIds, milestone_date: milestoneDateToAdd, milestone: milestoneToUpdate, are_you_sure: 'false'}, 'applicant_profile')
                .promise
                .then(function(data) {
                    console
                    $scope.alreadyMilestonedApplicants = data;
                    if ($scope.alreadyMilestonedApplicants.length > 0) {
                        $scope.confirmationPrompt = true;
                    } else {
                        window.location.reload();
                    }
                });
}
    $scope.confirmMarkMilestoneDates = function(applicantIds, milestoneDateToAdd, milestoneToUpdate) {
        milestoneToUpdate = milestoneToUpdate.toLowerCase();
        railApi.put('applicant_profiles/' + applicantIds + '/mark_milestones/', {ids: applicantIds, milestone_date: milestoneDateToAdd, milestone: milestoneToUpdate, are_you_sure: 'true'}, 'applicant_profile')
                .promise
                .then(function(data) {
                    window.location.reload();
                });
    }

    $scope.markMilestoneDate = function (milestoneSelected, applicant_profile) {
        var milestoneDate = '';
        if (milestoneSelected == 'presented') {
            milestoneDate = $scope.applicant_profile.presented_date;
        } else if (milestoneSelected == 'interviewed') {
            milestoneDate = $scope.applicant_profile.interviewed_date;
        } else if (milestoneSelected == 'offered') {
            milestoneDate = $scope.applicant_profile.offered_date;
        } else if (milestoneSelected == 'hired') {
            milestoneDate = $scope.applicant_profile.hired_date;
        } else if (milestoneSelected == 'regretted') {
            milestoneDate = $scope.applicant_profile.regretted_date;
        }

        railApi.put('applicant_profiles/' + $scope.applicant_profile.id + '/mark_milestone/', {id: $scope.applicant_profile.id, milestone: milestoneSelected, milestone_date: milestoneDate}, 'applicant_profile').promise.then(function(data) {
            $scope.loadStageApplicationsFx(applicant_profile.stage.id)
            $scope.generateLogs(data)
        });
    }

    $scope.removeMilestoneDate = function (milestoneSelected, applicant_profile) {
        railApi.put('applicant_profiles/' + $scope.applicant_profile.id + '/remove_milestone/', {id: $scope.applicant_profile.id, milestone: milestoneSelected}, 'applicant_profile').promise.then(function(data) {
            $scope.loadStageApplicationsFx(applicant_profile.stage.id)
            $scope.generateLogs(data)
        });
    }

    $scope.employeeEncryption = function() {
        // console.log("hgUserData.id: " + $rootScope.hgUserData.id + " aasm: " + $rootScope.hgUserData);
        var today = new Date();
        var formattedDate = $filter('date')(today, 'yyyy-MM-dd');

        const shaObj = new jsSHA("SHA-256", "TEXT", { encoding: "UTF8" });
        shaObj.update($rootScope.hgUserData.email + $rootScope.hgUserData.role + "hiregroundSALT" + formattedDate);
        const hash = shaObj.getHash("HEX");

        $scope.employeeHash = hash;
    }



    $scope.placeHolderVal = []

    $scope.updateMessage = function(val, emailBody, id){

        $scope.placeHolderVal = val
        var emailString = JSON.stringify(emailBody)
        val.forEach( function(value){
            emailString = emailString.replaceAll('{{'+value.label+'}}', !_.isEmpty(value.value) ? value.value : '______')
        })
        $scope.sendEmail.body = JSON.parse(emailString)
    }

    $scope.editor = true;
    $scope.emailPreview = false

    $scope.organization = $rootScope.clientSettings
    $scope.previewEmail = function(rawEmail, type){
        if(type == 'bulk'){
            $scope.applInfo = {
                first_name: "{{user.name.first}}",
                last_name: "{{user.name.last}}",
                email: "{{user.email}}"
            }
        }else{
            $scope.applInfo = {
                first_name: $scope.applicant_profile.applicant.first_name,
                last_name: $scope.applicant_profile.applicant.last_name,
                email: $scope.applicant_profile.applicant.email
            }
        }
        $scope.editor = !$scope.editor
        $scope.emailPreview = !$scope.emailPreview
        if (rawEmail != undefined){
            var sysTagConversion = [
                {
                    tag: "{{user.name.first}}",
                    value: $scope.applInfo.first_name
                },{
                    tag: "{{user.name.last}}",
                    value: $scope.applInfo.last_name
                },{
                    tag: "{{user.email}}",
                    value: $scope.applInfo.email
                },{
                    tag: "{{jobName}}",
                    value: $scope.job.name.en
                },{
                    tag: "{{job.name}}",
                    value: $scope.job.name.en
                },{
                    tag: "{{jobNameFr}}",
                    value: $scope.job.name.fr
                },{
                    tag: "{{jobNameEs}}",
                    value: $scope.job.name.es
                },{
                    tag: "{{organization.name}}",
                    value: $scope.organization.name
                },{
                    tag: "{{contactEmail}}",
                    value: $scope.organization.email
                },{
                    tag: "{{contactPhone}}",
                    value: $scope.organization.phone
                },{
                    tag: "{{global.url.hostname}}",
                    value: $scope.organization.url
                }
            ]
            sysTagConversion.forEach( function(value){
                rawEmail = rawEmail.replaceAll(value.tag, !_.isEmpty(value.value) ? value.value : '______')
            })
            $scope.processedEmail = rawEmail
        }

    }

    $scope.closeClearEmail = function(type){
        if(type == 'new'){
            setTimeout(function(){
                $('#clearSendEmail').trigger("click")
            }, 100);
        }
    }

    $scope.sendMoreInfoRequest = function(){
        
    }

}
module.exports = applicantCtrl;
