'use strict';

var authResolve = function (authorization) {
    return authorization.authorize();
};

module.exports = function ($stateProvider) {
    $stateProvider
        // base
        .state('/', {
            data: {
                pageTitle: 'AvailableCareersPageTitle'
            },
            url: '/',
            templateUrl: 'view/apply/list.html',
            controller: 'jobListCtrl'
        })

        .state('help', {
            data: {
                pageTitle: 'HelpPageTitle'
            },
            url: '/help',
            templateUrl: 'view/help/job-seeker-help.html'
        })

        .state('helpDashboard', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin', 'recruiter', 'hiringManager'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter', 'hiring_manager', 'decision_maker', 'observer', 'pending'],
                pageTitle: 'HelpPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/help/dashboard',
            templateUrl: 'view/help/dashboard-help.html'
        })

        .state('helpCenter', {
            data: {
                // requiredRoles: ['siteAdmin', 'recruiter', 'hiringManager'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter', 'hiring_manager', 'decision_maker', 'observer', 'pending'],
                pageTitle: 'HelpPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/HelpCenter',
            templateUrl: 'view/help/HelpCenter.html',
            controller: 'helpCenterCtrl'
        })

        .state('accountView', {
            data: {
                // requiredRoles: ['superAdmin', 'siteAdmin', 'recruiter', 'hiringManager'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter', 'hiring_manager', 'decision_maker', 'observer', 'pending'],
                pageTitle: 'UserProfilePageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/account/view',
            templateUrl: 'view/user/account.html',
            controller: 'userPreferencesCtrl'

        })

        .state('unreadEmailList', {
            data: {
                // requiredRoles: ['superAdmin', 'siteAdmin', 'recruiter', 'hiringManager'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter', 'hiring_manager', 'decision_maker', 'observer', 'pending'],
                pageTitle: 'UnreadEmailPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/account/unreadEmails/:id',
            templateUrl: 'view/email/unreadList.html',
            controller: 'unreadEmailCtrl'

        })

        // Applicant view
        .state('applicantView', {
            data: {
                // requiredRoles: ['superAdmin', 'siteAdmin', 'recruiter', 'hiringManager'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter', 'hiring_manager', 'decision_maker'],
                pageTitle: 'CandidateDetailsPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/applicant/:applicant_id',
            templateUrl: 'view/applicant/view.html',
            controller: 'candidateHistoryCtrl'
        })
        // Apply
        .state('apply', {
            data: {
                pageTitle: 'JobApplicationPageTitle',
                appliedByRecruiter: false
            },
            url: '/apply/:page/:id?language',
            templateUrl: 'view/apply/apply.html',
            controller: 'applyCtrl'
        })

        // Internal Apply
        .state('internalApply', {
            data: {
                pageTitle: 'InternalJobApplicationPageTitle',
                appliedByRecruiter: false
            },
            url: '/internalApply/:internal_id/:page/:id?language',
            templateUrl: 'view/apply/apply.html',
            controller: 'applyCtrl'
        })

        // Apply
        .state('recruiterApplyJob', {
            data: {
                // requiredRoles: ['recruiter', 'siteAdmin', 'superAdmin', 'hiringManager'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter', 'hiring_manager'],
                pageTitle: 'Apply.AddCandidate',
                appliedByRecruiter: true
            },
            resolve: { authorize: authResolve },
            url: '/recruiterApply/:page/:id?language',
            templateUrl: 'view/apply/apply.html',
            controller: 'applyCtrl'
        })

        .state('applyList', {
            data: {
                pageTitle: 'AvailableCareersPageTitle'
            },
            url: '/job-list/:language',
            templateUrl: 'view/apply/list.html',
            controller: 'jobListCtrl'
        })

        .state('internalApplyList', {
            data: {
                pageTitle: 'AvailableCareersPageTitle'
            },
            url: '/internal-job-list/:internal_id',
            templateUrl: 'view/apply/internallist.html',
            controller: 'internalJobListCtrl'
        })

        .state('allJobApplyList', {
            data: {
                pageTitle: 'InternalAndPublicJobs'
            },
            url: '/internal-public-job-list/:internal_id',
            templateUrl: 'view/apply/allJobsList.html',
            controller: 'internalJobListCtrl'
        })

        .state('applyInternalList', {
            data: {
                pageTitle: 'AvailableCareersPageTitle'
            },
            url: '/job-list/:page',
            templateUrl: 'view/apply/list.html',
            controller: 'jobListCtrl'
        })

        // Bulk actions
        .state('bulkActions', {
            data: {
                // requiredRoles: ['superAdmin', 'recruiter'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter'],
                pageTitle: 'BulkActionsTitle'
            },
            resolve: {authorize: authResolve},
            url: '/bulk-actions/:id',
            templateUrl: 'view/bulk-actions/list.html'
        })

        // Candidate sources
        .state('candidateSource', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'CandidateSourcePageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/sources',
            templateUrl: 'view/candidate-source/list.html'
        })
        .state('candidateSourceArchiveList', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'CandidateSourcePageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/sources-archived',
            templateUrl: 'view/candidate-source/archive-list.html'
        })
        .state('candidateSourceEdit', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'EditCandidateSourcePageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/sources/view/:id',
            templateUrl: 'view/candidate-source/edit.html'
        })

        .state('candidateSourceAdd', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'AddCandidateSourcePageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/source/add/',
            templateUrl: 'view/candidate-source/add.html'
        })

        // Job Posting Categories
        .state('category', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'JobPostingCategoriesPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/category',
            templateUrl: 'view/category/list.html'
        })

        .state('categoryArchiveList', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'JobPostingCategoriesPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/category-archived',
            templateUrl: 'view/category/archive-list.html'
        })

        .state('categoryEdit', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'EditJobPostingCategoryPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/sources/category/:id&:resourceName',
            templateUrl: 'view/category/edit.html'
        })

        .state('categoryAdd', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'AddJobPostingCategoryPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/category/add/:type',
            templateUrl: 'view/category/add.html'
        })

        .state('feedsDocs', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin', 'recruiter'],
                requiredRoles: ['recruiter_admin', 'super_admin', 'recruiter'],
                pageTitle: 'JobFeedDocumentationTitle'
            },
            resolve: { authorize: authResolve },
            url: '/feeds/docs',
            templateUrl: 'view/feeds/docs.html'
        })

        .state('feedsJson', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin', 'recruiter'],
                requiredRoles: ['recruiter_admin', 'super_admin', 'recruiter'],
                pageTitle: 'JobFeedJSONTitle'
            },
            resolve: { authorize: authResolve },
            url: '/feeds/json',
            controller: function($stateParams, $state){
                // alert(myConfig.apiIP);
                window.location = myConfig.apiIP + "/jobs/json_feed?client=" + myConfig.client;
            }
        })

        .state('feedsXml', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin', 'recruiter'],
                requiredRoles: ['recruiter_admin', 'super_admin', 'recruiter'],
                pageTitle: 'JobFeedXMLTitle'
            },
            resolve: { authorize: authResolve },
            url: '/feeds/xml',
            controller: function($stateParams, $state){
                window.location = myConfig.apiIP + "/jobs/xml_feed?client=" + myConfig.client;
            }
        })

        // Rejection Reasons
        .state('rejectionReasons', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'RejectionReasonsPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/rejection-reasons',
            templateUrl: 'view/rejection-reasons/list.html'
        })

        .state('rejectionReasonsArchived', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'RejectionReasonsPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/rejection-reasons-archived',
            templateUrl: 'view/rejection-reasons/archive-list.html'
        })

        .state('rejectionReasonEdit', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'EditRejectionReasonsPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/rejection-reasons/view/:id',
            templateUrl: 'view/rejection-reasons/edit.html'
        })

        .state('rejectionReasonAdd', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'AddRejectionReasonsPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/rejection-reasons/add/',
            templateUrl: 'view/rejection-reasons/add.html'
        })
        // End of Rejection Reasons

        .state('careerInfo', {
            data: {
                pageTitle: 'JobDescriptionPageTitle'
            },
            url: '/career/info/:page/:id?language',
            templateUrl: 'view/apply/info.html',
            controller: 'careerInfoCtrl'
        })

        .state('reviewOffer', {
            data: {
                pageTitle: 'ReviewOffer'
            },
            url: '/:page/offer/:hash?language',
            templateUrl: 'view/offer-letter/reviewOffer.html',
            controller: 'offerLetterReviewCtrl'
        })


        .state('acceptOffer', {
            data: {
                pageTitle: 'AcceptOffer'
            },
            url: '/:page/accept/:hash?language',
            templateUrl: 'view/offer-letter/acceptOffer.html',
            controller: 'offerLetterReviewCtrl'
        })

        .state('answerInformationRequest', {
            data: {
                pageTitle: 'AnswerInformationRequest'
            },
            url: '/informationRequest/answer/:hash?language',
            templateUrl: 'view/information-request/answerForm.html',
            controller: 'informationRequestAnswerCtrl'
        })

        .state('informationRequestList', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter'],
                pageTitle: 'informationRequestList'
            },
            resolve: { authorize: authResolve },
            url: '/information-request/list',
            templateUrl: 'view/information-request/list.html'
        })

        .state('informationRequestView', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter'],
                pageTitle: 'ViewInformationRequest'
            },
            resolve: { authorize: authResolve },
            url: '/information-request/view/:id',
            templateUrl: 'view/information-request/view.html',
            controller: 'informationRequestCtrl'
        })

        .state('internalCareerInfo', {
            data: {
                pageTitle: 'JobDescriptionPageTitle'
            },
            url: '/career/internalInfo/:internal_id/:page/:id?language',
            templateUrl: 'view/apply/internalInfo.html',
            controller: 'careerInfoCtrl'
        })

        .state('clientDetails', {
            data: {
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'CompanyDetailsPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/client/details',
            templateUrl: 'view/client/details.html',
            controller: 'clientCtrl'
        })
        .state('clientSettings', {
            data: {
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'SiteSettingsPageTitle'
            },
            resolve: {authorize: authResolve},
            url: '/client/settings',
            templateUrl: 'view/client/settings.html'
        })
        .state('clientReports', {
            data: {
                requiredRoles: ['super_admin'],
                pageTitle: 'CompanyReportsPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/client/reports',
            templateUrl: 'view/client/reports.html',
            controller: 'reportsCtrl'
        })
        .state('siteConfiguration', {
            data: {
                requiredRoles: ['super_admin'],
                pageTitle: 'SiteConfiguration'
            },
            resolve: { authorize: authResolve },
            url: '/client/siteConfiguration',
            templateUrl: 'view/client/siteConfiguration.html',
            controller: 'siteConfigurationCtrl'
        })
        .state('jobBoardFeeds', {
            data: {
                requiredRoles: ['super_admin'],
                pageTitle: 'JobBoardFeeds'
            },
            resolve: { authorize: authResolve },
            url: '/client/jobBoardFeeds',
            templateUrl: 'view/client/jobBoardFeeds.html',
            controller: 'siteConfigurationCtrl'
        })
        .state('clientAccountLicenses', {
            data: {
                requiredRoles: ['super_admin'],
                pageTitle: 'AccountLicenses'
            },
            resolve: {authorize: authResolve},
            url: '/client/accountLicenses',
            templateUrl: 'view/client/accountLicenses.html'
        })
        .state('clientCandidateRemoval', {
            data: {
                requiredRoles: ['super_admin'],
                pageTitle: 'RemoveMultipeCandidate'
            },
            resolve: {authorize: authResolve},
            url: '/client/candidateRemoval',
            templateUrl: 'view/client/candidateRemoval.html',
            controller: 'userManagementCtrl'
        })
        .state('jobPostingOptions', {
            data: {
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'JobPostingOptionsPageTitle'
            },
            resolve: {authorize: authResolve},
            url: '/client/job-posting-options',
            templateUrl: 'view/client/jobPostingOptions.html',
            controller: 'jobPostingOptionsCtrl'
        })
        .state('departments', {
            data: {
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'CompanyDepartmentsPageTitle'
            },
            resolve: {authorize: authResolve},
            url: '/departments',
            templateUrl: 'view/departments/list.html'
        })
        .state('departmentsAdd', {
            data: {
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'AddCompanyDepartmentsPageTitle'
            },
            resolve: {authorize: authResolve},
            url: '/departments/add',
            templateUrl: 'view/departments/add.html'
        })
        .state('departmentsEdit', {
            data: {
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'EditCompanyDepartmentsPageTitle'
            },
            resolve: {authorize: authResolve},
            url: '/departments/edit/:id',
            templateUrl: 'view/departments/edit.html'
        })
        .state('dashboard', {
            data: {
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter', 'hiring_manager', 'decision_maker', 'observer', 'pending'],
                pageTitle: 'DashboardPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/dashboard',
            templateUrl: 'view/dashboard/dashboard.html',
            controller: 'dashboardCtrl'
        })

        .state('unreadSms', {
            data: {
                // requiredRoles: ['superAdmin', 'recruiter'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter'],
                pageTitle: 'UnreadSmsTitle'
            },
            resolve: {authorize: authResolve},
            url: '/unread-sms',
            templateUrl: 'view/dashboard/unread-sms.html',
            controller: 'notificationsCtrl'
        })

        // Job templates
        .state('emailTemplateList', {
            data: {
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'EmailTemplatesPageTitle'
            },
            resolve: {authorize: authResolve},
            url: '/template/email',
            templateUrl: 'view/email/templates/list.html',
            controller: 'templateEmailCtrl'
        })
        .state('emailTemplateArchiveList', {
            data: {
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'EmailTemplatesPageTitle'
            },
            resolve: {authorize: authResolve},
            url: '/template/emails-archived',
            templateUrl: 'view/email/templates/archive-list.html',
            controller: 'templateEmailCtrl'
        })
        .state('emailTemplateAdd', {
            data: {
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'AddNewEmailTemplatePageTitle'
            },
            resolve: {authorize: authResolve},
            url: '/template/email/add',
            templateUrl: 'view/email/templates/add.html',
            controller: 'templateEmailCtrl'
        })
        .state('emailTemplateClone', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'AddNewEmailTemplatePageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/template/email/add/:id',
            templateUrl: 'view/email/templates/add.html',
            controller: 'templateEmailCtrl'
        })
        .state('emailTemplateEdit', {
            data: {
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'EditEmailTemplatePageTitle'
            },
            resolve: {authorize: authResolve},
            url: '/template/email/edit/:id',
            templateUrl: 'view/email/templates/edit.html',
            controller: 'templateEmailCtrl'
        })
        .state('emailTemplateDelete', {
            data: {
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'DeleteEmailTemplatePageTitle'
            },
            resolve: {authorize: authResolve},
            url: '/template/email/delete/:id',
            templateUrl: 'view/email/templates/delete.html'
        })

        // Errors
        .state('errorUnknown', {
            data: {
                pageTitle: 'UnknownErrorPageTitle'
            },
            url: '/error/unknown',
            templateUrl: 'view/error/unknown.html'
        })
        .state('errorAcl', {
            data: {
                pageTitle: 'PermissionRequiredPageTitle'
            },
            url: '/error/acl',
            templateUrl: 'view/error/acl.html'
        })
        .state('errorSso', {
            data: {
                pageTitle: 'SingleSignOnPageTitle'
            },
            url: '/error/sso',
            templateUrl: 'view/error/sso.html'
        })


        // ClientSettings: Internal Flalgs (Domains and IPs)
        .state('internalFlags', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'InternalFlagsPageTitle'
            },
            resolve: {authorize: authResolve},
            url: '/client/internal-flags',
            templateUrl: 'view/client/internal.html',
            controller: 'internalFlagsCtrl'
        })

        // Integrations
        .state('clientIntegrations', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'IntegrationsTitle'
            },
            resolve: {authorize: authResolve},
            url: '/client/integrations',
            templateUrl: 'view/client/integrations.html',
            controller: 'integrationsCtrl',
            reloadOnSearch: false
        })



        // Applicant management
        .state('jobApplication', {
            data: {
                // requiredRoles: ['superAdmin', 'recruiter', 'hiringManager'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter', 'hiring_manager', 'decision_maker'],
                pageTitle: 'ViewJobApplicantsPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/job/applicants/:job_id',
            templateUrl: 'view/job/application-management.html',
            controller: 'applicantCtrl'
        })
        .state('jobApplication.profile', {
            data: {
                // requiredRoles: ['superAdmin', 'recruiter', 'hiringManager'],
                requiredRoles: ['observer', 'super_admin', 'recruiter_admin', 'recruiter', 'hiring_manager', 'decision_maker'],
                pageTitle: 'ViewJobApplicantsPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/:applicant_profile_id',
            templateUrl: 'view/job/partials/application-management/profile.html'
        })

        // Search history link back to jobseeker profile
        .state('jobLinkProfile', {
            data: {
                // requiredRoles: ['superAdmin', 'recruiter', 'hiringManager'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter', 'hiring_manager', 'decision_maker'],
                pageTitle: 'ViewJobApplicantsPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/applicant/jobProfile/:job_id/:applicant_profile_id/:stage_id',
            controller: 'applicantCtrl',
            templateUrl: 'view/job/partials/application-management/historyLinkProfile.html'
        })



        // Jobs
        .state('jobList', {
            data: {
                // requiredRoles: ['hiringManager', 'recruiter', 'siteAdmin', 'superAdmin'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter', 'hiring_manager', 'decision_maker', 'observer', 'pending'],
                pageTitle: 'JobListPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/job/list/',
            params: {
                status: null
            },
            templateUrl: 'view/job/list.html'
        })
        .state('jobAddSuccess', {
            data: {
                // requiredRoles: ['superAdmin', 'recruiter', 'hiringManager'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter'],
                pageTitle: 'JobAddSuccessPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/job/success/:id',
            templateUrl: 'view/job/success.html',
            controller: 'jobCtrl'
        })
        .state('jobAdd', {
            data: {
                // requiredRoles: ['superAdmin', 'recruiter'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter'],
                pageTitle: 'PostJobPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/job/add',
            templateUrl: 'view/job/add.html',
            controller: 'jobCtrl'
        })
        .state('jobEdit', {
            data: {
                // requiredRoles: ['superAdmin', 'recruiter', 'hiringManager'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter', 'hiring_manager'],
                pageTitle: 'JobEditPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/job/edit/:id',
            templateUrl: 'view/job/edit.html',
            controller: 'jobCtrl'
        })

        // Job templates
        .state('jobTemplateList', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'JobTemplatesPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/template/job',
            templateUrl: 'view/job/templates/list.html'
        })

        .state('offerLetterList', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter'],
                pageTitle: 'OfferLetterList'
            },
            resolve: { authorize: authResolve },
            url: '/offer-letter/list',
            templateUrl: 'view/offer-letter/list.html'
        })

        .state('expiredOfferLink', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                pageTitle: 'OfferLetterList'
            },
            url: '/expiredLink',
            templateUrl: 'view/offer-letter/expiredLink.html'
        })

        .state('offerLetterView', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter'],
                pageTitle: 'ViewOfferLetter'
            },
            resolve: { authorize: authResolve },
            url: '/offer-letter/view/:id',
            templateUrl: 'view/offer-letter/view.html',
            controller: 'offerLetterCtrl'
        })

        // Tag List
        .state('tagList', {
            data: {
                requiredRoles: ['recruiter', 'recruiter_admin', 'super_admin'],
                pageTitle: 'Tags.AvailableTagsTitle'
            },
            resolve: { authorize: authResolve },
            url: '/tags',
            templateUrl: 'view/tag/list.html',
            controller: 'tagsCtrl'
        })

        .state('tagAdd', {
            data: {
                requiredRoles: ['recruiter', 'recruiter_admin', 'super_admin'],
                pageTitle: 'Tags.AddTagTitle'
            },
            resolve: { authorize: authResolve },
            url: '/tags/add/',
            templateUrl: 'view/tag/add.html',
            controller: 'tagsCtrl'
        })

        .state('tagEdit', {
            data: {
                requiredRoles: ['recruiter', 'recruiter_admin', 'super_admin'],
                pageTitle: 'Tags.EditTagTitle'
            },
            resolve: { authorize: authResolve },
            url: '/tags/edit/:id',
            templateUrl: 'view/tag/edit.html',
            controller: 'tagEditCtrl'
        })

        .state('tagArchiveList', {
            data: {
                requiredRoles: ['recruiter', 'recruiter_admin', 'super_admin'],
                pageTitle: 'Tags.Archived'
            },
            resolve: { authorize: authResolve },
            url: '/tags/tag-archived/',
            templateUrl: 'view/tag/archive-list.html',
            controller: 'tagsCtrl'
        })

        .state('jobTemplateArchiveList', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'JobTemplatesPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/template/job-archived',
            templateUrl: 'view/job/templates/archive-list.html'
        })

        .state('jobTemplateAdd', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'PostJobTemplatePageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/template/job/add',
            templateUrl: 'view/job/templates/add.html',
            controller: 'jobCtrl'
        })
        .state('jobTemplateEdit', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'JobTemplateEditPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/template/job/view/:id',
            templateUrl: 'view/job/templates/edit.html'
        })

        // Questions
        .state('questionList', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'QuestionBankPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/question',
            params: {
                categorySelected: null
            },
            templateUrl: 'view/question/list.html',
            controller: 'questionTemplateAddCtrl'
        })
        .state('questionAdd', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'AddNewQuestionPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/question/add/:CategoryId',
            templateUrl: 'view/question/add.html',
            controller: 'questionTemplateAddCtrl'
        })
        .state('questionEdit', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'Question.ViewEditQuestion'
            },
            resolve: { authorize: authResolve },
            url: '/question/view/:id',
            templateUrl: 'view/question/edit.html',
            controller: 'questionTemplateEditCtrl'
        })

        .state('questionArchived', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'ArchivedQuestions'
            },
            resolve: { authorize: authResolve },
            url: '/question-archived',
            templateUrl: 'view/question/list-archived.html'
        })


        // Question Categories
        // .state('questionCategoryList', {
        //     data: {
        //         // requiredRoles: ['siteAdmin', 'superAdmin'],
        //         requiredRoles: ['recruiter_admin', 'super_admin'],
        //         pageTitle: 'QuestionCategoriesPageTitle'
        //     },
        //     resolve: { authorize: authResolve },
        //     url: '/classification',
        //     templateUrl: 'view/question-category/list.html',
        //     controller: 'questionTemplateAddCtrl'
        // })
        .state('questionCategoryEdit', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'EditQuestionCategoryPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/classification/view/:id',
            templateUrl: 'view/question-category/edit.html'
        })
        .state('questionCategoryAdd', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'AddQuestionCategoryPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/classification/add',
            templateUrl: 'view/question-category/add.html',
            controller: 'questionTemplateAddCtrl'
        })

        // Question Section
        .state('questionSectionCategoryList', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'QuestionCategoriesPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/section-classification',
            templateUrl: 'view/form-category/list.html',
            controller: 'formCtrl'
        })

        .state('questionSectionCategoryEdit', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'EditFormCategoryPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/section-classification/view/:id',
            templateUrl: 'view/form-category/edit.html'

        })

        .state('questionSectionCategoryAdd', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'AddQuestionCategoryPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/section-classification/add',
            templateUrl: 'view/form-category/add.html',
            controller: 'formCtrl'
        })

        .state('questionSectionList', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'FormsPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/section',
            templateUrl: 'view/question-section/list.html',
            controller: 'formCtrl'
        })
        .state('questionSectionEdit', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'Forms.ViewForm'
            },
            resolve: { authorize: authResolve },
            url: '/section/view/:id',
            templateUrl: 'view/question-section/edit.html',
            controller: 'formEditCtrl'
        })
        .state('questionSectionAdd', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'AddNewFormPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/section/add',
            templateUrl: 'view/question-section/add.html',
            controller: 'formCtrl'
        })

        .state('questionSectionClone', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'AddNewFormPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/section/add/:id',
            templateUrl: 'view/question-section/add.html',
            controller: 'formCtrl'
        })

        .state('questionSectionArchiveList', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'ArchivedFormsPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/section-archive',
            templateUrl: 'view/question-section/archive-list.html',
            controller: 'formCtrl'
        })

        .state('login', {
            data: {
                pageTitle: 'LoginPageTitle'
            },
            url: '/login',
            templateUrl: 'view/login.html',
            controller: 'loginCtrl'
        })

        .state('reportList', {
            data: {
                // requiredRoles: ['superAdmin', 'recruiter'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter', 'observer'],
                pageTitle: 'reportListPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/report',
            templateUrl: 'view/report/list.html',
            controller: 'reportsCtrl'
        })

        .state('reportJobsPosted', {
            data: {
                // requiredRoles: ['superAdmin', 'recruiter'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter', 'observer'],
                pageTitle: 'JobsPostedPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/report/jobs-posted',
            templateUrl: 'view/report/jobs-posted.html',
            controller: 'reportJobPostingCtrl'
        })

        .state('reportEEO', {
            data: {
                // requiredRoles: ['superAdmin', 'recruiter'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter', 'observer'],
                pageTitle: 'EEOReport'
            },
            resolve: { authorize: authResolve },
            url: '/report/eeo',
            templateUrl: 'view/report/eeo.html',
            controller: 'reportEEOCtrl'
        })

        .state('reportJobPosting', {
            data: {
                // requiredRoles: ['superAdmin', 'recruiter'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter', 'observer'],
                pageTitle: 'Reports.JobPublishingReport'
            },
            resolve: { authorize: authResolve },
            url: '/report/reportJobPosting',
            templateUrl: 'view/report/job-posting.html',
            controller: 'reportJobPostingCtrl'
        })

        .state('reportClosedJobs', {
            data: {
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter', 'observer'],
                pageTitle: 'ClosedJobsReport'
            },
            resolve: { authorize: authResolve },
            url: '/report/reportClosedJobs',
            templateUrl: 'view/report/job-closed.html',
            controller: 'reportJobPostingCtrl'
        })

        .state('reportJobViews', {
            data: {
                // requiredRoles: ['superAdmin', 'recruiter'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter', 'observer'],
                pageTitle: 'JobViewsPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/report/job-views',
            templateUrl: 'view/report/job-views.html'
        })

        .state('reportNewJobViews', {
            data: {
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter', 'observer'],
                pageTitle: 'NewJobViewsReportPateTitle'
            },
            resolve: { authorize: authResolve },
            url: '/report/reportNewJobViews',
            templateUrl: 'view/report/new-job-views.html',
            controller: 'reportJobViewCtrl'
        })

        .state('reportNewCandidateSource', {
            data: {
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter', 'observer'],
                pageTitle: 'CandidateSourceByJobs'
            },
            resolve: { authorize: authResolve },
            url: '/report/reportNewCandidateSource',
            templateUrl: 'view/report/new-candidate-source.html',
            controller: 'reportCandidateSourceCtrl'
        })

        .state('reportCandidateContactInfo', {
            data: {
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter', 'observer'],
                pageTitle: 'CandidateContactInfo'
            },
            resolve: { authorize: authResolve },
            url: '/report/reportCandidateContactInfo',
            templateUrl: 'view/report/candidate-contact-info.html',
            controller: 'reportCandidateContactInfoCtrl'
        })

        .state('reportMilestone', {
            data: {
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter'],
                pageTitle: 'MilestoneReport'
            },
            resolve: { authorize: authResolve },
            url: '/report/reportMilestone',
            templateUrl: 'view/report/milestone-report.html',
            controller: 'reportMilestoneCtrl'
        })

        .state('reportCandidateGeneric', {
            data: {
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter'],
                pageTitle: 'CandidateApplicationsAdvanced'
            },
            resolve: { authorize: authResolve },
            url: '/report/reportCandidateGeneric',
            templateUrl: 'view/report/candidateGeneric-report.html',
            controller: 'reportCandidateGenericCtrl'
        })

        .state('reportActiveUsersByJobPosting', {
            data: {
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter', 'observer'],
                pageTitle: 'CandidateSourceByJobs'
            },
            resolve: { authorize: authResolve },
            url: '/report/reportActiveUsersByJobPosting',
            templateUrl: 'view/report/active-user-by-job-posting.html',
            controller: 'reportJobPostingCtrl'
        })

        .state('reportCandidateSource', {
            data: {
                // requiredRoles: ['superAdmin', 'recruiter'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter', 'observer'],
                pageTitle: 'CandidateSourcePageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/report/candidate-source',
            templateUrl: 'view/report/candidate-source.html'
        })

        .state('reportTimeToHire', {
            data: {
                // requiredRoles: ['superAdmin', 'recruiter'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter', 'observer'],
                pageTitle: 'TimetoHirePageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/report/time-to-hire',
            templateUrl: 'view/report/time-to-hire.html'
        })

        .state('reportApplicantsByStage', {
            data: {
                // requiredRoles: ['superAdmin', 'recruiter'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter', 'observer'],
                pageTitle: 'ReportApplicantsByStageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/report/applicants-by-stage',
            templateUrl: 'view/report/applicants-by-stage.html'
        })

        .state('reportApplicantsByRejection', {
            data: {
                // requiredRoles: ['superAdmin', 'recruiter'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter', 'observer'],
                pageTitle: 'ReportApplicantsByRejectionTitle'
            },
            resolve: { authorize: authResolve },
            url: '/report/applicants-by-rejection',
            templateUrl: 'view/report/applicants-by-rejection.html'
        })

        .state('reportUserAccessLogs', {
            data: {
                requiredRoles: ['super_admin', 'recruiter_admin'],
                pageTitle: 'UserAccessLogs'
            },
            resolve: { authorize: authResolve },
            url: '/report/user-access-logs',
            templateUrl: 'view/report/user-access-logs.html',
            controller: 'reportUserAccessLogsCtrl'
        })

        .state('reportCandidateRemovalLogs', {
            data: {
                requiredRoles: ['super_admin', 'recruiter_admin'],
                pageTitle: 'CandidateRemovalLogs'
            },
            resolve: { authorize: authResolve },
            url: '/report/candidate-removal-logs',
            templateUrl: 'view/report/candidate-removal-logs.html',
            controller: 'reportCandidateRemovalLogCtrl'
        })

        .state('reportUserChangeLogs', {
            data: {
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter', 'observer'],
                pageTitle: 'UserChangeLogsReport'
            },
            resolve: { authorize: authResolve },
            url: '/report/userChangeLogs',
            templateUrl: 'view/report/user-change-logs.html'
        })

        .state('exportUserChangeLogs', {
            data: {
                requiredRoles: ['super_admin', 'recruiter_admin',],
                pageTitle: 'UserChangeLogsReport'
            },
            resolve: { authorize: authResolve },
            url: '/report/user-change-logs',
            templateUrl: 'view/report/user-change-logs.html',
            controller: 'reportUserChangeLogsCtrl'
        })

        .state('reportJob', {
            data: {
                // requiredRoles: ['superAdmin', 'recruiter'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter', 'observer'],
                pageTitle: 'ReportsPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/report/job/:id',
            templateUrl: 'view/report/job.html'
        })

        .state('search', {
            data: {
                // requiredRoles: ['superAdmin', 'recruiter'],
                requiredRoles: ['super_admin', 'recruiter_admin', 'recruiter'],
                pageTitle: 'SearchPageTitle'
            },
            resolve: {authorize: authResolve},
            url: '/search',
            templateUrl: 'view/search/search.html',
            controller: 'searchCtrl'
        })

        .state('termsAndConditions', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'TermsConditionsPageTitle'
            },
            resolve: {authorize: authResolve},
            url: '/terms-conditions',
            templateUrl: 'view/client/terms-conditions.html'
        })

        .state('sms', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'SmsAdminPageTitle'
            },
            resolve: {authorize: authResolve},
            url: '/sms',
            templateUrl: 'view/client/sms.html'
        })

        .state('userManagement', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'UserManagementPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/users',
            templateUrl: 'view/user/management.html',
            controller: 'userManagementCtrl'
        })

        .state('userPermissions', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'UserPermissionsPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/user-permissions',
            templateUrl: 'view/user/user-permissions.html',
            controller: 'userPermissionsCtrl'
        })

        .state('userManagementCreate', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'CreateUserPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/user/create',
            templateUrl: 'view/user/create-user.html',
            controller: 'userManagementCtrl'
        })

        .state('userManagementCreateSuccess', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'CreateUserSuccessPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/user/success',
            templateUrl: 'view/user/success.html',
            controller: 'userManagementCtrl'
        })

        .state('userManagementEditUser', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'EditUserPageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/user/edit/:id',
            templateUrl: 'view/user/edit.html',
            controller: 'userManagementEditCtrl'
        })

        .state('userManagementPasswordReset', {
            data: {
                pageTitle: 'PasswordResetPageTitle'
            },
            url: '/password-reset/:token',
            templateUrl: 'view/user/password-reset.html',
            controller: 'userManagementResetPasswordCtrl'
        })

        .state('userPasswordResetRequest', {
            data: {
                pageTitle: 'PasswordResetPageTitle'
            },
            url: '/password-reset-request',
            templateUrl: 'view/user/password-reset-request.html',
            controller: 'passwordResetRequestCtrl'
        })

        .state('userPasswordResetValidation', {
            data: {
                pageTitle: 'PasswordResetPageTitle'
            },
            url: '/auth/password/edit',
            templateUrl: 'view/user/password-reset-validate.html',
            controller: 'userManagementResetPasswordCtrl'
        })

        .state('userManagementInvitationAccept', {
            data: {
                pageTitle: 'InvitationAccept'
            },
            url: '/invitation-accept/:invitation_token',
            templateUrl: 'view/user/invitation-accept.html',
            controller: 'userManagementAcceptInvitationCtrl'
        })

        // Workflow
        .state('workflow', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'WorkflowsPageTitle'
            },
            resolve: {authorize: authResolve},
            url: '/workflows',
            templateUrl: 'view/workflow/list-workflow.html'
        })

        .state('workflowArchived', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'ArchivedWorkflows'
            },
            resolve: { authorize: authResolve },
            url: '/workflow-archived',
            templateUrl: 'view/workflow/archive-list.html'
        })

        .state('workflowAdd', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'AddWorkflowPageTitle'
            },
            resolve: {authorize: authResolve},
            url: '/workflow-add/',
            templateUrl: 'view/workflow/add-workflow.html',
            controller: 'workflowCtrl'
        })

        .state('workflowClone', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'AddNewEmailTemplatePageTitle'
            },
            resolve: { authorize: authResolve },
            url: '/workflow-add/:id',
            templateUrl: 'view/workflow/add-workflow.html',
            controller: 'workflowCtrl'
        })

        .state('workflowStageAdd', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'AddNewStagePageTitle'
            },
            resolve: {authorize: authResolve},
            url: '/workflow-stage-add/:id',
            templateUrl: 'view/workflow/add-stage.html',
            controller: 'stagesCtrl'
        })

        .state('workflowEdit', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'EditWorkflowPageTitle'
            },
            resolve: {authorize: authResolve},
            url: '/workflow-edit/:id',
            templateUrl: 'view/workflow/edit-workflow.html'
        })

        .state('stageEdit', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'EditWorkflowStagePageTitle'
            },
            resolve: {authorize: authResolve},
            url: '/stage-edit/:id/:stage_id',
            templateUrl: 'view/workflow/edit-stage.html'
        })

        .state('twitter', {
            data:{
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'AddTwitterAccount'
            },
            resolve: {authorize: authResolve},
            url:'/twitter',
            templateUrl: 'view/twitter/twitter.html'
        })

        .state('twitterAuthToken', {
            data: {
                // requiredRoles: ['siteAdmin', 'superAdmin'],
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'AddTwitterAccount'
            },
            resolve: {authorize: authResolve},
            controller: function($stateParams, $state){
                window.location = 'https://api.twitter.com/oauth/authorize?oauth_token=' + $stateParams.id;
            },
            url: '/twitterAuthToken/:id',
            template: 'Redirecting to twitter...'
        })

        .state('locationList', {
            data: {
                requiredRoles: ['recruiter_admin', 'super_admin'],
                pageTitle: 'LocationListingTitle'
            },
            resolve:{authorize: authResolve},
            url: '/client/locations',
            templateUrl: 'view/locations/list.html',
            controller: 'locationsCtrl'
        })
};
