'use strict';

var clientInfoCtrl = function ($scope, RailRestangular, railApi, $rootScope, $state) {

    // var userRestApi = railApi.all('employees');
    var userRestApi = RailRestangular.service('employees');
    var licenseRestApi = RailRestangular.service('licenses');
    var clientSettingsRestApi = RailRestangular.service('organizations');

    $scope.hgForm = {
        preferred_language: 'en',
        notification_frequency: 'none',
        mailing_list: false
    };
    
    // Add NEW Recruiter Admins to Mailing list
    $scope.addToMailingList = function() {
        if ($scope.hgForm.aasm_state && $scope.hgForm.aasm_state == 'recruiter_admin') {
            $scope.hgForm.mailing_list = true;
        }
    }

    $scope.showErrorLabel = false;
    $scope.createUserError = false;
    $scope.createUserMessage = '';
    $scope.showCreateUserForm = false;
    $scope.newUserModel = {};
    $scope.newUserModel.employee = {};
    $scope.newUserModel.employee.aasm_state = {};
    $scope.availableLicenses = {};
    $scope.availableLicenseList = [];
    $scope.availableAclRoleList = {
        pending: 'Inactive',
        observer: 'Observer',
        decision_maker: 'Decision Maker',
        recruiter: 'Recruiter',
        hiring_manager: 'Hiring Manager',
        recruiter_admin: 'Recruiter Admin'
    };

    /**
     * Language List
     */
    clientSettingsRestApi.getList().then(function (clientData) {
        $scope.languageList = clientData[0].available_languages;
    });


    /**
     * Getting the list of Users (excluding hg users)
     */
    var getUserList = function() {
        /**
         * Get List of Users
         */
        userRestApi.getList().then(function (users) {
            $scope.userList = users;
            $scope.usersCount = users.length;
        }, function(errorResponse) {
            $scope.showErrorLabel = true;
            $scope.errorMessage = errorResponse;
        });
    };

    railApi.get('organizations/1').promise.then(function(data) {
        $scope.organization = data;
    });

    /**
     * Getting license information for client
     */
    var getLicenseList = function () {
        /**
         * Get List of Users
         */
        licenseRestApi.getList().then(function (licenses) {
            $scope.licenseList = {};
            $scope.licenseList.super_admin = {};
            $scope.licenseList.recruiter_admin = {};
            $scope.licenseList.recruiter = {};
            $scope.licenseList.hiring_manager = {};
            $scope.licenseList.decision_maker = {};
            $scope.licenseList.observer = {};
            $scope.licenseList.pending = {};
            $scope.licenseList.archived = {};

            angular.forEach(licenses, function (val, key) {
                $scope.licenseList[val.key] = val;

            });
        }, function (errorResponse) {
            $scope.showErrorLabel = true;
            $scope.errorMessage = errorResponse;
        });
    };

    /**
     * Getting license information for client
     */
    var getAvailableLicenseList = function () {
        /**
         * Get List of Licenses available for creating new users
         */
        licenseRestApi.getList().then(function (licenses) {
            $scope.availableLicenses = _.filter(licenses,  function(e){ return e.number_seats > e.user_count; });

            angular.forEach($scope.availableLicenses, function (val) {
                $scope.availableLicenseList.push(val.key);
            });
            angular.forEach($scope.aclRoleListActive, function (val, key) {
                if ($scope.availableLicenseList.includes(key)) {
                    
                } else {
                    delete $scope.availableAclRoleList[key];
                }
            });
        }, function (errorResponse) {
            $scope.showErrorLabel = true;
            $scope.errorMessage = errorResponse;
        });
    };

    getUserList();
    getLicenseList();
    getAvailableLicenseList();

    $scope.createUserChangeLog = function (change_requested) {
        $scope.change_requested = change_requested
        railApi.post('user_change_logs/create_log', {
            first_name: $scope.hgForm.first_name,
            last_name: $scope.hgForm.last_name,
            email: $scope.hgForm.email,
            aasm_state: $scope.hgForm.aasm_state,
            change_made: $scope.change_requested, 
            change_made_by: $rootScope.hgUserData.email,
        });
    }

    $scope.showModalExistingUser = function (license) {
        $scope.licence_key = license;
        $scope.existingUserModal = $scope.modal.open({
            templateUrl: 'existingUserModal.ejs',
            scope: $scope,
            size: 'lg'
        });
    };

    // $scope.userChangeLogs = railApi.get('user_change_logs').data;

    /**
     * Create a user
     *
     * When submitting the form, this will post the user data to the API
     * Shows a message when an error happens, or tells the user when a duplicate email has been entered.
     */
    $scope.createUser = function(hgForm) {
        userRestApi.post(hgForm).then(function(response) {
            $scope.showCreateUserForm = false;

            // todo show message "Created Successfully"
            $scope.newAccount = 'Account Created' 
            $scope.createUserChangeLog($scope.newAccount)

            getUserList();
            $state.go('userManagementCreateSuccess');

        }, function(errorResponse) {

            $scope.createUserError = true;

            // User Already Exists
            if(406 === errorResponse.status &&
                errorResponse.data &&
                errorResponse.data.errors &&
                errorResponse.data.errors.email &&
                errorResponse.data.errors.email.message &&
                'Duplicate e-mail address. This user is already in the system.' === errorResponse.data.errors.email.message[0]) {

                // user already exists in database
                $scope.createUserMessage = 'User Already Exists';

              // License has maxed seats
            } else if(403 === errorResponse.status) {
                $scope.createUserMessage = 'You have the maximum allowed users for that license';

              // User Already Exists
            } else if(406 === errorResponse.status &&
                        errorResponse.data &&
                        errorResponse.data.errors &&
                        errorResponse.data.key) {

                // user already exists in database
                $scope.createUserMessage = 'User Already Exists';
                $scope.errorMessage = errorResponse;
                $scope.showModalExistingUser(errorResponse.data.key);
            }  else {
                $scope.createUserMessage = 'Unknown Error';
            }

        });
    };

    $scope.saveNotes = function() {
        railApi.put('organizations/1', $scope.organization, 'organization');
    };

    /**
     * Create User Form: AclRoles
     *
     * @type {boolean}
     */
    $scope.toggleCreateUserForm = function() {
        $scope.newUserModel.user = {};
        $scope.showCreateUserForm = !$scope.showCreateUserForm;
        $scope.createUserError = false;
    };

    $scope.aclRoleList = {
        archived: 'Archived',
        pending: 'Inactive',
        observer: 'Observer',
        decision_maker: 'Decision Maker',
        recruiter: 'Recruiter',
        hiring_manager: 'Hiring Manager',
        recruiter_admin: 'Recruiter Admin'
    };

    $scope.aclRoleListActive = {
        observer: 'Observer',
        decision_maker: 'Decision Maker',
        recruiter: 'Recruiter',
        hiring_manager: 'Hiring Manager',
        recruiter_admin: 'Recruiter Admin'
    };

    $scope.reloadThisPage = function(){
        window.location.reload();
    }
    
    // Resets the used_seats counter
    $scope.resetLicenses = function() {
        railApi.get('licenses/counter_reset');
        window.location.reload();
    }

    $scope.setLicensesObserver = function() {

        if($scope.licenseList.observer.number_seats < $scope.licenseList.observer.user_count) {
            $scope.invalidAccountLicenseModal = $scope.modal.open({
                templateUrl: 'invalidAccountLicenseModal.ejs',
                scope: this,
                size: 'lg'
            });
        }
        else {
            railApi.put('licenses/'+ $scope.licenseList.observer.id, {number_seats: $scope.licenseList.observer.number_seats}, 'license');
        }
        
    }

    $scope.setLicensesDM = function() {

        if($scope.licenseList.decision_maker.number_seats < $scope.licenseList.decision_maker.user_count) {
            $scope.invalidAccountLicenseModal = $scope.modal.open({
                templateUrl: 'invalidAccountLicenseModal.ejs',
                scope: this,
                size: 'lg'
            });
        }
        else {
            railApi.put('licenses/'+ $scope.licenseList.decision_maker.id, {number_seats: $scope.licenseList.decision_maker.number_seats}, 'license');
        }
        
    }

    $scope.setLicensesHM = function() {

        if($scope.licenseList.hiring_manager.number_seats < $scope.licenseList.hiring_manager.user_count) {
            $scope.invalidAccountLicenseModal = $scope.modal.open({
                templateUrl: 'invalidAccountLicenseModal.ejs',
                scope: this,
                size: 'lg'
            });
        }
        else {
            railApi.put('licenses/'+ $scope.licenseList.hiring_manager.id, {number_seats: $scope.licenseList.hiring_manager.number_seats}, 'license');
        }
        
    }

    $scope.setLicensesRecruiter = function() {

        if($scope.licenseList.recruiter.number_seats < $scope.licenseList.recruiter.user_count) {
            $scope.invalidAccountLicenseModal = $scope.modal.open({
                templateUrl: 'invalidAccountLicenseModal.ejs',
                scope: this,
                size: 'lg'
            });
        }
        else {
            railApi.put('licenses/'+ $scope.licenseList.recruiter.id, {number_seats: $scope.licenseList.recruiter.number_seats}, 'license');
        }
        
    }

    $scope.setLicensesRecruiterAdmin = function() {

        if($scope.licenseList.recruiter_admin.number_seats < $scope.licenseList.recruiter_admin.user_count) {
            $scope.invalidAccountLicenseModal = $scope.modal.open({
                templateUrl: 'invalidAccountLicenseModal.ejs',
                scope: this,
                size: 'lg'
            });
        }
        else {
            railApi.put('licenses/'+ $scope.licenseList.recruiter_admin.id, {number_seats: $scope.licenseList.recruiter_admin.number_seats}, 'license');
        }
        
    }

    $scope.openCLoseJobModal = function(){
        $scope.closeJobModal = $scope.modal.open({
            templateUrl: 'candidateRemovalConfirmationModal.ejs',
            scope: this,
            size: 'lg'
        });
    }
    $scope.removalLog = []
    $scope.removeApplicants = async function(applicant_ids, profile_ids, removal_note, removalDate){
        $scope.showSpinnerModal = true
        $scope.removalLog = []
        for (const prof_id of profile_ids){
            await railApi.put('applicants/remove_candidate_information', {profile_ids: [prof_id], removal_note: removal_note, bulk_removal_date: removalDate}, 'applicants').promise.then(function(data){
                $scope.showSpinnerModal = false;
                $scope.removalLog.push(data)
            })
            if (profile_ids[profile_ids.length-1] == prof_id){
                if(applicant_ids.length > 0){
                    for (const app_id of applicant_ids){
                        await railApi.put('applicants/remove_candidate_information', {applicant_ids: [app_id], removal_note: removal_note, bulk_removal_date: removalDate}, 'applicants').promise.then(function(data){
                            $scope.showSpinnerModal = false;
                            $scope.removalLog.push(data)
                        })
                        if (applicant_ids[applicant_ids.length-1] == app_id){
                            $scope.removalComplete = true
                            $scope.countRemoved($scope.removalLog, removalDate, removal_note)
                        }
                    }
                }else{
                    $scope.removalComplete = true
                    $scope.countRemoved($scope.removalLog, removalDate, removal_note)
                }
                
            }
        }
        
    }
    $scope.countRemoved = function(removalLog, removal_date, removal_note){
        $scope.removedApplicants = _.filter(removalLog, function(data){
            return data.status.includes("Applicant")
        })
        $scope.removedProfiles = _.filter(removalLog, function(data){
            return data.status.includes("Profile")
        })
        railApi.post('candidate_removal_logs', {
            removal_datezzz: removal_date,
            no_candidates_removed: $scope.removedApplicants.length,
            no_applications_removed: $scope.removedProfiles.length,
            supporting_notes: removal_note
        }).promise.then(function(){
            $scope.logCreated = true
        })
    }

    $scope.getAffectedCandidates = function(removalDate){
        $scope.showSpinner = true;
        railApi.put('applicants/candidates_to_remove', {removalDate: removalDate}, 'date').promise.then(function(data){
            $scope.showSpinner = false;
            $scope.applicants_affected = data;
        })
    }

    $scope.emptyLog = function(){
        $scope.removalLog = []
    }

};

module.exports = clientInfoCtrl;