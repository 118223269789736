'use strict';

var clientInfoCtrl = function ( Restangular, railApi, $state, $scope) {

    // var clientRestApi = Restangular.all('client');

    var clientRestApi = railApi.index('organizations');


    $scope.client = {};
    $scope.client.settings = {};

    // var clientRestApi = railApi.index('organizations');
    $scope.result =  railApi.index('organizations');
    $scope.$watch('result', function (newVal, oldVal) {
        if (newVal != oldVal) {
            if (!newVal.pending) { // and no error
                $scope.client = newVal.data[0];
            }
        }
    }, true);


    // clientRestApi.getList({fields: 'id settings'}).then(function (data) {
    //     $scope.client = data[0];
    // }, function (err) {
    //     // error happened
    //     console.log(err);
    // });


    /**
     * INTERNAL IP ADDRESSES
     *
     */
    // $scope.ipAddressUpdateError = false;
    // $scope.ipAddressUpdateErrorMessages = [];
    // $scope.editIpAddressSettings = false;
    //
    // $scope.toggleEditIpAddressSettings = function () {
    //     $scope.editIpAddressSettings = !$scope.editIpAddressSettings;
    // };
    //
    // $scope.addNewIpAddress = function () {
    //     $scope.ipAddressUpdateError = false;
    //     if (undefined !== $scope.newIpAddress && '' !== $scope.newIpAddress) {
    //         if (undefined === $scope.client.settings.internalIpAddresses) {
    //             $scope.client.settings.internalIpAddresses = [];
    //         }
    //
    //         var dupeCheck = $scope.client.settings.internalIpAddresses.indexOf($scope.newIpAddress);
    //
    //         if (-1 == dupeCheck) {
    //             $scope.client.settings.internalIpAddresses.push($scope.newIpAddress);
    //
    //             $scope.client.put().then(function (response) {
    //                 $scope.newIpAddress = '';
    //             }).catch(function (errResponse) {
    //
    //                 $scope.ipAddressUpdateErrorMessages = [];
    //                 angular.forEach(errResponse.data.errors, function (value, key) {
    //                     $scope.ipAddressUpdateErrorMessages.push(value.message[0]);
    //                 });
    //
    //                 $scope.ipAddressUpdateErrorMessage = errResponse.data.errors;
    //                 console.log($scope.errorMessages);
    //
    //                 var removeIndex = $scope.client.settings.internalIpAddresses.indexOf($scope.newIpAddress);
    //
    //                 $scope.deleteIpAddress(removeIndex);
    //
    //                 $scope.ipAddressUpdateError = true;
    //             });
    //
    //         } else {
    //             $scope.ipAddressUpdateError = true;
    //             $scope.ipAddressUpdateErrorMessages = [];
    //             $scope.ipAddressUpdateErrorMessages.push('Value Already Exists');
    //         }
    //
    //     }
    // };

    // $scope.deleteIpAddress = function (index) {
    //     $scope.ipAddressUpdateError = false;
    //     $scope.client.settings.internalIpAddresses.splice(index, 1);
    //     $scope.client.put().then(function (response) {
    //
    //     }).catch(function (err) {
    //         $scope.ipAddressUpdateError = true;
    //         console.log('failed');
    //     });
    // };


    /**
     * INTERNAL DOMAINS
     *
     */
    $scope.domainUpdateError = false;
    $scope.domainUpdateErrorMessages = [];
    $scope.editDomainSettings = false;

    $scope.toggleEditInternalDomainSettings = function () {
        $scope.editDomainSettings = !$scope.editDomainSettings;
    };


    $scope.addNewDomain = function () {
        $scope.domainUpdateError = false;

        if (undefined !== this.newDomain && '' !== this.newDomain) {

            if (undefined === $scope.client.internal_domains || null === $scope.client.internal_domains) {
                $scope.client.internal_domains = [];
                var dupeCheck = -1
            }

            else {
                var dupeCheck = $scope.client.internal_domains.indexOf(this.newDomain);
            }

            if (-1 == dupeCheck) {

                $scope.client.internal_domains.push(this.newDomain);
                this.newDomain = '';

                var path = '/organizations/' + $scope.client.id;

                $scope.newDomainResult = railApi.put(path, {id: $scope.client.id, internal_domains: $scope.client.internal_domains}, 'organization');

                // $scope.$watch('newDomainResult', function(newVal, oldVal) {
                //     if(newVal != oldVal) {
                //         $scope.clientData = railApi.index('organizations').data;
                //         $scope.newDomain = '';
                //     }
                // })
                // $scope.$watch('result', function (newVal, oldVal) {
                //     console.log(newVal);
                //     if (newVal != oldVal) {
                //         if (!newVal.pending) { // and no error
                //             $scope.newDomain = '';
                //             $state.reload();
                //         }
                //         else {

                //             $scope.client.put().then(     (response) {
                //                 $scope.newDomain = '';
                //             }).catch(function (errResponse) {

                //                 $scope.domainUpdateErrorMessages = [];
                //                 angular.forEach(errResponse.data.errors, function (value, key) {
                //                     $scope.domainUpdateErrorMessages.push(value.message[0]);
                //                 });

                //                 $scope.domainUpdateErrorMessage = errResponse.data.errors;

                //                 var removeIndex = $scope.internal_domains.indexOf(this.newDomain);

                //                 $scope.deleteDomain(removeIndex);

                //                 $scope.domainUpdateError = true;
                //             });
                //       }
                //     }
                // }, true);

            } else {
                $scope.domainUpdateError = true;
                $scope.domainUpdateErrorMessages = [];
                $scope.domainUpdateErrorMessages.push('Value Already Exists');
            }
        }
    };

    $scope.deleteDomain = function (index) {
        $scope.domainUpdateError = false;
        $scope.client.internal_domains.splice(index, 1);

        var path = '/organizations/' + $scope.client.id;
        $scope.result = railApi.put(path, {id: $scope.client.id, internal_domains: $scope.client.internal_domains}, 'organization').promise.then(function(){
            $scope.clientData = railApi.index('organizations').data;
        });
        // $scope.$watch('result', function (newVal, oldVal) {
        //     if (newVal != oldVal) {
        //         if (!newVal.pending) { // and no error
        //             $state.reload();
        //         }
        //         else {
        //             $scope.domainUpdateError = true;
        //             console.log('failed');
        //         }
        //     }
        // }, true);

        // $scope.client.put().then(function (response) {
        //
        // }).catch(function (err) {
        //
        // });
    };
};

module.exports = clientInfoCtrl;
