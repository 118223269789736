'use strict';

var userManagementResetPasswordCtrl = function ($scope, $stateParams, RailRestangular, $state, $window, railApi, $location) {

    $scope.formData = {};
    $scope.formData.token = $stateParams.token;
    $scope.formData.password = '';
    $scope.formData.password_confirmation = '';

    var clientSettingsRestApi = RailRestangular.service('organizations');
    clientSettingsRestApi.getList().then(function (clientData) {
        $scope.passwordRequirements = clientData[0].length_setting;
        $scope.trigger = 'go';
    }, function (err) {
        // error happened
        console.log(err);
    });

	// Attempt at getting rid of the 404 error that occurs when you click on a password reset link twice.
    // $scope.resetPasswordValidate = function () {
    //   $scope.redirect_url = RailRestangular.one('auth/password/edit').get({
    //     redirect_url: $location.search().redirect_url,
    //     reset_password_token: $location.search().reset_password_token
    //   }).then(function (response) {
    //       console.log(response);
    //       console.log(response.status);
    //       if(200 === response.status) {
    //         console.log(data.data);
    //         $window.open(data.data, "_self");
    //       }
    //       else {
    //         $scope.showPasswordValidateFail = true;
    //         $scope.showPasswordValidateMessage = 'This token has been used or is invalid';
    //       };
    //   }, function(data) {
    //     console.log(data);
    //     });
    // };

    $scope.resetPasswordValidate = function () {
      $scope.redirect_url = RailRestangular.one('auth/password/edit').get({
        redirect_url: $location.search().redirect_url,
        reset_password_token: $location.search().reset_password_token
      }).then(function(data){
        console.log(data);
        $window.open(data.data, "_self");
      });
    };

    $scope.toggleNewPassword = function () { $scope.typeNewPassword = !$scope.typeNewPassword; };    

    $scope.toggleConfirmPassword = function () { $scope.typeConfirmPassword = !$scope.typeConfirmPassword; };    



    $scope.submitResetPassword = function () {
        var payload = {};
        var user_email = $window.localStorage.getItem('uid');
        var user_client = $window.localStorage.getItem('client');
        var user_access_token = $window.localStorage.getItem('access-token');
        // payload.token = $scope.formData.token;
        payload.password = $scope.formData.password;
        payload.password_confirmation = $scope.formData.password_confirmation;
        $window.localStorage.setItem('uid', $location.search().uid);
        $window.localStorage.setItem('client', $location.search().client_id);
        $window.localStorage.setItem('access-token', $location.search().token);
        console.log($location.search().uid)
        $scope.result = RailRestangular.one('auth/password').customPUT(payload, '', '', {'uid': $location.search().uid,
                                                                                         'config': $location.search().config,
                                                                                         'reset_password': $location.search().reset_password,
                                                                                         'expiry': $location.search().expiry,
                                                                                         'client': $location.search().client_id,
                                                                                         'access-token': $location.search().token})
            .then(function() {
                $state.go('login');
                $window.localStorage.setItem('uid', user_email);
                $window.localStorage.setItem('client', user_client);
                $window.localStorage.setItem('access-token', user_access_token);
            }, function(err) {
                console.log(err);
                $state.go('errorUnknown');
                $window.localStorage.setItem('uid', user_email);
                $window.localStorage.setItem('client', user_client);
                $window.localStorage.setItem('access-token', user_access_token);
            }
        );
    }
};

module.exports = userManagementResetPasswordCtrl;
