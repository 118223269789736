/**
 * Created by dylan on 22/01/15.
 */

var hgValidate = require('hg-validators');

/**
 * Function Validators Factory
 *
 * Returns all hg function validators.
 *
 * @constructor
 */

var Service = function ($rootScope) {
    this.requireLanguages = function () {
        return function (validation) {
            validation = validation || hgValidate('{PATH}');

            return function () {
                var customValidation = {};
                if ($rootScope.available_languages) {
                    $rootScope.available_languages.forEach(function (currentLang) {
                        customValidation[currentLang] = validation;

                    });

                    return hgValidate(customValidation);
                }
            };
        };
    };
};

Service.$inject = ['$rootScope'];

module.exports = Service;