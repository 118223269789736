'use strict';

var siteConfigurationCtrl = function ($scope, railApi) {

  $scope.organizationData = railApi.index('organizations').data;
  
  $scope.enableUserAccessLogs = function(status){
    if(status == true){
      railApi.put('organizations/1', {show_user_access_logs: false}, 'organization').promise.then(function(){
      })
    }else{
      railApi.put('organizations/1', {show_user_access_logs: true}, 'organization').promise.then(function(){
      })
    }
  }

  $scope.enableDisableracandidateinforemoval = function(status){
    if( status == true ){
      railApi.put('organizations/1', {ra_candidate_info_removal: false}, 'organization').promise.then(function(){
        // window.location.reload();
      })
    }else{
      railApi.put('organizations/1', {ra_candidate_info_removal: true}, 'organization').promise.then(function(){
        // window.location.reload();
      })
    }
  }
  ///////////////////////////////PGi Integration
  $scope.payrollIntegration = function(status, pgiId){
    $scope.payrollLoad = false
    console.log($(this).parent())
    if( status == true ){
      railApi.put('organizations/1', {integrate_to_pgi: false, pgi_client_id: pgiId}, 'organization').promise.then(function(){
        // window.location.reload();
      })
    }else{
      railApi.put('organizations/1', {integrate_to_pgi: true, pgi_client_id: pgiId}, 'organization').promise.then(function(){
        // window.location.reload();
      })
    }
  }
  $scope.changePgiDivId = function(divId){
    railApi.put('organizations/1', {pgi_client_id: divId}, 'organization').promise.then(function(){
      if(divId == ""){
        railApi.put('organizations/1', {integrate_to_pgi: false}, 'organization').promise.then(function(){
          $scope.organizationData[0].integrate_to_pgi = false
        })
      }
    })
  }
  ///////////////////////////////

  ///////////////////////////////Linkedin Feed///////////////////////////////
  $scope.enableLinkedin = function(status, linkinId){
    console.log($(this).parent())
    if( status == true ){
      railApi.put('organizations/1', {generate_linkedin_feed: false, linkedin_company_id: linkinId}, 'organization').promise.then(function(){
        // window.location.reload();
      })
    }else{
      railApi.put('organizations/1', {generate_linkedin_feed: true, linkedin_company_id: linkinId}, 'organization').promise.then(function(){
        // window.location.reload();
      })
    }
  }

  $scope.changeLinkedinId = function(companyId){
    railApi.put('organizations/1', {linkedin_company_id: companyId}, 'organization').promise.then(function(){
      if(companyId == ""){
        railApi.put('organizations/1', {generate_linkedin_feed: false}, 'organization').promise.then(function(){
          $scope.organizationData[0].generate_linkedin_feed = false
        })
      }
    })
  }
  ///////////////////////////////

  ///////////////////////////////Charity Village Feed///////////////////////////////
  $scope.includeCvfFeed = function(status, cvId){
    if( status == true ){
      railApi.put('organizations/1', {include_to_charity_village: false, cvf_custom_name: cvId}, 'organization').promise.then(function(){
        // window.location.reload();
      })
    }else{
      railApi.put('organizations/1', {include_to_charity_village: true, cvf_custom_name: cvId}, 'organization').promise.then(function(){
        // window.location.reload();
      })
    }
  }

  $scope.changeCvfCustomName = function(cvId){
    railApi.put('organizations/1', {cvf_custom_name: cvId}, 'organization').promise.then(function(){
      if(cvId == ""){
        railApi.put('organizations/1', {include_to_charity_village: false}, 'organization').promise.then(function(){
          $scope.organizationData[0].include_to_charity_village = false
        })
      }
    })
  }
  ///////////////////////////////
  ///////////////////////////////Indeed Feed///////////////////////////////
  $scope.enableIndeedFeed = function(status){
    if( status == true ){
      railApi.put('organizations/1', {generate_indeed_feed: false}, 'organization').promise.then(function(){
        // window.location.reload();
      })
    }else{
      railApi.put('organizations/1', {generate_indeed_feed: true}, 'organization').promise.then(function(){
        // window.location.reload();
      })
    }
  }

  $scope.applyWithIndeed = function(status){
    if( status == true ){
      railApi.put('organizations/1', {apply_with_indeed: false}, 'organization').promise.then(function(){
        // window.location.reload();
      })
    }else{
      railApi.put('organizations/1', {apply_with_indeed: true}, 'organization').promise.then(function(){
        // window.location.reload();
      })
    }
  }
  ///////////////////////////////
  ///////////////////////////////offer letter system/////////////////////////////
  $scope.enableOfferLetterSystem = function(status){
    if( status == true ){
      railApi.put('organizations/1', {offer_letter_system: false}, 'organization').promise.then(function(){
        // window.location.reload();
      })
    }else{
      railApi.put('organizations/1', {offer_letter_system: true}, 'organization').promise.then(function(){
        // window.location.reload();
      })
    }
  }
  ///////////////////////////////
  ///////////////////////////////Information Request/////////////////////////////
  $scope.enableInformationRequest = function(status){
    if( status == true ){
      railApi.put('organizations/1', {info_request: false}, 'organization').promise.then(function(){
        // window.location.reload();
      })
    }else{
      railApi.put('organizations/1', {info_request: true}, 'organization').promise.then(function(){
        // window.location.reload();
      })
    }
  }
  ///////////////////////////////

    ///////////////////////////////Trovit Feed///////////////////////////////
    $scope.enableTrovitFeed = function(status){
      console.log($(this).parent())
      if( status == true ){
        railApi.put('organizations/1', {enable_trovit_feed: false}, 'organization').promise.then(function(){
          // window.location.reload();
        })
      }else{
        railApi.put('organizations/1', {enable_trovit_feed: true}, 'organization').promise.then(function(){
          // window.location.reload();
        })
      }
    }

    ///////////////////////////////

    ///////////////////////////////Glass Door Feed///////////////////////////////
    $scope.enableGlassDoorFeed = function(status){
      console.log($(this).parent())
      if( status == true ){
        railApi.put('organizations/1', {enable_glass_door_feed: false}, 'organization').promise.then(function(){
          // window.location.reload();
        })
      }else{
        railApi.put('organizations/1', {enable_glass_door_feed: true}, 'organization').promise.then(function(){
          // window.location.reload();
        })
      }
    }

    ///////////////////////////////

    ///////////////////////////////General Feed///////////////////////////////
    $scope.enableGeneralFeed = function(status){
      console.log($(this).parent())
      if( status == true ){
        railApi.put('organizations/1', {enable_general_feed: false}, 'organization').promise.then(function(){
          // window.location.reload();
        })
      }else{
        railApi.put('organizations/1', {enable_general_feed: true}, 'organization').promise.then(function(){
          // window.location.reload();
        })
      }
    }

    ///////////////////////////////

    ///////////////////////////////Jooble Feed///////////////////////////////
    $scope.enableJoobleFeed = function(status){
      console.log($(this).parent())
      if( status == true ){
        railApi.put('organizations/1', {enable_jooble_feed: false}, 'organization').promise.then(function(){
          // window.location.reload();
        })
      }else{
        railApi.put('organizations/1', {enable_jooble_feed: true}, 'organization').promise.then(function(){
          // window.location.reload();
        })
      }
    }

    ///////////////////////////////
    
    ///////////////////////////////Division Company ID///////////////////////////////
    $scope.enableDivisionCompanyId = function(status){
      if( status == true ){
        railApi.put('organizations/1', {division_company_id: false}, 'organization').promise.then(function(){
          // window.location.reload();
        })
      }else{
        railApi.put('organizations/1', {division_company_id: true}, 'organization').promise.then(function(){
          // window.location.reload();
        })
      }
    }

    ///////////////////////////////

    $scope.saveCompanyId = function(alternate_company_name, company_id, job_division){
      railApi.post('company_ids/', {alternate_company_name: alternate_company_name, company_id_no: company_id, job_category_id: job_division}).promise.then(function(data){
        $scope.getCompanyIds()
      })
    }

    $scope.getCompanyIds = function(){
      $scope.divisions = []
      railApi.index('job_categories', {'category_type': 'division'}).promise.then(function(divisions){
        railApi.index('company_ids/').promise.then(function(data){
          $scope.company_ids = data
          $scope.company_ids.forEach(function(compds){
            divisions.splice(_.indexOf(divisions, _.findWhere(divisions, { id : compds.job_category.id})), 1)
          })
        })
  
        $scope.divisions = divisions
      });
      
    }

    $scope.removeCompanyId = function(id, index, company_id_no){
      railApi.put('company_ids/' + id, {is_deleted: true, company_id_no: company_id_no}, 'company_id').promise.then(function(data){
        $scope.company_ids.splice(index, 1)
        $scope.getCompanyIds()
      })
    }

};

module.exports = siteConfigurationCtrl;