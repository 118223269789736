'use strict';
var config = require('../../config.js');

var questionTemplateEditCtrl = function ($scope, railApi, $state, $stateParams, $rootScope, $modal) {

    $scope.page = ""
    $scope.i = 0;
    $scope.hgForm;
    $scope.paramCategory;
    var preferred_language = $rootScope.preferred_language
    $scope.question_types = [
        { id: 'multiple-choice' },
        { id: 'multiple-answer' },
        { id: 'select-dropdown' },
        { id: 'long-answer' },
        { id: 'short-answer' }
    ];
    $scope.newAnswer = {
        en: "",
        fr: "",
        es: ""
    }

    railApi.get('question_templates/' + $stateParams.id).promise.then(function (data) {
        $scope.hgForm = data;
        $scope.hgForm.answer_templates_attributes = [];
        $scope.hgForm.form_selected_languages = data.required_languages

        var company_department_ids = $scope.hgForm.company_departments.map(function(c) {return c.id});
        var question_category_ids = $scope.hgForm.question_categories.map(function(c) {return c.id});

        $scope.hgForm.company_department_ids = company_department_ids;
        $scope.hgForm.question_category_ids = question_category_ids;
        console.log($scope.hgForm)

    })

    $scope.removeAnswer = function (id) {
        var answer_to_remove = _.find($scope.hgForm.answer_templates, {id: id})
        answer_to_remove['_destroy'] = 'true';
        $scope.hgForm.answer_templates_attributes.push(answer_to_remove)
        console.log($scope.hgForm.answer_templates_attributes)
        $scope.hgForm.answer_templates = $scope.hgForm.answer_templates.filter(function (answer) { return answer.id != id })
        $scope.hgForm.answer_templates.sort(function (a, b) {
            return a.order - b.order;
        })
        _.forEach($scope.hgForm.answer_templates, function (answer, index) {

            answer.order = index
        });

    }


    //$scope.hgFormQuery = railApi.get('question_templates/' + $stateParams.id);
    //$scope.hgForm = $scope.hgFormQuery.data

    $scope.companyDepartments = railApi.index('company_departments').data;
    $scope.questionCategories = railApi.index('question_categories').data;
    $scope.selected = railApi.get('question_templates/' + $stateParams.id).data;
    $scope.editable = false;
    // $scope.$watch("hgForm.answer_templates", function (newValue, oldValue) {
    //     if (newValue && (newValue !== oldValue)) {
    //         //this works but is firing too soon
    //         console.log('is this firing')
    //         //TODO: FIX THAT WITH A SERVICE.
    //         railApi.put('question_templates/' + $stateParams.id, $scope.hgForm, 'question_template')
    //     }
    // })


    $scope.addAnswer = function () {
        var maxOrder = _.max($scope.hgForm.answer_templates, function(answer){ return answer.order; }).order || 0;


        $scope.hgForm.answer_templates.push({
            "text": { "en": "", "fr": "", "es": "" }, "order": maxOrder + 1
        })
    }

    $scope.deleteAnswer = function (index) {
        if ($scope.hgForm.answer_templates.length > 2) {
            $scope.hgForm.answer_templates.splice(index, 1);
        }
    }

    $scope.reOrderAnswers = function (order1, order2) {


        var index1 = _.findIndex($scope.hgForm.answer_templates, { order: order1 })
        var index2 = _.findIndex($scope.hgForm.answer_templates, { order: order2 })


        $scope.hgForm.answer_templates[index1].order = order2;
        $scope.hgForm.answer_templates[index2].order = order1;


    }


    $scope.updateQuestion = function () {
        //railApi.put('questions/' + $stateParams.id, $scope.hgForm, 'question')
    }
    // $scope.archiveTemplate = function() {
    //   console.log("TOP");
    //   $scope.archiveQuestionTemplatePopup = $modal.open({
    //     templateUrl: 'archiveQuestionTemplatePopup.ejs',
    //     scope: $scope,
    //     size: 'lg'
    //   });
    //   console.log("bottom");
    // }

    $scope.dismissArchiveModal = function() {
      $scope.archiveQuestionTemplatePopup.dismiss();
    }

    $scope.dismissUnArchiveModal = function() {
      $scope.unArchiveQuestionTemplatePopup.dismiss();
    }

    $scope.setArchived = function (archived) {

        railApi.put('question_templates/' + $stateParams.id, { archived: archived, question_type: $scope.hgForm.question_type, question_category_ids: $scope.hgForm.question_category_ids }, 'question_template').promise
        .then(function (data) {
          $scope.hgForm.archived = archived;

          if ($scope.hgForm.archived === true) {
            $scope.archiveQuestionTemplatePopup = $modal.open({
              templateUrl: 'archiveQuestionTemplatePopup.ejs',
              scope: $scope,
              size: 'lg'
            });
          }
          else if ($scope.hgForm.archived === false) {
            $scope.unArchiveQuestionTemplatePopup = $modal.open({
              templateUrl: 'unArchiveQuestionTemplatePopup.ejs',
              scope: $scope,
              size: 'lg'
            });
          }
        });

    }

    $scope.submit = function() {
        _.forEach($scope.hgForm.answer_templates, function (answer) {
            $scope.hgForm.answer_templates_attributes.push(answer);

        });

        railApi.put('question_templates/' + $stateParams.id, $scope.hgForm, 'question_template');

        $state.go('^.questionList')
    }

    railApi.index('tags', {is_viewable: 'true'}).promise.then(function(tagData) {

        $scope.availableTags = tagData
    });


};

module.exports = questionTemplateEditCtrl;
