'use strict';
var config = require('../config.js');

var candidateHistoryCtrl = function ($scope, $http, Restangular, railApi, $stateParams, $rootScope, $state, Upload) {

    // $scope.userProfileQuery = railApi.get('applicants/' + $stateParams.applicant_id, {});


    railApi.get('applicants/applicants_by_role/', {'applicant_id': $stateParams.applicant_id, 'employee_id': $rootScope.hgUserData.id}).promise.then(function(myData){
        $scope.userProfileQuery = myData;
        $scope.applicant = $scope.userProfileQuery;
        $scope.applicant.totalNotes = _.map($scope.applicant.applicant_profiles, 'notes');

    });

    // $scope.applicant = $scope.userProfileQuery.data;
    $scope.first = true;
    $scope.apiUrl = config.apiIP;
    $scope.replyEmail = {};
    $scope.attachFilesIds = [];
    $scope.isIE = /*@cc_on!@*/false || !!document.documentMode;
    $scope.newContactInfo = false;
    $scope.employeeStatusString;
    $scope.employeeStatusBoolean = true;
    $scope.showStatusAlert = false;
    $scope.isCategoryChanged = false;
    $scope.generalTemplates = [];
    $scope.allTemplates = [];
    $scope.systemTemplates = [];
    $scope.offerTemplates = [];
    $scope.regretTemplates = [];
    $scope.selectedTemplates = [];
    $scope.generalCount;
    $scope.offerCount;
    $scope.regretCount;
    $scope.archivedCount;
    $scope.checkPlaceholders = true;
    // $scope.sendEmail = {
    //     subject: {
    //         en: 'test'
    //     }
    // };

    $scope.regretEmails = railApi.index('template_emails', 'template_email').data;


    railApi.index('template_emails', {is_deleted: true}).promise.then(function(data){$scope.archivedTemplates = data; $scope.archivedCount = data.length;});
    railApi.index('template_emails', {is_deleted: false}).promise.then(function(data){
        data.forEach(function(template){
            if (template.email_type == "general") {
                $scope.generalTemplates.push(template)
            }
            if (template.email_type == "offer") {
                $scope.offerTemplates.push(template)
            }
            if (template.email_type == "RejectionEmailtype") {
                $scope.regretTemplates.push(template)
            }
            if (template.is_system_default) {
                $scope.systemTemplates.push(template);
            }
            $scope.allTemplates.push(template)

        })
        $scope.offerCount = $scope.offerTemplates.length;
        $scope.regretCount = $scope.regretTemplates.length;
        $scope.generalCount = $scope.generalTemplates.length;
        $scope.selectedTemplates = $scope.generalTemplates;
        console.log($scope.allTemplates)


    })


    $scope.$watch('applicant', function(newVal, oldVal){
        if((newVal !== oldVal) && !$scope.userProfileQuery.pending) {

            $scope.files = [];
            $scope.coverLetterFileId = [];
            $scope.attachedFileIds = [];
            $scope.profileIds = [];
            $scope.totalEmails = 0;
            $scope.totalNotes = 0;

            if ($scope.applicant.uploaded_files){
                _.forEach($scope.applicant.uploaded_files, function(value, key, object){
                    if(value.file_type === 'resume') $scope.files.push(value);
                    if(value.file_type === 'cover_letter') $scope.coverLetterFileId.push(value);
                    if(value.file_type === 'attachment') $scope.attachedFileIds.push(value);
                })
            }

            function isTrue(element, index, array) {
                return element == true;
            };
            // const isTrue = (isItTrue) => isItTrue == true;
            var trueArray = []
            if ($scope.applicant.applicant_profiles){
                _.forEach($scope.applicant.applicant_profiles, function(value, key, object){
                    $scope.profileIds.push(value);
                    trueArray.push(value.mark_future_consideration)

                    // Find total notes and emails accross all jobs
                    var emailCount = value.hgemails.length;
                    $scope.totalEmails += emailCount;
                    var noteCount = value.notes.length;
                    $scope.totalNotes += noteCount;

                })
                $scope.markForFuture = trueArray.some(isTrue)
            }


            railApi.index('applicant_profiles/' + $scope.applicant.applicant_profiles[0].id, {id: $scope.applicant.applicant_profiles[0].id}, 'applicant_profile').promise.then(function(applicantProfile){
                railApi.index('tags', {is_viewable: 'true'}).promise.then(function(tagData) {
                    $scope.applicantTags = applicantProfile.applicant.tags;

                    $scope.availableTags = tagData.filter(function(tag) {
                         if ($scope.applicantTags.map(function(e) { return e.id; }).indexOf(tag.id) == -1) {

                            return true;

                         }

                         else {

                            return false;

                         }

                    });
                });
            });

            if($scope.applicant.current_employee) {
                $scope.employeeStatusString = 'current'
            }
            else if ($scope.applicant.previous_employee) {
              $scope.employeeStatusString = 'previous'
            }
            else {
              $scope.employeeStatusString = 'none'
            }


        }
        if((oldVal) && (newVal !== oldVal) && !$scope.userProfileQuery.pending) {
            $scope.newContactInfo = true;
        }
    }, true);


    $scope.contactInfoConfirm = function () {
        if ($scope.newContactInfo) {
            $scope.showStatusAlert = true;
            railApi.get('applicants/applicants_by_role/', {'applicant_id': $stateParams.applicant_id, 'employee_id': $rootScope.hgUserData.id}).promise.then(function(myData){
                $scope.userProfileQuery = myData;
                $scope.applicant = $scope.userProfileQuery;
                $scope.applicant.totalNotes = _.map($scope.applicant.applicant_profiles, 'notes');

            });
        }
    };

    $scope.getSendEmailApplication = function (applicantId, emailTemplate, replyable, profileApplications) {
        console.log('applications: ',profileApplications)

        var email_applicant_profile;

        profileApplications.forEach(function(e){
            if(e.id == applicantId) {
                email_applicant_profile = e;
            }
        })

        
        $scope.sendRegretLetterFx(email_applicant_profile, emailTemplate, replyable)
    }

    $scope.sendRegretLetterFx = function (applicant_profile, emailTemplate, replyable) {

      if(emailTemplate.files) {
        $scope.file_upload = [];
        var promises = [];
        var files = emailTemplate.files;
        var fileCount = 0;
        // $scope.errFiles = error;
        $scope.errorFile = false;
        var data = {
            file_type: 'attachment',
            employee_id: $rootScope.hgUserData.id
        };

        for (var i = 0; i < files.length; i+=1) {
            var file = files[i];
            if(!file.$error) {
                var response = Upload.upload({
                    url: config.apiIP + 'applicant_profiles/upload_file',
                    file: file,
                    fields: data
                }).progress(function (evt) {
                    file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                }).success(function (data, status, headers, config) {
                    // completed, data contains the parsed resume and fileModel
                    data.file_info['employee'] = $rootScope.hgUserData;
                    $scope.fileModel = data.file_info;
                    $scope.attachFilesIds.push($scope.fileModel.id);

                    $scope.file_upload.push(data.file_info);

                }).error(function(data, status, headers, config) {
                    $scope.errorMsg = status + ': ' + data.errors[0].message;
                    $scope.errorFile = true;
                }).then(function (success, error) {
                  if(success) {
                    fileCount = fileCount + 1;
                    if (fileCount === files.length) {
                        $rootScope.regretLetterReceived = railApi.post('send_hgemail/send_emails', {
                            t_uid: $scope.applicant.email,
                            f_uid: $rootScope.hgUserData.email,
                            subject: emailTemplate.subject[$rootScope.currentLang],
                            message: emailTemplate.body[$rootScope.currentLang],
                            applicant_profile_id: applicant_profile.id,
                            employee_id: $rootScope.hgUserData.id,
                            cc: emailTemplate.cc,
                            email_template_id: emailTemplate.id,
                            file_upload: $scope.file_upload,
                            replyable: replyable
                        }, 'send_hgemail');
                        $state.reload('jobApplication', {
                            job_id: applicant_profile.job.id
                        });
                    }
                    else {

                    }
                  }
                  else {

                  }
                });
                promises.push(response);
            }
          }
      }
      else {

        $rootScope.regretLetterReceived = railApi.post('send_hgemail/send_emails', {
            t_uid: $scope.applicant.email,
            f_uid: $rootScope.hgUserData.email,
            subject: emailTemplate.subject[$rootScope.currentLang],
            message: emailTemplate.body[$rootScope.currentLang],
            applicant_profile_id: applicant_profile.id,
            employee_id: $rootScope.hgUserData.id,
            cc: emailTemplate.cc,
            email_template_id: emailTemplate.id,
            replyable: replyable
        }, 'send_hgemail');
        $state.reload('jobApplication', {
            job_id: applicant_profile.job.id
          });
        }
    };

    $scope.showModalReplyEmail = function (email) {
        $scope.regretEmails = railApi.index('template_emails', {email_type: 'RejectionEmailtype'}, 'template_email').data;
        $scope.employeeList = railApi.index('employees', {}, 'employee').data;
        $scope.applicant_profile = railApi.get('applicant_profiles/' + email.applicant_profile_id).data;
        $scope.ReplyEmail = railApi.get('hgemails/' + email.id).promise.then(function(data) {
          $scope.replyEmail = data;
          var somethinghere = '<br><br>On ' + email.created_at + ', <span dir="ltr"> <<a href="mailto: ' + email.f_uid + '" target="_blank" title="Link: mailto: ' + email.f_uid + '">' + email.f_uid + '</a>> wrote: <br></span>';
          $scope.replyEmail.message = somethinghere + data.message;
          var replySubject = 'RE: ';
          $scope.replyEmail.subject = replySubject + data.subject;
        });
        $scope.replyEmailModal = $scope.modal.open({
            templateUrl: 'replyEmailModal.ejs',
            scope: $scope,
            windowClass: 'modal-xl',
            backdrop: 'static'
        });
    };

    $scope.sendReplyEmail = function (applicant_profile, replyEmail) {
      if(replyEmail.files) {
        $scope.file_upload = [];
        var promises = [];
        var files = replyEmail.files;
        var fileCount = 0;
        // $scope.errFiles = error;
        $scope.errorFile = false;
        var data = {
            file_type: 'attachment',
            employee_id: $rootScope.hgUserData.id
        };

        for (var i = 0; i < files.length; i+=1) {
            var file = files[i];
            if(!file.$error) {
                var response = Upload.upload({
                    url: config.apiIP + 'applicant_profiles/upload_file',
                    file: file,
                    fields: data
                }).progress(function (evt) {
                    file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                }).success(function (data, status, headers, config) {
                    // completed, data contains the parsed resume and fileModel
                    data.file_info['employee'] = $rootScope.hgUserData;
                    $scope.fileModel = data.file_info;
                    $scope.attachFilesIds.push($scope.fileModel.id);

                    $scope.file_upload.push(data.file_info);

                }).error(function(data, status, headers, config) {
                    $scope.errorMsg = status + ': ' + data.errors[0].message;
                    $scope.errorFile = true;
                }).then(function (success, error) {
                  if(success) {
                    fileCount = fileCount + 1;
                    if (fileCount === files.length) {
                        $rootScope.replyEmailReceived = railApi.post('send_hgemail/send_reply_email', {
                            id: replyEmail.id,
                            t_uid: applicant_profile.applicant.email,
                            f_uid: $rootScope.hgUserData.email,
                            subject: replyEmail.subject,
                            message: replyEmail.message,
                            applicant_profile_id: applicant_profile.id,
                            employee_id: $rootScope.hgUserData.id,
                            cc: replyEmail.cc,
                            file_upload: $scope.file_upload,
                            replyable: true
                        }, 'email');
                        $state.reload('jobApplication', {
                            job_id: $scope.job.id
                        });
                    }
                    else {
                        
                    }
                  }
                  else {

                  }
                });
                promises.push(response);
            }
          }
      }
      else {

        $rootScope.replyEmailReceived = railApi.post('send_hgemail/send_reply_email', {
            id: replyEmail.id,
            t_uid: applicant_profile.applicant.email,
            f_uid: $rootScope.hgUserData.email,
            subject: replyEmail.subject,
            message: replyEmail.message,
            applicant_profile_id: applicant_profile.id,
            employee_id: $rootScope.hgUserData.id,
            cc: emailTemplate.cc,
            replyable: true
        }, 'email');
        $state.reload('jobApplication', {
            job_id: $scope.job.id
          });
        }
    };

    $scope.showModalSearchTemplates = function () {
        $scope.searchResult;
        railApi.index('template_emails', {is_deleted: false}).promise.then(function(data){
            $scope.modalSearchTemplates = data; $scope.modalSearchTemplatesCount = data.length;
        });
        $scope.searchEmailTemplatesModal = $scope.modal.open({
            templateUrl: 'searchEmailTemplatesModal.ejs',
            scope: $scope,
            windowClass: 'modal-xl'
        });
    };



    $scope.removeEmailFile = function removeEmailFile(index) {
        this.$parent.sendEmail.files.splice(index,1);
        return;
    };
    

    $scope.removeReplyEmailFile = function removeReplyEmailFile(index) {
        this.$parent.replyEmail.files.splice(index,1);
        return;
    };

    $scope.changeStatusBoolean = function() {
        $scope.employeeStatusBoolean = !$scope.employeeStatusBoolean;

    }

    $scope.changeCategory = function(category){
        $scope.isCategoryChanged = true;

        if (category == 'offer') {
            $scope.selectedTemplates = $scope.offerTemplates;

        }
        if (category == 'regret') {
            $scope.selectedTemplates = $scope.regretTemplates;

        }
        if (category == 'general') {
            $scope.selectedTemplates = $scope.generalTemplates;

        }
        if (category == 'system') {
            $scope.selectedTemplates = $scope.systemTemplates;

        }

     }


     $scope.editor = true;
     $scope.emailPreview = false
 
     $scope.previewEmail = function(rawEmail, applications, profile_id){
        
         $scope.editor = !$scope.editor
         $scope.emailPreview = !$scope.emailPreview
         if (profile_id != undefined){
            var applicant_profile = _.find(applications, function(profile){
                return profile.id = profile_id
            })
            
            var sysTagConversion = [
                {
                    tag: "{{user.name.first}}",
                    value: $scope.applicant.first_name
                },{
                    tag: "{{user.name.last}}",
                    value: $scope.applicant.last_name
                },{
                    tag: "{{user.email}}",
                    value: $scope.applicant.email
                },{
                    tag: "{{jobName}}",
                    value: applicant_profile.job.name.en
                },{
                    tag: "{{job.name}}",
                    value: applicant_profile.job.name.en
                },{
                    tag: "{{jobNameFr}}",
                    value: applicant_profile.job.name.fr
                },{
                    tag: "{{jobNameEs}}",
                    value: applicant_profile.job.name.es
                },{
                    tag: "{{organization.name}}",
                    value: $scope.organization.name
                },{
                    tag: "{{contactEmail}}",
                    value: $scope.organization.email
                },{
                    tag: "{{contactPhone}}",
                    value: $scope.organization.phone
                },{
                    tag: "{{global.url.hostname}}",
                    value: $scope.organization.url
                }
            ]
            sysTagConversion.forEach( function(value){
                rawEmail = rawEmail.replaceAll(value.tag, !_.isEmpty(value.value) ? value.value : '______')
            })
            $scope.processedEmail = rawEmail
        }
 
     }




    // Show Notes Modal
    $scope.showNotesModal = function() {
        $scope.applications = $scope.applicant.applicant_profiles;
        $scope.notesModal = $scope.modal.open({
            templateUrl: 'notesModal.ejs',
            scope: $scope,
            size: 'lg'
        });
    }

    $scope.addNotesModal = function() {
        $scope.applications = $scope.applicant.applicant_profiles;
        $scope.addNoteModal = $scope.modal.open({
            templateUrl: 'addNoteModal.ejs',
            scope: $scope,
            size: 'lg',
            controller: 'notesCtrl'
        });
    }

    // Open Email Logs Modal
    $scope.showEmailLogsModal = function() {
        $scope.applications = $scope.applicant.applicant_profiles;
        $scope.emailLogsModal = $scope.modal.open({
            templateUrl: 'emailLogsModal.ejs',
            scope: $scope,
            size: 'lg'
        });
    }


    // $scope.removeTag = function(applicant_profile, tag) {
    //     $scope.tagRemoval = railApi.put('applicant_profiles/' + applicant_profile.id + '/remove_tag/' + tag.id, {id: applicant_profile.id, tag_id: tag.id}, 'applicant_profile')
    //                                .promise
    //                                .then(function(data) {
    //                                     window.location.reload();
    //                                });
    // };

    // $scope.addTag = function(applicant_profile, tag) {
    //     $scope.tagRemoval = railApi.put('applicant_profiles/' + applicant_profile.id + '/tag_candidate/' + tag.id, {id: applicant_profile.id, tag_id: tag.id}, 'applicant_profile')
    //                                .promise
    //                                .then(function(data) {
    //                                     window.location.reload();
    //                                });
    // };


     $scope.removeTagWithIndex = function( tag, index) {
         // alert("JobID: "  + $stateParams.job_id + " applicant_profile.id: " + applicant_profile.id + " applicantID: " + applicant_profile.applicant_id + "  fullName: " + applicant_profile.applicant.name);
         $scope.tagRemoval = railApi.put('applicant_profiles/' + $scope.applicant.applicant_profiles[0].id + '/remove_tag/' + tag.id, {id: $scope.applicant.applicant_profiles[0].id, tag_id: tag.id}, 'applicant_profile')
                                    .promise
                                    .then(function(data) {
                                        //  applicant_profile.applicant.tags.splice(index, 1);
                                         $scope.availableTags.push(tag);
                                         $scope.applicantTags.splice(index, 1);

                                    });
     };


    $scope.tagCandidate = function(tag, index) {
        $scope.tagIt = true;
        var tagIndex = index
        railApi.put('applicant_profiles/' + $scope.applicant.applicant_profiles[0].id + '/tag_candidate/' + tag.id, {id: $scope.applicant.applicant_profiles[0].id, tag_id: tag.id}, 'applicant_profile')
                            .promise
                            .then(function(data) {

                                $scope.tag = data;
                                railApi.index('applicant_profiles', {
                                    job_id: $stateParams.job_id,
                                    applicant_id:  $scope.applicant.id
                                }).promise.then(function() {
                                    // applicant_profile.applicant.tags.push(tag);
                                    $scope.currentTags =  $scope.applicant.tags
                                    $scope.availableTags.splice(tagIndex, 1);
                                    $scope.applicantTags.push(tag);
                                });
                            });
    };
    // Open Employee Status Modal
    $scope.showEmployeeStatusModal = function() {
        $scope.applications = $scope.applicant.applicant_profiles;
        $scope.employeeStatusModal = $scope.modal.open({
            templateUrl: 'employeeStatusModal.ejs',
            scope: $scope,
            size: 'md'
        });
    }
    // Change Employee Status
    // $scope.employeeStatusChange = function (applicant) {
    //     var path = 'applicants/' + applicant.id;
    //     var current_employee_status = applicant.current_employee;
    //     var previous_employee_status = applicant.previous_employee;
    //     $scope.result = railApi.put(path, {current_employee: current_employee_status, previous_employee: previous_employee_status}, 'applicant');
    //     $scope.$watch('result', function (newVal, oldVal) {
    //           if(newVal !== oldVal && newVal.pending === false) {
    //                   $state.reload('jobApplication', {
    //                        job_id: $scope.job.id
    //                   });
    //               }
    //       }, true)
    //   };


    $scope.employeeStatusChange = function (status, id) {

        var path = 'applicants/' + id;
          if(status == 'current') {
              var current_employee_status = true;
              var previous_employee_status = false;
          }
          if(status == 'previous') {
              var current_employee_status = false;
              var previous_employee_status = true;
          }
          if(status == 'none') {
              var current_employee_status = false;
              var previous_employee_status = false;
          }

        $scope.applicant.current_employee = current_employee_status;
        $scope.applicant.previous_employee = previous_employee_status;
        $scope.result = railApi.put(path, {current_employee: current_employee_status, previous_employee: previous_employee_status}, 'applicant');

      };

    $scope.changeStatusAlert = function () {
        $scope.showStatusAlert = !$scope.showStatusAlert;
    };

    $scope.reloadPage = function(){
        $state.reload();
    }

    $scope.changeStatusAlertTrue = function () {
        $scope.showStatusAlert = true;
    };


    $scope.parseEmailToObject = function(emailString) {
        if (typeof emailString === 'string' || emailString instanceof String) {
            $scope.sendEmail = JSON.parse(emailString)

      
        }
        else {
            $scope.sendEmail = emailString

        }

        if( $scope.sendEmail.custom_placeholder.length > 0) {
            $scope.loadCustomPlaceholderForm($scope.sendEmail, 'new', 'load');

        }

    }
    $scope.confirmedSearchTemplates = function (selectedResult) {

        
        $scope.sendEmail = selectedResult;


        if (typeof $scope.sendEmail === 'string' || $scope.sendEmail instanceof String) {
            $scope.sendEmail = JSON.parse($scope.sendEmail)
           
        }
        
        
        $scope.searchResult = null;
        $scope.modalSearchTemplates = null;
        $scope.applications = $scope.applicant.applicant_profiles;
        $scope.employeeList = railApi.index('employees', {}, 'employee').data;
        $scope.sendEmailModal = $scope.modal.open({
            templateUrl: 'sendEmailModal.ejs',
            scope: $scope,
            windowClass: 'modal-xl',
            backdrop: 'static',
            keyboard: false
          });
    };


    $scope.backToSendEmailModal = function () {
        $scope.searchResult = null;
        $scope.modalSearchTemplates = null;
        $scope.applications = $scope.applicant.applicant_profiles;
        $scope.employeeList = railApi.index('employees', {}, 'employee').data;
        $scope.sendEmailModal = $scope.modal.open({
            templateUrl: 'sendEmailModal.ejs',
            scope: $scope,
            windowClass: 'modal-xl',
            backdrop: 'static',
            keyboard: false
          });

    }



    $scope.loadCustomPlaceholderForm = function(sendEmail, type, from){
        $scope.sendEmail = sendEmail
        $scope.customPlaceholders = $scope.sendEmail.custom_placeholder
        $scope.emailBody = $scope.sendEmail.body
        $scope.templateId = $scope.sendEmail.id
        
        if(from == 'modal') {
            $scope.checkPlaceholders = true;
        }

        if(type == 'new'){
            $scope.placeHolderVal = []
        }

        $scope.type = type

        if($scope.customPlaceholders.length > 0 && $scope.checkPlaceholders){
            $scope.checkPlaceholders = false;
            
            $scope.customPlaceHolderModal = $scope.modal.open({
                templateUrl: 'customPlaceHolderModal.ejs',
                scope: $scope,
                size: 'lg',
                backdrop: 'static'
            });
        }
        
    }

    $scope.placeHolderVal = []

    $scope.updateMessage = function(val, emailBody, id){
        
        $scope.placeHolderVal = val
        var emailString = JSON.stringify(emailBody)
        val.forEach( function(value){
            emailString = emailString.replaceAll('{{'+value.label+'}}', !_.isEmpty(value.value) ? value.value : '______')
        })
        $scope.sendEmail.body = JSON.parse(emailString)
    }

    $scope.changeStatusAlertFalse = function () {
        $scope.showStatusAlert = false;
        $scope.newContactInfo = false;

    };

    $scope.blackFlagThisCandidate = function(id){
        var path = 'applicants/' + id + '/black_flag';
        $scope.result = railApi.put(path, {id: id}, 'applicant');
        $state.reload();
    }

    $scope.removePersonalInfo = function(id){
        railApi.put('applicants/remove_single_candidate_information', {id: id}, 'applicant').promise.then(function(){
            window.location.reload(true)
        })
    }



    if(typeof InstallTrigger !== 'undefined'){ //if mozilla or not
        $scope.browserType = 'HoverToEditHeading'
    } else {
        $scope.browserType = 'ClickToEditHeading'
    }

    railApi.get('organizations/1').promise.then(function(data) {
        $scope.organization = data;
    });
};

module.exports = candidateHistoryCtrl;

// _.map(newVal, function(val, index, applicant){
//     console.log(val);
//     console.log(index);
//     console.log(applicant);
//     if (applicant.uploaded_files){
//
//         if (_.find(applicant.uploaded_files, { file_type: 'resume' })) $scope.files.push(_.find(applicant.uploaded_files, { file_type: 'resume' }).id);
//         if (_.find(applicant.uploaded_files, { file_type: 'cover_letter' })) $scope.coverLetterFileId.push(_.find(applicant.uploaded_files, { file_type: 'cover_letter' }).id);
//         if (applicant.attachedFileIds) {
//             for(var n in applicant.attachedFileIds) {
//                 $scope.attachedFileIds.push(applicant.attachedFileIds[n]);
//             }
//
//         }
//     }
//     $scope.profileIds.push(applicant.id);
// });
