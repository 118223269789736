'use strict';

var workflowCtrl = function ($rootScope, $scope, railApi, $state, $stateParams) {

    console.log("state:", $state)

    $scope.companyDepartments = railApi.index('company_departments').data;
    $scope.languages = $rootScope.clientSettings.available_languages;
    $scope.showAddStage = 'false';
    $scope.hgForm = {
        name_en: '',
        name_fr: '',
        name_es: '',
        required_languages: ['en'],
        is_editable: true,
        is_deleted: false,
        aasm_state: 'published',

        stages_attributes: [
            {
                name_en: 'New',
                name_fr: 'Nouvelle',
                name_es: 'Nuevo',
                order: 0,
                stage_type: 'new',
                interview: false,
                dm_notification: false,
                dm_stage: false,
                is_editable: true,
                is_system_default: true,
                is_deleted: false,
                name: {
                  en: 'New',
                  fr: 'Nouvelle',
                  es: 'Nuevo'
                }
            },
            {
                name_en: 'Hired',
                name_fr: 'Embauché',
                name_es: 'Contratado',
                order: 998,
                stage_type: 'hired',
                interview: false,
                dm_notification: false,
                dm_stage: false,
                is_editable: true,
                is_system_default: true,
                is_deleted: false,
                name: {
                  en: 'Hired',
                  fr: 'Embauché',
                  es: 'Contratado'
                }
            },
            {
                name_en: 'Rejected',
                name_fr: 'Rejeté',
                name_es: 'Rechazado',
                order: 999,
                stage_type: 'rejected',
                interview: false,
                dm_notification: false,
                dm_stage: false,
                is_editable: true,
                is_system_default: true,
                is_deleted: false,
                name: {
                  en: 'Rejected',
                  fr: 'Rejeté',
                  es: 'Rechazado'
                }
            }
        ]
    };
    $scope.hgForm.workflow_selected_languages = $scope.hgForm.required_languages;
    $scope.hgForm.workflow_edit_selected_languages = $scope.hgForm.required_languages;
    $scope.newStage = {
        name_en: '',
        name_fr: '',
        name_es: '',
        order: 997,
        stage_type: '',
        interview: false,
        dm_notification: false,
        dm_stage: false,
        is_editable: true,
        is_system_default: false,
        is_deleted: false,
        name: {
          en: '',
          fr: '',
          es: ''
        }
    };

    if ($stateParams.id) {

        railApi.get('workflows/' + $stateParams.id).promise.then(function (data) {
            $scope.hgForm = data;
            $scope.hgForm.workflow_selected_languages = data.required_languages;
            $scope.hgForm.workflow_edit_selected_languages = data.required_languages;
            $scope.hgForm.stage_error = 'false';
            $scope.hgForm.stage_error_update = [];
            if($state.current.url == '/workflow-add/:id'){
                $scope.hgForm.is_editable = true;
                $scope.hgForm.is_deleted = false;
                delete $scope.hgForm['id'];
                for (var i = 0; i < $scope.hgForm.stages_attributes.length; i++) {
                  delete $scope.hgForm.stages_attributes[i]['id'];
                }
            }
        })
    }

    $scope.addNewStage = function() {
      var newOrder = $scope.hgForm.stages_attributes.length - 2
      $scope.hgForm.stages_attributes.splice(newOrder, 0, $scope.newStage);
      $scope.showAddStage = 'false';
      $scope.newStage = {
          name_en: '',
          name_fr: '',
          name_es: '',
          order: 997,
          stage_type: '',
          interview: false,
          dm_notification: false,
          dm_stage: false,
          is_editable: true,
          is_system_default: false,
          is_deleted: false,
          name: {
            en: '',
            fr: '',
            es: ''
          }
       };
    }

    $scope.resetNewStage = function() {
      $scope.newStage = {
          name_en: '',
          name_fr: '',
          name_es: '',
          order: 997,
          stage_type: '',
          interview: false,
          dm_notification: false,
          dm_stage: false,
          is_editable: true,
          is_system_default: false,
          is_deleted: false,
          name: {
            en: '',
            fr: '',
            es: ''
          }
       };
    }

    $scope.redirect = function() {
      $state.go('workflowClone', {id: $scope.hgForm.id});
    }
};

module.exports = workflowCtrl;
