'use strict';
var config = require('../config.js');

var unreadEmailCtrl = function ($scope, $state, $stateParams, railApi, $rootScope, Upload) {

  $scope.apiUrl = config.apiIP;

  $scope.unreadEmails = [];
  $scope.attachFilesIds = [];

  var emailQuery = railApi.get('employees/unread_emails/' + $rootScope.hgUserData.id, {})
                          .promise
                          .then(function(data) {
                            $scope.unreadEmails = data;
                            $scope.totalUnreadEmails = $scope.unreadEmails.length;
                          });

  // Open reply email modal
  $scope.showModalReplyEmail = function (email) {

    $scope.applicant_profile = email.applicant_profile;

    $scope.regretEmails = railApi.index('template_emails', {email_type: 'RejectionEmailtype'}, 'template_email').data;
    $scope.employeeList = railApi.index('employees', {}, 'employee').data;
    $scope.ReplyEmail = railApi.get('hgemails/' + email.id).promise.then(function(data) {
      $scope.replyEmail = data;
      var somethinghere = '<br><br>On ' + email.created_at + ', <span dir="ltr"> <<a href="mailto: ' + email.f_uid + '" target="_blank" title="Link: mailto: ' + email.f_uid + '">' + email.f_uid + '</a>> wrote: <br></span>';
      $scope.replyEmail.message = somethinghere + data.message;
      var replySubject = 'RE: ';
      $scope.replyEmail.subject = replySubject + data.subject;
    });
    $scope.replyEmailModal = $scope.modal.open({
      templateUrl: 'replyEmailModal.ejs',
      scope: $scope,
      windowClass: 'modal-xl',
      backdrop: 'static'
    });
  };

  $scope.sendReplyEmail = function (applicant_profile, replyEmail) {
    $scope.applicant_profile = applicant_profile;
    console.log(applicant_profile);

    if(replyEmail.files) {
      $scope.file_upload = [];
      var promises = [];
      var files = replyEmail.files;
      var fileCount = 0;
      // $scope.errFiles = error;
      $scope.errorFile = false;
      var data = {
          file_type: 'attachment',
          employee_id: $rootScope.hgUserData.id
      };

      for (var i = 0; i < files.length; i+=1) {
          var file = files[i];
          if(!file.$error) {
              var response = Upload.upload({
                  url: config.apiIP + 'applicant_profiles/upload_file',
                  file: file,
                  fields: data
              }).progress(function (evt) {
                  file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
              }).success(function (data, status, headers, config) {
                  // completed, data contains the parsed resume and fileModel
                  data.file_info['employee'] = $rootScope.hgUserData;
                  $scope.fileModel = data.file_info;
                  $scope.attachFilesIds.push($scope.fileModel.id);

                  $scope.file_upload.push(data.file_info);

              }).error(function(data, status, headers, config) {
                  $scope.errorMsg = status + ': ' + data.errors[0].message;
                  $scope.errorFile = true;
              }).then(function (success, error) {
                if(success) {
                  fileCount = fileCount + 1;
                  if (fileCount === files.length) {
                    $rootScope.replyEmailReceived = railApi.post('send_hgemail/send_reply_email', {
                        id: replyEmail.id,
                        t_uid: applicant_profile.applicant.email,
                        f_uid: $rootScope.hgUserData.email,
                        subject: replyEmail.subject,
                        message: replyEmail.message,
                        applicant_profile_id: applicant_profile.id,
                        employee_id: $rootScope.hgUserData.id,
                        cc: replyEmail.cc,
                        file_upload: $scope.file_upload,
                        replyable: true
                    }, 'email');
                    $state.reload('jobApplication', {
                        job_id: applicant_profile.job.id
                      });
                  }
                  else {

                  }
                }
                else {

                }
              });
              promises.push(response);
          }
        }
    } else {
      $rootScope.replyEmailReceived = railApi.post('send_hgemail/send_reply_email', {
          id: replyEmail.id,
          t_uid: applicant_profile.applicant.email,
          f_uid: $rootScope.hgUserData.email,
          subject: replyEmail.subject,
          message: replyEmail.message,
          applicant_profile_id: applicant_profile.id,
          employee_id: $rootScope.hgUserData.id,
          cc: replyEmail.cc,
          replyable: true
      }, 'email');
      // console.log('print job id here: --->' + applicant_profile.job.id);

      $state.reload('jobApplication', {
          job_id: applicant_profile.job.id
        });
      }
  };
  
  $scope.editor = true;
  $scope.emailPreview = false
  $scope.previewEmail = function(rawEmail, applicant_profile){
      $scope.organization = $rootScope.clientSettings
      $scope.editor = !$scope.editor
      $scope.emailPreview = !$scope.emailPreview
      
      if (rawEmail != undefined){
        
        var sysTagConversion = [
            {
                tag: "{{user.name.first}}",
                value: applicant_profile.applicant.first_name
            },{
                tag: "{{user.name.last}}",
                value: applicant_profile.applicant.last_name
            },{
                tag: "{{user.email}}",
                value: applicant_profile.applicant.email
            },{
                tag: "{{jobName}}",
                value: applicant_profile.job.name.en
            },{
                tag: "{{job.name}}",
                value: applicant_profile.job.name.en
            },{
                tag: "{{jobNameFr}}",
                value: applicant_profile.job.name.fr
            },{
                tag: "{{jobNameEs}}",
                value: applicant_profile.job.name.es
            },{
                tag: "{{organization.name}}",
                value: $scope.organization.name
            },{
                tag: "{{contactEmail}}",
                value: $scope.organization.email
            },{
                tag: "{{contactPhone}}",
                value: $scope.organization.phone
            },{
                tag: "{{global.url.hostname}}",
                value: $scope.organization.url
            }
        ]
        sysTagConversion.forEach( function(value){
            rawEmail = rawEmail.replaceAll(value.tag, !_.isEmpty(value.value) ? value.value : '______')
        })
        $scope.processedEmail = rawEmail
    }
  }


};

module.exports = unreadEmailCtrl;
