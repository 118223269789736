/**
 * This code was swiped from this blog post - https://www.bennadel.com/blog/2457-accessing-scope-on-the-dom-using-angularjs.htm
 */
'use strict';

var Directive = function ($parse) {
    return {
        restrict: 'A',
        link: function ($scope, element, attributes) {

            /**
             * Return the ngRepeat expression, this has to be extracted from the anchor comment used in the ngRepeat transclusion.
             *
             * @returns {*}
             */
            function getNgRepeatExpression() {

                var ngRepeatComment = element.contents().filter(
                    function () {
                        return ((this.nodeType === 8) && (this.nodeValue.indexOf('ngRepeat:') !== -1));
                    }
                );

                return (getNgRepeatExpressionFromComment(ngRepeatComment[0]));
            }

            /**
             * Get the ngRepeat expression from the comment that is used as the transclusion base for the ngRepeat
             * link phase. This is in the form of: "ngRepeat: expression"
             *
             * @param comment
             * @returns {*}
             */
            function getNgRepeatExpressionFromComment(comment) {
                var parts = comment.nodeValue.split(':');
                return (parts[1].replace(/^\s+|\s+$/g, ''));
            }

            /**
             * Get the children with the ngRepeat attribute
             *
             * @returns {*}
             */
            function getNgRepeatChildren() {
                var attributeVariants = [
                    '[ng-repeat]',
                    '[data-ng-repeat]',
                    '[x-ng-repeat]',
                    '[ng_repeat]',
                    '[ngRepeat]',
                    '[ng\\:repeat]'
                ];

                return (element.children(attributeVariants.join(',')));
            }

            /**
             * Gets called when the user has stopped sorting AND the DOM has actually changed
             *
             * @param event
             * @param ui
             */
            function handleUpdate(event, ui) {

                // gather all of the children that have some variant of the ngRepeat directive
                var children = getNgRepeatChildren();

                // extract all of the ngRepeat items out of the dom using the scope() plugin
                // also adds/updates the "order" property
                var items = $.map(children,
                    function (domItem, index) {
                        var scope = $(domItem).scope();
                        scope[itemName].order = index;
                        return (scope[itemName]);
                    }
                );

                // now that we have the re-ordered collection, let's assign it back to the original collection so that
                // AngularJS can update the rendering of the ngRepeat
                $scope.$apply(
                    function () {
                        collectionSetter($scope, items);
                    }
                );
            }

            /**
             * The pattern of acceptable invocation for the sortable expression. It must be in the form of
             * "REPEAT-ITEM in COLLECTION" where "collection" is something that can be get/set a value.
             * @type {RegExp}
             */
            var expressionPattern = /^([^\s]+) in (.+)$/i;

            /**
             * The pattern provided by the user in the nested ngRepeat directive
             *
             * @type {*}
             */
            var expression = getNgRepeatExpression();

            // ensure our expression works
            if (!expressionPattern.test(expression)) {
                throw (new Error('Expected ITEM in COLLECTION expression.'));
            }

            /**
             * Break the expression up in to parts that we can parse for item assignment. This will return an array with 3 items:
             * [ 0 ] = Full match.
             * [ 1 ] = First group; item.
             * [ 2 ] = Second group; collection.
             *
             * @type {*}
             */
            var expressionParts = expression.match(expressionPattern),

            // Pluck out the names of the relevant items
                itemName = expressionParts[1],
                collectionName = expressionParts[2],

            // Parse the collection name so that we can easily assign to it once the sort has been updated
                collectionGetter = $parse(collectionName),
                collectionSetter = collectionGetter.assign;

            // Apply the sortable items to the children.

            if (null != attributes.editable) {
                // Set flag true here
                var tempFlag = true;
                $scope.$watch(attributes.editable, function(newVal) {
                    // "or" condition where flag != false
                    if (null != newVal && true === newVal && tempFlag === true) {
                        element.sortable({
                            cursor: "move",
                            update: handleUpdate
                        });
                        // set flag false here, ensure .sortable only has one run
                        tempFlag = false;
                    }
                });
            } else {
                element.sortable({
                    cursor: "move",
                    update: handleUpdate,
                    items: "> *:not(.disabled)"
                });
            }

        }
    };
};

Directive.$inject = ['$parse'];

module.exports = Directive;
