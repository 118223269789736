/**
 * StartDate Services
 *
 * @param app the angular module.
 */
module.exports = function (app) {
    app
        .service('jobService', require('./jobService.js'))
        .service('applicantService', require('./applicantService.js'))
        .service('noteService', require('./noteService.js'))
    ;
};
