'use strict';



var Directive = function($window) {
    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            scope.errorDownload = false;
            scope.errorPDF = false;

            element.bind('click', function () {
                scope.errorDownload = false;
                scope.errorPDF = false;

                var httpRequest = new XMLHttpRequest();
                httpRequest.open('GET', attr.url, true);
                httpRequest.responseType = 'arraybuffer';

                var token = $window.localStorage.getItem('auth');

                if (null != token) {
                    httpRequest.setRequestHeader('auth', token);
                }

                httpRequest.onload = function () {
                    if (200 === httpRequest.status) {
                        var blob = new Blob([httpRequest.response],
                            {type: httpRequest.getResponseHeader('content-type')});
                        var url =  URL.createObjectURL(blob);
                        var filename = 'resume';

                        if (null != httpRequest.getResponseHeader('content-disposition') &&
                            -1 < httpRequest.getResponseHeader('content-disposition').indexOf('filename=')) {
                            filename = httpRequest.getResponseHeader('content-disposition');
                            filename = filename.substring(filename.indexOf('filename=') + 9, filename.lastIndexOf(';'));
                        }

                        if (null != attr.download) {
                            // IE
                            if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                                window.navigator.msSaveOrOpenBlob(blob, filename);
                            } else {
                                // create an "a" element so we can download the file
                                var a = document.createElement('a');
                                document.body.appendChild(a);
                                a.id = 'downloadAux';
                                a.href = url;
                                a.download = filename;
                                $('#downloadAux').css('display', 'none');
                                a.click();
                                document.getElementById('downloadAux').remove();
                            }
                        } else {
                            if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                                window.navigator.msSaveOrOpenBlob(blob, filename);
                            } else {
                                window.open(url);
                            }
                        }
                    } else {
                        if (null != attr.download) {
                            scope.errorHgDownload = true;
                        } else {
                            scope.errorPDF = true;
                        }
                    }
                };

                httpRequest.send();
            });
        }
    };
};

Directive.$inject = ['$window'];

module.exports = Directive;
