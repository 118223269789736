'use strict';

var userManagementAcceptInvitationCtrl = function ($scope, $stateParams, RailRestangular, railApi, $state) {

    $scope.formData = {};
    $scope.formData.invitation_token = $stateParams.invitation_token;
    $scope.formData.password = '';
    $scope.formData.password_confirmation = '';


    RailRestangular.all('organizations').getList().then(function (data) {
        // done
        var clientData = data[0].plain();

        $scope.passwordRequirements = clientData.settings;
        $scope.trigger = 'go';
    }, function (err) {
        // error happened
        console.log(err);
    });

    $scope.$on('$viewContentLoaded', function() {
        var payload = {};
        payload.invitation_token = $scope.formData.invitation_token;

        $scope.result = railApi.index('auth/invitation/accept', payload, 'employee');
    });

    $scope.submitAcceptInvitation = function () {
        var payload = {};
        payload.invitation_token = $scope.formData.invitation_token;
        payload.password = $scope.formData.password;
        payload.password_confirmation = $scope.formData.password_confirmation;

        $scope.result = railApi.put('auth/invitation', payload, 'employee');

        $scope.$watch('result', function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (!newVal.pending) { // and no error
                    $state.go('login')
                }
            }
        }, true)
    }
};


module.exports = userManagementAcceptInvitationCtrl;