/**
 * Created by Neo on 2017-09-18.
 */
'use restrict';

var locationsCtrl = function ($scope, railApi, $stateParams, $timeout) {

    $scope.locations = railApi.get('locations').data;

    $scope.common_locations = {};
    $scope.common_location = {};
    $scope.disableBtn = false;
    $scope.objCommonLocation = null;
    $scope.selectedCity = "";
    $scope.selectedLocations = null;

    $scope.formatLocations = function(locationString){
        if(angular.isDefined(locationString && locationString != null)) {
            if(typeof locationString === "string"){
                $scope.common_locations = JSON.parse(locationString);

            }else if(typeof locationString === "object"){
                $scope.common_locations = locationString;
                $scope.common_location = $scope.common_locations;

            }else{
                $scope.common_locations = {};
                $scope.common_location = {};
            }

            //if($scope.common_locations.id == 10994){
            //    $scope.disableBtn = true;
            //}else{
            //    $scope.disableBtn = false;
            //}
        }else{
            $scope.common_locations = null;
        }
        //return JSON.parse(locationString);


        return $scope.common_locations;
    };

    $scope.searchCommonLocation = function($inputText) {
        if ($inputText.length > 2 ) {
            $scope.selectedLocations = railApi.get('common_locations/search/'+ $inputText).data;
        }
    };

    //$scope.$watch($scope.common_locations, function(NewValue, OldValue){
    //    if(typeof NewValue === "string"){
    //        $scope.common_locations = JSON.parse(NewValue);
    //    }else if(typeof NewValue === "object"){
    //        $scope.common_locations = NewValue;
    //    }
    //
    //    console.log("type:" + typeof $scope.common_locations + "");
    //    //console.log("city: " + $scope.common_locations.city);
    //    if ($scope.common_locations != null) {
    //        alert("common location is not null: " + $scope.common_locations);
    //    }else{
    //        alert("common location is null");
    //    }
    //    //$scope.common_locations = JSON.parse($scope.common_locations);
    //    //console.log($scope.common_locations.city);
    //}, true);

    function activateBtn(){
        $scope.disableBtn = false;
    };

    $scope.addWatch = function(){
        $scope.disableBtn = true;

        //console.log("length: " + $scope.locationLength);

        $timeout(activateBtn, 2000);
    };

    $scope.resetDropDown = function(){
        $scope.common_locations = null;
        $scope.common_location = {};
    }

    $scope.addLocation = function(myLocations){
        console.log("-----: " + myLocations);

        railApi.post('locations/create_multiple', $scope.formatLocations(myLocations), 'location').promise.then(function(myData){
            $scope.locations = railApi.get('locations').data;
        });
    }

    //$scope.removeTagOnBackspace = function(event){
    //    if(event.keyCode === 8) {
    //        console.log('here');
    //    }
    //}

};

module.exports = locationsCtrl;