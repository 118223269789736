'use strict';



var Directive = function(railApi) {
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        template: "<span class=\"select\">\n    <hg-select placeholder=\"{{ 'Job.PlaceholderType' | hgTranslate }}\"\n               ng-model=\"hgForm.job_type.id\"\n               options-model=\"types\"\n               options=\"{ maxItems: 1, returnInt: true }\" >\n        <hg-option ng-repeat=\"type in types\" value=\"{{type.id}}\">\n            {{type.name | hgGetLang: currentLang}}\n        </hg-option>\n        <hg-option selected ng-if=\"hgForm.job_type_id.id\" value=\"{{hgForm.job_type_id.id}}\">\n            {{hgForm.job_type_id.name | hgGetLang: currentLang}}\n        </hg-option>\n    </hg-select>\n</span>",

        link: function(scope, elem, attrs) {
            scope.types = railApi.index('job_types').data

        }
    };
};

Directive.$inject = ['railApi'];

module.exports = Directive;