'use strict';



var Directive = function(railApi) {
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        template: "<label class=\"select\">\n    <hg-select placeholder=\"{{ 'Job.PlaceholderQuestionForms' | hgTranslate }}\"\n               ng-model=\"hgForm.form_ids\"\n    >\n        <hg-option ng-repeat=\"section in questionSections.forms\" value=\"{{section.id}}\">\n            {{section.name | hgGetLang: currentLang}}\n        </hg-option>\n        <hg-option selected ng-repeat=\"section in hgForm.forms\" value=\"{{section.id}}\">\n            {{section.name | hgGetLang: currentLang}}\n        </hg-option>\n    </hg-select>\n</label>\n",

        link: function(scope, elem, attrs) {
            scope.questionSections = railApi.index('forms', {'archived': false}).data

        }
    };
};

Directive.$inject = ['railApi'];

module.exports = Directive;
