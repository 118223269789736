/**
 * Created by dylan on 17/12/14.
 */



var Directive = function ($compile) {
    return {
        restrict: 'E',
        replace: true,
        controller: Controller,
        template: "<span>\n\n    <!--User-Icon Button-->\n    <span id=\"activity\" class=\"activity-dropdown\">\n        <i ng-class=\"icon || 'fa fa-user'\"></i>\n        <b class=\"badge\"> {{ total }} </b>\n    </span>\n\n    <!--AJAX Activity Dropdown Menu-->\n    <div class=\"ajax-dropdown\">\n\n        <!--Sections (Msgs, Notify, Tasks) -->\n        <div class=\"btn-group btn-group-justified\" data-toggle=\"buttons\">\n            <label class=\"btn btn-default\"\n                   data-ng-click=\"$parent.loadItem(item)\"\n                   data-ng-repeat=\"item in items\">\n\n                <input type=\"radio\" name=\"activity\">\n\n                <span data-localize=\"{{ item.title }}\">\n                    {{ item.title }}\n                </span>\n                ({{ item.count }})\n            </label>\n        </div>\n\n        <!--Scrollable Content-->\n        <div class=\"ajax-notifications custom-scroll\">\n\n            <!--Default message for content-->\n            <div class=\"alert alert-transparent\">\n                <h4>Click a button to show messages here</h4>\n                This blank page message helps protec your privacy, or you can show the first message here\n                automatically.\n            </div>\n            <i class=\"fa fa-lock fa-4x fa-border\"></i>\n        </div>\n\n        <!--Footer-->\n        <span> {{ footer }}\n            <button type=\"button\"\n                    class=\"btn btn-xs btn-default pull-right\"\n                    data-loading-text=\"Loading...\"\n                    data-ng-click=\"refresh($event)\">\n\n                <i class=\"fa fa-refresh\"></i>\n            </button>\n        </span>\n    </div>\n\n</span>\n",
        link: function (scope, element) {
            element.find('.activity-dropdown').on('click', function (event) {
                var activityDropDown = $(this);
                var badge = activityDropDown.find('.badge');
                var ajaxDropDown = activityDropDown.next('.ajax-dropdown');

                if (badge.hasClass('bg-color-red')) {
                    badge.removeClassPrefix('bg-color-').text('0');
                }

                if (!ajaxDropDown.is(':visible')) {
                    ajaxDropDown.fadeIn(150).addClass('active');
                } else {
                    ajaxDropDown.fadeOut(150).removeClass('active');
                }

                event.preventDefault();
            });

            scope.$watch('currentContent', function (newContent, oldContent) {
                if (newContent !== oldContent) {
                    $compile(element.find('.ajax-notifications').html(newContent))(scope);
                }
            });

            if (0 < scope.total) {
                element.find('.activity-dropdown .badge')
                    .addClass('bg-color-red bounceIn animated');
            }
        }
    };
};
Directive.$inject = ['$compile'];

var Controller = function ($scope, $http, Notification) {
    $scope.items = Notification.items;
    $scope.total = Notification.total;
    $scope.currentContent = '';

    $scope.loadItem = function (toLoad) {
        $http.get(toLoad.src).then(function (result) {
            $scope.currentContent = result.data;
        });
    };
};
Controller.$inject = ['$scope', '$http', 'notificationService'];

module.exports = Directive;