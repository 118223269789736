'use strict';

var reportsCtrl = function ($scope, railApi, $stateParams, $location) {
    $scope.icon = ""
    
    $scope.generateMailingList = function (){
        $scope.icon = "fa fa-spinner fa-spin"
        $scope.color = ""
        railApi.get('employees/mailing_list').promise.then(function(myData){
            var blob = new Blob([myData], {type: "text/plain; charset=utf-8"});
            saveAs(blob, "report.csv");
            $scope.icon = "fa fa-check"
            $scope.color = "color: limegreen"
        });
    }

    
    $scope.generateAccountLicensesReport = function (){
        $scope.account_license_icon = "fa fa-spinner fa-spin"
        $scope.account_license_color = ""
        railApi.get('organizations/account_licenses_report').promise.then(function(myData){
            var blob = new Blob([myData], {type: "text/plain; charset=utf-8"});
            saveAs(blob, "hg_account_licenses_report.csv");
            $scope.account_license_icon = "fa fa-check"
            $scope.account_license_color = "color: limegreen"
        });
    }

    $scope.notvalid = function(){
        $location.path("/notallowed");
    }

    $scope.tryReport = function(){
        railApi.get('candidate_removal_logs/get_removal_log').promise.then(function(data){
            console.log(data)
        })
    }
    
};



module.exports = reportsCtrl;
