/**
 * Created by dylan on 17/12/14.
 */

/**
 * Notification Service
 *
 * Keeps track of all notifications for the notification viewer.
 *
 * @param Restangular
 * @constructor
 */
var Service = function (Restangular) {
    var service = this;
    service.items = [
        {
            title: 'Messages',
            count: 14,
            src: 'ajax/notify/mail.html'
        },
        {
            title: 'News',
            count: 3,
            src: 'ajax/notify/notifications.html'
        },
        {
            title: 'Tasks',
            count: 4,
            src: 'ajax/notify/tasks.html'
        }
    ];

    Object.defineProperty(service, 'total', {
        get: function () {
            return service.items
                .map(function (item) {
                    return item.count;
                })
                .reduce(function (prev, cur) {
                    return prev + cur;
                })
        }
    });
};
Service.$inject = ['Restangular'];

module.exports = Service;