'use strict';


var getId = (function () {
    var id = 0;

    return function () {
        return id += 1;
    };
}());

var Directive = function () {
    return {
        restrict: 'E',
        compile: function (tElem) {
            tElem = $(tElem);
            var id = 'hgEditableToggle#' + getId();
            var edit = tElem.find('hg-edit').attr('ng-show', 'this[\'' + id + '\']');
            var text = tElem.find('hg-text').attr('ng-show', '!this[\'' + id + '\']');
            var browsertype;
            return function (scope, elem, attrs) {
                elem.clicked = false;
                elem.attr('id', id);
                scope[id] = false;

                if (null == scope.hgEditableToggle) {
                    scope.hgEditableToggle = {};
                    elem.parents().find('hg-update-model').attr('init-load', true);
                }

                scope.hgEditableToggle[id] = false;

                

                if(typeof InstallTrigger !== 'undefined' ){ //if mozilla or edge
                    browsertype = 'mouseenter'
                } else if(navigator.userAgent.indexOf("Edg") != -1) {
                    browsertype= 'mouseup'
                }
                else {
                    browsertype = 'click'
                }

                // This is called when clicking on a hgeditable field
                
                elem.bind(browsertype, function () {
                    elem.parents().find('hg-update-model').attr('init-load', false);
                    if (false === scope[id] &&
                        (null == attrs.editable || true === JSON.parse(attrs.editable))
                    ) {
                        _.map(scope.hgEditableToggle, function(value, key) {
                            if (id !== key) {
                                scope.hgEditableToggle[key] = false;
                                scope[key] = false;
                            }
                        });

                        scope[id] = true;
                        scope.hgEditableToggle[id] = true;
                        scope.$apply();
                    }

                    elem.clicked = true;
                });

                // prevent submit form
                elem.bind('keydown', function (e) {
                    if (13 === e.keyCode &&
                        (!elem.find('hg-edit label textarea').length)
                    ) {
                        e.preventDefault();
                        scope[id] = false;
                        scope.hgEditableToggle[id] = false;
                        scope.$apply();

                        return false;
                    }
                });

                //This is called when clicking outside of a hgEdit field
                $('body').bind('click.' + id, function (e) {
                    if (elem.clicked &&
                        null == $(e.target).parents('hg-editable').attr('id') &&
                        null == $(e.toElement).parents('hg-editable').attr('id') &&
                        false === $(e.target).hasClass('remove') &&
                        false === $(e.toElement).hasClass('remove')
                    ) {
                        scope[id] = false;
                        scope.hgEditableToggle[id] = false;
                        elem.clicked = false;
                        scope.$apply();
                    }
                });

                /*
                 * Fix for Bug Id: 7886
                 * After calling this event, the even click + id gets unbound
                 * Elements of the same level gets same hgEditableToggleId so if you remove event from one,
                 * it gets removed from all the elements of the same level
                 * Hence commenting this part.
                 */

                /*elem.on('$destroy', function () {
                    $('body').unbind('click.' + id);
                });*/

                if (null != attrs.editableUpdate) {
                    scope.$watch('hgEditableToggle.' + id, function (newVal, oldVal) {
                        if (null != newVal && newVal !== oldVal) {
                            if (false === newVal) {
                                scope.$eval(attrs.editableUpdate);
                            }
                        }
                    }, true);
                }

                //Changes for input group task : 7875
                elem.on('mouseover', function(){
                    //if editable property of the attribute is defined
                    //or is undefined in both cases pointer cursor will be used
                    if (attrs.editable === "true" || attrs.editable === undefined){
                        elem.css('cursor', 'pointer');
                    } else {
                        elem.find('hg-text').attr("title", "Edit not allowed");
                        elem.css('cursor', 'text');
                    }
                });

            };
        }
    };
};

Directive.$inject = [];

module.exports = Directive;