/**
 * StartDate Directives
 *
 * @param app the angular module.
 */
module.exports = function (app) {
    app
        .controller('applyCtrl', require('./applyCtrl.js'))
        .controller('internalFlagsCtrl', require('./internalFlagsCtrl.js'))
        .controller('userManagementCtrl', require('./userManagementCtrl.js'))
        .controller('userManagementEditCtrl', require('./userManagementEditCtrl.js'))
        .controller('userPermissionsCtrl', require('./userPermissionsCtrl.js'))
        .controller('userManagementResetPasswordCtrl', require('./userManagementResetPasswordCtrl.js'))
        .controller('loginCtrl', require('./loginCtrl.js'))
        .controller('passwordResetRequestCtrl', require('./passwordResetRequestCtrl.js'))
        .controller('userManagementAcceptInvitationCtrl', require('./userManagementAcceptInvitationCtrl.js'))
        .controller('demoCtrl', require('./demoCtrl.js'))
        .controller('jobListCtrl', require('./jobListCtrl.js'))
        .controller('internalJobListCtrl', require('./internalJobListCtrl.js'))
        .controller('applicantCtrl', require('./applicantCtrl.js'))
        .controller('careerInfoCtrl', require('./careerInfoCtrl.js'))
        .controller('candidateHistoryCtrl', require('./candidateHistoryCtrl.js'))
        .controller('helpCenterCtrl', require('./helpCenterCtrl.js'))
        .controller('jobCtrl', require('./jobCtrl.js'))
        .controller('stagesCtrl', require('./stagesCtrl.js'))
        .controller('locationsCtrl', require('./locationsCtrl.js'))
        .controller('indeedSponsorCtrl', require('./indeedSponsorCtrl.js'))
        .controller('reportJobPostingCtrl', require('./reportJobPostingCtrl.js'))
        .controller('integrationsCtrl', require('./integrationsCtrl.js'))
        .controller('reportEEOCtrl', require('./reportEEOCtrl.js'))
        .controller('questionTemplateAddCtrl', require('./questionTemplate/questionTemplateAddCtrl.js'))
        .controller('questionTemplateEditCtrl', require('./questionTemplate/questionTemplateEditCtrl.js'))
        .controller('formCtrl', require('./form/formCtrl.js'))
        .controller('formEditCtrl', require('./form/formEditCtrl.js'))
        .controller('notesCtrl', require('./notesCtrl.js'))
        .controller('settingsCtrl', require('./settingsCtrl.js'))
        .controller('dashboardCtrl', require('./dashboardCtrl.js'))
        .controller('jobViewsCtrl', require('./dashboard/jobViewsCtrl.js'))
        .controller('reportJobViewCtrl', require('./reportJobViewCtrl.js'))
        .controller('reportCandidateSourceCtrl', require('./reportCandidateSourceCtrl.js'))
        .controller('reportCandidateContactInfoCtrl', require('./reportCandidateContactInfoCtrl.js'))
        .controller('reportUserAccessLogsCtrl', require('./reportUserAccessLogsCtrl.js'))
        .controller('reportUserChangeLogsCtrl', require('./reportUserChangeLogsCtrl.js'))
        .controller('reportMilestoneCtrl', require('./reportMilestoneCtrl.js'))
        .controller('reportCandidateGenericCtrl', require('./reportCandidateGenericCtrl.js'))
        .controller('searchCtrl', require('./searchCtrl.js'))
        .controller('tagsCtrl', require('./tag/tagsCtrl.js'))
        .controller('tagEditCtrl', require('./tag/tagEditCtrl.js'))
        .controller('reportsCtrl', require('./hgAdmin/reportsCtrl.js'))
        .controller('siteConfigurationCtrl', require('./hgAdmin/siteConfigurationCtrl.js'))
        .controller('notificationsCtrl', require('./notificationsCtrl.js'))
        .controller('unreadEmailCtrl', require('./unreadEmailCtrl.js'))
        .controller('jobPostingOptionsCtrl', require('./jobPostingOptionsCtrl.js'))
        .controller('clientCtrl', require('./clientCtrl.js'))
        .controller('userPreferencesCtrl', require('./userPreferencesCtrl.js'))
        .controller('reportCandidateRemovalLogCtrl', require('./reportCandidateRemovalLogCtrl.js'))
        .controller('templateEmailCtrl', require('./templateEmailCtrl.js'))
        .controller('offerLetterCtrl', require('./offerLetterCtrl.js'))
        .controller('offerLetterListCtrl', require('./offerLetterListCtrl.js'))
        .controller('offerLetterReviewCtrl', require('./offerLetterReviewCtrl.js'))
        .controller('informationRequestCtrl', require('./informationRequestCtrl.js'))
        .controller('workflowCtrl', require('./workflowCtrl.js'))
        .controller('informationRequestAnswerCtrl', require('./informationRequestAnswerCtrl.js'))
    ;
};
