'use strict';



var Directive = function(railApi) {
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        template: "<label class=\"select\">\n    <hg-select placeholder=\"{{ 'Job.PlaceholderReportCategories' | hgTranslate }}\"\n               ng-model=\"hgForm.job_category_ids\">\n        <hg-option ng-repeat=\"reportCategory in reportCategories.job_categories\" value=\"{{reportCategory.id}}\">\n            {{ reportCategory.name  | hgGetLang: currentLang }}\n        </hg-option>\n        <hg-option selected ng-repeat=\"reportCategory in hgForm.job_category_ids_from\" value=\"{{reportCategory.id}}\">\n            {{ reportCategory.name  | hgGetLang: currentLang }}\n        </hg-option>\n    </hg-select>\n</label>",

        link: function(scope, elem, attrs) {
            scope.reportCategories = railApi.index('job_categories', {'query': {'category_type': 'report'}}).data

        }
    };
};

Directive.$inject = ['railApi'];

module.exports = Directive;