'use strict';

var Directive = function () {
    return {
        link: function(scope, element, attributes) {
            //prevents the user from typing a value into a number field larger than the maximum allowed
            element.on("keydown keyup", function(e) {
                if (Number(element.val()) > Number(attributes.hgLimitTo) &&
                    e.keyCode != 46 // delete
                    &&
                    e.keyCode != 8 // backspace
                ) {
                    e.preventDefault();
                    element.val(undefined);
                }
            });
        }
    };
};

module.exports = Directive;