'use strict';
var config = require('../config.js');

var userManagementEditCtrl = function ($scope, Upload, $rootScope, railApi, $state, $window) {

  $scope.updateContactInfo = function (id, fname, lname, title, file, fileType, error) {
    

    
    if (file && (undefined === error)) {
      Upload.upload({
        url: config.apiIP + 'employees/'+ id +'/upload_esignature',
        file: file,
        fields: data
      }).progress(function (evt) {
        $scope.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
      }).success(function (data, status, headers, config) {
        railApi.put('employees/' + id, { first_name: fname, last_name: lname, title: title }, 'employee').promise.then(function(data){
          window.location.reload();
        })
      }).error(function (data, status, headers, config) {
        $scope.fileError = data.error;
        if (config.fields.type === 'resume') {
          $scope.errorResume = true;
        }
        else {
          $scope.errorFile = true;
        }
      });
    } else {
      railApi.put('employees/' + id, { first_name: fname, last_name: lname, title: title }, 'employee').promise.then(function(data){
        window.location.reload();
      })
    }
  }

  $scope.applyNotificationDefault = function(employee_id){
    railApi.put('employees/'+ employee_id +'/apply_expiry_notification_default', {id: employee_id} , 'employees').promise.then(function(data){
    })
  }

  $scope.uploadSignature = function (file, fileType, error, id) {
    

  };

  $scope.removeESignature = function(employee_id, id, fname, lname, title){
      railApi.put('employees/'+ employee_id +'/remove_esignature', {id: employee_id} , 'employees').promise.then(function(data){
        railApi.put('employees/' + id, { first_name: fname, last_name: lname, title: title }, 'employee').promise.then(function(data){
          window.location.reload();
        })
      })
  }

};

module.exports = userManagementEditCtrl;