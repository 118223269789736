'use strict';

var Directive = function(Restangular, railApi) {
    return {
        restrict: 'AE',
        require: 'ngModel',
        link: function (scope, element, attrs) {
            var modelRest = Restangular.all(attrs.updateModel);
            scope.orderChangeSuccess = false;

            scope.$watch(attrs.ngModel, function(newValue, oldValue) {
                if ( !_.isEqual(newValue, oldValue)) {
                    _.forEach(newValue, function (value, i) {

                        new Promise(function(resolve, reject) {
                            try {
                                railApi.put(scope.modelSource1 + value.id, value, scope.modelSource2);
                                resolve();
                            } catch(e) {
                                reject();
                            }
                        }).then(function () {
                            scope.orderChangeSuccess = true;
                        });
                    });
                }
            });
        }
    };
};

Directive.$inject = ['Restangular', 'railApi'];

module.exports = Directive;
