angular.module("dropbox-picker", []).provider("DropBoxSettings", function() {
    this.box_linkType = 'shared', this.box_multiselect = 'true', this.box_clientId = null, this.linkType = 'direct', this.multiselect = false, this.extensions = ['.pdf', '.doc', '.docx', '.rtf', '.txt','.odf'], this.$get = function() {
        return {
            linkType: this.linkType,
            multiselect: this.multiselect,
            extensions: this.extensions,
            box_linkType: this.box_linkType,
            box_multiselect: this.box_multiselect,
            box_clientId: this.box_clientId

        }
    },
    this.configure = function(e) {
        for (key in e) this[key] = e[key]
    }

})
.directive("dropBoxPicker", ["DropBoxSettings",
            function(DropBoxSettings) {
    return {
        restrict: "A",
        scope: {
            dbpickerFiles: "="
        },
        link: function(scope, element, attrs) {
            function instanciate() {
                Dropbox.choose(dropboxOptions);
            }
            var dropboxOptions = {
                success: dropboxsuccess,
                cancel: function() {},
                linkType : DropBoxSettings.linkType,
                multiselect: DropBoxSettings.multiselect,
                extensions : DropBoxSettings.extensions,
            };
            function dropboxsuccess(files){
                scope.$apply(function() {
                    for (var i = 0; i < files.length; i++){
                        scope.dbpickerFiles.push(files[i]);
                        scope.dbpickerFiles[0].dropBox = true;
                    }
                });
            };
            element.bind("click", function() {
                instanciate()
            })
        }
    }
}])
.directive("boxPicker", ["DropBoxSettings",
            function(DropBoxSettings) {
    return {
        restrict: "A",
        scope: {
            boxpickerFiles: "="
        },
        link: function(scope, element, attrs) {
            function instanciate() {
                var success = false;
                var boxSelect = new BoxSelect(boxoptions);
                boxSelect.launchPopup();
                boxSelect.success(function(files) {
                    if(!success){
                        boxSelect.closePopup();
                        scope.$apply(function() {
                            for (var i = 0; i < files.length; i++){
                                scope.boxpickerFiles.push(files[i]);
                            }
                        });
                        //boxSelect.unregister(boxSelect.SUCCESS_EVENT_TYPE, successCallbackFunction);
                        success = true
                    }
                });
                boxSelect.cancel(function() {
                    console.log("The user clicked cancel or closed the popup");
                    boxSelect.closePopup();
                });
            }

            function successCallbackFunction(){
                boxSelect.closePopup();
            }

            var boxoptions = {
                clientId: DropBoxSettings.box_clientId,
                linkType: DropBoxSettings.box_linkType,
                multiselect: DropBoxSettings.box_multiselect
            };
            element.bind("click", function() {
                instanciate()
            })
        }
    }
}]);