/**
 * Created by John May 12th /2017
 */
'use strict';

var config = require('../../config.js');

var Directive = function ($http, $state) {
    return {
        require: '^form',
        restrict: 'A',
        link: function (scope, elem, attr, form) {
            var baseUrl = config.apiIP;
            /*var api = config.origin + '/api/v1/';*/
            var api = baseUrl;


            form.hgSearchSubmit = function () {
                scope.showSpinner = true;
                return $http[attr.method](api + attr.hgSearchSubmit, scope.hgForm)
                    .success(function (data, status) {
                        scope.showSpinner = false;
                        if (200 === status || 201 === status) {
                            form.hgSubmitted = data;
                            
                            form.hgServerErrors = {};
                            if (null == attr.noScroll) {
                                window.scrollTo(0, 0);
                            }

                            if (attr.hgAfterSubmit) {
                                scope.$eval(attr.hgAfterSubmit);
                            }

                            if (attr.hgGo) {
                                $state.go('^.' + attr.hgGo, { id: data.id });
                            }

                        }
                    })
                    .error(function (err) {
                        scope.showSpinner = false;
                        form.hgServerErrorsText = 'ValidationError';

                        if (err.name) {
                            form.hgServerErrorsText = err.name;
                        }

                        if ('ValidationError' === err.name) {
                            form.hgServerErrors = err.errors;

                            if (err.duplicateUser) {
                                form.hgServerErrors.duplicateUser = err.duplicateUser;
                            }

                        }else if ('ServerError' === err.name) {
                            form.hgServerErrors = err.errors;
                        } else {
                            form.hgServerErrorsText = 'ServerError';
                            form.hgServerErrors = {
                                '': {
                                    message: ['Server could not process request']
                                }
                            };
                        }
                    });
            };
        }
    };
};

Directive.$inject = ['$http', '$state'];

module.exports = Directive;