'use strict';
var config = require('../config.js');
const jsSHA = require("jssha");

var offerLetterCtrl = function ($scope, Jobs, railApi, $translate, Upload, $stateParams, $rootScope, $window, $filter, $sce) {

    $scope.applicant_profile =[];
    $scope.filesAndUrls = []
    $scope.docFiles = [];
    $scope.providedDocs = [];
    $scope.files = [];
    $scope.attachProvidedDocs = [{id: 'attachProvidedDocs1'}];
    $scope.apiUrl = config.apiIP;
    $scope.addFile = false
    $scope.showFormattedView = true;
    $scope.showFormattedCoverLetterView = true;
    $scope.organization = $rootScope.clientSettings

    
    $scope.exportLogUrl = config.apiIP + "/approval_emails/export_log_to_pdf?offer_letter_id=";
    $scope.exportOfferLetterUrl = config.apiIP + "/approval_emails/export_offer_letter?offer_letter_id="
    if ($stateParams.id) {
        $scope.offerLetter = railApi.get('approval_emails/' + $stateParams.id, { 'email_type': 'offer_letter' }).data;
        
    };

    $scope.reload = function(){
        window.location.reload()
    }

    $scope.updateEmailDraft = function(emailData){

        if((['returned','recalled','offer_expired','approved','offer_accepted','offer_declined'].indexOf(emailData.status) +1) && (emailData.approval_chains.length > 0)){
            emailData.content_edited = true
        }

        emailData.author_signature = emailData.signature_type == 'author_signature' ? true : false;
        emailData.recruitement_signature = emailData.signature_type == 'recruitement_signature' ? true : false;
        railApi.put('approval_emails/' + emailData.id, emailData, 'approval_email').promise.then(function(data){
            window.location.reload();
        })
    }

    $scope.updateApprovalChainOrder = function(approvers, approval_email_id, emailData){

        if((['returned','recalled','offer_expired','offer_accepted','offer_declined','cancelled'].indexOf(emailData.status) +1) && (emailData.approval_chains.length > 0)){
            railApi.put('approval_emails/' + emailData.id, {approvers_updated: true, content_edited: true}, 'approval_email').promise.then(function(data){
                railApi.post('/approval_chains/update_chain_order', {approval_chains: approvers, approval_email_id: approval_email_id}).promise.then(function(data){
                    $scope.changedOrder = false;
                })
            })
        }else{
            railApi.post('/approval_chains/update_chain_order', {approval_chains: approvers, approval_email_id: approval_email_id}).promise.then(function(data){
                $scope.changedOrder = false;
            })
        }
    }

    $scope.dateToday = function(){
        $scope.dateNowIso = new Date();
    }

    $scope.step1 = true;
    $scope.currStep = 1
    $scope.nextPrev = function(step){
        for(let stepz = 1; stepz < 3; stepz++){
            if(step == stepz){
                $scope['step' + stepz] = true
                $scope.currStep = stepz
            }else{
                $scope['step' + stepz] = false
            }
        }
    }
    $scope.addChain = []
    $scope.addview = []
    $scope.disableOrder = false
    $scope.changedOrder = false

    $scope.sendEmail = null
    $scope.addChain = false
    $scope.addApprover = function(sendEmail, count, role){
        $scope.addChain = true
    }

    $scope.reOrderApprovers = function (order1, order2, sendEmail) {
        $scope.changedOrder = true

        var index1 = _.findIndex(sendEmail.approval_chains, { chain_order: order1 })
        var index2 = _.findIndex(sendEmail.approval_chains, { chain_order: order2 })

        //var tempWeight = $scope.weighting[order1]
        //$scope.weighting[order1] = $scope.weighting[order2]
        //$scope.weighting[order2] = tempWeight

        sendEmail.approval_chains[index1].chain_order = order2;
        sendEmail.approval_chains[index2].chain_order = order1;
    }

    $scope.cancelAddChain = function () {
        $scope.addChain = false;
    }

    $scope.saveNewApprover = function(chain, chain_order, approval_email_id, index, type){
        var role
        if(type == 'approvers'){
            role = 'approval'
        }else{
            role = 'view'
        }
        if((['returned','recalled','offer_expired','offer_accepted','offer_declined','cancelled','approved'].indexOf($scope.offerLetter.status) +1) && ($scope.offerLetter.approval_chains.length > 0)){
            railApi.put('approval_emails/' + $scope.offerLetter.id, {approvers_updated: true, content_edited: true}, 'approval_email').promise.then(function(data){
                railApi.post('approval_chains/', {name: chain.name, email: chain.email, role: role, chain_order: (chain_order + 1), employee_id: chain.id ? chain.id : '',
                                                  approval_email_id: approval_email_id, type: type, request_signature: chain.request_signature}).promise.then(function(data){
                    $scope.offerLetter.content_edited = true;
                    $scope.offerLetter.approval_chains.push({
                        id: data.id,
                        name: data.name,
                        email: data.email,
                        role: data.role,
                        chain_order: data.chain_order,
                        request_signature: chain.request_signature
                    })
                    $scope.addChain = false
                    $scope.recipient = ''
                })
            })
        }else{
            railApi.post('approval_chains/', {name: chain.name, email: chain.email, role: role, chain_order: (chain_order + 1), employee_id: chain.id ? chain.id : '',
                                              approval_email_id: approval_email_id, type: type, request_signature: chain.request_signature}).promise.then(function(data){

                $scope.offerLetter.approval_chains.push({
                    id: data.id,
                    name: data.name,
                    email: data.email,
                    role: data.role,
                    chain_order: data.chain_order,
                    request_signature: chain.request_signature
                })
                $scope.addChain = false
                $scope.recipient = ''
            })
        }
    }

    $scope.removeChain = function(chain_id, type, index, sendEmail){
        if((['returned','recalled','offer_expired','offer_accepted','offer_declined','cancelled','approved'].indexOf(sendEmail.status) +1) && (sendEmail.approval_chains.length > 0)){
            railApi.put('approval_emails/' + sendEmail.id, {approvers_updated: true, content_edited: true}, 'approval_email').promise.then(function(data){
                railApi.post('/approval_chains/remove_chain', {id: chain_id, type: type, approval_email_id: sendEmail.id, type: type}).promise.then(function(data){
                    sendEmail.approval_chains = data
                    sendEmail.content_edited = true;
                })
            })
        }else{
            railApi.post('/approval_chains/remove_chain', {id: chain_id, type: type, approval_email_id: sendEmail.id, type: type}).promise.then(function(data){
                sendEmail.approval_chains = data
            })
        }
    }

    $scope.previewEmail = function(rawEmail){
        $scope.organization = $rootScope.clientSettings
        $scope.editor = !$scope.editor
        $scope.emailPreview = !$scope.emailPreview
        if(rawEmail != undefined){
            var sysTagConversion = [
                {
                    tag: "{{user.name.first}}",
                    value: $scope.offerLetter.applicant_profile.applicant_first_name
                },{
                    tag: "{{user.name.last}}",
                    value: $scope.offerLetter.applicant_profile.applicant_last_name
                },{
                    tag: "{{user.email}}",
                    value: $scope.offerLetter.applicant_profile.applicant_email
                },{
                    tag: "{{jobName}}",
                    value: $scope.offerLetter.job.name.en
                },{
                    tag: "{{job.name}}",
                    value: $scope.offerLetter.job.name.en
                },{
                    tag: "{{jobNameFr}}",
                    value: $scope.offerLetter.job.name.fr
                },{
                    tag: "{{jobNameEs}}",
                    value: $scope.offerLetter.job.name.es
                },{
                    tag: "{{organization.name}}",
                    value: $scope.organization.name
                },{
                    tag: "{{contactEmail}}",
                    value: $scope.organization.email
                },{
                    tag: "{{contactPhone}}",
                    value: $scope.organization.phone
                },{
                    tag: "{{global.url.hostname}}",
                    value: $scope.organization.url
                }
            ]
            sysTagConversion.forEach( function(value){
                rawEmail = rawEmail.replaceAll(value.tag, !_.isEmpty(value.value) ? value.value : '______')
            })
            $scope.processedEmail = rawEmail
        }

    }

    $scope.sendToApproval = function(approval_email_id){
        railApi.post('/approval_emails/send_to_approver', {approval_email_id: approval_email_id}).promise.then(function(data){
            $scope.sendForApprovalConfirmationModal = $scope.modal.open({
                templateUrl: 'sendForApprovalConfirmationModal.ejs',
                scope: $scope,
                size: 'lg'
            });
        })
    }

    $scope.initApplicantProfile = function(id){
        railApi.get('applicant_profiles/'+ id).promise.then(function(data){
            $scope.applicant_profile = data

            $scope.initResumes(data)
            $scope.jobForms = _.filter(data.job.forms,  function(e){ return e.privacy_status != 'Private'; });
            // $scope.notesList = data.notes
        })
    }

    $scope.ChangeFormatCoverLetterView = function ChangeFormatCoverLetterView (val) {
        $scope.showFormattedCoverLetterView = val;
    };

    $scope.divCss = {width:'100%',height:'1200px',padding: '0 0 50px 0'};

    $scope.initResumes = function(applicant_profile){
        if (null !== $rootScope.clientSettings.view_resume && _.find(applicant_profile.uploaded_files, { file_type: 'resume' })) {
            $scope.viewResume = $rootScope.clientSettings.view_resume;
        } else {
            $scope.viewResume = false;
        }
    }

    $scope.ChangeFormatView = function ChangeFormatView (val) {
        $scope.showFormattedView = val;
    };
    
    $scope.sendToCandidate = function(candidate_id, approval_email_id, employee_id, due_date){
        railApi.post('/approval_emails/send_to_candidate', {candidate_id: candidate_id, approval_email_id: approval_email_id, employee_id: employee_id, due_date: due_date, approval_skipped: $scope.contentEdited}).promise.then(function(data){
            $scope.offerLetter = data
            $scope.sendToCandidateConfirmationModal = $scope.modal.open({
                templateUrl: 'sendToCandidateConfirmationModal.ejs',
                scope: $scope,
                size: 'lg'
            });
        })
    }

    $scope.copyOldOfferMessage = function(oldMessage){
        $scope.oldMess = angular.copy(oldMessage)
    }

    $scope.checkButton = function(buttonName, status, approvers_count, is_edited){
        var show
        try{
            var buttons = [
                {stage: 'draft', buttons: ['edit','archive','send_for_approval','send_to_candidate','edit_approvers','edit_viewers'], check_edited: true},
                {stage: 'cancelled', buttons: ['edit','resend', 'archive','edit_approvers','send_to_candidate','edit_viewers'], check_edited: false},
                {stage: 'out_for_approval', buttons: ['cancel','send_reminder','skip'], check_edited: false},
                {stage: 'returned', buttons: ['edit','resend', 'archive','edit_approvers','edit_viewers','send_to_candidate'], check_edited: false},
                {stage: 'approved', buttons: ['send_to_candidate','edit','archive','resend'], check_edited: true},
                {stage: 'out_with_candidate', buttons: ['recall'], check_edited: false},
                {stage: 'recalled', buttons: ['edit','resend', 'archive','edit_approvers','resend_to_candidate','edit_viewers'], check_edited: true},
                {stage: 'offer_expired', buttons: ['edit','resend', 'archive','edit_approvers','resend_to_candidate','edit_viewers'], check_edited: true},
                {stage: 'offer_declined', buttons: ['edit','resend','resend_to_candidate','edit_approvers','edit_viewers','archive'], check_edited: true},
                {stage: 'offer_accepted', buttons: ['archive','recall_accepted'], check_edited: false},
                {stage: 'archived', buttons: ['clone','resend'], check_edited: false}
            ]
            const statButton = _.filter(buttons, function(e){
                return e.stage == status
            })[0]
            if (statButton.stage == 'draft' || statButton.stage == 'cancelled'){
                if (buttonName == 'send_to_candidate'){
                    show = (_.contains(statButton.buttons, buttonName) && (approvers_count < 1))
                    if(statButton.check_edited && buttonName == 'resend'){
                        return show && !is_edited
                    }else{
                        return show
                    }
                }else{
                    show = _.contains(statButton.buttons, buttonName)
                    if(statButton.check_edited && buttonName == 'resend'){
                        return show && !is_edited
                    }else{
                        return show
                    }
                }
            }else{
                show = _.contains(statButton.buttons, buttonName)
                if(statButton.check_edited && buttonName == 'resend'){
                    return show && is_edited
                }else{
                    return show
                }
            }
        }catch(e){

        }
    }

    $scope.recall = function(id){
        railApi.put('approval_emails/'+id+'/recall', {id: id}).promise.then(function(data){
            window.location.reload()
        })
    }

    $scope.cancel = function(id){
        railApi.put('approval_emails/'+id+'/cancel', {id: id}).promise.then(function(data){
            window.location.reload()
        })
    }

    $scope.beforeSendToCandidate = function(offer_letter){

        if(offer_letter.content_edited == true || ((_.filter(offer_letter.approval_chains, {skip: true}).length == offer_letter.approval_chains.length) && (_.filter(offer_letter.approval_chains, {role: 'approval'}).length > 0))){

            $scope.contentEdited = true;
            $scope.contentEditedPromtModal = $scope.modal.open({
                templateUrl: 'contentEditedPromtModal.ejs',
                scope: $scope,
                size: 'lg',
                backdrop: 'static'
            })

        }else{
            $scope.contentEdited = false;
            $scope.sendToCandidateModal = $scope.modal.open({
                templateUrl: 'sendToCandidateModal.ejs',
                scope: $scope,
                size: 'md',
                backdrop: 'static'
            })

        }
        
    }

    $scope.openSendToCandidate = function(){
        
        $scope.sendToCandidateModal = $scope.modal.open({
            templateUrl: 'sendToCandidateModal.ejs',
            scope: $scope,
            size: 'md',
            backdrop: 'static'
        })
        
        $scope.contentEditedPromtModal.dismiss()

    }

    $scope.checkStatus = function(status){
        var words = ['cancelled', 'approved', 'out_with_candidate', 'out_for_approval'];
  
        return (words.find(ol_status => ol_status == status) == undefined);
    }

    $scope.skipCurrent = function(offerLetter){

        railApi.post('approval_emails/'+offerLetter.id+'/skip_a_chain', offerLetter).promise.then(function(data){
            window.location.reload()
        })
    }

    $scope.archive = function(id){
        railApi.put('approval_emails/'+id+'/archive', {id: id}).promise.then(function(data){
            window.location.reload()
        })
    }

    $scope.printIt = function(offer_letter_id){
        
        $scope.employeeEncryption();
        // railApi.post('approval_emails/export_log_to_pdf', {offer_letter_id: offer_letter_id});
        
        window.location.href=$scope.exportLogUrl + offer_letter_id + '&employeeid='+$rootScope.hgUserData.id + '&hash=' + $scope.employeeHash;
    };

    $scope.exportOfferLetter = function(offer_letter_id){
        $scope.employeeEncryption();
        window.location.href=$scope.exportOfferLetterUrl + offer_letter_id + '&employeeid='+$rootScope.hgUserData.id + '&hash=' + $scope.employeeHash;
    }

    $scope.formattedViewURL = function (files, id, name) {
        var resumeFile = _.find(files, function(file){
            if(file.file_type == "formatted_resume" || (file.file_type == "resume" && file.original_file_name.includes(".pdf"))){
                return true;
            }
        });
        if (resumeFile === undefined) return false;
        var url = $scope.apiUrl + "/applicant_profiles/" + id + "/display_formatted_resume/"+ name +"#zoom=90&view=Fit";
        return $sce.trustAsResourceUrl(url)
    };

    $scope.formattedCoverLetterViewURL = function (files, id) {
        var resumeFile = _.find(files, function(file){

            if(file.file_type == "formatted_cover_letter" || (file.file_type == "cover_letter" && file.original_file_name.includes(".pdf"))){
                return true
            }
        });
        if (resumeFile === undefined) return false;
        var url = $scope.apiUrl + "/applicant_profiles/" + id + "/display_formatted_cover_letter#zoom=90&view=Fit";
        //var data = downloadPDF( )
        return $sce.trustAsResourceUrl(url)
    };

    $scope.employeeEncryption = function() {
        var today = new Date();
        var formattedDate = $filter('date')(today, 'yyyy-MM-dd');

        const shaObj = new jsSHA("SHA-256", "TEXT", { encoding: "UTF8" });
        shaObj.update($rootScope.hgUserData.email + $rootScope.hgUserData.role + "hiregroundSALT" + formattedDate);
        const hash = shaObj.getHash("HEX");

        $scope.employeeHash = hash;
    }

    $scope.assignFeedback = function(feedback){
        $scope.feedback = feedback
    }

    $scope.reminderSent = false

    $scope.sendReminder = function(offer_letter_id, approver){
        railApi.post('/approval_emails/'+offer_letter_id+'/send_reminder', {id: offer_letter_id}).promise.then(function(){
            $scope.reminderSent = true;
            // $scope.offerLetter = railApi.get('approval_emails/' + $stateParams.id, { 'email_type': 'offer_letter' }).data;
            setTimeout(function(){
                window.location.reload()
            }, 1000)
        })
    }

    $scope.parJson = function (json) {
        return json
    }
    $scope.getDocsIdAndUpload = function () {
        $scope.files = []

        var zzz = []
        $scope.providedDocs.forEach(function(docs){
            $scope.files.push(docs)
            if ($scope.files.length) {
                return true;
            } else {

                return false
            }
        })

    };

    $scope.clearProvidedDoc = function(index){
        $scope.providedDocs.splice(index, 1);
    }

    $scope.upload = function (files, file_type, attachment, error) {
        if(attachment.type == 'file'){
            if (files && files.length && (undefined === error)) {
                $scope.errorFile = false;
                $scope.errorInterviewFile = false;
    
                if ($stateParams.id) {
                    var upload_url = config.apiIP + 'approval_emails/' + $stateParams.id + '/upload_file';
                }
                else {
                    var upload_url = config.apiIP + 'approval_emails/upload_file';
                }
    
                for (var i = 0; i < files.length; i+=1) {
                    var file = files[i];
                    var data = {
                        file_type: file_type,
                        name: attachment.title,
                        type: 'offer_letter_attachment',
                        direction: 'outbound'
                    };
    
                    if ('offer_attachment' === file_type) {
                      if(file.dropBox) {
                          if(5000000 < file.bytes) {
                              $scope.errorFile = true;
                              return false;
                          }
                          data.dropBoxFile = file;
                          file = [];
                      } else {
                        if(5000000 < file.bytes) {
                            $scope.errorFile = true;
                            return false;
                        }
                      }
                    }
    
                    Upload.upload({
                        url: upload_url,
                        file: file,
                        fields: data
                    }).progress(function (evt) {
                          $scope.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    }).success(function (data, status, headers, config) {
                        $scope.clearProvidedDoc()
                        $scope.offerLetter.file_and_links.push({
                            id: data.file_and_link_id,
                            name: attachment.title,
                            attachment_type: 'offer_letter_attachment',
                            direction: 'outbound',
                            uploaded_file: {
                                id: data.file_info.id,
                                original_file_name: data.file_info.original_file_name
                            }
                        })
                        $scope.offerLetter.content_edited = true;
                    }).error(function(data, status, headers, config) {
                      if ('offer_attachment' === file_type) {
                        $scope.fileError = data.error;
                        $scope.errorFile = true;
                      }
                      else {
                        $scope.fileError = data.error;
                        $scope.errorInterviewFile = true;
                      }
                    });
                }
            } else {
                return false;
            }
        }else{
            railApi.post('/file_and_links/', {approval_email_id: $scope.offerLetter.id, name: attachment.title, 
                                              link: attachment.link, attachment_type: 'offer_letter_link', direction: 'outbound'}).promise.then(function(data){
                                                $scope.clearProvidedDoc()
                                                $scope.offerLetter.file_and_links.push({
                                                    id: data.id,
                                                    name: data.name,
                                                    direction: data.direction,
                                                    attachment_type: 'offer_letter_link',
                                                    link: data.link
                                                })
                                              })
                                              $scope.offerLetter.content_edited = true;
        }
        
    }; //upload["8", "25", 28, 27, 29, 30, "32"]

    $scope.saveFileRequest = function(requestData){
        railApi.post('/file_and_links/', {approval_email_id: $scope.offerLetter.id, name: requestData.title, 
            request_description: requestData.instructions, attachment_type: 'offer_letter_request', direction: 'inbound', mandatory: requestData.mandatory}).promise.then(function(data){
                $scope.clearProvidedDoc()
                $scope.offerLetter.file_and_links.push({
                    name: data.name,
                    request_description: data.request_description,
                    direction: data.direction,
                    attachment_type: data.attachment,
                    id: data.id,
                    mandatory: data.mandatory
                })
                $scope.offerLetter.content_edited = true;
            })
    }
    
    $scope.addNewInterviewFile = function () {
        var newItemNo = $scope.attachProvidedDocs.length + 1;
        $scope.attachProvidedDocs.push({'id' : 'attachProvidedDocs' + newItemNo});
    };

    $scope.removeDocuments = function(id, file_id, index) {
        
        if (file_id) {
            var remove_url = 'approval_emails/remove_file/' + file_id;
            railApi.put(remove_url, {file_id: file_id, file_and_link_id: id}, 'approval_emails').promise.then(function(data){
                    $scope.offerLetter.file_and_links.splice(_.indexOf($scope.offerLetter.file_and_links, _.findWhere($scope.offerLetter.file_and_links, { id : id})), 1);
                    $scope.offerLetter.content_edited = true;
            })
        }else{
            railApi.put('/file_and_links/' + id, {removed: true}, 'file_and_link').promise.then(function(data){
                $scope.offerLetter.file_and_links.splice(_.indexOf($scope.offerLetter.file_and_links, _.findWhere($scope.offerLetter.file_and_links, { id : id})), 1);
                $scope.offerLetter.content_edited = true;
            })
        }

    }

    $scope.downloadAllFiles = function(id){
        railApi.post('uploaded_files/download_all_files', {id: id}).promise.then(function(data){
            var downloadURL = $scope.apiUrl + data.zip_url;

            window.open(downloadURL);
        })
    }

    $scope.resendButtonAvailability = function(approval_length, is_edited){
        if(is_edited && approval_length > 0){
            return true
        }else if(!is_edited && approval_length > 0){
            return false
        }else if(is_edited && approval_length < 0){
            return false
        }else{
            return false
        }
        
    }

    $scope.loadEmployees = function(){
        $scope.employeeList = railApi.index('employees', {}, 'employee').data;
    }

    $scope.parseJson = function(data){
        try{
            $scope.employee = JSON.parse(data)
        }catch(e){
        }
    }

    $scope.preSelectSignature = function(data){
        data.author_signature ? $scope.offerLetter.signature_type = 'author_signature' :'';
        data.recruitement_signature ? $scope.offerLetter.signature_type = 'recruitement_signature' :'';
        !data.recruitement_signature && !data.author_signature ? $scope.offerLetter.signature_type = 'none' :'';
    }

    $scope.loadImageUrl = function(image_path){
        return "https://startdate-client-logo.s3.ca-central-1.amazonaws.com/" + image_path
    }
};


module.exports = offerLetterCtrl;