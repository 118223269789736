'use strict';

var config = require('../config.js');
var searchCtrl = function ($scope, railApi, $stateParams,$sce, $rootScope) {

    $scope.profileIds = [];
    $scope.allIds = [];
    $scope.apiUrl = config.apiIP;
    $scope.modalSource;

    $scope.organization = railApi.get('organizations/').data;

    $scope.calculateProfiles = function(profiles) {
        if(profiles) {
            var count = 0;
            var index = 0;
            angular.forEach(profiles.applicants, function(key, index){
                
                if(key.hasOwnProperty('applicant_profiles')) {
                    count = count + profiles.applicants[index].applicant_profiles.length;
                }
                index++;
            });
            return count;
        }
    };

     $scope.getApplicantJob = function(applicantJobId) {
        var applicantJobs = railApi.get('jobs/' + applicantJobId).promise.then(function(data){;
             $scope.job = data;
             $scope.jobForms = _.filter($scope.job.forms,  function(e){ return e.privacy_status != 'Private'; });
            //  console.log($scope.job);
            //  console.log(jobForms)
        });
        // console.log(applicantJobs);
    }

    // Form Answers
    $scope.getApplicantProfile = function(applicantProfile) {
        var applicantProfileQuery = railApi.get('applicant_profiles/' + applicantProfile).promise.then(function(data){
             $scope.applicant_profile = data;
            //  console.log($scope.applicant_profile);
        });
        // console.log(applicantProfileQuery);
    }

    $scope.showModalCopyCandidates = function () {

        $scope.modalSource = 'copy';
        
        if($scope.profileIds.length > 100) {
            $scope.availableJobs = railApi.index('jobs/').data;

        }
        else {
            $scope.availableJobs = railApi.index('jobs/available_jobs_multiple/' +  $scope.profileIds, '', 'job').data;

        }        // $scope.applicant_profiles = railApi.index('applicant_profiles/bulk_candidates/' + $scope.job.id, {job_id: $scope.job.id}, 'applicant_profile').data;
        $scope.copyToJobModal = $scope.modal.open({
            templateUrl: 'copyToJobModal.ejs',
            scope: $scope,
            size: 'lg'
        });
    };

    $scope.getAllIds = function (results) {


        $scope.allIds = [];

        results.applicants.forEach(function(applicant){
            applicant.applicant_profiles.forEach(function(profile){
                $scope.allIds.push(profile.id);
            })
        })


    }


    $scope.showModalCopyAllCandidates = function (applicants) {

        $scope.modalSource = 'copyall';

        // applicants.forEach(function(applicant){
        //     applicant.applicant_profiles.forEach(function(profile){
        //         $scope.profileIds.push(profile.id);
        //     })
        // })

        $scope.profileIds = $scope.allIds;
        if($scope.profileIds.length > 100) {
            $scope.availableJobs = railApi.index('jobs/').data;

        }
        else {
            $scope.availableJobs = railApi.index('jobs/available_jobs_multiple/' +  $scope.profileIds, '', 'job').data;

        }
        // $scope.applicant_profiles = railApi.index('applicant_profiles/bulk_candidates/' + $scope.job.id, {job_id: $scope.job.id}, 'applicant_profile').data;
        $scope.copyToJobModal = $scope.modal.open({
        templateUrl: 'copyToJobModal.ejs',
        scope: $scope,
        size: 'lg'

        })

        


    };

    $scope.emptyProfileIds = function() {
        $scope.modalSource = '';
        $scope.profileIds = [];
        
    }

    $scope.setIds = function (profile_id) {
        var index = $scope.profileIds.indexOf(profile_id);
        if (-1 === index) {
            $scope.profileIds.push(profile_id);
        } else {
            $scope.profileIds.splice(index, 1);
        }
    };

    $scope.copyAllCandidatesToJob = function(job) {
        $scope.copyToJob = railApi.post('applicant_profiles/search_copy_candidates_to_job/' + job, {ids: $scope.profileIds});
    };

    $scope.copyCandidatesToJob = function(job) {
        $scope.copyToJob = railApi.post('applicant_profiles/' + $scope.profileIds + '/copy_candidates_to_job/' + job, {}, 'applicant_profile');
    };
    
    $scope.formattedViewURL = function (files, appl_id, applicant_name) {
        var resumeFile = _.filter(files, function(file) {  return file.file_type == "formatted_resume" || file.file_type == "resume" });
        if (resumeFile === undefined || resumeFile.length < 1) return false
        var url = $scope.apiUrl + "/applicant_profiles/" + appl_id + "/display_formatted_resume/"+ applicant_name +"#zoom=90&view=Fit";
        return $sce.trustAsResourceUrl(url)
    };

    $scope.showHighlights = function(applicantId){

        if($('#searchNames'+applicantId).attr('words') != undefined){
            if($('#searchNames'+applicantId).attr('words').split(' ') != ''){
                var words = $('#searchNames'+applicantId).attr('words').split(' ');
                for(var i=0; i < words.length; i++) {
                  var r = new RegExp(words[i], 'ig')
                  $('#searchNames'+applicantId).html($('#searchNames'+applicantId).html().replace(r, function(data){
                    return '<span class="highlight">' + data + '</span>'
                  }));
                }
            }
        }
        
    }
    
};



module.exports = searchCtrl;
