'use strict';

var Directive = function(RailRestangular) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var candidateSourcesRest = RailRestangular.all(attrs.hgListManagement);
            scope.formSubmited = false;
            scope.newElements = false;
            scope.listData = candidateSourcesRest.getList({sort: 'order', query:{ is_viewable: true }}).$object;

            element.on('click', function(){
                scope.formErrors = false;
                scope.formSubmited = true;
                scope.dirtyData = false;
                scope.newElements = false;

                _.forEach(scope.listData, function (source, i) {
                    scope.errors = [];

                    // POST request
                    if (null == source.id && true === source.isViewable) {
                        candidateSourcesRest.post(source).then(function(data) {
                            scope.listData[i] = data;
                            scope.newElements = true;
                        },function(res) {
                            scope.formErrors = true;
                            scope.errors[source.id] = res.data.errors;
                        });
                    } else {
                        // PUT request
                        source.put().then(function(data) {
                        }, function(res) {
                            scope.formErrors = true;
                            scope.errors[source.id] = res.data.errors;
                        });
                    }
                });

                scope.$watch('dirtyData', function(oldVal, newVal){
                    if (true === scope.dirtyData && true === scope.newElements) {
                        scope.dirtyData = false;
                        scope.newElements = false;
                    }
                }, true);
            });
        }
    };
};

Directive.$inject = ['RailRestangular'];

module.exports = Directive;
