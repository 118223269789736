'use strict';

var serialize = function(obj, prefix) {
    var str = [];

    for(var p in obj) {
        if (obj.hasOwnProperty(p)) {
            var k = prefix ? prefix + "[" + p + "]" : p, v = obj[p];

            if (null != v && null != v.toJSON) {
                v = v.toJSON();
            }

            str.push(
                typeof v == "object"
                    ? serialize(v, k)
                    : encodeURIComponent(k) + "=" + encodeURIComponent(v)
            );
        }
    }
    return str.join("&");
};

module.exports = serialize;