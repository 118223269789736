'use strict';

var internalJobs = function ($rootScope, $stateParams, $state, RailRestangular) {
    var internaljobs = [];
    if ($rootScope.toStateParams && $rootScope.toStateParams.internal_id) {
        var internal_id = $rootScope.toStateParams.internal_id;
        var internaljobs = RailRestangular.service('jobs/internal/' + internal_id + '?page=candidate_dashboard');

        return internaljobs;
    }else {
        return internaljobs;
    }
};

internalJobs.$inject = ['$rootScope', '$stateParams', '$state', 'RailRestangular'];

module.exports = internalJobs;
