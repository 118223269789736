/**
 * Created by dylan on 04/02/15.
 */

var config = require('../config.js');
var serialize = require('../serialize.js');
/**
 * REST methods for the hg api.
 *
 * Methods are the same as the serverside api:
 *
 *      options, head, index, get, post, put, delete
 *
 * Each method will return a promise.
 * Once the promise completes it will attach some of the response data to the returned promise.
 *
 * Populated fields:
 *
 *      data: The response body.
 *      headers: The function to access the response headers.
 *      query: The query params sent in with the request.
 */
var Factory = function ($http, $q) {
    var baseUrl = config.origin + '/api/v1';

    return {
        /**
         * Performs an 'OPTIONS' http request.
         *
         * @param path the path (from the base url) to request.
         * @param query an optional query object (converted to query string).
         *
         * @returns Promise{ data, headers, query }
         */
        options: function (path, query) {
            var deferred = $q.defer();
            path = baseUrl + '/' + path;
            deferred.query = query;
            deferred.errors = {};

            $http.head(path + '?' + serialize(query || {}))
                .success(function (data, status, headers, config) {
                    deferred.headers = headers;
                    deferred.resolve(data, status, headers, config);
                })
                .error(function (data, status, headers, config) {
                    _.merge(deferred.errors, data);
                    deferred.headers = headers;
                    deferred.reject(data, status, headers, config);
                });

            return deferred;
        },

        /**
         * Performs an 'Head' http request.
         *
         * @param path the path (from the base url) to request.
         * @param query an optional query object (converted to query string).
         *
         * @returns Promise{ data, headers, query }
         */
        head: function (path, query) {
            var deferred = $q.defer();
            path = baseUrl + '/' + path;
            deferred.query = query;
            deferred.errors = {};

            $http.head(path + '?' + serialize(query || {}))
                .success(function (data, status, headers, config) {
                    deferred.headers = headers;
                    deferred.resolve(data, status, headers, config);
                })
                .error(function (data, status, headers, config) {
                    _.merge(deferred.errors, data);
                    deferred.headers = headers;
                    deferred.reject(data, status, headers, config);
                });

            return deferred;
        },

        /**
         * Performs an 'INDEX(GET)' http request.
         *
         * @param path the path (from the base url) to request.
         * @param query an optional query object (converted to query string).
         *
         * @returns Promise{ data, headers, query }
         */
        index: function (path, query) {
            var deferred = $q.defer();
            path = baseUrl + '/' + path;
            deferred.data = [];
            deferred.query = query;
            deferred.errors = {};
            deferred.pending = true;

            $http.get(path + '?' + serialize(query || {}))
                .success(function (data, status, headers, config) {
                    _.merge(deferred.data, data);
                    deferred.headers = headers;
                    deferred.pending = false;
                    deferred.resolve(data, status, headers, config);
                })
                .error(function (data, status, headers, config) {
                    _.merge(deferred.errors, data);
                    deferred.headers = headers;
                    deferred.pending = false;
                    deferred.reject(data, status, headers, config);
                });

            return deferred;
        },

        /**
         * Performs an 'GET(/:id)' http request.
         *
         * @param path the path (from the base url) to request.
         * @param query an optional query object (converted to query string).
         *
         * @returns Promise{ data, headers, query }
         */
        get: function (path, query) {
            var deferred = $q.defer();
            path = baseUrl + '/' + path;
            deferred.data = {};
            deferred.query = query;
            deferred.errors = {};
            deferred.pending = true;

            $http.get(path + '?' + serialize(query || {}))
                .success(function (data, status, headers, config) {
                    _.merge(deferred.data, data);
                    deferred.headers = headers;
                    deferred.pending = false;
                    deferred.resolve(data, status, headers, config);
                })
                .error(function (data, status, headers, config) {
                    _.merge(deferred.errors, data);
                    deferred.headers = headers;
                    deferred.pending = false;
                    deferred.reject(data, status, headers, config);
                });

            return deferred;
        },

        /**
         * Performs an 'PUT' http request.
         *
         * @param path the path (from the base url) to request.
         * @param changes the body to send in the query.
         *
         * @returns Promise{ data, headers, query }
         */
        put: function (path, changes) {
            var deferred = $q.defer();
            path = baseUrl + '/' + path;
            deferred.data = {};
            deferred.errors = {};
            deferred.pending = true;

            $http.put(path, changes)
                .success(function (data, status, headers, config) {
                    _.merge(deferred.data, data);
                    deferred.headers = headers;
                    deferred.pending = false;
                    deferred.resolve(data, status, headers, config);
                })
                .error(function (data, status, headers, config) {
                    _.merge(deferred.errors, data);
                    deferred.headers = headers;
                    deferred.pending = false;
                    deferred.reject(data, status, headers, config);
                });

            return deferred;
        },

        /**
         * Performs an 'POST' http request.
         *
         * @param path the path (from the base url) to request.
         * @param changes the document to post.
         *
         * @returns Promise{ data, headers, query }
         */
        post: function (path, changes) {
            var deferred = $q.defer();
            path = baseUrl + '/' + path;
            deferred.data = {};
            deferred.errors = {};
            deferred.pending = true;

            $http.post(path, changes)
                .success(function (data, status, headers, config) {
                    _.merge(deferred.data, data);
                    deferred.headers = headers;
                    deferred.pending = false;
                    deferred.resolve(data, status, headers, config);
                })
                .error(function (data, status, headers, config) {
                    _.merge(deferred.errors, data);
                    deferred.headers = headers;
                    deferred.pending = false;
                    deferred.reject(data, status, headers, config);
                });

            return deferred;
        },

        /**
         * Performs an 'DELETE' http request.
         *
         * @param path the path (from the base url) to request.
         *
         * @returns Promise{ data, headers, query }
         */
        delete: function (path) {
            var deferred = $q.defer();
            path = baseUrl + '/' + path;
            deferred.data = {};
            deferred.errors = {};

            $http.delete(path)
                .success(function (data, status, headers, config) {
                    _.merge(deferred.data, data);
                    deferred.headers = headers;
                    deferred.resolve(data, status, headers, config);
                })
                .error(function (data, status, headers, config) {
                    _.merge(deferred.errors, data);
                    deferred.headers = headers;
                    deferred.reject(data, status, headers, config);
                });

            return deferred;
        }
    };
};
Factory.$inject = ['$http', '$q'];

module.exports = Factory;