'use strict';

var integrationsCtrl = function ($scope, /*adalAuthenticationService, */railApi, $state, $sce, $window, $location, $http, $modal, $stateParams) {

    /**
     * Check for existing connections
     *
     */
    /*
    $scope.result =  railApi.index('organizations');
      $scope.$watch('result', function (newVal, oldVal) {
          if (newVal != oldVal) {
              if (!newVal.pending) { // and no error
                  $scope.client = newVal.data[0];
              }
          }
      }, true);
  app.all('/*', function(req, res, next) {
      res.header("Access-Control-Allow-Origin", "*");
      next();
  });
  */
    var linkedInPostModal;
    var companySelectModal;
    $scope.selectedCompany;
    $scope.isConnectedToLinkedIn;

    // indeed get organization for indeed booleans
    railApi.get('organizations/1').promise.then(function(data) {
        $scope.organization = data;
    });
    // indeed update functions
    $scope.enableApplyIndeed = function() {
        $scope.organization.apply_with_indeed = true;
    };
    $scope.disableApplyIndeed = function(){
        $scope.organization.apply_with_indeed = false;
    };
    $scope.connectIndeedFeed = function() {
        $scope.organization.generate_indeed_feed = true;
    };
    $scope.disconnectIndeedFeed = function(){
        $scope.organization.generate_indeed_feed = false;
    };
    // indeed watch function
    $scope.$watch('organization', function (newValue, oldValue) {
        if (newValue && oldValue && newValue !== oldValue) {
                railApi.put('organizations/1', $scope.organization, 'organization');
        }
    }, true);



    //on load check for url code indicating oauth in process. Also check for pre existing connection.

    if ($location.search().code != null) {

        var url = "https://www.linkedin.com/oauth/v2/accessToken"

        var headers = { "Content-Type": "application/x-www-form-urlencoded" }




        var redirect_uri = $location.protocol() +"://"+ $location.host() + "/view/client/redirect.html"
        var auth_data = "grant_type=authorization_code&code="+$location.search().code+"&redirect_uri=" + redirect_uri + "&client_id=78udshneqyjg2z&client_secret=A0gaHnhAKofaJIQt"
        //var auth_data = { client_id: "78udshneqyjg2z", grant_type: "authorization_code", code: $location.search().code.toString(), redirect_uri: "http://localhost:9000/view/client/redirect.html", client_secret: "A0gaHnhAKofaJIQt" }
        var data = {
            "url": url, "data": auth_data, "method": "POST", "headers": headers
        }
        $scope.authResponse = railApi.post('integrations', data, 'integration')

    }
    else if(sessionStorage.getItem("company_id") != null) {
        var access_token = sessionStorage.getItem("access_token");
        var company_id = sessionStorage.getItem("company_id")
        data = {
            method: "GET",
            url: "https://api.linkedin.com/v1/companies/"+company_id+"/relation-to-viewer/is-company-share-enabled?format=json",
            headers: {"Content-Type":"application/json", "Authorization": "Bearer " + access_token, "x-li-format":"json" }
        }
        $scope.isConnectedToLinkedIn = railApi.post('integrations', data, 'integration').data

        //https://api.linkedin.com/v1/companies/{id}/relation-to-viewer/is-company-share-enabled?format=json
    }
    // $scope.postText;
    // $scope.show = false;

    // $scope.userInfo = adalAuthenticationService.userInfo;
    // $scope.isConnectedToOutlook = adalAuthenticationService.userInfo.isAuthenticated;;


    // $scope.connectToOutlook = function () {
    //     adalAuthenticationService.login();
    // };

    // $scope.disconnectOutlook = function (index) {
    //     // MS forces the redirect on the page
    //     //adalAuthenticationService.logOut();
    //     $scope.logoutUrl = $sce.trustAsResourceUrl('https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=' + window.location.origin);
    //     adalAuthenticationService.clearCache();
    //     $scope.isConnectedToOutlook = false;
    //     setTimeout(function () {
    //         $scope.userInfo = adalAuthenticationService.userInfo;
    //     }
    //         , 5000);
    // };

    // $scope.$on("adal:loginSuccess", function () {
    //     $scope.testMessage = "loginSuccess";
    //     console.log("Connection Success:" + JSON.stringify(adalAuthenticationService.userInfo));
    //     $scope.userInfo = adalAuthenticationService.userInfo
    //     $scope.isConnectedToOutlook = true;

    //     var token = adalAuthenticationService.getCachedToken('7dde16b1-e277-4436-9d3f-f63ce4b86b40');
    //     console.log("Token:" + token);
    //     adalAuthenticationService.acquireToken('7dde16b1-e277-4436-9d3f-f63ce4b86b40');

    // });

    // $scope.$on("adal:loginFailure", function () {
    //     $scope.testMessage = "loginFailure";
    //     console.log("Connection Failure")
    // });

    // $scope.$on("adal:acquireTokenFailure", function (desc, error) {
    //     console.log("Token Failure:" + error);
    // });

    // $scope.$on("adal:acquireTokenSuccess", function (token) {
    //     console.log("Token Success:" + token);
    // });

    $scope.connectToLinkedIn = function () {
        var redirect_uri = $location.protocol() +"://"+ $location.host() + "/view/client/redirect.html"

        window.location = "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=78udshneqyjg2z&redirect_uri="+redirect_uri+"&state=Hc0tx5eFWV905f7H5ERe&scope=rw_company_admin"
        $scope.isConnectedToLinkedIn = true;
    };

    $scope.disconnectLinkedIn = function () {
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('company_id')
        $scope.isConnectedToLinkedIn = false;
    }

    function getCompanies() {
        var access_token = sessionStorage.getItem("access_token");

        data = {
            method: "GET",
            url: "https://api.linkedin.com/v1/companies?format=json&is-company-admin=true",
            headers: {"Content-Type":"application/json", "Authorization": "Bearer " + access_token, "x-li-format":"json" }
        }
        $scope.companyResponse = railApi.post('integrations', data, 'integration')



    }

    $scope.postToLinkedin = function () {
        //alert($stateParams.id)
        if ($stateParams.id) {
          var public_address = $location.protocol() +"://"+ $location.host() + "/#/career/info/public/" + $stateParams.id
        }
        else if ($stateParams.job_id) {
          var public_address = $location.protocol() +"://"+ $location.host() + "/#/career/info/public/" + $stateParams.job_id
        }

        if ($scope.hgForm) {
          var title = $scope.hgForm.name.en
        }
        else if ($scope.job) {
          var title = $scope.job.name.en
        }

        var url = "https://api.linkedin.com/v1/companies/"+ sessionStorage.getItem('company_id') +"/shares?format=json"
        var headers = { "Authorization": "Bearer " + sessionStorage.getItem("access_token"), "x-li-format":"json", "Content-Type":"application/json" }
        var share = '{ "visibility": { "code": "anyone" }, "comment": "'+$scope.postText+ ' ' + public_address + '", "content": { "title": "' + title + '" }  }'

        data = {
            "url": url, "data": share, "headers": headers, "method": "POST"
        }

        railApi.post('integrations', data, 'integration')



    }

    $scope.selection = function(company) {
        sessionStorage.setItem("company_id", company.id)
        $scope.selectedCompanyName = company.name
        $scope.isConnectedToLinkedIn = true;

    }

    $scope.$watch('authResponse', function (newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
            if (newValue.errors.error) {
                $scope.errorMessage = newValue.errors.error_description
            } else {
                if (newValue.data.access_token != null) {
                    sessionStorage.setItem("access_token", newValue.data.access_token)
                    getCompanies();


                }
            }
        }
    }, true);

    $scope.$watch('companyResponse', function (newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
            if (newValue.errors.error) {
                $scope.errorMessage = newValue.errors.error_description

            } else { //determine how many companies the user is an administrator for. You can only integrate with one at a time for now.
                if (newValue.data._total > 1) {
                    $scope.companies = newValue.data.values;
                    $scope.companySelect = true;
                    $scope.companySelectModal = $modal.open({
                        templateUrl: 'companySelectModal.ejs',
                        controller: 'integrationsCtrl',
                        scope: $scope,
                        size: 'lg'
                        });
                } else if (newValue.data._total == 1) {
                    sessionStorage.setItem("company_id", newValue.data.values[0].id)
                    alert("You have integrated with " + newValue.data.values[0].name + " company page")
                    $scope.isConnectedToLinkedIn = true;
                }
                else if (newValue.data._total == 0){
                    alert("You must be the administrator of a LinkedIn company page to use this functionality")
                    sessionStorage.removeItem("access_token")
                }
                window.location.href = '/ats.html#/client/integrations'
            }
        }
    }, true);





};

module.exports = integrationsCtrl;
