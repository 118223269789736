'use strict';
var config = require('../config.js');

var userPermissionsCtrl = function ($scope, railApi) {

  $scope.updateDmPermissions = function(notes, do_not_hire, dm_tag, dm_offer_tag, info_req_form) {
    railApi.put('organizations/1', {dm_notes_privacy: notes, dm_restrict_do_not_hire: do_not_hire, 
                                    dm_restrict_tag_actions: dm_tag, dm_restrict_offer_in_progress_tag: dm_offer_tag, 
                                    dm_restrict_information_request_form: info_req_form}, 'organization');
  }

  $scope.updateHmPermissions = function(do_not_hire, hm_tag, hm_offer_tag) {
    railApi.put('organizations/1', {hm_restrict_do_not_hire: do_not_hire, hm_restrict_tag_actions: hm_tag, hm_restrict_offer_in_progress_tag: hm_offer_tag}, 'organization');
  }

};

module.exports = userPermissionsCtrl;