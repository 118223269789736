'use restrict';

var reportUserChangeLogsCtrl = function ($scope, railApi) {
 
     $scope.total = 0;
     $scope.results = {};
     $scope.hgForm = null;
     $scope.userChangeLogsData = [];
 
     $scope.calculateTotal = function(result){
         var myTotal = 0;         
         for(var i = 0; i < result.length; i++){
             var logsNum = result[i][1];
             myTotal = myTotal + logsNum;
         }
 
         $scope.total = myTotal;
     };
 
    $scope.getUserChangeLogsReportData = function(hgForm, railApiName) {

      railApi.get('user_change_logs/').promise.then(function(myData) {
        $scope.allLogs = myData;

        $scope.userChangeLogs = $scope.allLogs.filter(function(item) {
          return item.change_made_at.toISOString().split('T')[0] >= hgForm.start_date.toISOString().split('T')[0] && item.change_made_at.toISOString().split('T')[0] <= hgForm.end_date.toISOString().split('T')[0];
        });
        $scope.userChangeLogsNumber = $scope.userChangeLogs.length;
      });
    }

    $scope.int_to_ip = function(ipInt) { //convert int ipaddress to 0.0.0.0 format
        return ( (ipInt>>>24) +'.' + (ipInt>>16 & 255) +'.' + (ipInt>>8 & 255) +'.' + (ipInt & 255) );
    }

};
 
 module.exports = reportUserChangeLogsCtrl;