'use strict';


var config = require('../../config.js');
// const { isNull } = require('util');

var Directive = function () {
    return {
        restrict: 'E',
        require: 'ngModel',
        scope: {
            model: '=ngModel'
        },
        replace: true,
        template: "\n<div class=\"row\">\n    <div class=\"col col-md-12\">\n        <div class=\"row\">\n            <div class=\"col col-md-6\">\n                <label class=\"input date-picker\">\n                    <i class=\"icon-append fa fa-calendar\"\n                       data-ng-click=\"open($event)\">\n                    </i>\n                    <input type=\"text\"\n                           placeholder=\"{{placeholder | hgTranslate}}\"\n                           aria-label=\"{{placeholder | hgTranslate}}\"\n                           class=\"form-control\"\n                           datepicker-popup=\"{{datepickerPopup}}\"\n                           show-button-bar=\"showButtonBar\"\n                           data-ng-click=\"open($event)\"\n                           ng-model=\"dt\"\n                           ng-change=\"selectDate(dt)\"\n                           is-open=\"opened\"\n                           min-date=\"minDate\"\n                           max-date=\"maxDate\"\n                           datepicker-options=\"{ 'show-weeks':{{showWeeks}} }\"\n                           />\n                </label>\n            </div>\n\n            <div class=\"col col-md-3 col-sm-6 time-picker\" data-ng-show=\"showTime\">\n                <timepicker ng-model=\"mytime\"\n                            ng-change=\"changed()\"\n                            hour-step=\"hstep\"\n                            minute-step=\"mstep\"\n                            show-meridian=\"ismeridian\">\n                </timepicker>\n            </div>\n\n            <div class=\"col col-md-1 col-sm-6 delete-date\" data-ng-show=\"showDelete\">\n                <a ng-show=\"dt\"\n                   href\n                   ng-click=\"clear()\"\n                   tooltip=\"Delete\">\n                    <i aria-label=\"Delete Icon\" class=\"fa fa-trash-o fa-lg\"></i>\n                </a>\n            </div>\n        </div>\n    </div>\n</div>\n",
        controller: function($scope, $attrs) {
            // datepicker
            var dateFormat = 'MMM D, YYYY';
            $scope.dt = null;
            $scope.showButtonBar = false;
            $scope.datepickerPopup = 'MMM dd, yyyy';
            $scope.opened = false;
            $scope.roundMin = true;
            var setInitialValues = true;
            var dateValue;

            //// timepicker
            $scope.showTime = true;
            $scope.mytime = null;
            $scope.ismeridian = true;
            $scope.hstep = 1;
            $scope.mstep = 15;

            // Show weeks option
            $scope.showWeeks = false; // by default false
            if (null != $attrs.showWeeks) {
                $scope.showWeeks = $scope.$eval($attrs.showWeeks);
            }

            if (null != $attrs.placeholder) {
                $scope.placeholder = $attrs.placeholder;
            } else {
                $scope.placeholder = 'NoValue';
            }

            // Delete option
            $scope.showDelete = false;

            if (null != $attrs.showDelete) {
                $scope.showDelete = $scope.$eval($attrs.showDelete);
            }


            if ($attrs.parseDateFormat) {
                dateFormat = $attrs.parseDateFormat;
            }

            if (null != $attrs.showButtonBar) {
                $scope.showButtonBar = $scope.$eval($attrs.showButtonBar);
            }

            if ($attrs.datepickerPopup) {
                $scope.datepickerPopup = $attrs.datepickerPopup;
            }

            $scope.today = function() {
                $scope.dt = new Date();
            };

            $scope.clear = function () {
                $scope.dt = null;
                $scope.mytime = null;
                $scope.model = null;
            };

            $scope.open = function($event) {
                $event.preventDefault();
                $event.stopPropagation();

                $scope.opened = !$scope.opened;
                $scope.$parent.setInitialValues = false;
            };


            $scope.selectDate = function(dt) {
                if (null == dt ||
                    '' === dt) {
                    $scope.dt = null;
                    $scope.mytime = null;
                    $scope.model = null;
                }
            };

            $scope.$watch('model', function (newValue, oldValue) {
                if (null == newValue ||
                    '' == newValue) {
                    $scope.dt = null;
                    $scope.mytime = null;
                } else {
                    if (setInitialValues) {
                        $scope.dt = moment(newValue).format(dateFormat);
                        if ($scope.showTime) {
                            $scope.mytime = new Date(newValue);
                        }

                        setInitialValues = false;
                    }
                }
            });

            $scope.$parent.$watch('setInitialValues', function (newValue) {
                if (newValue) {
                    $scope.dt = moment($scope.model).format(dateFormat);
                    if ($scope.showTime) {
                        $scope.mytime = new Date($scope.model);
                    }
                }
            });

            $scope.$watch('dt', function (newValue, oldValue) {
                if (null != newValue &&
                    !_.isEqual(newValue, oldValue)
                ) {
                    setInitialValues = false;
                    $scope.model = new Date(newValue);


                    if (null == $scope.mytime){
                        dateValue = new Date();
                    } else {
                        dateValue = new Date($scope.mytime);
                    }
                    if($attrs.defaultTime){
                        
                        if(oldValue == null){
                            $scope.model.setHours(23);
                            $scope.model.setMinutes(59);
                        }else{s
                            $scope.model.setHours(dateValue.getHours());
                            $scope.model.setMinutes(dateValue.getMinutes());
                        }
                    }else{
                        $scope.model.setHours(dateValue.getHours());
                        $scope.model.setMinutes(dateValue.getMinutes());
                    }
                    
                    $scope.mytime = $scope.model;
                }
            }, true);

            //// timepicker
            if (null != $attrs.showTime) {
                $scope.showTime = $scope.$eval($attrs.showTime);
            }

            if ($scope.showTime) {
                if (null != $attrs.showMeridian) {
                    $scope.ismeridian = $scope.$eval($attrs.showMeridian);
                }

                if (null != $attrs.hourStep) {
                    $scope.hstep = $scope.$eval($attrs.hourStep);
                }
                if (null != $attrs.minuteStep) {
                    $scope.mstep = $scope.$eval($attrs.minuteStep);
                }

                $scope.update = function() {
                    var d = new Date();
                    d.setHours( 14 );
                    d.setMinutes( 0 );
                    $scope.mytime = d;
                };

                $scope.changed = function () {
                    $scope.model = new Date($scope.mytime);
                    $scope.dt = moment($scope.model).format(dateFormat);
                    if ($scope.mytime && $scope.roundMin){
                        var currentMin = $scope.mytime.getMinutes();
                        if (currentMin < 15) {
                            currentMin = 0;
                        }
                        else if (currentMin > 15 && currentMin < 30){
                            currentMin = 15;
                        } else if (currentMin > 30 && currentMin < 45){
                            currentMin = 30;
                        }
                        else if (currentMin > 45 && currentMin < 60){
                            currentMin = 45;
                        }
                        $scope.mytime = $scope.model.setMinutes(currentMin);
                    }
                    $scope.roundMin = true;
                };
            }
        },
        link: function ($scope, elem, attr) {
            $scope.$watch( function(){
                elem = $(elem);

                var date;
                $scope.minDate = null;
                $scope.maxDate = null;
                
                if (attr.minDate) {
                    date = new Date($scope.$eval(attr.getMinDate));
                    $scope.minDate = date.setDate(date.getDate() + 1);
                }

                if (attr.maxDate) {
                    date = new Date($scope.$eval(attr.maxDate));
                    $scope.maxDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
                }

                // function convert(){
                //     return moment($scope.mytime).hour(23).minute(59)['_d']
                // }

                // if(attr.defaultTime){
                //         if($scope.mytime == null){
                //             console.log('assss')
                //         }else{
                //             try{
                //                 $scope.mytime = convert()
                //             }catch(e){
                //                 console.log('teste')
                //             }
                            
                //         }
                    
                // }

                elem.on('keydown', function() {
                    $scope.roundMin = false;
                });
            })
        }
    };
};

Directive.$inject = [];

module.exports = Directive;