/**
 * Random functions from hgadmin
 */
// jscs: disable

$.root_ = $('body');
/*
 * GLOBAL: interval array (to be used with jarviswidget in ajax and angular mode) to clear auto fetch interval
 */
$.intervalArr = [];

$.fn.removeClassPrefix = function (prefix) {
    this.each(function (i, el) {
        var classes = el.className.split(" ").filter(function (c) {
            return c.lastIndexOf(prefix, 0) !== 0;
        });
        el.className = $.trim(classes.join(" "));
    });
    return this;
};

$.fn.extend({

    //pass the options variable to the function
    jarvismenu: function (options) {
        var parents;
        var visible;

        var defaults = {
            accordion: 'true',
            speed: 200,
            closedSign: '[+]',
            openedSign: '[-]'
        };

        // Extend our default options with those provided.
        var opts = $.extend(defaults, options);
        //Assign current element to variable, in this case is UL element
        var $this = $(this);

        //add a mark [+] to a multilevel menu
        $this.find("li").each(function () {
            if ($(this).find("ul").size() !== 0) {
                //add the multilevel sign next to the link
                $(this).find("a:first").append("<strong class='collapse-sign'>" + opts.closedSign + "</strong>");

                //avoid jumping to the top of the page when the href is an #
                if ($(this).find("a:first").attr('href') == "#") {
                    $(this).find("a:first").click(function () {
                        return false;
                    });
                }
            }
        });

        //open active level
        $this.find("li.active").each(function () {
            $(this).parents("ul").slideDown(opts.speed);
            $(this).parents("ul").parent("li").find("b:first").html(opts.openedSign);
            $(this).parents("ul").parent("li").addClass("open");
        });

        $this.find("li a").click(function () {

            if ($(this).parent().find("ul").size() !== 0) {

                if (opts.accordion) {
                    //Do nothing when the list is open
                    if (!$(this).parent().find("ul").is(':visible')) {
                        parents = $(this).parent().parents("ul");
                        visible = $this.find("ul:visible");
                        visible.each(function (visibleIndex) {
                            var close = true;
                            parents.each(function (parentIndex) {
                                if (parents[parentIndex] == visible[visibleIndex]) {
                                    close = false;
                                    return false;
                                }
                            });
                            if (close) {
                                if ($(this).parent().find("ul") != visible[visibleIndex]) {
                                    $(visible[visibleIndex]).slideUp(opts.speed, function () {
                                        $(this).parent("li").find("b:first").html(opts.closedSign);
                                        $(this).parent("li").removeClass("open");
                                    });

                                }
                            }
                        });
                    }
                }// end if
                if ($(this).parent().find("ul:first").is(":visible") && !$(this).parent().find("ul:first").hasClass("active")) {
                    $(this).parent().find("ul:first").slideUp(opts.speed, function () {
                        $(this).parent("li").removeClass("open");
                        $(this).parent("li").find("b:first").delay(opts.speed).html(opts.closedSign);
                    });

                } else {
                    $(this).parent().find("ul:first").slideDown(opts.speed, function () {
                        /*$(this).effect("highlight", {color : '#616161'}, 500); - disabled due to CPU clocking on phones*/
                        $(this).parent("li").addClass("open");
                        $(this).parent("li").find("b:first").delay(opts.speed).html(opts.openedSign);
                    });
                } // end else
            } // end if
        });
    } // end function
});

(function ($, window, undefined) {

    var elems = $([]),
        jq_resize = $.resize = $.extend($.resize, {}),
        timeout_id, str_setTimeout = 'setTimeout',
        str_resize = 'resize',
        str_data = str_resize + '-special-event',
        str_delay = 'delay',
        str_throttle = 'throttleWindow';

    jq_resize[str_delay] = 150;

    jq_resize[str_throttle] = true;

    $.event.special[str_resize] = {

        setup: function () {
            if (!jq_resize[str_throttle] && this[str_setTimeout]) {
                return false;
            }

            var elem = $(this);
            elems = elems.add(elem);
            try {
                $.data(this, str_data, {
                    w: elem.width(),
                    h: elem.height()
                });
            } catch (e) {
                $.data(this, str_data, {
                    w: elem.width, // elem.width();
                    h: elem.height // elem.height();
                });
            }

            if (elems.length === 1) {
                loopy();
            }
        },
        teardown: function () {
            if (!jq_resize[str_throttle] && this[str_setTimeout]) {
                return false;
            }

            var elem = $(this);
            elems = elems.not(elem);
            elem.removeData(str_data);
            if (!elems.length) {
                clearTimeout(timeout_id);
            }
        },

        add: function (handleObj) {
            if (!jq_resize[str_throttle] && this[str_setTimeout]) {
                return false;
            }
            var old_handler;

            function new_handler(e, w, h) {
                var elem = $(this),
                    data = $.data(this, str_data);
                data.w = w !== undefined ? w : elem.width();
                data.h = h !== undefined ? h : elem.height();

                old_handler.apply(this, arguments);
            }

            if ($.isFunction(handleObj)) {
                old_handler = handleObj;
                return new_handler;
            } else {
                old_handler = handleObj.handler;
                handleObj.handler = new_handler;
            }
        }
    };

    function loopy() {
        timeout_id = window[str_setTimeout](function () {
            elems.each(function () {
                var width;
                var height;

                var elem = $(this),
                    data = $.data(this, str_data); //width = elem.width(), height = elem.height();

                // Highcharts fix
                try {
                    width = elem.width();
                } catch (e) {
                    width = elem.width;
                }

                try {
                    height = elem.height();
                } catch (e) {
                    height = elem.height;
                }
                //fixed bug


                if (width !== data.w || height !== data.h) {
                    elem.trigger(str_resize, [data.w = width, data.h = height]);
                }

            });
            loopy();

        }, jq_resize[str_delay]);

    }

})(jQuery, global);
// jscs: enable

// Messagebox
var ExistMsg = 0,
    SmartMSGboxCount = 0,
    PrevTop = 0;


$.SmartMessageBox = function (settings, callback) {
    var SmartMSG, Content;
    settings = $.extend({
        title: "",
        content: "",
        NormalButton: undefined,
        ActiveButton: undefined,
        buttons: undefined,
        input: undefined,
        inputValue: undefined,
        placeholder: "",
        options: undefined
    }, settings);

    //Messagebox Sound


    SmartMSGboxCount = SmartMSGboxCount + 1;

    if (ExistMsg == 0) {
        ExistMsg = 1;
        SmartMSG = "<div class='divMessageBox animated fadeIn fast' id='MsgBoxBack'></div>";
        $("body").append(SmartMSG);
    }

    var InputType = "";
    var HasInput = 0;
    if (settings.input != undefined) {
        HasInput = 1;
        settings.input = settings.input.toLowerCase();

        switch (settings.input) {
            case "text":
                settings.inputValue = $.type(settings.inputValue) === 'string' ? settings.inputValue.replace(/'/g, "&#x27;") : settings.inputValue;
                InputType = "<input class='form-control' type='" + settings.input + "' id='txt" +
                    SmartMSGboxCount + "' placeholder='" + settings.placeholder + "' value='" + settings.inputValue + "'/><br/><br/>";
                break;
            case "password":
                InputType = "<input class='form-control' type='" + settings.input + "' id='txt" +
                    SmartMSGboxCount + "' placeholder='" + settings.placeholder + "'/><br/><br/>";
                break;

            case "select":
                if (settings.options == undefined) {
                    alert("For this type of input, the options parameter is required.");
                } else {
                    InputType = "<select class='form-control' id='txt" + SmartMSGboxCount + "'>";
                    for (var i = 0; i <= settings.options.length - 1; i++) {
                        if (settings.options[i] == "[") {
                            Name = "";
                        } else {
                            if (settings.options[i] == "]") {
                                NumBottons = NumBottons + 1;
                                Name = "<option>" + Name + "</option>";
                                InputType += Name;
                            } else {
                                Name += settings.options[i];
                            }
                        }
                    }
                    ;
                    InputType += "</select>"
                }

                break;
            default:
                alert("That type of input is not handled yet");
        }

    }

    Content = "<div class='MessageBoxContainer animated fadeIn fast' id='Msg" + SmartMSGboxCount +
        "'>";
    Content += "<div class='MessageBoxMiddle'>";
    Content += "<span class='MsgTitle'>" + settings.title + "</span class='MsgTitle'>";
    Content += "<p class='pText'>" + settings.content + "</p>";
    Content += InputType;
    Content += "<div class='MessageBoxButtonSection'>";

    if (settings.buttons == undefined) {
        settings.buttons = "[Accept]";
    }

    settings.buttons = $.trim(settings.buttons);
    settings.buttons = settings.buttons.split('');

    var Name = "";
    var NumBottons = 0;
    if (settings.NormalButton == undefined) {
        settings.NormalButton = "#232323";
    }

    if (settings.ActiveButton == undefined) {
        settings.ActiveButton = "#ed145b";
    }

    for (var i = 0; i <= settings.buttons.length - 1; i++) {

        if (settings.buttons[i] == "[") {
            Name = "";
        } else {
            if (settings.buttons[i] == "]") {
                NumBottons = NumBottons + 1;
                Name = "<button id='bot" + NumBottons + "-Msg" + SmartMSGboxCount +
                    "' class='btn btn-default btn-sm botTempo'> " + Name + "</button>";
                Content += Name;
            } else {
                Name += settings.buttons[i];
            }
        }
    }
    ;

    Content += "</div>";
    //MessageBoxButtonSection
    Content += "</div>";
    //MessageBoxMiddle
    Content += "</div>";
    //MessageBoxContainer

    // alert(SmartMSGboxCount);
    if (SmartMSGboxCount > 1) {
        $(".MessageBoxContainer").hide();
        $(".MessageBoxContainer").css("z-index", 99999);
    }

    $(".divMessageBox").append(Content);

    // Focus
    if (HasInput == 1) {
        $("#txt" + SmartMSGboxCount).focus();
    }

    $('.botTempo').hover(function () {
        var ThisID = $(this).attr('id');
        // alert(ThisID);
        // $("#"+ThisID).css("background-color", settings.ActiveButton);
    }, function () {
        var ThisID = $(this).attr('id');
        //$("#"+ThisID).css("background-color", settings.NormalButton);
    });

    // Callback and button Pressed
    $(".botTempo").click(function () {
        // Closing Method
        var ThisID = $(this).attr('id');
        var MsgBoxID = ThisID.substr(ThisID.indexOf("-") + 1);
        var Press = $.trim($(this).text());

        if (HasInput == 1) {
            if (typeof callback == "function") {
                var IDNumber = MsgBoxID.replace("Msg", "");
                var Value = $("#txt" + IDNumber).val();
                if (callback)
                    callback(Press, Value);
            }
        } else {
            if (typeof callback == "function") {
                if (callback)
                    callback(Press);
            }
        }

        $("#" + MsgBoxID).addClass("animated fadeOut fast");
        SmartMSGboxCount = SmartMSGboxCount - 1;

        if (SmartMSGboxCount == 0) {
            $("#MsgBoxBack").removeClass("fadeIn").addClass("fadeOut").delay(300).queue(function () {
                ExistMsg = 0;
                $(this).remove();
            });
        }
    });

}
