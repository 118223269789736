'use strict';



var Directive = function () {
    return {
        require: 'ngModel',
        scope:{
            model: '=ngModel'
        },
        template: "<div class=\"row\">\n    <div class=\"col col-md-12 col-sm-12 col-lg-12\">\n        <div class=\"row\">\n            <div class=\"col col-lg-3 col-md-4 col-sm-4\">\n                <label class=\"select\">\n                    <select ng-model='month' ng-options=\"month.value as month.name | hgTranslate for month in months\"></select><i></i>\n                </label>\n            </div>\n\n            <div class=\"col col-lg-2 col-md-3 col-sm-4\">\n                <label class=\"select\">\n                    <select ng-model='day' ng-options=\"day for day in days\"></select><i></i>\n                </label>\n            </div>\n\n            <div class=\"col col-lg-3 col-md-4 col-sm-4\">\n                <label class=\"select\">\n                    <select ng-model='year' ng-options=\"year for year in years\"></select><i></i>\n                </label>\n            </div>\n\n        </div><!-- .row -->\n\n    </div> <!-- .col-md-12 -->\n</div><!-- .row -->",
        link: function($scope, $elem, $attrs, $ngCntrl) {

            $scope.$watch('model', function (newDate, oldDate) {
                if(newDate) {
                    $scope.month = new Date(newDate).getMonth();
                    $scope.day = new Date(newDate).getDate();
                    $scope.year = new Date(newDate).getFullYear();
                }
            });

            $scope.showPresent = $attrs['showPresent'] || false;

            var dayRange = [1, 31];
            var months = [
                'january',
                'february',
                'march',
                'april',
                'may',
                'june',
                'july',
                'august',
                'september',
                'october',
                'november',
                'december'
            ];

            $scope.months =  (function () {
                var lst = [],
                    mLen = months.length;

                for(var i= 0; i<mLen; i++){
                    lst.push({
                        value: i,
                        name: months[i]
                    });
                }
                return lst;
            }());


            $scope.days = [];
            while (dayRange[0] <= dayRange[1]) {
                $scope.days.push(dayRange[0]++);
            }

            $scope.years = [];
            var currentYear = moment().year();
            for (var j = currentYear; j >= 1900; j--) {
                $scope.years.push(j);
            }

            var updateDate = function(newValue){
                if(newValue && $scope.year && (null !== $scope.month) && $scope.day){
                    $scope.model = new Date($scope.year, $scope.month, $scope.day);
                }
            };
            $scope.$watch('day', function(newValue){
                updateDate(newValue);

            }, true);

            $scope.$watch('month', function(newValue){
                updateDate(newValue);

            }, true);

            $scope.$watch('year', function(newValue){
                updateDate(newValue);
            }, true);

        }
    };
};

Directive.$inject = [];

module.exports = Directive;