'use strict';


var getId = (function () {
    var id = 0;

    return function () {
        return id += 1;
    };
}());

var Directive = function () {
    return {
        restrict: 'E',
        compile: function (tElem) {
            tElem = $(tElem);
            var id = 'hgEditableMultipleToggle#' + getId();
            var edit = tElem.find('hg-edit-multiple').attr('ng-show', 'this[\'' + id + '\']');
            var text = tElem.find('hg-text-multiple').attr('ng-show', '!this[\'' + id + '\']');

            return function (scope, elem, attrs) {
                elem.attr('id', id);

                if( attrs.expand ) {
                    scope[id] = attrs.expand;
                } else{
                    scope[id] = false;
                }

                elem.bind('click', function (e) {
                    if (($(e.toElement).parents('div').hasClass('panel-heading') ||
                        $(e.target).parents('div').hasClass('panel-heading')) &&
                        (null == attrs.editable || true === JSON.parse(attrs.editable))
                    ) {
                        scope[id] = !scope[id];
                        scope.$apply();
                    }
                });

                $('body').bind('click.' + id, function (e) {
                    if ((!$(e.target).parents('div').hasClass('panel-heading') &&
                        !$(e.target).parents('div').hasClass('panel-body')) &&
                        (!$(e.toElement).parents('div').hasClass('panel-heading') &&
                        !$(e.toElement).parents('div').hasClass('panel-body'))
                    ) {
                        scope[id] = false;
                        scope.$apply();
                    }
                });

                elem.on('$destroy', function () {
                    $('body').unbind('click.' + id);
                });
            };
        }
    };
};

Directive.$inject = [];

module.exports = Directive;