'use strict';
var config = require('../config.js');

var offerLetterReviewCtrl = function ($scope, Jobs, railApi, $translate, $stateParams, Upload, $rootScope, $state) {
    $scope.providedDocs = [];
    $scope.uploaded_files_attributes = [];
    $scope.exportOfferLetterUrl = config.apiIP + "/approval_emails/export_offer_letter?offer_letter_id="
    
    $scope.applicant_profile = [];
    railApi.get('organizations/1').promise.then(function(data) {
        $scope.organization = data;
    });

    $scope.submitView = function(feedbackNote, response, approval_email_id, responder_role, request_signature){
        var include_signature = false
        if (request_signature){
            include_signature = response
        }
        railApi.post('approval_chains/feedback_response', {hash: $stateParams.hash, notes: feedbackNote, approved: response, approval_email_id: approval_email_id, responder_role: responder_role, include_signature: include_signature}).promise.then(function(data){
            $scope.responseSubmittedModal = $scope.modal.open({
                templateUrl: 'responseSubmittedModal.ejs',
                scope: $scope,
                size: 'md'
            });
        })
    }

    $scope.beforeSubmitOfferResponse = function(feedbackNote, decision, approval_email_id, files){
        $scope.submittedFeedback = feedbackNote
        $scope.submittedDecision = decision
        $scope.submittedFiles = files
        $scope.offerResponseConfirmationModal = $scope.modal.open({
                templateUrl: 'offerResponseConfirmationModal.ejs',
                scope: $scope,
                size: 'md'
            });
    }

    $scope.submitOfferResponse = function(feedbackNote, response, approval_email_id, files){
        
        railApi.post('approval_emails/candidate_response', {hash: $stateParams.hash, notes: feedbackNote, accepted: response, files: files}).promise.then(function(data){
            $scope.offerResponseConfirmationModal.dismiss();
            $scope.responseSubmittedModal = $scope.modal.open({
                templateUrl: 'responseSubmittedModal.ejs',
                scope: $scope,
                size: 'md'
            });
        })
    }

    $scope.formattedViewURL = function (files, appl_id, applicant_name) {
        var resumeFile = _.filter(files, function(file) {  return file.file_type == "formatted_resume" || file.file_type == "resume" });
        if (resumeFile === undefined || resumeFile.length < 1) return false
        var url = $scope.apiUrl + "/applicant_profiles/" + appl_id + "/display_formatted_resume/"+ applicant_name +"#zoom=90&view=Fit";
        return $sce.trustAsResourceUrl(url)
    };

    $scope.checkHash = function(approver){
        if(approver == undefined){
            window.location.href="/expiredLink.html";
        }
    }

    $scope.downloadAllFiles = function(id){
        var hash = $stateParams.hash
        railApi.post('uploaded_files/download_all_files', {hash: hash}).promise.then(function(data){
            var downloadURL = $scope.apiUrl + data.zip_url;

            window.open(downloadURL);
        })
    }

    $scope.exportOfferLetter = function(offer_letter_id){
        var hash = $stateParams.hash
        window.location.href = config.apiIP + "/approval_emails/export_offer_letter?hash=" + hash;
    }

    $scope.initOfferData = function(){
        var hash = $stateParams.hash
        setTimeout(function() {
            railApi.post('approval_emails/check_hash', {hash: hash}, 'approval_emails').promise.then(function(data){
                if(data.email_type =='offer_letter'){
                    $scope.offerLetter = data
    
                    var val = [{label: 'user.name.first', value: data.applicant_info.first_name},
                         {label: 'user.name.last', value: data.applicant_info.last_name},
                         {label: 'user.email', value: data.applicant_info.email},
                         {label: 'jobName', value: data.job_info.name.en},
                         {label: 'jobNameFr', value: data.job_info.name.fr},
                         {label: 'jobNameEs', value: data.job_info.name.es},
                         {label: 'organization.name', value: $scope.organization.name},
                         {label: 'contactEmail', value: $scope.organization.email},
                         {label: 'contactPhone', value: $scope.organization.phone},
                         {label: 'global.url.hostname', value: $scope.organization.url},
                         {label: 'clientName', value: $scope.organization.name},
                         {label: 'job.name', value: data.job_info.name.en},
                         {label: 'job.name_fr', value: data.job_info.name.fr},
                         {label: 'job.name_es', value: data.job_info.name.es},
                         {label: 'global.url.protocol', value: $scope.organization.url}]
                         
                    var emailString = data.message
                    val.forEach( function(value){
                        emailString = emailString.replaceAll('{{'+value.label+'}}', !_.isEmpty(value.value) ? value.value : '______')
                    })
        
                    $scope.offerPrev = emailString
                }else{
                    window.location.href="/expiredLink.html";
                }
                
            })
          }, 1000);
        
    }

    $scope.replacePlaceholders = function(offer_data){
        var val = [{label: 'user.name.first', value: offer_data.approval_email.applicant_info.first_name},
                {label: 'user.name.last', value: offer_data.approval_email.applicant_info.last_name},
                {label: 'user.email', value: offer_data.approval_email.applicant_info.email},
                {label: 'jobName', value: offer_data.approval_email.job_info.name.en},
                {label: 'jobNameFr', value: offer_data.approval_email.job_info.name.fr},
                {label: 'jobNameEs', value: offer_data.approval_email.job_info.name.es},
                {label: 'organization.name', value: $scope.organization.name},
                {label: 'contactEmail', value: $scope.organization.email},
                {label: 'contactPhone', value: $scope.organization.phone},
                {label: 'global.url.hostname', value: $scope.organization.url},
                {label: 'clientName', value: $scope.organization.name},
                {label: 'job.name', value: offer_data.approval_email.job_info.name.en},
                {label: 'job.name_fr', value: offer_data.approval_email.job_info.name.fr},
                {label: 'job.name_es', value: offer_data.approval_email.job_info.name.es},
                {label: 'global.url.protocol', value: $scope.organization.url}]
                
        var emailString = offer_data.approval_email.message
        val.forEach( function(value){
            emailString = emailString.replaceAll('{{'+value.label+'}}', !_.isEmpty(value.value) ? value.value : '______')
        })
        $scope.processedEmail = emailString
    }
    
    $scope.getDocsIdAndUpload = function (file_req_id, file_upload) {
        if(file_upload){
            var files = [];

            files.push({
                id: file_req_id,
                file: file_upload
            });

            if (files.length) {
                $scope.upload(files, 'offer_attachment');

                return true;
            } else {

                return false
            }
        }

        
    };

    $scope.clearProvidedDoc = function(index, file_and_link_id){
        $scope.uploaded_files_attributes = $scope.uploaded_files_attributes.filter(function(item) {
            return item.file_and_link_id != file_and_link_id;
        });
        $scope.providedDocs[index] = null
    }

    $scope.upload = function (files, file_type, attachment, error) {
        if (files && files.length && (undefined === error)) {
            $scope.errorFile = false;
            $scope.errorInterviewFile = false;

            if ($scope.offerLetter) {
                var upload_url = config.apiIP + 'approval_emails/' + $scope.offerLetter + '/upload_file';
            }
            else {
                var upload_url = config.apiIP + 'approval_emails/upload_file';
            }

            for (var i = 0; i < files.length; i+=1) {

                if ('offer_attachment' === file_type) {
                    if(5000000 < files[0].bytes) {
                        $scope.errorFile = true;
                        return false;
                    }
                  }

                var data = {
                    file_and_link_id: files[i].id,
                    direction: 'inbound',
                    file_type: 'offer_attachment'
                };

                Upload.upload({
                    url: upload_url,
                    file: files[i].file,
                    fields: data
                }).progress(function (evt) {
                      $scope.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                }).success(function (data, status, headers, config) {
                    $scope.uploaded_files_attributes.push({file: data.file_info, file_and_link_id: data.file_and_link_id})
                }).error(function(data, status, headers, config) {
                  if ('offer_attachment' === file_type) {
                    $scope.fileError = data.error;
                    $scope.errorFile = true;
                  }
                  else {
                    $scope.fileError = data.error;
                    $scope.errorInterviewFile = true;
                  }
                });
                
            }
        } else {
            return false;
        }
    }

    $scope.closeWindow = function() { 
        window.open('','_parent',''); 
        window.close(); 
      }
};

module.exports = offerLetterReviewCtrl;