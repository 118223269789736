
var config = require('../config.js');
var serialize = require('../serialize.js');

var jobService = function($http, CacheFactory){

    // if (!CacheFactory.get('jobCache')) {
    //     CacheFactory.createCache('jobCache', {
    //       deleteOnExpire: 'aggressive',
    //       recycleFreq: 5 * 60 *1000
    //     });
    //   }
  
    var jobCache = CacheFactory.get('jobCache');
    this.getActiveJobs = function(query, dash_display){
        console.log('SERVICE', dash_display)
        var testparam = "test";
        return $http.get(config.apiIP+'/jobs/active?dash_display='+dash_display+'&'+serialize(query || {}),{cache:jobCache})
    }

    this.getExpiredJobs = function(query, dash_display){
        console.log('SERVICE:Expired', dash_display)

        return $http.get(config.apiIP+'/jobs/expired?dash_display='+dash_display+'&'+serialize(query || {}),{cache:jobCache})
    }

    this.getAssignedJobs =  function() {
        return $http.get(config.apiIP+'jobs/assigned')
    }

    this.getJob = function(jobId){
        return new Promise(function(resolve,reject){
            $http.get(config.apiIP+'/jobs/'+jobId)
                .then(function(response){
                    resolve(response.data)
                })
                .catch(function(error){
                    reject(error);
                });
        });
            
    }

    this.flushCache = function(){
        jobCache.destroyAll();
    }
}

module.exports = jobService;