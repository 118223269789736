/**
 * Created by dylan on 12/12/14.
 */

/**
 * StartDate Directives
 *
 * @param app the angular module.
 */
module.exports = function (app) {
    app
        .directive('action', require('./action'))
        .directive('checklist-model', require('./checklist-model/checklistDirective.js'))
        .directive('datetimepicker', require('./datetimepicker'))
        .directive('hgChange', require('./hgChange'))
        .directive('hgDirtyData', require('./hgDirtyData'))
        .directive('hgDownload', require('./hgDownload'))
        .directive('hgDownloadRails', require('./hgDownloadRails'))
        .directive('hgDraggable', require('./hgDraggable'))
        .directive('hgDroppable', require('./hgDroppable'))
        .directive('hgEditable', require('./hgEditable'))
        .directive('hgEditableMultiple', require('./hgEditableMultiple'))
        .directive('hgEditor', require('./hgEditor'))
        .directive('hgFilter', require('./hgFilter'))
        .directive('hgFilterBy', require('./hgFilterBy'))
        .directive('hgInfo', require('./hgInfo'))
        .directive('hgInit', require('./hgInit'))
        .directive('ngInit', require('./hgInit'))
        .directive('hgJobList', require('./hgJobList'))
        .directive('hgLimitTo', require('./hgLimitTo'))
        .directive('hgListManagement', require('./hgListManagement'))
        .directive('hgNotEmpty', require('./hgNotEmpty'))
        .directive('hgOption', require('./hgOption'))
        .directive('hgPasswordStrength', require('./hgPasswordStrength'))
        .directive('hgScroll', require('./hgScroll'))
        .directive('hgSelect', require('./hgSelect'))
        .directive('hgShareJob', require('./hgShareJob'))
        .directive('hgShowTab', require('./hgShowTab'))
        .directive('hgSlider', require('./hgSlider'))
        .directive('hgSortable', require('./hgSortable'))
        .directive('hgSubmit', require('./hgSubmit'))
        .directive('hgSearchSubmit', require('./hgSearchSubmit'))
        .directive('hgUpdateModel', require('./hgUpdateModel'))
        .directive('hgUpdateModelMultiple', require('./hgUpdateModelMultiple'))
        .directive('hgValidate', require('./hgValidate'))
        .directive('hgWatch', require('./hgWatch'))
        .directive('hgWorkflowSelect', require('./hgWorkflowSelect/hgWorkflowSelect.js'))
        .directive('languageSelector', require('./languageSelector'))
        .directive('navContainer', require('./navigation'))
        .directive('navItem', require('./navigation/item'))
        .directive('navGroup', require('./navigation/group'))
        .directive('notifications', require('./notifications'))
        .directive('hgAddListItems', require('./hgAddListItems'))
        .directive('hgExportTable', require('./hgExportTable'))
        .directive('hgCharCount', require('./hgCharCount'))
        .directive('hgDateDropdown', require('./hgDateDropdown'))
        .directive('hgIndustry', require('./hgIndustry'))
        .directive('hgCloseReason', require('./hgCloseReason'))
        .directive('hgLocation', require('./hgLocation'))
        .directive('hgType', require('./hgType'))
        .directive('hgDisplayCategory', require('./hgDisplayCategory'))
        .directive('hgDivisionCategory', require('./hgDivisionCategory'))
        .directive('hgReportCategories', require('./hgReportCategories'))
        .directive('hgQuestionSection', require('./hgQuestionSection'))
        .directive('hgNumberValidator', require('./hgNumberValidator'))
        .directive('hgOfferLetterList', require('./hgOfferLetterList'))
        .directive('hgInformationRequestList', require('./hgInformationRequestList'))
        .directive('hgOfferScreenSize', require('./hgOfferScreenSize'))
    ;
};
