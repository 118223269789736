/**
 * Created by dylan on 20/01/15.
 */

var Filter = function () {
    return function (input) {
        return input[0].toUpperCase() + input.slice(1).replace(/[A-Z]/g, ' $&').split(" ")[0];
    }
};

module.exports = Filter;