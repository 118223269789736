'use strict';
var config = require('../config.js');

var reportCandidateGenericCtrl = function ($scope, railApi, $state, $stateParams) {

  $scope.apiUrl = config.apiIP;
  $scope.jobData = [];
  $scope.myLength = null;
  $scope.hgForm = null;

  // Filter parameters api call
  $scope.getMilestoneApiData = function(hgForm) {
    
    if (hgForm.job_location_id && hgForm.job_location_id !== null && hgForm.job_location_id !== []) {
      hgForm.job_location_id = hgForm.job_location_id.toString(hgForm.job_location_id)
    }
    if (hgForm.display_category_id && hgForm.display_category_id !== null && hgForm.display_category_id !== []) {
      hgForm.display_category_id = hgForm.display_category_id.toString(hgForm.display_category_id)
    }
    if (hgForm.report_category_id && hgForm.report_category_id !== null && hgForm.report_category_id !== []) {
      hgForm.report_category_id = hgForm.report_category_id.toString(hgForm.report_category_id)
    }
    if (hgForm.personnel_id && hgForm.personnel_id !== null && hgForm.personnel_id !== []) {
      hgForm.personnel_id = hgForm.personnel_id.toString(hgForm.personnel_id)
    }
    if (hgForm.tag_name && hgForm.tag_name !== null && hgForm.tag_name !== []) {
      hgForm.tag_name = hgForm.tag_name.toString(hgForm.tag_name)
    }

    railApi.get('jobs/jobs_applicants_generic_report/', {
                    job_id: hgForm.job_id,
                    job_start_date: hgForm.job_start_date,
                    job_end_date: hgForm.job_end_date,
                    job_status: hgForm.job_status,
                    job_type: hgForm.job_type,
                    key_word: hgForm.key_word,
                    job_location_id: hgForm.job_location_id,
                    display_category_id: hgForm.display_category_id,
                    report_category_id: hgForm.report_category_id,
                    division_category_id: hgForm.division_category_id,
                    department_id: hgForm.department_id,
                    tag_name: hgForm.tag_name,
                    personnel_id: hgForm.personnel_id,
                    applicant_id: hgForm.applicant_id,
                    candidate_application_start: hgForm.candidate_application_start,
                    candidate_application_end: hgForm.candidate_application_end,
                    milestone_start_date: hgForm.milestone_start_date,
                    milestone_end_date: hgForm.milestone_end_date,
                    milestone_type: hgForm.milestone_type
    }).promise.then(function(myData) { $scope.jobData = myData; $scope.myLength = myData.length; });
  }

  // Sort report results by
  $scope.sortResultsBy = function(selection) {
    if (selection == 'job_name') {
      $scope.jobData = $scope.jobData.sort(function(a, b){
        if (a.job_name < b.job_name) {return -1};
        if (a.job_name > b.job_name) {return 1};
        return 0;  
      })
    }
    if (selection == 'first_name') {
      $scope.jobData = $scope.jobData.sort(function(a, b){
        if (a.first_name < b.first_name) {return -1};
        if (a.first_name > b.first_name) {return 1};
        return 0;  
      })
    }
    if (selection == 'last_name') {
      $scope.jobData = $scope.jobData.sort(function(a, b){
        if (a.last_name < b.last_name) {return -1};
        if (a.last_name > b.last_name) {return 1};
        return 0;  
      })
    }
  }
  
  $scope.formatAnswer = function (answer) {
    answer = answer.replaceAll('"', '');
    answer = answer.replaceAll('{', '');
    answer = answer.replaceAll('}', '');
    answer = answer.replaceAll(',', ',\n');
    answer = answer.replaceAll(/[\[\]']+/g,'')
    answer = answer.split(', ').join('\n');

    return answer
}
};

module.exports = reportCandidateGenericCtrl;