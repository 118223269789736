/**
 * Created by dylan on 07/01/15.
 */
var config = require('../config.js');

var Filter = function ($translate, $stateParams, $rootScope) {
    var translateFilter = function (input, objectParams) {

        if(null == $translate.use() && $stateParams.language != 'fr') {
            $translate.use('en');
        }

        if ($stateParams.language == 'fr') {
            $translate.use('fr');
            $rootScope.currentLang = 'fr';
        }

        input = input || '';
        input = input[$translate.use()] || input;
        objectParams = objectParams || {};

        if (null == input.replace) {
            input = '';
        }

        return input.replace(
            /^([^\{]*)(\{.*\})?$/,
            function (blank, id, params) {
                params = _.merge({}, objectParams, JSON.parse(params || '{}'));
                id = id.trim();
                var result = $translate.instant(id, params);

                if (result === id) {
                    return $translate.instant('NO_LANG', {});
                } else {
                    return result;
                }
            }
        );
    };
    translateFilter.$stateful = true;

    return translateFilter;
};

Filter.$inject = ['$translate', '$stateParams', '$rootScope'];

module.exports = Filter;