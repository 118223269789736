'use strict';



var Directive = function () {
    return {
        restrict: 'EA',
        replace: true,
        transclude: false,
        template: "<div ng-show=\"null != filters\">\n    <div ng-repeat=\"value in filterValues\">\n        <h2 ng-show=\"(filters[value].length > 1)\" ng-click=\"showHideFilter(value)\">\n            {{value | hgTranslate}}\n\n            <i ng-hide=\"showFilter[value]\" class=\"fa fa-caret-down\"></i>\n            <i ng-show=\"showFilter[value]\" class=\"fa fa-caret-up\"></i>\n        </h2>\n        <div ng-show=\"(filters[value].length > 1) && showFilter[value]\">\n            <!--checkbox div start-->\n            <div class=\"checkbox\" ng-repeat=\"val in filters[value] | orderBy:'order'\"\n                 data-ng-show=\"null != $parent.values[value+val.id].count[currentLang]\">\n                <label ng-hide=\"($index>=numFilteredItems && !showAll)\">\n                    <input ng-model=\"$parent.selectedFilters[value][$parent.values[value+val.id].id].checked\"\n                           type=\"checkbox\"\n                           value=\"{{ $parent.values[value+val.id].name | hgGetLang:currentLang }}\"\n                           class=\"checkbox style-0\">\n                    {{ $parent.values[value+val.id].name | hgGetLang:currentLang }}\n                    <span class=\"text-muted\">({{ $parent.values[value+val.id].count[currentLang] }})</span>\n\n                </label>\n            </div>\n            <a href data-ng-show=\"filters[value].length > numFilteredItems\" data-ng-click=\"showAll = !showAll;\">\n                {{ (showAll) ? '-less' : '+more'; }}\n            </a>\n            <!--checkbox div end-->\n        </div>\n\n    </div>\n</div>\n",

        link: function (scope, element, attrs) {
            var id;
            var name;
            var order;
            var valuesFilters;
            scope.filters = [];
            scope.showFilter = [];
            scope.values = [];
            scope.filterValues = attrs.filters.split(/ ?, ?/);
            scope.selectedFilters = [];
            scope.showAll = false;
            scope.numFilteredItems = 8;

            if (null != attrs.filters && null != attrs.modelData) {
                scope.$watch(attrs.modelData, function (newValues, oldValues) {
                    if (!_.isEmpty(newValues) &&
                        !_.isEmpty(newValues) &&
                        !_.isEqual(oldValues, newValues)
                    ) {
                        scope.values = [];
                        scope.filters = [];
                        scope.showFilter = [];
                        var cities = [];
                        _.map(newValues, function (job) {
                            _.forEach(scope.filterValues, function (filter) {
                                scope.showFilter[filter] = true;

                                // If we don't want to count "hide type" jobs
                                if (null != job[filter] &&
                                    (('job_type' === filter &&
                                    false !== job.display_type) ||
                                    'job_type' !== filter)
                                ) {
                                    valuesFilters = job[filter];
                                    if (null != job[filter].id) {
                                        valuesFilters = [job[filter]];
                                    }

                                    _.forEach(valuesFilters, function (val) {
                                        id = val.id;
                                        if (null == scope.filters[filter]) {
                                            scope.filters[filter] = [];
                                        }

                                        if (null != val.name) {
                                            name = val.name;
                                        } else if (val.city) {
                                            name = val.city + ', ' + val.province;
                                        }
                                        if (null != val.order){
                                            order = val.order
                                        }else{
                                            order = 0
                                        }
                                        if (null == scope.values[filter + id]) {
                                            if (val.city) {
                                                cities.push({id: id, cityName: name});
                                            }
                                            
                                            scope.filters[filter].push({"id": id, "order": order});
                                            scope.values[filter + id] = {
                                                id: id,
                                                name: name,
                                                count: {},
                                                order: order
                                            }
                                        }

                                        // count by language
                                        _.forEach(job.required_languages, function (lang) {
                                            if (null == scope.values[filter + id].count[lang]) {
                                                scope.values[filter + id].count[lang] = 0;
                                            }

                                            scope.values[filter + id].count[lang] += 1;
                                        });

                                    });
                                }
                            });
                        });
                    }

                    // sort location by alphabets
                    cities = _.sortByAll(cities, 'cityName');

                    scope.filters['location'] = cities.map(function(city){
                        const container = {};
                        container.id = city.id;
                        container.order = 0;

                        return container

                    });

                }, true);
            }

            scope.showHideFilter = function (filter) {
                scope.showFilter[filter] = !scope.showFilter[filter];
            };

            /**
             *
             * Filter to return only jobs that have one of their params matching the specified filter name.
             *
             * @param name
             * @returns {Function}
             */
            scope.filterBy = function (name) {
                return function (job) {
                    // if there are no type filters, show all locations
                    if (null == scope.selectedFilters[name] || 0 == scope.selectedFilters[name].length) {
                        return true;
                    }

                    var result = false;
                    var notChecked = 0;
                    var i = 0;

                    angular.forEach(scope.selectedFilters[name], function (value, id) {
                        if (null != value.checked) {
                            if (true === value.checked) {

                                //we don't want to show "hide type" jobs in the list
                                if ('job_type' === name && false === job.display_type) {
                                    return false;
                                }

                                if (_.isArray(job[name])) {
                                    for (i = 0; i < job[name].length; i += 1) {
                                        if (job[name][i].id === id) {
                                            result = true;
                                        }
                                    }
                                } else {
                                    if (job.hasOwnProperty(name)) {
                                        if (job[name].id == id) {
                                            result = true;
                                        }
                                    }
                                }
                            } else {
                                notChecked += 1;
                            }
                        } else {
                            result = true;
                        }
                    });

                    if (notChecked === Object.keys(scope.selectedFilters[name]).length) {
                        result = true;
                    }

                    return result;
                };
            };
        }
    };
};

Directive.$inject = [];

module.exports = Directive;