'use restrict';

var notificationsCtrl = function ($scope, railApi, $stateParams, $state, $rootScope, $timeout) {

    $scope.switch = false;
    $scope.unreadSms = [];
    $scope.totalUnread = {};
    $scope.smsAccordion = {};
    $scope.replyButton = 'Notifications.SendReply'
    $scope.organization = railApi.get('organizations/').data;
    $scope.buttonClicked = false;

    $scope.notifToggel = function() {
        $scope.switch = !$scope.switch
    }

    $scope.groupBy = function(array, key) {
        $scope.grouped = _.groupBy(array, key)
        $scope.groupedLength = Object.entries($scope.grouped).length
    };

    $scope.getUnreadSms = function(){
        $scope.unreadSms = railApi.index('sms_messages/get_unread_sms/', {employee_id: $rootScope.hgUserData.id})
        .promise
        .then(function(data) {
            $scope.groupBy(data, 'applicant_id')
            $scope.totalUnread = data.length
        });
    };

    $scope.sendSMSMessage = function(sms_message, workPhone, id, messageList) {
        $scope.errorMessage = null;
        var phone = "+1" + workPhone.replace(/\D+/g, "");

        if (sms_message.length > 1 && phone.length == 12) {
            railApi.post('employees/send_2FA/', {'applicant_id': id,
                                            'sms_body': sms_message,
                                            'sms_phone': phone}).promise.then(function(myData){
                if(myData.sid.length > 5) {
                    alert("Sent Successfully");
                    $scope.closeMessages(messageList)
                }
            });
        }else{
            $scope.errorMessage = "Phone number or message is not valid!";
        }

    };

    $scope.closeMessages = function(messageList) {
        var message_ids = messageList.map(function(message){
            return message.id
        });
        message_ids.forEach(function(item){
            railApi.index('sms_messages/get_unread_sms/', {sms_id: item})
            .promise
            .then(function(){
                $state.reload('unreadSms');
            })
        })
    }

    $scope.showModalUnreadEmails = function () {
        $scope.unreadEmailModal = $scope.modal.open({
            templateUrl: 'unreadEmailModal.ejs',
            scope: $scope,
            size: 'lg',
            backdrop: 'static'
        });
    };

    $scope.showOfferLetterEventsModal = function () {
        $scope.offerLetterEventsModal = $scope.modal.open({
            templateUrl: 'offerLetterEventsModal.ejs',
            scope: $scope,
            windowClass: 'modal-xl',
            backdrop: 'static'
        });
    };
    
    $scope.showInformationRequestEventsModal = function () {
        $scope.informationRequestEventsModal = $scope.modal.open({
            templateUrl: 'informationRequestEventsModal.ejs',
            scope: $scope,
            windowClass: 'modal-xl',
            backdrop: 'static'
        });
    };

    $scope.showModalUnreadSms = function () {
        $scope.unreadEmailModal = $scope.modal.open({
            templateUrl: 'unreadSmsModal.ejs',
            scope: $scope,
            size: 'lg'
        });
    };

    $scope.showModalWatchedStages = function () {
        $scope.watchedStagesModal = $scope.modal.open({
            templateUrl: 'watchedStagesModal.ejs',
            scope: $scope,
            size: 'lg',
            backdrop: 'static'
        });
    };

    $scope.showModalExpiryNotifications = function () {
        $scope.jobExpiryNotificationsModal = $scope.modal.open({
            templateUrl: 'jobExpiryNotificationsModal.ejs',
            scope: $scope,
            size: 'lg',
            backdrop: 'static'
        });
    };

    $scope.showModalReplyEmail = function (email) {
        $scope.regretEmails = railApi.index('template_emails', {email_type: 'RejectionEmailtype'}, 'template_email').data;
        $scope.employeeList = railApi.index('employees', {}, 'employee').data;
        $scope.ReplyEmail = railApi.get('hgemails/' + email.id).promise.then(function(data) {
          $scope.replyEmail = data;
          var somethinghere = '<br><br>On ' + email.created_at + ', <span dir="ltr"> <<a href="mailto: ' + email.f_uid + '" target="_blank" title="Link: mailto: ' + email.f_uid + '">' + email.f_uid + '</a>> wrote: <br></span>';
          $scope.replyEmail.message = somethinghere + data.message;
          var replySubject = 'RE: ';
          $scope.replyEmail.subject = replySubject + data.subject;
        });
        $scope.replyEmailModal = $scope.modal.open({
            templateUrl: 'replyEmailModal.ejs',
            scope: $scope,
            windowClass: 'modal-xl',
            backdrop: 'static'
        });
    };

    $scope.emailQuery = function tick() {
        railApi.get('employees/unread_emails/' + $rootScope.hgUserData.id, {})
            .promise
            .then(function (data) {
                $scope.totalUnreadEmails = data.length;
                if($state.current.url == '/dashboard'){
                    $timeout(tick, 60000)
                }else{
                    $timeout.cancel();
                }
            });
    }

    $scope.updateUnread = false;

    $scope.getUnreadNotifications = function () {
        $scope.userID = sessionStorage.getItem('userID');

        railApi.get('employees/unread_emails/' + $scope.userID, {})
            .promise
            .then(function (data) {
                $scope.unreadEmails = data.length;
            });

        $scope.updateUnread = true;
    }

    $scope.removeNotiFromList = function(index, array_list, notification_id, type){
        // $rootScope.hgUserData.offer_notifications = $rootScope.hgUserData.offer_notifications.splice(index, 1)
        railApi.put('notifications/' + notification_id + '/' + type, {}, 'notifications').promise.then(function(data){
            var offer_notif = angular.copy($rootScope.hgUserData[array_list])
            offer_notif.splice(index, 1)
            $scope.buttonClicked = false;
            setTimeout(function(){
                $rootScope.hgUserData[array_list] = offer_notif;
                $scope.$digest();
            }, 0);
        })
    }

};

module.exports = notificationsCtrl;
