'use strict';
var config = require('../config.js');
var serialize = require('../serialize.js');

var Service = function ($rootScope, $stateParams, $state, hgApi, $http) {


    this.getResult = function (hgForm, selected, collection, queryId, hgFormFields, selectedFields, populate) {

        hgFormFields = hgFormFields || [];
        populate = populate || [];


        var baseUrl = config.origin + '/api/v1';
        var path = baseUrl + '/' + collection +'/'+ queryId;

        $http.get(path + '?' + serialize({ populate: populate })).
            then(function(response) {
                $rootScope.hgForm = {};
                $rootScope.selected = {};
                var data = _.cloneDeep(response.data);

                _.forEach(selectedFields, function(value){
                    $rootScope.selected[value] = _.cloneDeep(data[value]);
                });

                _.forEach(hgFormFields, function(value){
                    if (null != data[value] &&
                        (!_.isEmpty(data[value]) ||
                        (data[value] === Number(data[value])) ||
                        (data[value] === Boolean(data[value])) ||
                        (data[value] instanceof Date))
                        ) {
                        if (populate[value]) {
                            $rootScope.hgForm[value] = _.map(data[value], function(val){return val.id;});
                        } else {
                            $rootScope.hgForm[value] = data[value];
                        }
                    }
                });

                _.forEach(populate, function(value, key){
                    if (-1 !== key.indexOf('*')) {
                        var keys = key.split('.');

                        _.map($rootScope.hgForm[keys[0]], function(object, index){
                            _.forEach(object, function(value, key) {
                                if (key === keys[keys.length -1]) {
                                    $rootScope.hgForm[keys[0]][index][key] = value.id;
                                }
                            });
                        });
                    }
                });

            }, function(error) {
                console.log(error);
            });


    };
};

Service.$inject = ['$rootScope', '$stateParams', '$state', 'hgApi', '$http'];

module.exports = Service;