'use strict';
var config = require('../config.js');

var internalJobListCtrl = function ($rootScope, $scope, $http, railApi, $stateParams, $state, RailRestangular, internalJobs) {

    $scope.organizationData = railApi.get('organizations/').data;
    $scope.apiIP = config.apiIP;

    $scope.uploadInternalJobs = function (jobList) {
        $scope.internaljobs = [];
        for (var i = 0; i < jobList.length; i++) {

            $scope.internaljobs[i] = JSON.parse(JSON.stringify(jobList[i]));

            var temp = $scope.internaljobs[i].category_display[0];
            $scope.internaljobs[i].category_display = [];
            $scope.internaljobs[i].category_display = temp;

            var temp_division = $scope.internaljobs[i].category_division[0];
            $scope.internaljobs[i].category_division = [];
            $scope.internaljobs[i].category_division = temp_division;

        }
    };
    var indexedCategories = [];

    $scope.internalJobsToFilter = function () {
        indexedCategories = [];
        return $scope.internaljobs;
    };

    $scope.getInternalApply = function(internal_id, job_id){
        $state.transitionTo('internalCareerInfo', {internal_id: internal_id, page: 'internal', id: job_id});
    };

    $scope.getInternalList = function(internal_id){
        $state.transitionTo('internalApplyList', {internal_id: $stateParams.internal_id});
    };

    $scope.organization = railApi.get('organizations/').data;

    $scope.filterJobs = function (job) {
        var categoryIsNew = indexedCategories.indexOf(job.category_display.name.en || job.category_division.name.en) == -1;
        if (categoryIsNew) {
            indexedCategories.push(job.category_display.name.en || job.category_division.name.en);
        }
        return categoryIsNew;
    };

    $scope.jobResult = {pending: true};

    internalJobs.getList().then(function (internaljobs) {
        $scope.jobList = internaljobs;

        $scope.jobResult = {pending: false};
    }).catch(function (err) {
        console.log(err);
        $scope.jobResult = {pending: false};
    })
};

module.exports = internalJobListCtrl;
