/**
 * Created by dylan on 20/01/15.
 */

'use strict';

var deactivate = function () {
    $(this).removeClass('active');
};

var Directive = function () {
    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            element = $(element);
            var nav = element.parents('.nav').first();
            var links = nav.find('li');
            var container = nav.next('.tab-content').first();
            var tabs = container.find('div[data-hg-tab]');
            var currentLink = element.parent();
            var currentTab = container.find('div[data-hg-tab=\'' + attr.hgShowTab + '\']').first();

            element.click(function () {
                links.each(deactivate);
                tabs.each(deactivate);
                currentLink.addClass('active');
                currentTab.addClass('active');
            });
        }
    };
};

module.exports = Directive;