'use strict';
var config = require('../config.js');

var passwordResetRequestCtrl = function ($scope, RailRestangular, $location, $window) {

    $scope.userAccount = {};
    $scope.userAccount.email = '';

    $scope.resetPassword = function () {
        var payload = {};
        payload.email = $scope.userAccount.email;
        $window.localStorage.setItem('uid', $scope.userAccount.email);
        payload.redirect_url = config.origin + '/password-reset/';
        RailRestangular.all('auth/password').post(payload).then(function (data) {
            $scope.showPasswordResetSuccess = true;
            $scope.showPasswordResetMessage = 'Email Sent';
            $window.localStorage.removeItem('uid');
        }, function () {
            $scope.showPasswordResetFail = true;
            $scope.showPasswordResetMessage = 'Unable to reset password';
            $window.localStorage.removeItem('uid');
        });
    };


};

module.exports = passwordResetRequestCtrl;
