var sumValues = function (input) {
    var sum = 0;
    var i;

    for (i = 0; i < input.length; i += 1) {
        if (null != input[i]) {
            if (_.isNumber(input[i])) {
                sum += input[i];
            } else {
                sum += sumValues(_.values(input[i]));
            }
        }
    }

    return sum;
};

var Filter = function () {
    return function (input) {
        return sumValues(input);
    };
};

module.exports = Filter;
