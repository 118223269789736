/**
 * Created by dylan on 26/01/15.
 */
'use strict';

var Directive = function () {
    return {
        restrict: 'A',
        link: function (scope, elem, attr) {
            scope.$evalAsync(function () {
                scope.$eval(attr.ngInit || attr.hgInit);
            });
        }
    };
};

module.exports = Directive;