/**
 * Created by dylan on 18/12/14.
 */



var Directive = function () {
    return {
        restrict: 'AE',
        controller: Controller,
        transclude: true,
        replace: true,
        scope: {
            icon: '@',
            title: '@',
            iconCaption: '@',
            active: '=?'
        },
        template: "<li data-ng-class=\"{active: active}\">\n    <a href=\"\">\n        <i data-ng-if=\"hasIcon\" class=\"{{ icon }}\"><em data-ng-if=\"hasIconCaption\"> {{ iconCaption }} </em></i>\n        <span class=\"menu-item-parent\" data-localize=\"{{ title }}\">{{ title }}</span>\n    </a>\n    <ul data-ng-transclude=\"\"></ul>\n</li>"
    };
};

var Controller = function ($scope) {
    $scope.active = false;
    $scope.hasIcon = angular.isDefined($scope.icon);
    $scope.hasIconCaption = angular.isDefined($scope.iconCaption);

    this.setActive = function (active) {
        $scope.active = active;
    }
};
Controller.$inject = ['$scope'];

module.exports = Directive;