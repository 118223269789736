'use strict';



var Directive = function() {
    return {
        restrict: 'EA',
        replace: true,
        transclude: false,
        scope: {
            tooltipText: '@tooltipText'
        },
        template: "<span class=\"fa fa-info-circle text-primary\"\n      tooltip-append-to-body=\"true\"\n      data-tooltip-html-unsafe=\"{{ tooltipText | hgTranslate }}\">\n\n</span>"
    };
};

Directive.$inject = [];

module.exports = Directive;