'use restrict';

var reportUserChangeLogsCtrl = function ($scope, railApi) {
    
    $scope.getRemovalLogs = function(hgForm){
        railApi.get('candidate_removal_logs/get_removal_log', {start_date: hgForm.start_date, end_date: hgForm.end_date}).promise.then(function(data){
            $scope.removalLog = data
        })
    }

    $scope.int_to_ip = function(ipInt) { //convert int ipaddress to 0.0.0.0 format
        return ( (ipInt>>>24) +'.' + (ipInt>>16 & 255) +'.' + (ipInt>>8 & 255) +'.' + (ipInt & 255) );
    }

};
 
 module.exports = reportUserChangeLogsCtrl;