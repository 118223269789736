

var Directive = function($window) {
    var config = require('../../config');
    return {
        restrict: 'AE',
        replace: true,
        transclude: false,
        template: "<div class=\"socialMedia\">\n    {{ 'SocilaMediaShareLabel' | hgTranslate }}:\n    <br>\n    <i ng-repeat=\"platform in platforms\"\n       ng-click=\"socialWindow(platform.url)\"\n       class=\"fa fa-{{ platform.icon }}-square fa-2x\"\n       style=\"margin-right: .5rem\"\n       tooltip=\"{{ platform.name }}\"\n       tooltip-placement=\"top\"\n    >\n    </i>\n</div>",

        link: function(scope, elem, attrs) {
            var apiUrl = config.apiIP;
            var share_url = apiUrl + 'jobs/'+ attrs.jobId +'/job_share?client='+ attrs.clientId

            var url = encodeURIComponent($window.location.href),
                title = attrs.hgShareJob,
                position = attrs.hgShareSummary.replace(/(<([^>]+)>)/ig,""),
                clientBusiness = attrs.clientName,
                email = eval(attrs.hgEmailText);
                var networks = attrs.networks.split(',');

            // we need to add an empty parameter at the beginning of the array for the indexOf method to work without checking for false (as "0" == false)
            networks.unshift('noop');
            scope.platforms = [];

            if (networks.indexOf('linked-in') != -1 ) {
                scope.platforms.push({
                    name: 'LinkedIn',
                    icon: 'linkedin',
                    url: 'https://www.linkedin.com/sharing/share-offsite/?url=' + $window.location.href
                });
            }

            if (networks.indexOf('twitter') != -1 ) {
                scope.platforms.push({
                    name: 'Twitter',
                    icon: 'twitter',
                    url: 'https://twitter.com/intent/tweet?text=Check out this job: ' + title.substring(0, 50) + ' @ ' + share_url
                });
            }

            if (networks.indexOf('facebook') != -1 ) {
                scope.platforms.push({
                    name: 'Facebook',
                    icon: 'facebook',
                    url: 'http://www.facebook.com/share.php?u=' + share_url
                });
            }

            if (networks.indexOf('email') != -1 ){
                scope.platforms.push({
                    name: 'Email',
                    icon: 'envelope',
                    url: 'mailto:?&body=' + email + ''
                })
            }

            /**
             * Helper function to open a new window for the "share" buttons.
             *
             * @param url
             * @returns {boolean}
             */
            scope.socialWindow = function(url) {
                $window.open(url, 'Share', 'toolbar=0,resizable=1,status=0,width=640,height=528');
                return false;
            };
        }
    };
};

Directive.$inject = ['$window'];

module.exports = Directive;
