

var findOrderInJFQ= function(items, qid){
    angular.forEach(items, function(item) {
        if(item['question_id'] == qid){
            return item['order'];
        }
    });
    return 0;
}

var orderByJFQ = function() {
    return function(items, jfq) {

        var result = []
        angular.forEach(jfq, function(jfq) {
            result[jfq.question_id] = jfq.order
        });

        var filtered = [];
        angular.forEach(items, function(item) {
            filtered.push(item);
        });

        filtered.sort(function (a, b) {
            return result[a['id']] > result[b['id']] ? 1 : -1;
        });


        return filtered;
    };

};
module.exports = orderByJFQ;