'use strict';

var jobViewsCtrl = function ($scope, jobService, $state, railApi, $stateParams,smoothScroll) {

   // Title of the page
   $scope.title = ""

   //Recruiter Dash variable
   $scope.dashView = 'all';

   // Type (active/expired)
   $scope.type = "active"

   // jobs listing
   $scope.jobs = {}

    // Query Parameters for pagination
    $scope.limit = 10;
    $scope.offset = 0;

    // Pagination parameters
    $scope.page = 1;
    $scope.total = 0; // populated from first call meta field

    // Loading spinner
    $scope.loading = false;

    $scope.organizationData = railApi.get('organizations/').data;

    $scope.scrollToTop = function(elem, callbackAfter){
        if(elem){
            var options =   {duration:800,
                easing:"easeInQuint",
                offset:120,
                callbackAfter: function(element) {
                    if(callbackAfter) callbackAfter();
                }};
            smoothScroll(elem,options);
        }else{
            if(callbackAfter) callbackAfter();
        }
   }



   $scope.loadJobs = function(dashViewBoolean){

        // Element id to handle scroll to the top of the view. So far this is a
        // jarring interface so I've removed it.
        // var elem = document.getElementById('jobViewsTable-'+$scope.type);

        // Only run the spinner the first time
        // console.log(dashViewBoolean);


        if($scope.jobs == null || _.isEmpty($scope.jobs) )  {
            $scope.loading = true;
        }


        var queryParams = {limit:$scope.limit, offset: $scope.offset}

        if($scope.query){
            queryParams['order'] = $scope.query
        }

        var serviceCall = ($scope.type == 'active') ? jobService.getActiveJobs(queryParams, $scope.dashView) :  jobService.getExpiredJobs(queryParams, $scope.dashView);
        var apiPromise = railApi.index('jobs/assigned', {dash_display: $scope.dashView}).promise.then(function(response){
            $scope.loading = false;
            // var data = response.data
            $scope.jobs = response.jobs;
            $scope.total = response.meta.total;
        });



        // apiPromise.then(function(response){
        //     $scope.loading = false;
        //     var data = response.data
        //     $scope.jobs = data.jobs;
        //     console.log($scope.jobs);
        //     $scope.total = data.meta.total;
        //     $scope.$applyAsync()
        // }).catch(function(error){
        //     $scope.loading = false;
        //     $scope.error = error;
        // })

   }


};

module.exports = jobViewsCtrl;
