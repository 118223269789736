'use strict';



var Directive = function(railApi) {
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        template: "<label class=\"select\">\n    <hg-select placeholder=\"{{ 'Job.PlaceholderLocations' | hgTranslate }}\"\n               ng-model=\"hgForm.location.id\"\n               options=\"{ maxItems: 1, returnInt: true}\">\n        <hg-option ng-repeat=\"location in locations\" value=\"{{location.id}}\">\n            {{location.city}}, {{location.province}}\n        </hg-option>\n        <hg-option selected ng-if=\"hgForm.location.id\" value=\"{{hgForm.location.id}}\">\n            {{location.city}}, {{location.province}}\n        </hg-option>\n    </hg-select>\n</label>",

        link: function(scope, elem, attrs) {
            scope.locations = railApi.index('locations').data;

        }
    };
};

Directive.$inject = ['railApi'];

module.exports = Directive;