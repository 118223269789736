/**
 * Created by dylan on 05/02/15.
 */

var Filter = function ($translate, $rootScope) {
    var getLangFilter = function (input, lang) {
        var result = input;

        if (input instanceof Object) {
            input = input || {};
            result = input[lang];
            for (var i = 0; i < $rootScope.fallbackLanguage.length && null == result; i += 1) {
                result = input[$rootScope.fallbackLanguage[i]];
            }

            if (null == result) {
                return $translate.instant('NO_LANG', {});
            }
        }

        return result;
    };
    getLangFilter.$stateful = true;

    return getLangFilter;
};
Filter.$inject = ['$translate', '$rootScope'];

module.exports = Filter;