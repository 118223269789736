/**
 * Created by dylan on 12/02/15.
 */

'use strict';

var Directive = function () {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function (scope, elem, attr, model) {
            scope.$watch(function () {
                return model.$modelValue;
            }, function () {
                scope.$eval(attr.hgWatch);
            });
        }
    };
};

module.exports = Directive;