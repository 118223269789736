'use strict';

var tagsCtrl = function ($scope, railApi, $stateParams, $state, $rootScope) {

    if ($state.current.name == 'tagList') {
      var tagQuery = railApi.get('tags/', {}).promise.then(function(data) {
        $scope.maxOrder = data.length;
        $scope.tags = data;
      });
    }

    else if ($state.current.name == 'tagArchiveList') {
      var archiveTagQuery = railApi.get('tags/', { is_viewable: false }).promise.then(function(data) {
        $scope.maxArchiveOrder = data.length;
        $scope.archiveTags = data;
      });
    }

    else if ($state.current.name == 'tagAdd') {
      var tagQuery = railApi.get('tags/', {}).promise.then(function(data) {
        $scope.maxOrder = data.length;
        $scope.hgForm = {
            name: {},
            helper_text: {},
            order: $scope.maxOrder,
            required_languages: [$rootScope.preferred_language],
            is_viewable: true,
            is_editable: true
        };
        $scope.hgForm.tag_selected_languages = [$rootScope.preferred_language];
      });
    };

    $scope.error = [];

    $scope.formSubmit = function () {
        $scope.errors = [];
        req_lang.forEach(function (elem, index, array) {
            if ($scope.hgForm.name[elem] == '') {
                $scope.errors.push("Name should have the following languages: "+elem);
                $scope.tagError = true;
            }
            else if($scope.tagError == true) {
                $scope.result = railApi.post('tags', $scope.hgForm, 'tag')
            }
        });
    };
};

module.exports = tagsCtrl;
