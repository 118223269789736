'use strict';

//https://github.com/Waxolunist/bootstrap3-wysihtml5-bower


var getId = (function () {
    var id = 0;

    return function () {
        return id += 1;
    };
}());

var Directive = function () {
    return {
        require: 'ngModel',
        restrict: 'E',
        replace: true,
        transclude: false,
        template: "<div>\n    <textarea class=\"hg-editor-textarea\" rows=\"8\"></textarea>\n    <div class=\"counterDiv\">\n        <small class=\"note\">Character count:</small> <small class=\"editor-counter note\">0</small>\n    </div>\n</div>",
        link: function (scope, elem, attr, model) {
            elem = $(elem);
            var textBox = elem.find('textarea');
            var spanElem = elem.find('small.editor-counter');
            var id = 'wysihtml5' + getId();
            var spanId = 'charCount' + getId();
            var isEditorLoaded = false;
            // id assigned to the textarea
            textBox.attr('id',id);

            // Character counter function
            var charCounter = function () {
                var count = wysihtml5Sandbox[0].contentWindow.document.body.textContent.length ||
                    wysihtml5Sandbox[0].contentWindow.document.body.innerText.length;
                $('#' + spanId).html(count);
            };

            
            // if rows attribute are set then update rows in textarea
            if(attr.rows){
                textBox.attr('rows', attr.rows );
            }

            // id assigned to the character count span
            spanElem.attr('id',spanId);

            //submitId is to check what is the ID of the submit button and code below checks if it exists. It will only affect notes.
            document.getElementById(attr.submitId) ? document.getElementById(attr.submitId).disabled = true : '';
            
            // initialize wysihtml5
            textBox.wysihtml5({
                blockquote: false,
                image: false,
                link: false,
                html: true,
                'font-styles': true,
                size: 'sm',
                events: {
                    load: function() {
                        isEditorLoaded = true;
                        if( model.$viewValue ) {
                            wysihtml5Sandbox[0].contentWindow.document.body.innerHTML = model.$viewValue;
                        }
                        wysihtml5Sandbox.contents().find('body').on('keyup', function () {
                            charCounter();

                            document.getElementById(attr.submitId) ? document.getElementById(attr.submitId).disabled = wysihtml5Sandbox[0].contentWindow.document.body.innerHTML.length < 1 : '';
                        });
                        charCounter();
                    },
                    change: function() {
                        var val = textBox.val();
                        if (val !== model.$viewValue) {
                            model.$setViewValue(val);
                        }
                    }
                }
            });

            var wysihtml5Sandbox = $('#'+ id).next().next();

            //add lang attribute to inner documents html tags (accessibility)
            var frames = document.getElementsByTagName('iframe');

            //html.setAttribute('lang', 'en');
            // for(var i = 0; i < frames.length; i++)
            // {
            //     // this like was altered to fix 9884 email template bodies not rendering
            //     var framedoc = frames[i].contentDocument || frames.contentWindow.document;
            //     // var framedoc = frames[i].contentDocument || frames[i].contentWindow.document;
            //     var html = framedoc.getElementsByTagName('html');
            //     html[0].lang = 'en';
            // }



            //give iframe class title (accessibility)
            var iframes = document.getElementsByTagName("iframe");
            for(var i = 0; i < iframes.length; i++)
            {
                iframes[i].setAttribute("title", "Edit field " + i);
            }

            model.$render = function () {
                var textboxValue = model.$viewValue;
                if( model.$viewValue === undefined) {
                    textboxValue = '';
                }
                wysihtml5Sandbox[0].contentWindow.document.body.innerHTML = textboxValue;
                if (true === isEditorLoaded) {
                    charCounter();
                }
            };
        }
    };
};
Directive.$inject = [];

module.exports = Directive;