'use strict';

var Directive = function () {
    return {
        link: function(scope, elem) {
            var el = elem[0];

            el.addEventListener(
                'dragover',
                function(e) {
                    e.dataTransfer.dropEffect = 'move';
                    // allows us to drop
                    e.preventDefault();

                    this.classList.add('over');
                    return false;
                },
                false
            );

            el.addEventListener(
                'dragenter',
                function(e) {
                    this.classList.add('over');
                    return false;
                },
                false
            );

            el.addEventListener(
                'dragleave',
                function(e) {
                    this.classList.remove('over');
                    return false;
                },
                false
            );

            el.addEventListener(
                'drop',
                function(e) {
                    // Stops some browsers from redirecting.
                    e.preventDefault();

                    var item = document.getElementById(e.dataTransfer.getData('Text'));
                    if ($(item).attr('data-ng-click') || $(item).attr('ng-click')) {
                        $(item).trigger('click');
                    } else if ($(item).attr('droppable-action')) {
                        $(item).find('a').trigger('click');
                    } else {
                        var binId = this.id;
                        this.appendChild(item);

                        scope.$apply();
                    }
                },
                false
            );
        }
    };
};

Directive.$inject = [];

module.exports = Directive;