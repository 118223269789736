'use strict';
var config = require('../config.js');

var RailRestangular = function (Restangular) {
    return Restangular.withConfig(function(RestangularConfigurer) {
        RestangularConfigurer.setBaseUrl(config.apiIP);
        // RestangularConfigurer.addResponseInterceptor(function (data, operation, route, url, response, deferred) {
        //     return data.result;
        // });
    }).withConfig(function(RestangularProvider) {
        // RestangularProvider.addFullRequestInterceptor(function (element, operation, what, url, headers, params) {
        //     if (operation === "put") {
        //
        //         console.log(element);
        //         var element2 = {'job_category': element};
        //         console.log(element2);
        //         element = element2;
        //
        //         return element;
        //     }
        //     return element;
        // })
    });
};

RailRestangular.$inject = ['Restangular'];

module.exports = RailRestangular;