'use strict';
var config = require('../config.js');

var reportEEOCtrl = function ($scope, railApi, $state, $stateParams) {

    $scope.apiUrl = config.apiIP;
    $scope.jobData = [];
    $scope.jobId = null;
    $scope.indexOfJob = null;
    $scope.myLength = null;

    $scope.questionData = [];
    $scope.hgForm = null;


    $scope.getEEOApiData = function(hgForm) {
        
        if (hgForm.job_id > 0) {
            //alert('neoneo jobID: ' + hgForm.job_id + " startdate: " +  hgForm.start_date + " enddate: " + Date(hgForm.end_date));

            // 'job_id': hgForm.job_id
            railApi.get('jobs/jobs_applicants/', {'job_eeo': 'EEO', 'job_id': hgForm.job_id}).promise.then(function(myData) {
         
                $scope.jobData = myData;
                
                $scope.myLength = myData.length;

                // $scope.test = JSON.parse(myData.app_answer);
                // console.log(JSON.parse(myData[0].app_answer))
    
                // _.forEach(myData, function(value, index){
                    // console.log(value.app_answer + "------" + index);
                    // console.log(JSON.parse(value.app_answer))

                    // _.forEach(JSON.parse(value.app_answer), function(value1, index1){
                    //     console.log(value1 + " --- "  + index1);
                    // });
                    // if (value.legend.en.startsWith("EEO")) {
                        
                    //     $scope.questionData.push(value.questions);
                    //     console.log("pushing successfully: ");
                    // }
                    // }else{
                    //     console.log("pushing failed");
                    // }
    
                // });
    
                console.log('Success!');
            });
        }else{
            //alert("undefined job id");
            railApi.get('jobs/jobs_applicants/', {'job_eeo': 'EEO', 'job_start_date': hgForm.start_date, 'job_end_date': hgForm.end_date}).promise.then(function(myData) {
         
                $scope.jobData = myData;
    
                // console.log("total records: " + myData.length);
                $scope.myLength = myData.length;


    
                // _.forEach(myData.jobs[0].forms, function(value, index){
                //     if (value.legend.en.startsWith("EEO")) {
                //         $scope.questionData.push(value.questions);
                //         console.log("pushing successfully: ");
                //     }
                // });
    
                console.log('Success!');
            });
        }
    }


    $scope.eeoFieldTitleFormat = function (myTitle) {
        var dashPosition = myTitle.indexOf("-");
        if (dashPosition != -1) {
            return myTitle.slice(0, dashPosition);
        }else{
            return myTitle
        }
        // myTitle = myTitle.slice(0, myTitle.indexOf("-"));

        // return myTitle;

    };

    $scope.formatAnswer = function (answer) {
        answer = answer.replaceAll('"', '');
        answer = answer.replaceAll(/[\[\]']+/g,'')
        answer = answer.split(', ').join('\n');

        return answer
    };

};

module.exports = reportEEOCtrl;