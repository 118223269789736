/**
 * Created by dylan on 27/01/15.
 */
'use strict';

var config = require('../../config.js');

var Directive = function ($http, $state, conversions, $stateParams) {
    return {
        require: '^form',
        restrict: 'A',
        link: function (scope, elem, attr, form) {
            var api = config.apiIP;

            form.hgSubmit = function () {
                var hgForm = conversions.convertData(scope.hgForm);
                var convertedHgForm = {};
                if (attr.wrapper) {
                    convertedHgForm[attr.wrapper] = hgForm;
                } else {
                    convertedHgForm = hgForm;
                }


                scope.showSpinner = true;
                scope.disableButton = true;
                return $http[attr.method](api + attr.hgSubmit, convertedHgForm)
                    .success(function (data, status) {
                        scope.showSpinner = false;
                        scope.disableButton = false;
                        if (200 === status || 201 === status) {
                            form.hgSubmitted = data;
                            form.hgServerErrors = {};
                            if (null == attr.noScroll) {
                                window.scrollTo(0, 0);
                            }

                            if (attr.hgAfterSubmit) {
                                scope.$eval(attr.hgAfterSubmit);
                            }

                            if (attr.hgGo) {
                                var passParams = {};
                                passParams['id'] = data.id;
                                if ('resourceName' in $stateParams) { passParams['resourceName'] = $stateParams.resourceName };
                                $state.go('^.' + attr.hgGo, passParams);
                            }

                        }
                    })
                    .error(function (err) {
                        scope.showSpinner = false;
                        scope.disableButton = false;
                        form.hgServerErrorsText = 'ValidationError';

                        if (err.name) {
                            form.hgServerErrorsText = err.name;
                        }

                        if ('ValidationError' === err.name) {
                            form.hgServerErrors = err.errors;

                            if (err.duplicateUser) {
                                form.hgServerErrors.duplicateUser = err.duplicateUser;
                            }

                        }else if ('ServerError' === err.name) {
                            form.hgServerErrors = err.errors;
                        } else {
                            form.hgServerErrorsText = 'ServerError';
                            form.hgServerErrors = {
                                '': {
                                    message: ['Server could not process request']
                                }
                            };
                        }
                    });
            };
        }
    };
};

Directive.$inject = ['$http', '$state', 'conversions', '$stateParams'];

module.exports = Directive;
