'use strict';
var config = require('../config.js');
const jsSHA = require("jssha");

var informationRequestCtrl = function ($scope, Jobs, railApi, $translate, Upload, $stateParams, $rootScope, $state, $filter) {

    
    $scope.filesAndUrls = []
    $scope.docFiles = [];
    $scope.providedDocs = [];
    $scope.files = [];

    if ($stateParams.id) {
        $scope.infoRequest = railApi.get('approval_emails/' + $stateParams.id, { 'email_type': 'info_request' }).data;
        
    };

    $scope.exportLogUrl = config.apiIP + "/approval_emails/export_info_req_log_to_pdf?info_req_id=";
    $scope.exportInfoReqUrl = config.apiIP + "/approval_emails/export_information_request?info_req_id="

    $scope.answered = {}
    $scope.text_answers_attributes = []
    

    $scope.employeeEncryption = function() {
        // console.log("hgUserData.id: " + $rootScope.hgUserData.id + " aasm: " + $rootScope.hgUserData);
        var today = new Date();
        var formattedDate = $filter('date')(today, 'yyyy-MM-dd');

        const shaObj = new jsSHA("SHA-256", "TEXT", { encoding: "UTF8" });
        shaObj.update($rootScope.hgUserData.email + $rootScope.hgUserData.role + "hiregroundSALT" + formattedDate);
        const hash = shaObj.getHash("HEX");

        $scope.employeeHash = hash;
    }

    $scope.generateLogs = function(applicant_profile){
        $scope.allLogs = [];
        $scope.allLogs = $scope.allLogs.concat(applicant_profile.applicant_profile_logs);
        $scope.allLogs = $scope.allLogs.concat(applicant_profile.applicant.applicant_logs);
        if($scope.allLogs[$scope.allLogs.length - 1] == undefined){
            $scope.allLogs.pop();
        }
    }

    $scope.assignFeedback = function(feedback){
        $scope.feedback = feedback
    }

    $scope.addTextAnswer = function(id) {
        $scope.text_answers_attributes.push({'question_id': id, 'answer_text': ''})
    }

    $scope.getApplicantProfile = function(id){
        railApi.get('applicant_profiles/'+ id).promise.then(function(data){
            $scope.applicant_profile = data
            $scope.generateLogs(data)
        })
    }

    $scope.cancelInfoReq = function(id){
        railApi.put('approval_emails/'+id+'/cancel', {id: id}).promise.then(function(data){
            window.location.reload()
        })
    }
    $scope.resendInfoReq = function(id, due_date){
        railApi.put('approval_emails/'+id+'/resend_form', {id: id, due_date: due_date}, 'resend').promise.then(function(data){
            $scope.sendToReceiverConfirmationModal = $scope.modal.open({
                templateUrl: 'sendToReceiverConfirmationModal.ejs',
                scope: $scope,
                size: 'lg'
            });
        })
    }
    $scope.archive = function(id){
        railApi.put('approval_emails/'+id+'/archive', {id: id}).promise.then(function(data){
            window.location.reload()
        })
    }

    $scope.printIt = function(info_req_id){
        
        $scope.employeeEncryption();
        // railApi.post('approval_emails/export_log_to_pdf', {offer_letter_id: offer_letter_id});
        
        window.location.href=$scope.exportLogUrl + info_req_id + '&employeeid='+$rootScope.hgUserData.id + '&hash=' + $scope.employeeHash;
    };

    $scope.dateToday = function(){
        $scope.dateNowIso = new Date();
    }

    $scope.reloadThisPage = function(){
        window.location.reload();
    }

    $scope.exportInfoReq = function(info_req_id){
        $scope.employeeEncryption();
        window.location.href=$scope.exportInfoReqUrl + info_req_id + '&employeeid='+$rootScope.hgUserData.id + '&hash=' + $scope.employeeHash;
    }

    $scope.previewEmail = function(rawEmail){
        $scope.organization = $rootScope.clientSettings
        if(rawEmail != undefined){
            var sysTagConversion = [
                {
                    tag: "{{user.name.first}}",
                    value: $scope.infoRequest.applicant_profile.applicant_first_name
                },{
                    tag: "{{user.name.last}}",
                    value: $scope.infoRequest.applicant_profile.applicant_last_name
                },{
                    tag: "{{user.email}}",
                    value: $scope.infoRequest.applicant_profile.applicant_email
                },{
                    tag: "{{jobName}}",
                    value: $scope.infoRequest.job.name.en
                },{
                    tag: "{{job.name}}",
                    value: $scope.infoRequest.job.name.en
                },{
                    tag: "{{jobNameFr}}",
                    value: $scope.infoRequest.job.name.fr
                },{
                    tag: "{{jobNameEs}}",
                    value: $scope.infoRequest.job.name.es
                },{
                    tag: "{{organization.name}}",
                    value: $scope.organization.name
                },{
                    tag: "{{contactEmail}}",
                    value: $scope.organization.email
                },{
                    tag: "{{contactPhone}}",
                    value: $scope.organization.phone
                },{
                    tag: "{{global.url.hostname}}",
                    value: $scope.organization.url
                }
            ]
            sysTagConversion.forEach( function(value){
                rawEmail = rawEmail.replaceAll(value.tag, !_.isEmpty(value.value) ? value.value : '______')
            })
            $scope.processedEmail = rawEmail
        }

    }

    $scope.step1 = true;
    $scope.currStep = 1
    $scope.nextPrev = function(step){
        for(let stepz = 1; stepz < 4; stepz++){
            if(step == stepz){
                $scope['step' + stepz] = true
                $scope.currStep = stepz
            }else{
                $scope['step' + stepz] = false
            }
        }
    }

    $scope.getDocsIdAndUpload = function () {
        $scope.files = []

        var zzz = []
        $scope.providedDocs.forEach(function(docs){
            $scope.files.push(docs)
            if ($scope.files.length) {
                return true;
            } else {

                return false
            }
        })

    };

    $scope.clearProvidedDoc = function(index){
        $scope.providedDocs.splice(index, 1);
    }

    $scope.upload = function (files, file_type, attachment, error) {
        if(attachment.type == 'file'){
            if (files && files.length && (undefined === error)) {
                $scope.errorFile = false;
                $scope.errorInterviewFile = false;
    
                if ($stateParams.id) {
                    var upload_url = config.apiIP + 'approval_emails/' + $stateParams.id + '/upload_file';
                }
                else {
                    var upload_url = config.apiIP + 'approval_emails/upload_file';
                }
    
                for (var i = 0; i < files.length; i+=1) {
                    var file = files[i];
                    var data = {
                        file_type: file_type,
                        name: attachment.title,
                        type: 'info_req_attachment',
                        direction: 'outbound'
                    };
    
                    if ('offer_attachment' === file_type) {
                      if(file.dropBox) {
                          if(5000000 < file.bytes) {
                              $scope.errorFile = true;
                              return false;
                          }
                          data.dropBoxFile = file;
                          file = [];
                      } else {
                        if(5000000 < file.bytes) {
                            $scope.errorFile = true;
                            return false;
                        }
                      }
                    }
    
                    Upload.upload({
                        url: upload_url,
                        file: file,
                        fields: data
                    }).progress(function (evt) {
                          $scope.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    }).success(function (data, status, headers, config) {
                        $scope.clearProvidedDoc()
                        $scope.infoRequest.file_and_links.push({
                            id: data.file_and_link_id,
                            name: attachment.title,
                            attachment_type: 'info_req_attachment',
                            direction: 'outbound',
                            uploaded_file: {
                                id: data.file_info.id,
                                original_file_name: data.file_info.original_file_name
                            }
                        })
                        $scope.infoRequest.content_edited = true;
                    }).error(function(data, status, headers, config) {
                      if ('offer_attachment' === file_type) {
                        $scope.fileError = data.error;
                        $scope.errorFile = true;
                      }
                      else {
                        $scope.fileError = data.error;
                        $scope.errorInterviewFile = true;
                      }
                    });
                }
            } else {
                return false;
            }
        }else{
            railApi.post('/file_and_links/', {approval_email_id: $scope.infoRequest.id, name: attachment.title, 
                                              link: attachment.link, attachment_type: 'info_req_link', direction: 'outbound'}).promise.then(function(data){
                                                $scope.clearProvidedDoc()
                                                $scope.infoRequest.file_and_links.push({
                                                    id: data.id,
                                                    name: data.name,
                                                    direction: data.direction,
                                                    attachment_type: 'info_req_link',
                                                    link: data.link
                                                })
                                              })
                                              $scope.infoRequest.content_edited = true;
        }
        
    }; //upload["8", "25", 28, 27, 29, 30, "32"]

    $scope.saveFileRequest = function(requestData){
        railApi.post('/file_and_links/', {approval_email_id: $scope.infoRequest.id, name: requestData.title, 
            request_description: requestData.instructions, attachment_type: 'info_req_request', direction: 'inbound', mandatory: requestData.mandatory}).promise.then(function(data){
                $scope.clearProvidedDoc()
                $scope.infoRequest.file_and_links.push({
                    name: data.name,
                    request_description: data.request_description,
                    direction: data.direction,
                    attachment_type: data.attachment,
                    id: data.id,
                    mandatory: data.mandatory
                })
                // $scope.offerLetter.content_edited = true;
            })
    }

    $scope.removeDocuments = function(id, file_id, index) {
        
        if (file_id) {
            var remove_url = 'approval_emails/remove_file/' + file_id;
            railApi.put(remove_url, {file_id: file_id, file_and_link_id: id}, 'approval_emails').promise.then(function(data){
                    $scope.infoRequest.file_and_links.splice(_.indexOf($scope.infoRequest.file_and_links, _.findWhere($scope.infoRequest.file_and_links, { id : id})), 1);
                    $scope.infoRequest.content_edited = true;
            })
        }else{
            railApi.put('/file_and_links/' + id, {removed: true}, 'file_and_link').promise.then(function(data){
                $scope.infoRequest.file_and_links.splice(_.indexOf($scope.infoRequest.file_and_links, _.findWhere($scope.infoRequest.file_and_links, { id : id})), 1);
                $scope.infoRequest.content_edited = true;
            })
        }

    }
}

module.exports = informationRequestCtrl;