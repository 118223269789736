

'use strict';

var Directive = function () {
    return {
        restrict: 'A',
        link: function (scope, elem) {
            $(elem).bind('click', function() {
                window.scrollTo(0, 0);
            });

        }
    };
};

module.exports = Directive;
