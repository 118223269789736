'use strict';

var Service = function ($window) {
    this.gotoAnchor = function (id) {
        $('html, body').animate({
            scrollTop: $('#' + id).position().top
        }, 1000);
    };
};

Service.$inject = ['$window'];

module.exports = Service;