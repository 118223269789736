'use strict';

var Directive = function ($timeout) {
    return {
        restrict: 'E',
        require: 'ngModel',
        scope: {
            options: '=',
            placeholder: '@'
        },
        link: function (scope, elem, attr, model) {
            elem = $(elem);

            var selectize;
            var options;
            var timeout;
            var interval = 500;
            var defaultOptions = {
                delimiter: '~#~',
                create: false,
                dropdownParent: 'body',
                value: false,
                open: false,
                clearDropDown: false,
                allowIdEmpty: false,
                plugins: ['remove_button']
            };

            if (null == attr.sortField || scope.$parent.$eval(attr.sortField)) {
                defaultOptions.sortField = 'text';
            }

            

            attr.ngModelOptions = scope.$parent.$eval(attr.ngModelOptions);
            if (null != attr.ngModelOptions &&
                null != attr.ngModelOptions.debounce
            ) {
                interval = attr.ngModelOptions.debounce;
            }

            defaultOptions.onType = function (value) {
                scope.$evalAsync(function () {
                    if (null != attr.ngKeyup) {
                        $timeout.cancel(timeout);
                        timeout = $timeout(function() {
                            scope.$parent.$eval(attr.ngKeyup, { $viewValue: value });
                            selectize.typing = true;
                        }, interval);
                    }
                });
            };

            defaultOptions.onChange = function (value) {
                if (options.maxItems != 1) {
                    value = value.split('~#~').map(function (item) {
                        return (isNaN(item)) ? item : parseInt(item, 10);
                    });
                    if (!value[0]) {
                        value.pop();
                    }

                } else {
                    if (!isNaN(value)) {
                        if (value !== "") {
                            value = parseInt(value);
                        }
                    }
                }

                scope.$evalAsync(function () {
                    model.$setViewValue(value);

                    if (null != attr.ngChange) {
                        scope.$parent.$eval(attr.ngChange, {$viewValue: value});
                    }
                });
            };

            scope.$watch(function () {
                return model.$viewValue;
            }, function (newVal, oldVal) {
                var selected = selectize.getValue().split('~#~');
                if (null != newVal && -1 === selected.indexOf(newVal)) {
                    selectize.addItem(newVal);
                }

                if (null == newVal && null != oldVal) {
                    selectize.setValue(newVal);
                }
            });

            if (null != attr.optionsModel) {
                scope.$parent.$watch(attr.optionsModel, function (val) {
                    if(null != val) {
                        selectize.setValue(model.$viewValue);
                    }
                }, true);
            }

            options = _.merge({}, defaultOptions, scope.options || {});
            selectize = elem.selectize(options)[0].selectize;
            $.data(elem[0], 'selectize', selectize);

            // watch placeholder after language change or first time translated
            scope.$watch(
                function() {
                    return scope.placeholder;
                },
                function(newValue, oldValue) {
                        selectize.settings.placeholder = attr.placeholder;
                        selectize.updatePlaceholder();
                        //aria-label populated with placeholder text - so generate concurrently
                        var inputs = document.getElementsByTagName("input");
                        var placeholders;
                        for(var i = 0; i < inputs.length; i++)
                        {
                            if(inputs[i].getAttribute("placeholder")) {

                                placeholders = inputs[i].getAttribute("placeholder");
                                inputs[i].setAttribute("aria-label", placeholders);
                            }
                            else
                            {
                                inputs[i].setAttribute("aria-label", "no placeholder available");
                            }
                        }
                        selectize.$control[0].firstChild.id = "selectionInput"
                        if ( attr.ngReadonly == "true"){
                            var selectionInput = document.querySelector("#selectionInput")
                            selectionInput.setAttribute("readonly", "readonly")
                        }
                        
                }
            );

        }
    };
};
Directive.$inject = ['$timeout'];

module.exports = Directive;