/**
 * Created by dylan on 05/01/15.
 */
'use strict';

var selector = 'hgForm';
var delay = 800;
var hgValidate = require('hg-validators');

/**
 * Turns path strings into expressions.
 *
 * name.first[language]
 *
 * becomes
 *
 * 'name.first' + language
 *
 * @param path
 * @returns {string}
 */
var getPathExpr = function (path) {
    // Regex to extract out dynamic variables.
    path = path.split(/\[([^\]]*)]/);

    // First path is a string, so we wrap it in quotes.
    path[0] = '\'' + path[0] + '\'';

    // Every other path is dynamic, so we concat it to the string.
    for (var i = 1; i < path.length; i += 2) {
        path[i] = ' + \'.\' + ' + path[i];
    }

    return path.join('');
};

var Directive = function ($parse) {
    return {
        require: '^form',
        restrict: 'A',
        compile: function (tElem, tAttr) {
            tElem = $(tElem);

            if (!tElem.is('form')) {
                throw new Error('hgValidate: Can only be used on form elements.');
            }

            var selectRegex = RegExp('^' + selector + '\\.');
            var inputs = tElem.find('[ng-model^="' + selector + '"]').toArray();

            if (-1 !== tAttr.hgValidate.indexOf('module.exports')) {
                tAttr.hgValidate = tAttr.hgValidate.replace(/.*module\.exports[^\{]&/)
            }

            tElem.attr('novalidate', true);

            // Attach error messages (ng-repeat) to each input for this form.
            angular.forEach(inputs, function (input) {
                var errorPath = getPathExpr(input.getAttribute('ng-model').replace(selectRegex, ''));
                $(input)
                    .attr({
                        name: '{{' + errorPath + '}}',
                        required: 'true'
                    })
                    .after(angular.element('<div/>')
                        .attr({
                            class: 'note note-error ',
                            style: 'font-size: 30px',
                            'ng-repeat':
                                'message in ' +
                                tAttr.name + '.hgErrors[' + errorPath + '].message'
                        })
                        // translated {{\'Validations.\' + message | hgTranslate}}
                        .text('{{message}}')
                    )
                    .parent('label')
                    .attr('ng-class',
                        '{' +
                            '\'state-error\': (' +
                                    tAttr.name + '.hgErrors[' + errorPath + '] && ' +
                                    tAttr.name + '[' + errorPath + '].$touched' +
                                ') || ' +
                                tAttr.name + '.hgServerErrors[' + errorPath + '],' +
                            '\'state-success\': ' +
                                '!' + tAttr.name + '.hgErrors[' + errorPath + '] && ' +
                                '!' + tAttr.name + '.hgServerErrors[' + errorPath + '] && ' +
                                '(' +
                                    'null != ' + tAttr.name + '[' + errorPath + '].$modelValue || ' +
                                    tAttr.name + '[' + errorPath + '].$touched' +
                                ')' +
                        '}'
                    );
            });

            return function (scope, elem, attr, form) {
                scope.$watch(selector, function (newVal) {
                    form.hgErrors = hgValidate(
                        attr.name,
                        $parse(attr.hgValidate)(scope, { hgValidate: hgValidate })
                    ).test(newVal).errors || {};
                }, true);
            };
        }
    };
};
Directive.$inject = ['$parse'];

module.exports = Directive;
