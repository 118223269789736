/*
* This filter is created to show "Added by Recruiter" source in Candidate source list only
* when recruiter want to apply candidate to a Job 
 */

var Filter = function () {
    return function(obj, unwantedElem, remove) {
        if (obj) {
            return obj.filter(function (elem) {
                if (remove) {
                    return elem.name.en != unwantedElem;
                } else {
                    return true;
                }

            });

        }
    }
};


Filter.$inject = [];

module.exports = Filter;