/**
 * Created by dylan on 18/12/14.
 */



var Directive = function () {
    return {
        restrict: 'AE',
        controller: Controller,
        transclude: true,
        replace: true,
        link: function (scope, element) {
            element.first().jarvismenu({
                accordion: true,
                speed: 235,
                closedSign: '<em class="fa fa-plus-square-o"></em>',
                openedSign: '<em class="fa fa-minus-square-o"></em>'
            });
        },
        template: "<nav><ul data-ng-transclude=\"\"></ul></nav>"
    };
};

var Controller = function ($scope) {};
Controller.$inject = ['$scope'];

module.exports = Directive;