 var Directive = function () {
    return {
        require: 'ngModel',
        link: function(scope, element, attrs, modelCtrl) {
          modelCtrl.$parsers.push(function (input) {
            var validatedNumber = input.replace(/[a-zA-Z]/g, ''); 
            if (validatedNumber!=input) {
              modelCtrl.$setViewValue(validatedNumber);
              modelCtrl.$render();
            }         
            return validatedNumber;         
          });
        }
      };
};

module.exports = Directive;