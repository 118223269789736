'use strict';
var notesCtrl = function ($rootScope, $scope,noteService, jobService,applicantService, railApi, $state, $stateParams, conversions) {

    // Toggle for displaying the add notes textfield
    $scope.showAddNotes = false;

    // Toggle for displaying the add notes textfield
    $scope.showAddNotes = false;

    // Holds the notes models
    $scope.notes = {};

    // Workflow Stages (Readonly)
    $scope.workflow = {};


    $scope.editedNoteBody = "";

    $scope.load = function(employeeId,applicantProfileId,jobId){

        $scope.employeeId = employeeId;

        applicantService.getApplicantProfile(applicantProfileId)
            .then(function(applicant){
                $scope.applicant = applicant;
            }).catch(function(error){
                console.log("Unable to get profile:"+error);
            })

        if(jobId){
            jobService.getJob(jobId)
                .then(function(job){
                    $scope.job = job;
                    $scope.workflow = job.workflow;
                }).catch(function(error){
                console.log("Unable to get job:"+error);
            })
        }
    }

    $scope.getWorkflowStage = function(stageId){

        var name = ""

        if($scope.workflow){
            if(stageId == null){
                name = ""
            }else{
                name = _($scope.workflow.stages).find({id:
                stageId}).name[$rootScope.currentLang]
            }
            
        }
        return name ? name : "";
    }
    $scope.getApplicantData = function(applicantProfile, jobInfo){
        $scope.applicant_profile = applicantProfile;
        $scope.job = jobInfo
        
    }
    $scope.getApplicantDataNotesModal = function(applicant, jobId, noteBody) {

        applicant.applicant_profiles.forEach(function(e){ 
            if(e.id == jobId){
                $scope.applicant_profile = e;
            }
        })
        $scope.job = $scope.applicant_profile.job;

        var note =  {
            body: noteBody,
            stage_id: $scope.applicant_profile.stage_id,
            applicant_profile_id: $scope.applicant_profile.id,
            employee_id: $rootScope.hgUserData.id,
            job_id: $scope.job.id,
            is_edited: false,
            is_deleted: false
        };

        noteService.addNote(note)
            .then(function(response){
                var newNote = response.data;
                note.id = newNote.id;
                note.employee = {
                    name: newNote.employee.name,
                    id: newNote.employee.id
                  }
                $scope.applicant_profile.notes.push(note);
            })
            .catch(function(error){
                console.log("Unable to add note:"+error);
            });
        
    }
    /**
     * Triggered to add note. Pushes up to the service and broadcasts
     * as notesCtrl.note_added
     *
     */


     /*
     (applicant_profile.notes) ?
                                    applicant_profile.notes.push({
                                        body: newNote,
                                        stage_id: applicant_profile.stage_id,
                                        applicant_profile_id: applicant_profile.id,
                                        employee_id: hgUserData.id,
                                        employee: {
                                            id: hgUserData.id,
                                            name: hgUserData.name
                                            },
                                        job_id: applicant_profile.job_id
                                    }): applicant_profile.notes =[{
                                        body: newNote,
                                        stage_id: applicant_profile.stage_id,
                                        applicant_profile_id: applicant_profile.id,
                                        employee_id: hgUserData.id,
                                        job_id: applicant_profile.job_id
                                    }];

                                    notes.push({
                                                body: newNote,
                                                stage_id: applicant_profile.stage_id,
                                                applicant_profile_id: applicant_profile.id,
                                                employee_id: hgUserData.id,
                                                employee: {
                                                    id: hgUserData.id,
                                                    name: hgUserData.name
                                                },
                                                job_id: applicant_profile.job_id
                                      });
                                      writeNote(newNote,
                                                applicant_profile,
                                                hgUserData);
                                      getSelectedTab(applicant_profile, applicant_profile.job, applicant_profile.job.workflow.stages);
                                      */
    $scope.addNote = function(noteBody){

        var note =  {
            body: noteBody,
            stage_id: $scope.applicant_profile.stage_id,
            applicant_profile_id: $scope.applicant_profile.id,
            employee_id: $rootScope.hgUserData.id,
            job_id: $scope.job.id,
            is_edited: false,
            is_deleted: false
        };

        noteService.addNote(note)
            .then(function(response){
                var newNote = response.data;
                note.id = newNote.id;
                note.employee = {
                    name: newNote.employee.name,
                    id: newNote.employee.id
                  }
                $scope.applicant_profile.notes.push(note);
            })
            .catch(function(error){
                console.log("Unable to add note:"+error);
            });

        //$rootScope.$emit('notesCtrl.note_added',{note:note,applicant:$scope.applicant});
    }

    $scope.editNote = function(note, noteHistList){
        railApi.get('notes/' + note.id).promise.then(function(data){
            $scope.previousNote =  {
                body: data.body,
                original_id: data.id,
                is_edited: true,
                employee_id: data.employee.id,
            };
            
            $scope.previous_note = railApi.post('note_histories', $scope.previousNote).data
            
            // this part updates the list of note history that displays on view
            $scope.notesHistList = noteHistList
            $scope.notesHistList.unshift($scope.previous_note)
            $scope.noteHistories = $scope.notesHistList
            // 
            console.log(note);
            note.is_edited = true;
            noteService.updateNote(note)
                .then(function(response){
                    console.log("Updated Note:");
                })
                .catch(function(error){
                    console.log("Unable to edit note:"+error);
                });
        });
    }

    $scope.removeNote = function(index, note){
        note.is_deleted = true;
        noteService.updateNote(note)
                .then(function(response){
                    console.log("Updated Note:");
                })
                .catch(function(error){
                    console.log("Unable to edit note:"+error);
                });
        var i = $scope.applicant_profile.notes.indexOf(note);
        $scope.applicant_profile.notes.splice(i, 1);
        // noteService.deleteNote(note)
        //     .then(function(response){
        //         var i = $scope.applicant_profile.notes.indexOf(note);
        //         $scope.applicant_profile.notes.splice(i, 1);
        //     })
        //     .catch(function(error){
        //         console.log("Unable to delete note:"+error);
        //     });
 
         //$rootScope.$emit('notesCtrl.note_removed',$scope.note);
    }
    // Delete modal notes
    $scope.removeModalNote = function(index, note, appNotes){

        note.is_edited = true;
        note.is_deleted = true;
        noteService.updateNote(note)
                .then(function(response){
                    console.log("Updated Note:");
                })
                .catch(function(error){
                    console.log("Unable to edit note:"+error);
                });
        appNotes.splice(appNotes.indexOf(note), 1);
        var i = $scope.applicant_profile.notes.indexOf(note);
        $scope.applicant_profile.notes.splice(i, 1);
 
    }

    $scope.notesView = function(viewValue, noteList, profileId, employeeId){
        if(viewValue == 'active'){
            $scope.notesList = noteList;
        }else if(viewValue == 'deleted'){
            if($rootScope.hgUserData.role == 'recruiter_admin' || $rootScope.hgUserData.role == 'super_admin'){
                $scope.notesList = railApi.index('notes', {is_deleted: true, applicant_profile_id: profileId}).data
            }else{
                $scope.notesList = railApi.index('notes', {is_deleted: true, applicant_profile_id: profileId, employee_id: employeeId}).data
            }
        }else{
            if($rootScope.hgUserData.role == 'recruiter_admin' || $rootScope.hgUserData.role == 'super_admin'){
                railApi.index('notes', {is_deleted: true, applicant_profile_id: profileId}).promise.then(function(data){
                    $scope.notesList = [].concat(data, noteList)
                })
            }else{
                railApi.index('notes', {is_deleted: true, applicant_profile_id: profileId, employee_id: employeeId}).promise.then(function(data){
                    $scope.notesList = [].concat(data, noteList)
                })
            }
        }
    }

}
module.exports = notesCtrl;
