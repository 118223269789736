'use restrict'
var config = require('../config.js');

var jobPostingOptionsCtrl = function ($scope, railApi, $stateParams, $state, $rootScope) {

    $scope.coverLetterOptionChange = function(option){
        
        if(option == "optional"){
            var optional = true
            var mandatory = false
            var not_accepted = false
        }
        if(option == "mandatory"){
            var optional = false
            var mandatory = true
            var not_accepted = false
        }
        if(option == "not_accepted"){
            var optional = false
            var mandatory = false
            var not_accepted = true
        }

        railApi.put('organizations/1/', {optional_cover_letter: optional, mandatory_cover_letter: mandatory, not_accepted_cover_letter: not_accepted}, 'organization');
    }
    

};

module.exports = jobPostingOptionsCtrl;