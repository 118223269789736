'use strict';

//angular.module('hgPasswordStrength', [])
//    .directive('hgPasswordStrength', function() {

var Directive = function () {
    return {
        restrict: 'A',
        scope: {
            passwd: '=hgPasswordStrength',
            trigger: '=trigger'
        },
        link: function (scope, elem, attrs) {
            scope.passwd = '';

            var checkStrength = function (password) {

                var counts = {};
                counts.isLowerValid = false;
                counts.isUpperValid = false;
                counts.isNumberValid = false;
                counts.isSymbolValid = false;
                counts.messages = [];

                    var matches = {};

                    matches.characterCount = password.length;

                    matches.lowerFoundArray = password.match(/[a-z]/g);
                    matches.upperFoundArray = password.match(/[A-Z]/g);
                    matches.numbersFoundArray = password.match(/\d/g);
                    matches.symbolsFoundArray = password.match(/[$-/:-?{-~!@#\\"^_`\[\]]/g);


                    // LOWERCASE
                    if (matches.lowerFoundArray) {
                        counts.lowerUsed = matches.lowerFoundArray.length || 0;
                    } else {
                        counts.lowerUsed = 0;
                    }

                    if (0 !== attrs.hgPasswdLower) {
                        if (counts.lowerUsed >= attrs.hgPasswdLower) {
                            counts.isLowerValid = true;
                        } else {
                            if (attrs.hgPasswdLower != 0) {
                                counts.messages.push('Requires a Lowercase Character');
                            }
                        }
                    }

                    // UPPERCASE
                    if (matches.upperFoundArray) {
                        counts.upperUsed = matches.upperFoundArray.length || 0;
                    } else {
                        counts.upperUsed = 0;
                    }
                    if (0 !== attrs.hgPasswdUpper) {
                        if (counts.upperUsed >= attrs.hgPasswdUpper) {
                            counts.isUpperValid = true;
                        } else {
                            if (attrs.hgPasswdUpper != 0) {
                                counts.messages.push('Requires an Uppercase Character');
                            }
                        }
                    }

                    // NUMBERS
                    if (matches.numbersFoundArray) {
                        counts.numbersUsed = matches.numbersFoundArray.length || 0;
                    } else {
                        counts.numbersUsed = 0;
                    }

                    if (0 !== attrs.hgPasswdNumber) {
                        if (counts.numbersUsed >= attrs.hgPasswdNumber) {
                            counts.isNumberValid = true;
                        } else {
                            if (attrs.hgPasswdNumber != 0) {
                                counts.messages.push('Requires a Number');
                            }
                        }
                    }

                    // SYMBOLS
                    if (matches.symbolsFoundArray) {
                        counts.symbolsUsed = matches.symbolsFoundArray.length || 0;
                    } else {
                        counts.symbolsUsed = 0;
                    }

                    if (0 !== attrs.hgPasswdSpecial) {
                        if (counts.symbolsUsed >= attrs.hgPasswdSpecial) {
                            counts.isSymbolValid = true;
                        } else {
                            if (attrs.hgPasswdSpecial != '') {
                                counts.messages.push('Requires a Symbol');
                            }

                        }
                    }

                if (password.length < attrs.hgPasswdLength) {
                    counts.messages.push('Must be at least ' + attrs.hgPasswdLength + ' Characters');
                }

                if (password.length >= attrs.hgPasswdLength
                    && counts.isLowerValid == true
                    && counts.isUpperValid == true
                    && counts.isNumberValid == true
                    && counts.isSymbolValid == true) {
                    counts.isValid = true;
                }

                return counts;
            };


            scope.$watch('passwd', function () {
                scope.$parent.isValid = checkStrength(scope.passwd);
            });

            scope.$watch('trigger', function () {
                scope.$parent.isValid = checkStrength(scope.passwd);
            });

        }
    };

};


module.exports = Directive;

