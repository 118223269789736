'use strict';



var Directive = function(railApi) {
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        template: "<label class=\"select\">\n    <hg-select placeholder=\"{{ 'Job.PlaceholderDivisionCategory' | hgTranslate }}\"\n               ng-model=\"hgForm.category_division.id\"\n               options=\"{ maxItems: 1, returnInt: true }\">\n        <hg-option ng-repeat=\"categoryDivision in categoriesDivision.job_categories\" value=\"{{categoryDivision.id}}\">\n            {{categoryDivision.name | hgGetLang: currentLang}}\n        </hg-option>\n        <hg-option selected ng-if=\"hgForm.category_division_id.id\" value=\"{{hgForm.category_division_id.id}}\">\n            {{hgForm.category_division_id.name | hgGetLang: currentLang}}\n        </hg-option>\n    </hg-select>\n</label>",

        link: function(scope, elem, attrs) {
            scope.categoriesDivision = railApi.index('job_categories', {'query': {'category_type': 'division'}}).data

        }
    };
};

Directive.$inject = ['railApi'];

module.exports = Directive;