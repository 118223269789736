'use strict';
var config = require('../config.js');

var jobListCtrl = function ($scope, Jobs, railApi, $translate, $stateParams, $rootScope) {

    $scope.organizationData = railApi.get('organizations/').data;
    $scope.apiIP = config.apiIP;

    // http://url/#/job-list/fr
    if ($stateParams.language == 'fr') {
        $rootScope.preferred_language = "fr";
        $translate.use('fr'); 
        
    } else if ($stateParams.language == 'es') {
        $rootScope.preferred_language = "es";
        $translate.use('es');
    }else{
        // $rootScope.currentLang = "en";
        $rootScope.preferred_language = "en";
        $translate.use('en');
    }

    railApi.get('organizations/1').promise.then(function(companyData) {
        if(companyData.google_tag_manager_enabled == true) {
            var gtmID = companyData.google_tag_manager_id;
    
            // Add Head Script
            var headScript = document.createElement('script');
            headScript.type = "text/javascript"
            headScript.textContent = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','" + gtmID + "');"
            document.getElementsByTagName('head')[0].prepend(headScript);
    
            // Add Body Script
            var newNoScript = document.createElement('noscript');
            var newFrame = document.createElement('iframe');
            newFrame.src = "https://www.googletagmanager.com/ns.html?id=" + gtmID;
            newFrame.height = "0";
            newFrame.width = "0";
            newFrame.style = "display:none;visibility:hidden"
    
            newNoScript.appendChild(newFrame);    
            document.getElementsByTagName('body')[0].prepend(newNoScript);
        }
    });

    $scope.uploadJobs = function (jobList) {
        $scope.jobs = [];
        for (var i = 0; i < jobList.length; i++) {

            $scope.jobs[i] = JSON.parse(JSON.stringify(jobList[i]));

            var temp = $scope.jobs[i].category_display[0];
            $scope.jobs[i].category_display = [];
            $scope.jobs[i].category_display = temp;

            var temp_division = $scope.jobs[i].category_division[0];
            $scope.jobs[i].category_division = [];
            $scope.jobs[i].category_division = temp_division;

        }
    };
    var indexedCategories = [];

    $scope.jobsToFilter = function () {
        indexedCategories = [];
        return $scope.jobs;
    };

    $scope.filterJobs = function (job) {
        var categoryIsNew = indexedCategories.indexOf(job.category_display.name.en || job.category_division.name.en) == -1;
        if (categoryIsNew) {
            indexedCategories.push(job.category_display.name.en || job.category_division.name.en);
        }
        return categoryIsNew;
    };

    $scope.jobResult = {pending: true};

    Jobs.getList().then(function (jobs) {
        $scope.jobList = jobs;

        $scope.jobResult = {pending: false};
    }).catch(function (err) {
        console.log(err);
        $scope.jobResult = {pending: false};
    })

    // Add Google Tag Manager Scripts to Job Board and Job Info Pages
    // $scope.addGoogleScripts = function() {
    //     var gtmID = $rootScope.clientSettings.google_tag_manager_id;

    //     // Add Head Script
    //     var headScript = document.createElement('script');
    //     headScript.type = "text/javascript"
    //     headScript.textContent = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','" + gtmID + "');"
    //     document.getElementsByTagName('head')[0].prepend(headScript);

    //     // Add Body Script
    //     var newNoScript = document.createElement('noscript');
    //     var newFrame = document.createElement('iframe');
    //     newFrame.src = "https://www.googletagmanager.com/ns.html?id=" + gtmID;
    //     newFrame.height = "0";
    //     newFrame.width = "0";
    //     newFrame.style = "display:none;visibility:hidden"

    //     newNoScript.appendChild(newFrame);    
    //     document.getElementsByTagName('body')[0].prepend(newNoScript);

    // }

    // if($rootScope.clientSettings.google_tag_manager_enabled == true){
    //     $scope.addGoogleScripts(); 
    // }
    
};

module.exports = jobListCtrl;