/**
 * Created by Neo on 2019-01
 */
'use restrict';

var reportJobViewCtrl = function ($scope, railApi, $stateParams, $timeout) {

    $scope.total = 0;
    $scope.results = {};
    $scope.hgForm = null;
    $scope.jobViewData = [];
    $scope.jobReportCategoryData = [];

    $scope.calculateTotal = function(result){

        var myTotal = 0;

        for(var i = 0; i < result.length; i++){
            var jobsNum = result[i][1];
            myTotal = myTotal + jobsNum;
        }

        $scope.total = myTotal;

    };


    // for job posting report
    $scope.getJobViewReportData = function(hgForm, railApiName) {
        if (hgForm.job_id > 0) {
            railApi.get('jobs/'+railApiName+'/', {'job_id': hgForm.job_id}).promise.then(function(myData) {
                $scope.jobViewData = myData;
            });
        }else{
            railApi.get('jobs/'+railApiName+'/', {'job_start_date': hgForm.start_date, 'job_end_date': hgForm.end_date}).promise.then(function(myData) {
         
                $scope.jobViewData = myData;
    
            });
        }
        
    }
};

module.exports = reportJobViewCtrl;