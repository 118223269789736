'use strict';

var dashboardCtrl = function ($scope, $state, $stateParams, railApi, jobService, smoothScroll, $rootScope) {

    var serialize = require('../serialize.js');
   
    $scope.tooltipsCount = railApi.index('tool_tips');
    $scope.tooltip = $scope.tooltipsCount.data;

    $scope.goToJobList = function (){
        $state.go('^.jobList')
    }

    // Title of the page
   $scope.title = ""

   //Recruiter Dash variable
   var dashOption = '';
   $scope.userRole = $rootScope.hgUserData.role
   
   if(sessionStorage.getItem("dashViewSession") === null) {
        if($scope.userRole == "recruiter") {
            $scope.dashView = 'assigned';
        }
        else {
            $scope.dashView = 'all';
        }
   }
   else if(sessionStorage.getItem("dashViewSession") === "all") {
             $scope.dashView = 'all';
   }
   else if(sessionStorage.getItem("dashViewSession") === "assigned") {
            $scope.dashView = 'assigned';
   }

   // Type (active/expired)
   $scope.type = "active"

   // jobs listing
   $scope.expiredJobs = {}

    // Query Parameters for pagination
    $scope.expiredLimit = 10;
    $scope.expiredOffset = 0;
    $scope.expiredOrder = 'end_date ASC';

    // Pagination parameters
    $scope.expiredPage = 1;
    $scope.expiredTotal = 0; // populated from first call meta field

       // jobs listing
   $scope.activeJobs = {}

   // Query Parameters for pagination
   $scope.activeLimit = 10;
   $scope.activeOffset = 0;
   $scope.activeOrder = 'end_date ASC';


   // Pagination parameters
   $scope.activePage = 1;
   $scope.activeTotal = 0; // populated from first call meta field

   //Parameter for showing offer letter
   $scope.offerLetterLimit = 4;
   $scope.showSeeMore = true;


    //Parameter for showing info requests
    $scope.infoRequestLimit = 4;
    $scope.showSeeMoreInfoRequest = true;

   
   

    // Loading spinner
    $scope.loading = false;

    $scope.organizationData = railApi.get('organizations/').data;
    
    if($scope.userRole == "recruiter" || $scope.userRole == "recruiter_admin" || $scope.userRole == "super_admin"){
        $scope.offerLetters = railApi.index('approval_emails/', {dashboard: 'draft', email_type:'offer_letter'}).data;
    }

    if($scope.userRole == "recruiter" || $scope.userRole == "recruiter_admin" || $scope.userRole == "super_admin"){
        $scope.infoRequests = railApi.index('approval_emails/', { dashboard: 'cancelled', email_type: 'info_request', order_status: true }).data;
    }

    $scope.openOfferLetterView = function openJobWindow (offerletter) {
        var url = "#/offer-letter/view/" + offerletter.id;
        window.open(url, '_blank');
    }

    $scope.openInfoRequestView = function openJobWindow (infoRequest) {
        var url = "#/information-request/view/" + infoRequest.id;
        window.open(url, '_blank');
    }

    $scope.seeMoreButton = function() {

        $scope.offerLetterLimit = null;
        $scope.showSeeMore = false;

    }

    $scope.seeLessButton = function() {

        $scope.offerLetterLimit = 4;
        $scope.showSeeMore = true;

    }

    $scope.seeMoreInfoRequestButton = function() {

        $scope.infoRequestLimit = null;
        $scope.showSeeMoreInfoRequest = false;

    }

    $scope.seeLessInfoRequestButton = function() {

        $scope.infoRequestLimit = 4;
        $scope.showSeeMoreInfoRequest = true;

    }

    $scope.scrollToTop = function(elem, callbackAfter){
        if(elem){
            var options =   {duration:800,
                easing:"easeInQuint",
                offset:120,
                callbackAfter: function(element) {
                    if(callbackAfter) callbackAfter();
                }};
            smoothScroll(elem,options);
        }else{
            if(callbackAfter) callbackAfter();
        }
   }

   $scope.populateAllDashboard = function() {
    $scope.dashView = 'all';

    sessionStorage.setItem("dashViewSession", $scope.dashView);
    
    $scope.loadActiveJobs();
    $scope.loadExpiredJobs();
   };

   $scope.populateAssignedDashboard = function() {
    $scope.dashView = 'assigned';

    sessionStorage.setItem("dashViewSession", $scope.dashView);

    $scope.loadActiveJobs();
    $scope.loadExpiredJobs();
   };

   $scope.loadActiveJobs = function(offset, query){
    if(offset){
        $scope.activeOffset = offset;
    }
    else {
        $scope.activeOffset = 0;
    }

    if(query){
        $scope.activeOrder = query;
    }

    var apiPromise = railApi.index('jobs/active', {dash_display: $scope.dashView, limit:$scope.activeLimit, offset: $scope.activeOffset, order: $scope.activeOrder}).promise.then(function(response){
        $scope.loading = false;
        $scope.activeJobs = response.jobs;
        $scope.activeTotal = response.meta.total;
 });


 };

 $scope.loadExpiredJobs = function(offset, query){
    
    if(offset){
        $scope.expiredOffset = offset;
    }
    else {
        $scope.expiredOffset = 0;
    }

    if(query){
        $scope.expiredOrder = query;
    }

     var apiPromise = railApi.index('jobs/expired', {dash_display: $scope.dashView, limit:$scope.expiredLimit, offset: $scope.expiredOffset, order: $scope.expiredOrder}).promise.then(function(response){
         $scope.loading = false;
         $scope.expiredJobs = response.jobs;
         $scope.expiredTotal = response.meta.total;
     });


 };



   $scope.loadJobs = function(){

        // Element id to handle scroll to the top of the view. So far this is a
        // jarring interface so I've removed it.
        // var elem = document.getElementById('jobViewsTable-'+$scope.type);

        // Only run the spinner the first time

        console.log($scope.dashView)

        // if($scope.jobs == null || _.isEmpty($scope.jobs) )  {
        //     $scope.loading = true;
        // }

        var queryParams = {limit:$scope.limit, offset: $scope.offset}

        if($scope.query){
            queryParams['order'] = $scope.query
        }
        

        // var serviceCall = ($scope.type == 'active') ? jobService.getActiveJobs(queryParams, $scope.dashView) :  jobService.getExpiredJobs(queryParams, $scope.dashView);
        var apiPromise = railApi.index('jobs/assigned', {dash_display: $scope.dashView}, serialize(queryParams || {})).promise.then(function(response){
            console.log('response:', response)
            $scope.loading = false;
            // var data = response.data
            $scope.jobs = response.jobs;
            $scope.total = response.meta.total;
        }).catch(function(error){
            $scope.loading = false;
            $scope.error = error;
        });

        // apiPromise.then(function(response){
        //     $scope.loading = false;
        //     var data = response.data
        //     $scope.jobs = data.jobs;
        //     console.log($scope.jobs);
        //     $scope.total = data.meta.total;
        //     $scope.$applyAsync()
        // }).catch(function(error){
        //     $scope.loading = false;
        //     $scope.error = error;
        // })

   }

   $scope.assignedAndOpenJobs = function() {
       console.log('here', $scope.jobs);
    // var assignedJobs = jobService.getAssignedJobs();
       var assignedJobs = railApi.index('jobs/assigned').data;
       console.log('please be here:', assignedJobs);
   }

   $scope.displayOption = function() {
    if ($scope.dashView == 'all'){
        dashOption = true
    } else if ($scope.dashView == 'assigned') {
        dashOption = false
    }
    return dashOption;  
    }

    sessionStorage.setItem('userID', $rootScope.hgUserData.id); // Used for unread notifications

};

module.exports = dashboardCtrl;
