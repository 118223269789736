'use strict';

var Factory = function ($rootScope, $state, principal) {
    return {
        authorize: function () {

            return principal.identity()
                .then(function (userData) {

                    var isAuthenticated = principal.isAuthenticated();

                    if ($rootScope.toState.data &&
                        $rootScope.toState.data.aclRoles &&
                        $rootScope.toState.data.aclRoles.length > 0 && !principal.isInRoles($rootScope.toState.data.aclRoles)) {

                        if (isAuthenticated) {
                            // not authorized
                            $state.go('errorAcl');
                        }

                        else {
                            // not authenticated (save state to return to after login)
                            $rootScope.returnToState = $rootScope.toState;
                            $rootScope.returnToStateParams = $rootScope.toStateParams;

                            // now, send them to the signin state so they can log in
                            $window.location = '/auth/sign_in';
                        }
                    }

                    $rootScope.hgUserData = userData;

                    if ($rootScope.hgUserData === null) {
                        window.location = '/#';
                    }
                });
        }
    };
};


Factory.$inject = ['$rootScope', '$state', 'principal'];

module.exports = Factory;