/**
 * Created by Neo on 2018-02-15.
 */
'use restrict';

var reportJobPostingCtrl = function ($scope, railApi, $stateParams, $timeout) {

    $scope.total = 0;
    $scope.results = {};
    $scope.hgForm = null;
    $scope.jobData = [];
    $scope.jobReportCategoryData = [];

    $scope.calculateTotal = function(result){

        var myTotal = 0;

        for(var i = 0; i < result.length; i++){
            var jobsNum = result[i][1];
            myTotal = myTotal + jobsNum;
        }

        $scope.total = myTotal;

    };


    // For Job Publishing Report - opened, closed, active
    $scope.getJobPostingReportData = function(hgForm, railApiName, jobStatus) {
        console.log(jobStatus)
        jobStatus = jobStatus ? jobStatus : '';
        // if (hgForm.job_id > 0) {
        //     railApi.get('jobs/'+railApiName+'/', {'status': jobStatus, 'job_id': hgForm.job_id}).promise.then(function(myData) {
        //         $scope.jobData = myData;

        //         _.forEach($scope.jobData.jobs, function(value, index){
        //             if (value.recruiters.length < 1) {
        //                 $scope.jobData.jobs[index].recruiters = [];
        //                 $scope.jobData.jobs[index].recruiters.splice(0, 0, 'N/A');
        //             }

        //             if (value.hiring_managers.length < 1) {
        //                 $scope.jobData.jobs[index].hiring_managers = [];
        //                 $scope.jobData.jobs[index].hiring_managers.splice(0, 0, 'N/A');
        //             }
                    
        //             if (value.decision_makers.length < 1) {
        //                 console.log("empty dm id: " + value.id + "<br>");
        //                 $scope.jobData.jobs[index].decision_makers = [];
        //                 $scope.jobData.jobs[index].decision_makers.splice(0, 0, 'N/A');
        //             }

        //         });

        //     });
        // }else{
        //     railApi.get('jobs/'+railApiName+'/', {'status': jobStatus, 'job_start_date': hgForm.start_date, 'job_end_date': hgForm.end_date}).promise.then(function(myData) {
         
        //         $scope.jobData = myData;
            
        //         _.forEach($scope.jobData.jobs, function(value, index){
        //             if (value.recruiters.length < 1) {
        //                 $scope.jobData.jobs[index].recruiters = [];
        //                 $scope.jobData.jobs[index].recruiters.splice(0, 0, 'N/A');
        //             }

        //             if (value.hiring_managers.length < 1) {
        //                 $scope.jobData.jobs[index].hiring_managers = [];
        //                 $scope.jobData.jobs[index].hiring_managers.splice(0, 0, 'N/A');
        //             }

        //             if (value.decision_makers.length < 1) {
        //                 $scope.jobData.jobs[index].decision_makers = [];
        //                 $scope.jobData.jobs[index].decision_makers.splice(0, 0, 'N/A');
        //             }
                
        //         });
    
        //     });
        // }
        railApi.get('jobs/' + railApiName + '/', {
                    'job_start_date': hgForm.start_date, 
                    'job_end_date': hgForm.end_date, 
                    'job_id': hgForm.job_id,
                    'job_report_type': hgForm.job_report_type
        }).promise.then(function(myData) {
            $scope.jobData = myData; 
        });
        
    }

    $scope.getActiveUserJobPosting = function(){
        $scope.activeEmployees = []
        railApi.index('employees/active_report').promise.then(function(data){
            data.forEach(function(items){
                items.jobs.forEach(function(jobList){
                    var employeeJobs = {
                        lastname: items.last_name,
                        firstname: items.first_name,
                        role: items.role,
                        status: items.aasm_state,
                        jobId: jobList.id,
                        jobTitle: jobList.name_en,
                        jobStatus: jobList.status,
                        expiryDate: jobList.end_date
                    }
                    $scope.activeEmployees.push(employeeJobs)
                })
            })
        })
    }

    $scope.formatAnswer = function (answer) {
        answer = answer.replaceAll('"', '');
        answer = answer.replaceAll('{', '');
        answer = answer.replaceAll('}', '');
        answer = answer.replaceAll(',', ',\n');
        answer = answer.replaceAll(/[\[\]']+/g,'')
        answer = answer.split(', ').join('\n');
    
        return answer
     };
    
};

module.exports = reportJobPostingCtrl;