/**
 * Created by sukhjeet on 26/03/15.
 */

var Filter = function () {
    return function(text, maxLength, wholeWord) {
        var title = text,
            mLength = maxLength,
            newTitle,
            leftTitle,
            wholeWord = wholeWord;

        newTitle = title.substring(0,mLength);

        if( title.charAt(mLength) != ' ' && (wholeWord)) {
            leftTitle = title.substring(mLength);
            var spacePos = (mLength) + Math.min(leftTitle.length, leftTitle.indexOf(' '));

            if (spacePos > mLength) {
                newTitle = title.substr(0, spacePos);
            } else {
                newTitle = title;
            }
        }
        if ( newTitle.length < title.length ) {
            newTitle = newTitle + '...';
        }
        return newTitle;
    }
};


Filter.$inject = [];

module.exports = Filter;