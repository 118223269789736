/**
 * Request Interceptor Factory
 *
 * Intercepts all out-going/in-coming requests
 * to ensure authorization tokens are sent and received.
 *
 * @param $window
 * @constructor
 */
var Factory = function ($q, $window, $location) {
    return {
        request: function (req) {

            req.headers = req.headers || {};
            var token = $window.localStorage.getItem('auth');
            var accessToken = $window.localStorage.getItem('access-token');
            var client = $window.localStorage.getItem('client');
            var uid = $window.localStorage.getItem('uid');
            var url = req.url.split("/");

            //if (null != token && url.indexOf('api')) {
            if (null != token) {
                req.headers.auth = token;
            }
            if (accessToken) {
                req.headers['access-token'] = accessToken;
                req.headers['client'] = client;
                req.headers['uid'] = uid;
            }

            if(myConfig.domain){
                req.headers['x-domain'] = myConfig.domain;
            }

            return req;
        },
        response: function (res) {
            var headers = res.headers();
            var token = headers.auth;

            if (_.isObject(res.data)) {
                res.data = JSON.parse(JSON.stringify(res.data), function (key, val) {
                    if ('string' === typeof val &&
                        /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/.test(val)) {
                        return new Date(val);
                    }

                    return val;
                });
            }

                if (null != token) {
                    $window.localStorage.setItem('auth', token);
                }
                if (headers['access-token']) {
                    $window.localStorage.setItem('access-token', headers['access-token']);
                    $window.localStorage.setItem('client', headers['client']);
                    $window.localStorage.setItem('uid', headers['uid']);
                }

            return res;
        },
        responseError: function (res) {
            var headers = res.headers();
            console.log(res);

            if (401 === res.status) {
                // redirect to page explaining what happened.
                switch (headers['x-session-error']) {
                    case 'ssoError':

                        // Log them out.
                        $window.localStorage.removeItem('auth');
                        $window.localStorage.removeItem('access-token');
                        $window.localStorage.removeItem('client');
                        $window.localStorage.removeItem('uid');

                        $location.path('/error/sso');
                        $location.replace();
                        break;
                    case 'sessionExpired':

                        // Log them out.
                        $window.localStorage.removeItem('auth');
                        $window.localStorage.removeItem('access-token');
                        $window.localStorage.removeItem('client');
                        $window.localStorage.removeItem('uid');
                        $window.location = '/#/login?msg=sessionExpired';
                        $location.replace();
                        break;
                    case 'aclError':
                        $location.path('/error/acl');
                        $location.replace();
                        break;
                    case 'loginError':
                        $window.location = '/#/login?msg=loginError';
                        $location.replace();
                        break;
                     default:
                         // Log them out.
                        $window.localStorage.removeItem('auth');
                        $window.localStorage.removeItem('access-token');
                        $window.localStorage.removeItem('client');
                        $window.localStorage.removeItem('uid');
                        $window.location = '/#/login?msg=sessionExpired';
                        $location.replace();
                    
                }

            } else if (404 === res.status) {
                if(headers['base-domain'] === $location.host()) {
                    $window.location.href = '/applicant-tracking.html#/';
                } else {
                    $window.location.href = '/404.html#/';
                }
            } else if (504 === res.status) {
                // retry failed request by refreshing the page. Add this code because of task id: 7727
                //location.reload();
                console.log(res.status, ' Time out');
            } // else if ('ValidationError' !== res.data.name) {
                // if (500 === res.status) {
                //     $location.path('/error/unknown');
                //     $location.replace();
                // }
             // }

            return $q.reject(res);
        }
    };
};


Factory.$inject = ['$q', '$window', '$location'];

module.exports = Factory;
