'use strict';

var PrincipalFactory = function ($q, RailRestangular, $window) {
    var _identity = undefined,
        _authenticated = false;

    return {
        isIdentityResolved: function () {
            return angular.isDefined(_identity);
        },
        isAuthenticated: function () {
            return _authenticated;
        },
        hasRole: function (role) {

            if (!_authenticated || !_identity.aclRoles) {
                return false;
            }

            return _identity.aclRoles.indexOf(role) !== -1;
        },
        isHgStaff: function () {
            if (_identity.aclRoles.indexOf('super_admin') !== -1 ||
                _identity.aclRoles.indexOf('recruiter_admin') !== -1) {
                return true;
            } else {
                return false;
            }
        },
        isInRoles: function (roles) {
            if (!_authenticated || !_identity.aclRoles) {
                return false;
            }

            if (this.isHgStaff()) {
                return true;
            } else {
                // check other roles
                for (var i = 0; i < roles.length; i++) {
                    if (this.hasRole(roles[i])) {
                        return true;
                    }
                }
            }

            return false;
        },
        authenticate: function (identity) {
            _identity = identity;
            _authenticated = identity != null;
        },
        identity: function (force) {
            var deferred = $q.defer();

            if (force === true) {
                _identity = undefined;
            }

            // check and see if we have retrieved the identity data from the server. if we have, reuse it by immediately resolving
            if (angular.isDefined(_identity)) {
                deferred.resolve(_identity);

                return deferred.promise;
            }

            // otherwise, retrieve the identity data from the server, update the identity object, and then resolve.
            var uid = $window.localStorage.getItem('uid');
            var path123 = 'employees/account/' + btoa(uid);
            RailRestangular.one(path123).get().then(function (data) {
                _identity = data.plain();
                _authenticated = true;
                deferred.resolve(_identity);
            }, function () {
                _identity = null;
                _authenticated = false;
                deferred.resolve(_identity);
            });

            return deferred.promise;
        }
    };
};

PrincipalFactory.$inject = ['$q', 'RailRestangular', '$window'];

module.exports = PrincipalFactory;