'use strict';



var Directive = function(railApi) {
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        template: "<label class=\"select\">\n    <hg-select placeholder=\"{{ 'Job.PlaceholderDisplayCategory' | hgTranslate }}\"\n               ng-model=\"hgForm.category_display.id\"\n               options-model=\"categories\"\n               options=\"{ maxItems: 1, returnInt: true }\">\n        <hg-option ng-repeat=\"category in categories.job_categories\" value=\"{{category.id}}\">\n            {{category.name | hgGetLang: currentLang}}\n        </hg-option>\n        <hg-option selected ng-if=\"hgForm.category_display_id.id\" value=\"{{hgForm.category_display_id.id}}\">\n            {{hgForm.category_display_id.name | hgGetLang: currentLang}}\n        </hg-option>\n    </hg-select>\n</label>",

        link: function(scope, elem, attrs) {
            scope.categories = railApi.index('job_categories', {'query': {'category_type': 'display'}}).data

        }
    };
};

Directive.$inject = ['railApi'];

module.exports = Directive;