var run = function ($rootScope, RailRestangular, $modal, $translate, $http, $state, $stateParams, authorization, principal, $window) {
    console.log('Starting StartDate module.');
    if ('startdate.ca' !== window.location.host && 'www.startdate.ca' !== window.location.host) {
        var config = require('./config');
        $rootScope.apiUrl = config.apiIP;
        // RailRestangular.one('get-ip').get().then(function (data) {
        //     $rootScope.ip = data;
        // });

        $rootScope.clientSettings = {};
        $rootScope.preferred_language = 'en';
        $rootScope.fallbackLanguage = ['en'];
        $rootScope.logo = {};

        $rootScope.domain = myConfig.domain;

        function getParameterByName(name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        }

        // get client language settings
        RailRestangular.all('organizations').getList().then(function (data) {
            $rootScope.clientSettings = data[0];
            $rootScope.imageLogoUrl = function () {
                if($rootScope.clientSettings.logo != null){
                    var url = 'https://startdate-client-logo.s3.ca-central-1.amazonaws.com/'+$rootScope.clientSettings.logo
                }else{
                    var url = 'https://startdate-client-logo.s3.ca-central-1.amazonaws.com/default_logo.png'
                }
                return url
            };
            var lang = data[0].preferred_language;
            $rootScope.fallbackLanguage = ['en'];

            if (null != data[0].logo) {
                $rootScope.logo = data[0].logo;
            }

            if (null != data[0].preferred_language) {
                $rootScope.preferred_language = lang;
            } else {
                $rootScope.preferred_language = 'en';
            }

            if (null != data[0].available_languages) {
                $rootScope.fallbackLanguage = data[0].available_languages;
            }

            if (null != data[0].application_message) {
                $rootScope.application_message = data[0].application_message;
            }

            if (null != data[0].no_available_careers_message) {
                $rootScope.no_available_careers_message = data[0].no_available_careers_message;
            }


            // if (null != data[0].settings.emailSuccessContent) {
            //     $rootScope.emailSuccessContent = data[0].settings.emailSuccessContent;
            // }

            // if (null != data[0].settings.sendEmailSuccessMessage) {
            //     $rootScope.sendEmailSuccessMessage = data[0].settings.sendEmailSuccessMessage;
            // }

            // if (null != data[0].settings.emailSuccessSubject) {
            //     $rootScope.emailSuccessSubject = data[0].settings.emailSuccessSubject;
            // }

            $rootScope.available_languages = $rootScope.fallbackLanguage;
            $translate.use($rootScope.preferred_language);


        }, function (err) {
            var href = $window.location.href.split('/').filter(function (n) {
                return '' !== n;
            });
            var page = href[(href.length - 1)];

            $rootScope.preferred_language = 'en';
            $rootScope.fallbackLanguage = ['en'];
            if ('404.html' === page || '404.html#' === page ||
                'applicant-tracking.html' === page || 'applicant-tracking.html#' === page
            ) {
                $rootScope.fallbackLanguage = ['en', 'fr'];
            }
            $rootScope.available_languages = $rootScope.fallbackLanguage;
            $translate.use($rootScope.preferred_language);
        });
    }

    $rootScope.hgUserAuth = principal;

    $rootScope.$on('$stateChangeStart', function (event, toState, toStateParams) {
        // track the state the user wants to go to; authorization service needs this
        $rootScope.toState = toState;
        $rootScope.toStateParams = toStateParams;

        if ( ('/'=== $rootScope.toState.url) && ('/ats.html' === $window.location.pathname) ) {
            $window.location = '/ats.html';
        }

        // if the principal is resolved, do an authorization check immediately. otherwise,
        // it'll be done when the state it resolved.
        if (principal.isIdentityResolved()) {

            $rootScope.userData = {
                userId: '2354986345634'
            };

            authorization.authorize();
        }
    });

    // Update page title
    var listener = function(event, toState) {
        $rootScope.title = (toState.data && toState.data.pageTitle)
            ? toState.data.pageTitle
            : 'StartDate';

        // if($window.location.host.indexOf("localhost") == -1) {
        //     setTimeout(function(){
        //         Analytics.trackPage();
        //     },2000);
        // }

    };
    $rootScope.$on('$stateChangeSuccess', listener);

    Object.defineProperty($rootScope, 'language', {
        get: function () {
            return $translate.use();
        }
    });

    $rootScope.request = RailRestangular;
    $rootScope.modal = $modal;
    $rootScope.moment = moment;
    $rootScope._ = _;

    $rootScope.showFlags = false;
};

run.$inject = ['$rootScope',
    'RailRestangular',
    '$modal',
    '$translate',
    '$http',
    '$state',
    '$stateParams',
    'authorization',
    'principal',
    '$window'
];
module.exports = run;