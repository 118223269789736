'use strict';
var config = require('../config.js');

var clientCtrl = function ($scope, Upload, deviceDetector, $location, railApi, $stateParams, $state, $rootScope) {
    $scope.uploadLogo = function(file, fileType, error) {
        if (file && (undefined === error)) {
            Upload.upload({
                url: config.apiIP + 'organizations/upload_logo?client='+$rootScope.clientSettings.client_id,
                file: file,
                fields: data
            }).progress(function (evt) {
                $scope.icon = "fa fa-spinner fa-spin"
                $scope.color = ""
                $scope.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                location.reload(true);
            }).success(function (data, status, headers, config) {
                // completed, data contains the parsed resume and fileModel
                mapLinguaxResponse(data, fileType);
            }).error(function(data, status, headers, config) {
                $scope.fileError = data.error;
                if (config.fields.type === 'resume') {
                    $scope.errorResume = true;
                }
                else {
                    $scope.errorFile = true;
                }
            });
        }else{
            return false;
        }
        
    };

    $scope.uploadBigLogo = function(file, fileType, error) {
        if (file && (undefined === error)) {
            Upload.upload({
                url: config.apiIP + 'organizations/upload_social_logo?client='+$rootScope.clientSettings.client_id,
                file: file,
                fields: data
            }).progress(function (evt) {
                $scope.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                location.reload();
            }).success(function (data, status, headers, config) {
                // completed, data contains the parsed resume and fileModel
                mapLinguaxResponse(data, fileType);
            }).error(function(data, status, headers, config) {
                $scope.fileError = data.error;
                if (config.fields.type === 'resume') {
                    $scope.errorResume = true;
                }
                else {
                    $scope.errorFile = true;
                }
            });
        }else{
            return false;
        }
        
    };

    $scope.uploadEmailLogo = function(file, fileType, error) {
        if (file && (undefined === error)) {
            Upload.upload({
                url: config.apiIP + 'organizations/upload_email_logo?client='+$rootScope.clientSettings.client_id,
                file: file,
                fields: data
            }).progress(function (evt) {
                $scope.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                location.reload();
            }).success(function (data, status, headers, config) {
                // completed, data contains the parsed resume and fileModel
                mapLinguaxResponse(data, fileType);
            }).error(function(data, status, headers, config) {
                $scope.fileError = data.error;
                if (config.fields.type === 'resume') {
                    $scope.errorResume = true;
                }
                else {
                    $scope.errorFile = true;
                }
            });
        }else{
            return false;
        }
        
    };

    $scope.uploadCompEmailSignature = function(file, fileType, error) {
        if (file && (undefined === error)) {
            Upload.upload({
                url: config.apiIP + 'organizations/upload_email_signature?client='+$rootScope.clientSettings.client_id,
                file: file,
                fields: data
            }).progress(function (evt) {
                $scope.progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                location.reload();
            }).success(function (data, status, headers, config) {
                // completed, data contains the parsed resume and fileModel
                mapLinguaxResponse(data, fileType);
            }).error(function(data, status, headers, config) {
                $scope.fileError = data.error;
                if (config.fields.type === 'resume') {
                    $scope.errorResume = true;
                }
                else {
                    $scope.errorFile = true;
                }
            });
        }else{
            return false;
        }
        
    };

    $scope.removeEmailBanner = function(client_id, type){
        railApi.put('organizations/1/remove_email_logo', {client_id: client_id, type: type}, 'organizations').promise.then(function(data){
            location.reload(true);
        })
    }
};

module.exports = clientCtrl;