/**
 * Created by Joseph on 14/02/17.
 */

'use strict';

var conversions = function () {
    this.convertData = function (dataOriginal) {


        // var data = Object.assign({}, dataOriginal);
        var data = JSON.parse(JSON.stringify(dataOriginal));

        // converting objects
        var convert = function (obj) {
            data.job_category_ids = [];
            if (data.report_category_ids_concat && data.display_category && data.display_category[0] && data.division_category && data.division_category[0]) {
                data.job_category_ids = _.uniq(data.job_category_ids.concat(data.report_category_ids_concat, data.display_category[0].id, data.division_category[0].id));

            }else if (data.report_category_ids_concat && data.display_category && data.display_category[0]) {
                data.job_category_ids = _.uniq(data.job_category_ids.concat(data.report_category_ids_concat, data.display_category[0].id));
            }
            else if (data.report_category_ids_concat && data.division_category && data.division_category[0]){
                data.job_category_ids = _.uniq(data.job_category_ids.concat(data.report_category_ids_concat, data.division_category[0].id));
            }
            else if (data.display_category && data.display_category[0] && data.division_category && data.division_category[0]){
                data.job_category_ids = _.uniq(data.job_category_ids.concat(data.display_category[0].id, data.division_category[0].id));
            }
            else if (data.division_category && data.division_category[0]){
                data.job_category_ids = _.uniq(data.job_category_ids.concat(data.division_category[0].id));
            }
            else if (data.display_category && data.display_category[0]){
                data.job_category_ids = _.uniq(data.job_category_ids.concat(data.display_category[0].id));
            }
            else {
                data.job_category_ids = _.uniq(data.report_category_ids_concat);
            }

            var idValues = ['id'];
            var idString = "id";
            var attrValues = "_attributes";
            var nameValues = ['en', 'fr', 'es'];
            var nameComparison = [];
            var idComparison = [];

            for (var parent in obj) {

                // console.log(parent); // use to find which property is throwing error

                var keys = [];
                if (!_.isEmpty(obj[parent])) {
                    keys = Object.keys(obj[parent]);
                }
                nameComparison = _.intersection(nameValues, keys);
                // case 5 : inside an array (ie stages)
                // action : disable case 4
                if (isNaN(parseInt(parent))) {
                    idComparison = _.intersection(keys, idValues);
                }

                // case 1 : object with '_id'
                // action : value of nested 'id : #' is used
                // to overwrite object_name_id
                if  ((_.includes(parent,"_id")) &&
                    (obj[parent] != null) &&
                    (typeof obj[parent].id != 'undefined')) {
                    var parentId = obj[parent]["id"];
                    obj[parent] = parentId;
                }

                // case 2 : object without name / id
                // action : loop through deeper levels
                if (typeof obj[parent] == 'object' &&
                    (nameComparison.length < 1) &&
                    (idComparison.length < 1)) {
                    convert(obj[parent]);

                    // case 3 : object matching 'en', 'fr', 'es'
                    // action : concatinate the parent key with each child key
                    // and child key's value to create new records, delete old object
                } else if (nameComparison.length > 0) {
                    for (var child in obj[parent]) {
                        if(obj[parent][child] != null) {
                            var newName = parent + '_' + child;
                            obj[newName] = obj[parent][child];
                        }
                    }
                    delete(obj[parent]);

                    // case 4 : object matching 'id'
                    // action : concatinate id with parent key and delete object if empty
                } else if (_.includes(Object.keys(obj[parent]), idString)) {
                    if (isNaN(obj[parent][idString])) {
                        obj[parent][idString] = parseInt(obj[parent][idString]);
                    }
                    var newId = parent + '_' + idString;
                    obj[newId] = obj[parent][idString];
                    delete obj[parent][idString];
                    if (_.isEmpty(obj[parent])) {
                        delete obj[parent];
                    }
                }
            }
        };
        // console.log('\noriginal data\n');
        // console.log(data);
        // console.log('\nconverted data\n');
        convert(data);

        for (var value in data) {
            if (_.isNull(data[value])) {
                delete data[value];
            }
        }

        // concatenating hiring managers with recruiters
        if (data.hiring_manager_ids_concat || data.recruiter_ids_concat || data.decision_maker_ids_concat) {
            data.employee_ids = [];
            data.employee_ids = _.uniq(data.employee_ids.concat(data.recruiter_ids_concat, data.decision_maker_ids_concat, data.hiring_manager_ids_concat));
        }
        return data
    };
};

module.exports = conversions;
