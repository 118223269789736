'use strict';

//https://github.com/hhurz/tableExport.jquery.plugin

var getLabels = function () {
    var filterKey = [];
    // get all checkbox and radio button values
    $('input[type=checkbox], input[type=radio]').each(function( ){
        if ( $( this)[0].checked ){
            filterKey.push( $.trim( $(this).parent().text() ) );
        }
    });

    // get all input type=text values
    $('label.label').each(function( ){
        if ($( this ).siblings().find('input').val()){
            // label
            var labelTxt = $( this ).text() + ': ';
            // input value
            var inputVal = $.trim( $( this ).siblings().find('input').val() );
            filterKey.push( labelTxt + inputVal );
        }
    });

    // get all selectize.js dropdown value
    $( '.selectize-input>div' ).each(function( ) {
        // select label
        var labelTxt = $( this ).parent().parent().parent().siblings().text() + ': ';
        // select text
        var SelectVal = $.trim( $( this ).text() );
        filterKey.push( labelTxt + SelectVal );
    });

    return filterKey;
};


var Directive = function () {
    var link = function(scope, elem, attr){
        var newDate = new Date();
        var dateString = (newDate.getMonth() + 1) + '/' + newDate.getDate() + '/' +  newDate.getFullYear();
        var filterKey = [];

        scope.$watch(attr.ngModel, function(newValue, oldValue) {
            if (newValue !== oldValue && !_.isEmpty(newValue)) {
                filterKey = getLabels();
            }
        });

        $(elem).bind('click', function() {
            // If we have a table
            if ( $('.' + attr.className).length ) {

                // trigger to show table tab.
                $('tab-heading').filter(function() {
                    return $(this).html() === 'Table';
                }).trigger('click');

                $('.' + attr.className).tableExport({
                    type: attr.fileType,
                    jspdf: {
                        orientation: 'p',
                        margins: {
                            left: 20,
                            top: 10
                        }
                    },
                    fileName: _.kebabCase(attr.fileTitle) + '-' + dateString,
                    displayTableName: true,
                    tableName: filterKey.join(', '),
                    theadSelector: 'tr'
                });
            }
        });
    };

    return {
        require: 'ngModel',
        link: link
    };
};

module.exports = Directive;
