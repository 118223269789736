'use strict';

require('ionrangeslider');

var defaults = {
    type: 'single',
    min: 0,
    max: 100,
    step: 10,
    grid: true,
    grid_snap: true,
    prefix: '%',
    keyboard: true
};


var getId = (function () {
    var id = 0;

    return function () {
        return id += 1;
    };
}());

var Directive = function () {
    return {
        require: 'ngModel',
        scope: {
            model: '=ngModel',
            secondaryValue: '=secondaryValue'
        },
        restrict: 'A',
        link: function (scope, elem, attr, model) {
            var id = getId();
            var create = true;
            elem = $(elem);

            scope.createSlider = function(newVal) {
                create = false;
                defaults.from = newVal;
                _.merge(defaults, JSON.parse(attr.defaults || '{}'));

                elem.attr('id', id);
                $('#' + id).ionRangeSlider(defaults);
            };

            if (null == attr.createOnWatch) {
                scope.createSlider(0);
            }

            scope.$watch('model', function (newVal) {
                if (null != attr.secondaryValue && null != newVal) {
                    if (create) {
                        scope.createSlider(newVal);
                    }
                    scope.secondaryValue = defaults.max - parseInt(newVal);
                    scope.model = parseInt(newVal);

                }
            }, true);
        }
    };
};
Directive.$inject = [];

module.exports = Directive;
