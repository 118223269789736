'use strict';

var loginCtrl = function ($scope, $http, $location, $window, RailRestangular, $interval, railApi, $rootScope) {
    $scope.user = {};
    $scope.login = {};
    $scope.login.message = '';
    $scope.errorMessage = false;
    $scope.mfa_pin = "";
    $scope.codeSent = false;

    // $scope.userAccessLogs = railApi.get('user_access_logs').data;
    // console.log($scope.userAccessLogs);

    $scope.create_user_access_log = function() { // User logs will only be created for non-admin users
        if ($scope.user.email != 'clientsupport@hiregroundsoftware.com') {
            railApi.post('user_access_logs/create_user_log', {email: $scope.user.email}).promise.then(function(myData){
                $window.location = '/ats.html';
            })
            
        } else {
            $window.location = '/ats.html';
        }
    }
    $scope.submit = function () {
        
        // not needed when logging in.
        $window.localStorage.removeItem('auth');
        $window.localStorage.removeItem('access-token');
        $window.localStorage.removeItem('client');
        $window.localStorage.removeItem('uid');

        // var url = $location.protocol() + '://' + $location.host() + '/api/v1/login';
        var url = $location.protocol() + '://' + $location.host() + 'auth/sign_in';

        // If 2FA is enabled
        if ($rootScope.clientSettings.mfa_feature == true) {
            railApi.post('employees/mfa_verification/', {'email': $scope.user.email, 'mfa_pin': $scope.mfa_pin}).promise.then(function(myData){
                if(myData.status == "approved") {
                    console.log("Sent Successfully");
                    // Then call regular login API
                    RailRestangular.all('auth/sign_in').post($scope.user).then(function (data) {
                        //Moved page redirect inside create_user_access_log function
                        //  $window.location = '/ats.html';
                        $scope.create_user_access_log();

                    }, function (response) {
                        // Erase the token if the user fails to log in
                        $window.localStorage.removeItem('auth');
                        $window.localStorage.removeItem('access-token');
                        $window.localStorage.removeItem('client');
                        $window.localStorage.removeItem('uid');

                        // Handle login errors
                        if (401 === response.status) {
                            if(response.data.errors[0].indexOf('Invalid login credentials.') > -1){
                                $scope.login.message = 'Login.LoginErrorMessage';
                            }else if(response.data.errors[0].indexOf('A confirmation email was sent to your account') > -1){
                                $scope.login.message = 'Login.LoginErrorMessage';
                            }
                            else{
                                $scope.login.message = 'Login.LoginAttemptsError';
                            }
                        }
                    });
                }else{
                    $scope.login.message = 'Login.MfaErrorMessage';
                }
            });
        } else {
            RailRestangular.all('auth/sign_in').post($scope.user).then(function (data) {
                //Moved page redirect inside create_user_access_log function
                // $window.location = '/ats.html';
                $scope.create_user_access_log(); 

            }, function (response) {
                // Erase the token if the user fails to log in
                $window.localStorage.removeItem('auth');
                $window.localStorage.removeItem('access-token');
                $window.localStorage.removeItem('client');
                $window.localStorage.removeItem('uid');

                // Handle login errors
                if (401 === response.status) {
                    if(response.data.errors[0].indexOf('Invalid login credentials.') > -1){
                        $scope.login.message = 'Login.LoginErrorMessage';
                    }else if(response.data.errors[0].indexOf('A confirmation email was sent to your account') > -1){
                        $scope.login.message = 'Login.LoginErrorMessage';
                    }
                    else{
                        railApi.post('employees/send_lockout_email/', {'email': $scope.user.email, 'language': $scope.currentLang});
                        $scope.login.message = 'Login.LoginAttemptsError';
                    }
                }
            });
        }

    };

    if ('sessionExpired' === $location.search().msg) {
        $scope.errorMessage = 'Errors.SessionExpired';
    }

    if ('loginError' === $location.search().msg) {
        $scope.errorMessage = 'Errors.login';
    }
//    $scope.checkAuth = function () {
//        Restangular.all('login').getList().then(function (response) {
//            console.log(response[0].plain());
//        });
//    };

    // Still needs to be modified to send the SMS text
    $scope.sendCode = function() {
        railApi.post('employees/send_2FA_pin/', {'email': $scope.user.email}).promise.then(function(myData){
            if(myData.status == "pending") {
                console.log("Sent Successfully");
                $scope.codeSent = true;
                $scope.validFor = 600;
                $scope.timeInterval = $interval(function(){
                    $scope.validFor--;
                    if($scope.validFor == 0){
                        $interval.cancel($scope.timeInterval);
                        $scope.codeSent = false;
                    }
                }, 1000);
            }else if (myData.status == "noemailfound"){
                $scope.codeSent = false;
                $scope.login.message = 'Login.MfaNoEmailErrorMessage';
            }else if (myData.status == "Phone number is not available") {
                $scope.codeSent = false;
                $scope.login.message = 'Login.MfaNoPhoneErrorMessage';
            }else if (myData.status == "Account is not available") {
                $scope.codeSent = false;
                $scope.login.message = 'Login.MfaNoAccountErrorMessage';
            }else{
                console.log("N/A");
            }
        });
    }
};

module.exports = loginCtrl;
