var Filter = function() {
    // app.filter('searchFor', function(){

    // All filters must return a function. The first parameter
    // is the data that is to be filtered, and the second is an
    // argument that may be passed with a colon (searchFor:searchString)
    //alert('hello')

    return function (arr, searchString) {
        var result = [];
        
        if (searchString != null) {
            searchString2 = searchString.toLowerCase();
            // Using the forEach helper method to loop through the array
            //searchArray = ['item'];
            angular.forEach(arr, function (jobs) {
                var clientNameEn ='', clientNameFr ='', jobNotes ='';
                if (jobs.name.en != null) {
                    clientNameEn = jobs.name.en.toLowerCase();
                };
                if (jobs.name.fr != null) {
                        clientNameFr = jobs.name.fr.toLowerCase();
                };
                if (jobs.job_notes != null) {
                    jobNotes = jobs.job_notes.toLowerCase();
                    console.log(jobNotes);
                };  
                    //clientNotes = jobs.notes.toLowerCase();
                //if (clientNameEn) {    
                if (clientNameEn.search(searchString2) != -1 ) {
                    result.push(jobs);
                } else if (clientNameFr.search(searchString2) != -1 ) {
                    result.push(jobs);
                } else if (jobNotes.search(searchString2) != -1){
                    result.push(jobs);
                };    
            });
            
          
            return result;
        } else {
            return arr;
        }
    };
};

Filter.$inject = [];

module.exports = Filter;
