'use strict';

var Directive = function () {
    return {
        restrict: 'A',
        link: function($scope, $elem, $attr){

            var count = $.trim($elem.val()).length;
            $elem.after('<small>Character count: <strong>'+ count +'</strong></small>');

            $elem.bind('keyup', function(event){
                var count = $.trim($elem.val()).length;
                $(this).next().html('Character count: <strong>'+ count+'</strong>');

                var maxLen = $attr.maxlength || 10000000;
                var minLen = $attr.minlength || 0;

                if(count > maxLen || count < minLen) {
                    $elem.parent('label').addClass('state-error');
                } else {
                    $elem.parent('label').removeClass('state-error');
                }

            });
        }
    };
};

module.exports = Directive;