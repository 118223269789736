'use restrict';

var reportUserAccessLogsCtrl = function ($scope, railApi) {
 
     $scope.total = 0;
     $scope.results = {};
     $scope.hgForm = null;
     $scope.userAccessLogsData = [];
 
     $scope.calculateTotal = function(result){
         var myTotal = 0;         
         for(var i = 0; i < result.length; i++){
             var logsNum = result[i][1];
             myTotal = myTotal + logsNum;
         }
 
         $scope.total = myTotal;
     };
 
    $scope.getUserAccessLogsReportData = function(hgForm, railApiName) {

      railApi.get('user_access_logs/').promise.then(function(myData) {
          $scope.allLogs = myData;

          $scope.userAccessLogs = $scope.allLogs.filter(function(item) {
            return item.date.toISOString().split('T')[0] >= hgForm.start_date.toISOString().split('T')[0] && item.date.toISOString().split('T')[0] <= hgForm.end_date.toISOString().split('T')[0];
          });
          $scope.userAccessLogsNumber = $scope.userAccessLogs.length;
        });
      }

    $scope.int_to_ip = function(ipInt) { //convert int ipaddress to 0.0.0.0 format
        return ( (ipInt>>>24) +'.' + (ipInt>>16 & 255) +'.' + (ipInt>>8 & 255) +'.' + (ipInt & 255) );
    }
};
 
 module.exports = reportUserAccessLogsCtrl;