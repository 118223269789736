'use strict';

var settingsCtrl = function ($scope, railApi, $stateParams, $rootScope) {

    $scope.ieDetector = /*@cc_on!@*/false || !!document.documentMode;
    
    $scope.colorPadding = function () {
        if($scope.ieDetector == true){
            return {"padding": ""};
        } else {
            return {"padding": "2px"};
        }
    }

    $scope.updateDefaultSort = function (defaultSort) {
        $rootScope.clientSettings.candidate_default_sort = defaultSort
    }
};



module.exports = settingsCtrl;
