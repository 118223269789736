'use strict';


var Directive = function(){

    function link(scope, element, attr){
        scope.items = scope.ngModel;
        scope.label = attr.btnLabel;
        scope.placeholder = attr.fieldPlaceholder;

        //*Add item from list
        scope.addItem = function(value, event){
            if (scope.items == undefined){
                scope.items = [];
            }

            if( (event.keyCode ==13) || (event.buttons==1) || (event.button==0)) {

                if( value )  scope.items.push(value);
                scope.newItem = '';
            }
        };

        //remove item from list
        scope.removeItem = function(value){
            var deleteItem;
            deleteItem = scope.items.indexOf(value);
            scope.items.splice(deleteItem,1);
        };

    }

    return {
        restrict: 'E',
        link:link,
        require: 'ngModel',
        scope: {
            ngModel: '='
        },
        template: "<input ng-keyup=\"addItem(newItem, $event);\" ng-model=\"newItem\" class=\"form-control\" placeholder=\"{{placeholder}}\">\n\n<a href=\"\" ng-click=\"addItem(newItem, $event);\">{{label}}</a>\n\n<ul class=\"list-unstyled\">\n    <li ng-repeat=\"item in items\">\n        {{item}} <span ng-click=\"removeItem(item)\"><i class=\"fa fa-trash-o\"></i></span>\n    </li>\n</ul>\n\n<!--<ul class=\"list-unstyled\">-->\n    <!--<li ng-repeat=\"item in items\">-->\n        <!--<hg-editable>-->\n            <!--<hg-text title=\"{{ 'ClickToEdit' | hgTranslate}}\">-->\n                <!--{{ answer.text[AddQuestionForm.currentLang] || ('emptyValue' | hgTranslate) }}-->\n                <!--<a href-->\n                   <!--ng-click=\"hgForm.answers.splice($index, 1);-->\n                                            <!--\"-->\n                   <!--tooltip=\"Delete {{answer.text[AddQuestionForm.currentLang]}}\"-->\n                   <!--class=\"ng-scope\">-->\n                    <!--<i class=\"fa fa-trash-o\"></i>-->\n                <!--</a>-->\n            <!--</hg-text>-->\n            <!--<hg-edit>-->\n                <!--<label class=\"input\">-->\n                    <!--<input type=\"text\"-->\n                           <!--ng-model=\"answer.text[AddQuestionForm.currentLang]\"-->\n                           <!--placeholder=\"Answer\">-->\n                <!--</label>-->\n            <!--</hg-edit>-->\n        <!--</hg-editable>-->\n    <!--</li>-->\n<!--</ul>-->"
    };
};


Directive.$inject = [];

module.exports = Directive;