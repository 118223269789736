'use strict';

var Directive = function ($interpolate, $timeout) {
    return {
        restrict: 'E',
        link: function (scope, elem, attr) {
            elem = $(elem);
            // hg-select is being changed into a div with the below
            var selectize = $.data(elem.parent('hg-select')[0]).selectize;


            // This is where the hg-options is being changed into a div/input, need to find a
            // way to add a aria-label to the below
            var decodeHtmlEntity = function (input){
                var e = document.createElement('div');
                e.innerHTML = input;

                return (0 === e.childNodes.length) ? "" : e.childNodes[0].nodeValue;
            };

            var elemHtml = elem.html();

            selectize.addOption({
                value: attr.value,
                text: decodeHtmlEntity( $interpolate(elemHtml)(scope) )
            });

            if (null != attr.selected) {
                selectize.addItem(attr.value);
            }

            selectize.refreshOptions(false);

            attr.$observe('value', function (newVal, oldVal) {
                if (null == oldVal) {
                    selectize.addOption({
                        value: newVal,
                        text: $interpolate(elemHtml)(scope)
                    });
                } else {
                    selectize.updateOption(oldVal, {
                        value: newVal,
                        text: $interpolate(elemHtml)(scope)
                    });
                }

                if (selectize.typing) {
                    selectize.refreshOptions(true);
                }
            });

            scope.$watch(function () {
                return $interpolate(elemHtml)(scope);
            }, function (newVal, oldVal) {
                if (!_.isEqual(oldVal, newVal)) {
                    selectize.updateOption(attr.value, {
                        value: attr.value,
                        text: newVal
                    });
                }
            });

            elem.on('$destroy', function () {
                var values = selectize.getValue().split('~#~');

                if (-1 === values.indexOf(attr.value)) {
                    selectize.removeOption(attr.value, true);
                }
            });
        }
    };
};
Directive.$inject = ['$interpolate', '$timeout'];

module.exports = Directive;
