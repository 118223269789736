/**
 * Created by dylan on 12/12/14.
 */

// Add all global deps here.

global.jQuery = global.$ = require('jquery');
global._ = require('lodash');
global.moment = require('moment');

require('es6-shim');
require('jqueryui');
require('bootstrap');
require('angular');
require('angular-sanitize');
require('angular-ui-router');
require('angular-bootstrap');
require('angular-flot');
require('angular-translate');
require('angular-translate-loader-static-files');
require('restangular');
require('ionrangeslider');
require('ng-file-upload');
require('ng-file-upload-shim');
require('angular-route');
require('angular-google-analytics');
require('re-tree');
require('ng-device-detector');
require('angular-loading-bar');
require('./smartadminglobals.js');
require('angular-cache');
require('ngSmoothScroll');
require('angular-animate');

global.myConfig = require('./ng/config.js');