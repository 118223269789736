var Filter = function() {
    // app.filter('searchFor', function(){

    // All filters must return a function. The first parameter
    // is the data that is to be filtered, and the second is an
    // argument that may be passed with a colon (searchFor:searchString)
    //alert('hello')

    return function (arr, isInternal) {


        var result = [];

        //searchString = searchString.toLowerCase();

        // Using the forEach helper method to loop through the array
        //searchArray = ['item'];
        if (isInternal) {
            angular.forEach(arr, function (applicant_list) {

                /*if (applicant_list.applicant.name  == searchString) {*/

                /* if (applicant_list.applicant.name  == searchString) {*/
                if (applicant_list.applicant.is_internal) {
                    result.push(applicant_list);

                }
                ;

            });

            return result;
        }
        else return arr;

    };
};

Filter.$inject = [];

module.exports = Filter;