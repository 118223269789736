/**
 * Created by neo on 2016-03-09.
 */
'use strict';
var config = require('../config.js');

var careerInfoCtrl = function($scope, $timeout, $state, $stateParams, railApi, $rootScope){
    //$scope.redirectPage = function() {

        $scope.currentLang = $stateParams.language;
        $scope.apiUrl = config.apiIP;

        $scope.counter = 16;
        var mytimeout = null; // the current timeoutID

        railApi.get('organizations/1').promise.then(function(companyData) {
            if(companyData.google_tag_manager_enabled == true) {
                var gtmID = companyData.google_tag_manager_id;
        
                // Add Head Script
                var headScript = document.createElement('script');
                headScript.type = "text/javascript"
                headScript.textContent = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','" + gtmID + "');"
                document.getElementsByTagName('head')[0].prepend(headScript);
        
                // Add Body Script
                var newNoScript = document.createElement('noscript');
                var newFrame = document.createElement('iframe');
                newFrame.src = "https://www.googletagmanager.com/ns.html?id=" + gtmID;
                newFrame.height = "0";
                newFrame.width = "0";
                newFrame.style = "display:none;visibility:hidden"
        
                newNoScript.appendChild(newFrame);    
                document.getElementsByTagName('body')[0].prepend(newNoScript);
            }
        });

        if ($stateParams.id) {
          if ($stateParams.internal_id) {
            $scope.jobResult = railApi.get('jobs/apply/' + $stateParams.id, { internal_id: $stateParams.internal_id });
            $scope.job = $scope.jobResult.data;
            $scope.$watch('job.id', function (newVal, oldVal) {
                if (newVal != oldVal) {
                    if (!newVal.pending) { // and no error
                        $scope.docFiles = _.filter($scope.job.uploaded_files, { 'file_type': 'job_attachment' });
                    }
                }
            }, true)
          } else {
            $scope.jobResult = railApi.get('jobs/apply/' + $stateParams.id);
            $scope.job = $scope.jobResult.data;
            $scope.$watch('job.id', function (newVal, oldVal) {
                if (newVal != oldVal) {
                    if (!newVal.pending) { // and no error
                        $scope.docFiles = _.filter($scope.job.uploaded_files, { 'file_type': 'job_attachment' });
                    }
                }
            }, true)
          }
        };

        // actual timer method, counts down every second, stops on zero
        $scope.onTimeout = function () {
            if (0 === $scope.counter) {
                $scope.$broadcast('timer-stopped', 0);
                $timeout.cancel(mytimeout);
                return;
            }
            $scope.counter = $scope.counter - 1;
            mytimeout = $timeout($scope.onTimeout, 1000);
        };

        // this is called initially
        $scope.startTimeout = function () {
            mytimeout = $timeout($scope.onTimeout, 1000);
        };

        $scope.$on('timer-stopped', function (event, remaining) {
            if (0 === remaining) {
                $state.transitionTo('applyList');
            }
        });
        
    //}

    // Add Google Tag Manager Scripts to Job Board and Job Info Pages
    // $scope.addGoogleScripts = function() {
    //     var gtmID = $rootScope.clientSettings.google_tag_manager_id;

    //     // Add Head Script
    //     var headScript = document.createElement('script');
    //     headScript.type = "text/javascript"
    //     headScript.textContent = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','" + gtmID + "');"
    //     document.getElementsByTagName('head')[0].prepend(headScript);

    //     // Add Body Script
    //     var newNoScript = document.createElement('noscript');
    //     var newFrame = document.createElement('iframe');
    //     newFrame.src = "https://www.googletagmanager.com/ns.html?id=" + gtmID;
    //     newFrame.height = "0";
    //     newFrame.width = "0";
    //     newFrame.style = "display:none;visibility:hidden"

    //     newNoScript.appendChild(newFrame);    
    //     document.getElementsByTagName('body')[0].prepend(newNoScript);

    // }

    // if($rootScope.clientSettings.google_tag_manager_enabled == true){
    //     $scope.addGoogleScripts(); 
    // }

};

module.exports = careerInfoCtrl;
