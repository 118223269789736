/**
 * Created by dylan on 18/12/14.
 */

/**
 * StartDate Resources
 * (Factory, Service, Provider, Constant, Value)
 *
 * @param app the angular module.
 */
module.exports = function (app) {
    app
        .service('anchorScrollService', require('./anchorScrollService.js'))
        .service('applicationManagementService', require('./applicationManagementService.js'))
        .service('authorization', require('./authorization.js'))
        .service('fnValidatorService', require('./fnValidatorService.js'))
        .service('getClientDataService', require('./getClientDataService.js'))
        .service('getResourceAndPopulateService', require('./getResourceAndPopulateService.js'))
        .service('updateCandidateSourceArrayService', require('./updateCandidateSourceArrayService.js'))
        .service('notificationService', require('./notificationService.js'))
        .service('uploadFilesService', require('./uploadFilesService.js'))
        .service('conversions', require('./conversions.js'))
        .factory('hgApi', require('./hgApi.js'))
        .factory('principal', require('./principal.js'))
        .factory('tokenInterceptorFactory', require('./tokenInterceptorFactory.js'))
        .factory('RailRestangular', require('./RailRestangular.js'))
        .factory('Jobs', require('./jobs.js'))
        .factory('internalJobs', require('./internaljobs.js'))
        .factory('railApi', require('./railApi.js'))
    ;
};