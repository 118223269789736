'use strict';
var config = require('../../config.js');

var formEditCtrl = function ($rootScope, $scope, railApi, $state, $stateParams, $modal) {
  $scope.hgForm = railApi.get('forms/' + $stateParams.id ).data;

  $scope.setArchived = function (archived) {
    railApi.put('forms/' + $stateParams.id + '/archive', {archived: archived}, 'form').promise
    .then(function (data) {
      $scope.hgForm = data;
    //   $scope.hgForm.archived = archived;

        if ($scope.hgForm.archived === true) {
          $scope.archiveForm = $modal.open({
            templateUrl: 'archiveForm.ejs',
            scope: $scope,
            size: 'lg'
          });
        }
        else if ($scope.hgForm.archived === false) {
          $scope.unArchiveForm = $modal.open({
            templateUrl: 'unArchiveForm.ejs',
            scope: $scope,
            size: 'lg'
          });
        }
      });
  }

  $scope.cloneForm = function() {
    $scope.cloneFormConfirmationPopup = $modal.open({
      templateUrl: 'cloneFormConfirmationPopup.ejs',
      scope: $scope,
      size: 'lg'
    });
  }

  $scope.redirect = function() {
    $state.go('questionSectionClone', {id: $scope.hgForm.id});
  }

  $scope.dismissArchiveModal = function () {
    $scope.archiveForm.dismiss();
  }

  $scope.dismissUnArchiveModal = function () {
    $scope.unArchiveForm.dismiss();
  }

  $scope.dismissCloneModal = function () {
    $scope.cloneFormConfirmationPopup.dismiss();
  }

};

module.exports = formEditCtrl;
