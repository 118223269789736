'use strict';

var Service = function ($rootScope, $stateParams, $state, railApi) {
    this.getSelectedTab = function (applicant_profile, job, stages) {
        var selectedTab;
        var stagesWorkload;
        var order = -1;

        if ($stateParams.stage_id != "" && $stateParams.job_id != "") {
            selectedTab = $stateParams.stage_id;

            return selectedTab;
        }

        if (applicant_profile && applicant_profile[0]) {
            selectedTab = applicant_profile[0].stage_id;
        } else {
            stagesWorkload = _(job.workloads).filter('applicants_count').sortBy('order');
            if (stagesWorkload.first()) {
                selectedTab = stagesWorkload.first().stage_id;

                if (!(($rootScope.hgUserAuth.isInRoles(['hiring_manager', 'recruiter', 'recruiter_admin', 'super_admin'])) || (true === _.find(stages, {id: selectedTab}).dm_stage && $rootScope.hgUserAuth.isInRoles(['decision_maker'])))) {
                    selectedTab = '';

                    job.workloads.forEach(function(workload){
                        if (0 < workload.applicants_count &&
                            (order > workload.stage.order || -1 === order) &&
                            true === _.find(stages, {id: workload.stage.id}).dm_stage
                        ) {
                            order = workload.stage.order;
                            selectedTab = workload.stage_id;
                        }
                    });
                }

            }

        }

        return selectedTab;
    };

    this.loadStageApplications = function (scope, parent, stage_id, showStage) {
        parent.applicationsPage = 1;

        // Neo: Update current stageId to URL parameters. Sukhjeet: this line of code removed because of task id: 7338
        //$stateParams['stageId'] = stageId;

        if (showStage && _(scope.totalApplications).find({ id: stage_id }).total) {
            parent.selectedTab = stage_id;
            parent.applicationsQuery = railApi.index('applicant_profiles', {
                // fields: 'id profileId userId applicationMethodId isInternal log rejectionId',
                limit: parent.limit,
                // sort: '-' + parent.sortBy,
                job_id: $stateParams.job_id,
                stage_id: stage_id
                // populate: {
                //     userId: 'name',
                //     applicationMethodId: 'name',
                //     rejectionId: true
                // }
            });

            parent.applicationsNextQuery = railApi.index('applicant_profiles', {
                // fields: 'id profileId userId applicationMethodId isInternal log rejectionId',
                limit: parent.limit,
                offset: parent.limit,
                // sort: '-' + parent.sortBy,
                job_id: $stateParams.job_id,
                stage_id: stage_id
                // populate: {
                //     userId: 'name',
                //     applicationMethodId: 'name',
                //     rejectionId: true
                // }
            });

            parent.applications = parent.applicationsQuery.data;
            parent.applicationsNext = parent.applicationsNextQuery.data;
        }
    };

    this.moveApplicant = function (applicant_profile, stage_id, scope, rejection_reason_id) {
        var prevStageId = applicant_profile.stage_id;
        var idPreviusApplicantProfile;
        var parent = scope;
        var indexStage;
        var showStage = ($rootScope.hgUserAuth.isInRoles(['hiring_manager', 'recruiter', 'recruiter_admin', 'super_admin'])) || (true === _.find(scope.workflow.stages, {id: stage.id}).dm_stage && $rootScope.hgUserAuth.isInRoles(['decision_maker']));

        if (null != scope.$parent.maxResults) {
            parent = scope.$parent;
        }

        parent.maxResults -= 1;
        idPreviusApplicantProfile = parent.idPreviusApplicantProfile;

        applicant_profile.stage_id = stage_id;
        if(null == rejection_reason_id) {
            railApi.put('applicant_profiles/' + applicant_profile.id + '/move_to_stage/' + stage_id);
        } else {
            applicant_profile.rejection_reason_id = rejection_reason_id;
            railApi.put('applicant_profiles/' + applicant_profile.id + '/reject/' + rejection_reason_id);
        }

        var index = _(scope.applications.applicant_profiles).findIndex({id: applicant_profile.id});

        if(null != scope.applicationsNext.applicant_profiles[0]) {
            if (idPreviusApplicantProfile !== scope.applicationsNext.applicant_profiles[0].id) {
                scope.applications.applicant_profiles.push(scope.applicationsNext.applicant_profiles[0]);
            }
            scope.applicationsNext.applicant_profiles.splice(0, 1);
        }

        parent.idPreviusApplicantProfile = applicant_profile.id;

        if (0 === scope.maxResults) {
            $state.transitionTo('jobApplication', {job_id: $stateParams.job_id});
            parent.showTabs = true;
            if (showStage) {
                parent.selectedTab = stage_id;
                //this.loadStageApplications(scope, parent, stageId, showStage);
            } else {
                parent.selectedTab = null;
            }
        } else {
            if (null != scope.applications.applicant_profiles[index + 1]) {
                $state.transitionTo('jobApplication.profile', {applicant_profile_id: scope.applications.applicant_profiles[index + 1].id});
            } else {
                if (null != scope.applications[index - 1] && null != scope.applications.applicant_profiles[index - 1].id) {
                    $state.transitionTo('jobApplication.profile', {applicant_profile_id: scope.applications.applicant_profiles[index - 1].id});
                } else {
                    parent.stageChange = false;
                    parent.applicationsPage -= 1;
                    if (-1 === parent.applicationsPage) {
                        parent.applicationsPage = 1;
                    }
                }
            }
        }

        var prevStageIdIndex = _(scope.totalApplications).findIndex({ id: prevStageId });
        scope.totalApplications[prevStageIdIndex].total =  scope.totalApplications[prevStageIdIndex].total - 1;
        if (showStage) {
            indexStage = _(scope.totalApplications).findIndex({ id: stage_id });
            if (null !=  scope.totalApplications[indexStage] &&
                null != scope.totalApplications[indexStage].total
            ) {
                scope.totalApplications[indexStage].total =  scope.totalApplications[indexStage].total + 1;
            } else {
                if (null == scope.totalApplications[indexStage]) {
                    scope.totalApplications[indexStage] = {};
                }

                scope.totalApplications[indexStage].total = 1;
            }
        }

        scope.applications.applicant_profiles.splice(index, 1);

        if(null == scope.applicationsNext.applicant_profiles[0]) {
            parent.applicationsNextQuery.query.offset = (parent.applicationsPage + 1) * parent.applicationsNextQuery.query.limit;
            parent.applicationsNext = railApi.index('applicant_profiles', parent.applicationsNextQuery.query).data;
        }
    };

    this.setFirstApplicant = function (applications, stageChange) {
        if (false === stageChange &&
            null != applications.applicant_profiles &&
            null != applications.applicant_profiles[0] &&
            (null == $stateParams.applicant_profile_id ||
            -1 === _.findIndex(applications.applicant_profiles, {id: $stateParams.applicant_profile_id}))
        ) {
            $state.transitionTo('jobApplication.profile', {applicant_profile_id: applications.applicant_profiles[0].id});
        }
    };

    this.sendRegretLetter = function (applicantId) {
        $rootScope.regretLetterReceived = hgApi.post('bulk-actions', {
            jobId: $stateParams.jobId,
            applicantIds: [applicantId],
            type: 'regret-letter'
        });
    };
};

Service.$inject = ['$rootScope', '$stateParams', '$state', 'railApi'];

module.exports = Service;