'use strict';
var config = require('../../config.js');

var formCtrl = function ($rootScope, $scope, railApi, $state, $stateParams, $modal) {
    var preferred_language = $rootScope.preferred_language

    $scope.hgForm = {
        name: {},
        archived: false,
        is_deleted: false,
        is_editable: true,
        legend: {},
        questions_attributes: [],
        required_languages: [preferred_language],
        form_category_ids: []
    };
    $scope.successfulFormCreationPopup;

    $scope.editedQuestion = {};

    if ($stateParams.id) {
        $scope.hgForm.questions_attributes = []

        railApi.get('forms/' + $stateParams.id + '?page=clone').promise.then(function (data) {
            $scope.hgForm = data;
            $scope.privacy_status = $scope.hgForm.privacy_status.toLowerCase();
            $scope.hgForm.form_selected_languages = data.required_languages
            // $scope.hgForm.questions_attributes = $scope.hgForm.questions;
            _.forEach($scope.hgForm.questions_attributes, function (question, values) {
                question.isBeingEdited = false;
                _.forEach(question.answers_attributes, function (answer, values) {

                    answer.weighting = answer.weighting / 10;
                })
            })
        })

        // //$scope.hgForm['questions_attributes'] = $scope.hgForm['questions']
    }
    $scope.categoriesQuery = railApi.index('question_categories', {
        limit: 1000,
        fields: 'id name'
    }).data;
    $scope.categories = $scope.categoriesQuery;
    $scope.companyDepartments = railApi.index('company_departments').data;


    $scope.questionAnswers = [];
    //$scope.weighting = [];

    $scope.newQuestion = {
        name: {},
        helper_text: {},
        form_notes: "",
        legend: {},
        question_type: "",
        answers_attributes: [],
        required_languages: [preferred_language],
        is_required_question: false,
        weighting: 0
    }

    $scope.question_types = [
        { id: 'multiple-choice' },
        { id: 'multiple-answer' },
        { id: 'select-dropdown' },
        { id: 'long-answer' },
        { id: 'short-answer' }
    ];
    $scope.questions = {}
    $scope.questionCategorySelected = {};
    $scope.questionsAdded = [];
    $scope.hgForm.form_selected_languages = ['en'];
    $scope.selected = {
        questions: []
    }
    $scope.formCategories = railApi.index('form_categories').data;
    $scope.addingNewQuestion = 'false';
    $scope.addingNewQuestionTemplate = false;
    $scope.newQuestionId = {};
    $scope.privacy_status = 'public';

    if($scope.hgForm.privacy_status != null) {

        $scope.privacy_status = lowercase($scope.hgForm.privacy_status)
    }

    $scope.setPrivateForm = function(status) {
        $scope.privacy_status = status;
    }

    $scope.onSubmit = function () {

        if ($scope.privacy_status == null) {
            $scope.privacy_status = "public";
        }

        $scope.hgForm.privacy_status = $scope.privacy_status;

        railApi.post('forms/', $scope.hgForm, 'form').promise.then(function (data) {
            $scope.hgForm = data;

            $scope.successfulFormCreationPopup = $modal.open({
                templateUrl: 'successfulFormCreationPopup.ejs',
                scope: $scope,
                size: 'lg'
            });
        })
    }

    $scope.dismissConfirmationModal = function () {
        $scope.successfulFormCreationPopup.dismiss();
    }

    $scope.redirect = function () {
        $state.go('questionSectionList');
    }

    $scope.getCategoryQuestions = function () {
        if ($scope.questionCategorySelected.id) {
            $scope.questions.question_templates = undefined;
            //$scope.categories = undefined;
            $scope.newQuestionId.id = undefined;

            $scope.questionsQuery = railApi.get('question_templates', {
                question_category_ids: $scope.questionCategorySelected.id,
                is_deleted: false,
                archived: false,
                limit: 100,
                offset: 0
            }).data;
            $scope.questions = $scope.questionsQuery
        }

    }

    $scope.addQuestion = function (id) {
        $scope.newQuestion.is_required_question = 'false';
        $scope.newQuestion.required_languages = $scope.hgForm.required_languages;
        if (id) {
            $scope.newQuestion = _.find($scope.questions.question_templates, { id: id })
            $scope.newQuestion.weighting = 0;
            $scope.newQuestion.answers_attributes = $scope.newQuestion.answer_templates;


            _.forEach($scope.newQuestion.answers_attributes, function (answer, index) {
                answer.is_stop_answer = false;
                answer.weighting = 0;
                //$scope.weighting[index] = 0;
            });
        }
        $scope.addingNewQuestion = true;

    }

    $scope.resetQuestionForm = function () {
        //$scope.addingNewQuestionTemplate = 'false';
        //$scope.addingNewQuestion = true;
        $scope.newQuestion = {
            name: {},
            helper_text: {},
            form_notes: "",
            legend: {},
            question_type: undefined,
            order: "",
            required_languages: $scope.hgForm.required_languages,
            answers_attributes: [],
            weighting: 0
        }
        $scope.questionCategorySelected.id = undefined;//this doesn't work at all
        $scope.questions.question_templates = undefined;
        //$scope.categories = undefined;
        $scope.newQuestionId.id = undefined;

    }

    $scope.setNewQuestionTemplate = function (value) {
        $scope.addingNewQuestionTemplate = value;
    }

    $scope.setNewQuestion = function (value) {
        $scope.addingNewQuestion = value;
        $scope.newQuestion.is_required_question = 'false'
    }

    $scope.addQuestionToForm = function () {
        $scope.addingNewQuestion = 'false';
        $scope.addingNewQuestionTemplate = 'false';


        if ($scope.newQuestion.question_type == 'long-answer' || $scope.newQuestion.question_type == 'short-answer') {
            $scope.newQuestion.answers_attributes = [];
        }
        $scope.newQuestion.is_stop_question = false;

        _.forEach($scope.newQuestion.answers_attributes, function (answer, value) {
            if (answer.is_stop_answer == true) {
                $scope.newQuestion.is_stop_question = true;
            }
        });
        $scope.newQuestion.isBeingEdited = false;

        $scope.newQuestion.order = $scope.hgForm.questions_attributes.length;
        $scope.hgForm.questions_attributes.push($scope.newQuestion);

        $scope.resetQuestionForm();
    }

    $scope.cancel = function() {
        $scope.addingNewQuestion = 'false';
        $scope.addingNewQuestionTemplate = 'false';



        $scope.newQuestion.is_stop_question = false;

        $scope.resetQuestionForm();
    }

    $scope.multipleAnswersInvalid = true;
    $scope.multipleAnswerValidator = function(){
        var arrayOfValidity = []
        var textLengthValid
        for(i=0; i < $scope.newQuestion.answers_attributes.length; i++){
            try{
                textLengthValid = $scope.newQuestion.answers_attributes[i].text.en.length >= 1
            }catch(e){
                textLengthValid = false;
            }
            if($scope.hgForm.required_languages.indexOf('en') >=0){
                arrayOfValidity.push($scope.newQuestion.answers_attributes[i].text.en != '' && textLengthValid)
            }
            if($scope.hgForm.required_languages.indexOf('fr') >=0){
                arrayOfValidity.push($scope.newQuestion.answers_attributes[i].text.fr != '' && $scope.newQuestion.answers_attributes[i].text.fr != undefined)
            }
            if($scope.hgForm.required_languages.indexOf('es') >=0){
                arrayOfValidity.push($scope.newQuestion.answers_attributes[i].text.es != '' && $scope.newQuestion.answers_attributes[i].text.es != undefined)
            }
        }
        if(arrayOfValidity == ''){
            $scope.multipleAnswersInvalid = true;
        }else{
            $scope.multipleAnswersInvalid = arrayOfValidity.includes(false)
        }
        console.log($scope.multipleAnswersInvalid)
    }


    $scope.setQuestionWeighting = function (question) {
        var questionWeight = 0;

        if (question.question_type == "multiple-choice" || question.question_type == "select-dropdown") {
            // console.log(question)
            _.forEach(question.answers_attributes, function (key, value) {
                // console.log(key.weighting)
                if (key.weighting * 10 > questionWeight) {
                    questionWeight = key.weighting * 10;
                }
            });

        } else if (question.question_type == "multiple-answer") {

            _.forEach(question.answers_attributes, function (key, value) {

                questionWeight += key.weighting * 10
            });

        } else if (question.question_type == "short-answer" || question.question_type == "long-answer") {

        }
        // console.log(questionWeight)
        question.weighting = questionWeight;

    }

    $scope.addAnswer = function () {

        $scope.newQuestion.answers_attributes.push({
            "text": { "en": "", "fr": "", "es": "" }, "order": $scope.newQuestion.answers_attributes.length + 1, "weighting": 0, is_stop_answer: false
        });
        //$scope.weighting[$scope.newQuestion.answers_attributes.length] = 0;
        //console.log($scope.weighting)
    }

    $scope.removeAnswer = function (order) {
        //$scope.weighting.splice(order, 1);
        $scope.newQuestion.answers_attributes = $scope.newQuestion.answers_attributes.filter(function (answer) { return answer.order != order });
        $scope.newQuestion.answers_attributes.sort(function (a, b) {
            return a.order - b.order;
        })
        _.forEach($scope.newQuestion.answers_attributes, function (answer, index) {

            answer.order = index;
        });
    }

    $scope.reOrderAnswers = function (order1, order2) {


        var index1 = _.findIndex($scope.newQuestion.answers_attributes, { order: order1 })
        var index2 = _.findIndex($scope.newQuestion.answers_attributes, { order: order2 })

        //var tempWeight = $scope.weighting[order1]
        //$scope.weighting[order1] = $scope.weighting[order2]
        //$scope.weighting[order2] = tempWeight

        $scope.newQuestion.answers_attributes[index1].order = order2;
        $scope.newQuestion.answers_attributes[index2].order = order1;


    }

    $scope.editAddAnswer = function (index) {

        $scope.editedQuestion.answers_attributes.push({
            "text": { "en": "", "fr": "", "es": "" }, "order": $scope.editedQuestion.answers_attributes.length + 1, "weighting": 0, is_stop_answer: false
        });

    }

    $scope.editRemoveAnswer = function (order, question_id) {
        // var question = _.findIndex($scope.hgForm.questions_attributes, {id: question_id});
        $scope.editedQuestion.answers_attributes = $scope.editedQuestion.answers_attributes.filter(function (answer) { return answer.order != order });
        $scope.editedQuestion.answers_attributes.sort(function (a, b) {
            return a.order - b.order;
        })
        _.forEach($scope.editedQuestion.answers_attributes, function (answer, index) {

            answer.order = index;
        });
    }

    $scope.editReOrderAnswers = function (order1, order2, question_id) {

        // var question = _.findIndex($scope.hgForm.questions_attributes, {id: question_id});

        var index1 = _.findIndex($scope.editedQuestion.answers_attributes, { order: order1 })
        var index2 = _.findIndex($scope.editedQuestion.answers_attributes, { order: order2 })


        $scope.editedQuestion.answers_attributes[index1].order = order2;
        $scope.editedQuestion.answers_attributes[index2].order = order1;


    }

    $scope.editQuestion = function (index, question) {
        angular.copy(question, $scope.editedQuestion)
        $scope.hgForm.questions_attributes[index].isBeingEdited = !$scope.hgForm.questions_attributes[index].isBeingEdited

        _.forEach($scope.hgForm.questions_attributes, function (currentQuestion, values) {
            if(currentQuestion.order != question.order) {
                currentQuestion.isBeingEdited = false;
            }
         })


    }

    $scope.saveQuestion = function (index) {
        $scope.hgForm.questions_attributes[index] = $scope.editedQuestion;
        $scope.editedQuestion = {};

    }

    $scope.cancelQuestion = function (index) {
        $scope.hgForm.questions_attributes[index].isBeingEdited = !$scope.hgForm.questions_attributes[index].isBeingEdited
        $scope.editedQuestion = {};
    }

    $scope.$watch('newQuestion.question_type', function (newVal, oldVal) {
        if (newVal != oldVal) {
            if (newVal == 'multiple-choice' || newVal == 'multiple-answer' || newVal == 'select-dropdown') {
                while ($scope.newQuestion.answers_attributes.length < 2) {
                    $scope.newQuestion.answers_attributes.push({
                        "text": { "en": "", "fr": "", "es": "" }, "order": $scope.newQuestion.answers_attributes.length + 1, "weighting": 0, is_stop_answer: false
                    });
                }

            }
        }
    }, true);


    railApi.index('tags', {is_viewable: 'true'}).promise.then(function(tagData) {

        $scope.availableTags = tagData
    });

    $scope.addTagToQuestion = function(tag){
        $scope.answer.questionTag = []
        $scope.answer.questionTag.push(tag)

    }



};

module.exports = formCtrl;
