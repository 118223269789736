/**
 * Created by dylan on 12/12/14.
 */

/**
 * StartDate Filters
 *
 * @param app the angular module.
 */
module.exports = function (app) {
    // Bootstrap lodash.
    require('./_.js')(app);

    app
        .filter('hgFilterStringMax', require('./hgFilterStringMax.js'))
        .filter('hgGetLang', require('./hgGetLang.js'))
        .filter('hgSumArrayValues', require('./hgSumArrayValues.js'))
        .filter('hgTitleize', require('./hgTitleize.js'))
        .filter('hgTranslate', require('./hgTranslate.js'))
        .filter('hgAbs', require('./hgAbs.js'))
        .filter('orderObjectBy', require('./orderObjectBy.js'))
        .filter('orderByJFQ', require('./orderByJFQ.js'))
        .filter('hgInterpolateString', require('./hgInterpolateString.js'))
        .filter('hgFilterCandidateSource', require('./hgFilterCandidateSource.js'))
        .filter('hgFilterCandidate', require('./hgFilterCandidate.js'))
        .filter('hgFilterCandidateByFutureConsideration', require('./hgFilterCandidateByFutureConsideration.js'))
        .filter('hgFilterCandidateByBlackFlag', require('./hgFilterCandidateByBlackFlag.js'))
        .filter('hgFilterCandidateByInternal', require('./hgFilterCandidateByInternal.js'))
        .filter('hgFilterJobList', require('./hgFilterJobList.js'))
        .filter('trustAsSafe',['$sce', function ($sce) {
            return function(url) {
              return (url) ? $sce.trustAsResourceUrl(url) : url;
            };
          }])
        

    ;
};