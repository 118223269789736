'use strict';

var tagEditCtrl = function ($scope, railApi, $stateParams) {

    $scope.tag = railApi.get('tags/' + $stateParams.id, {}).data;

    $scope.hgForm = $scope.tag;

    $scope.error = [];

    $scope.formSubmit = function () {
        $scope.errors = [];
        req_lang.forEach(function (elem, index, array) {
            if ($scope.hgForm.name[elem] == '') {
                $scope.errors.push("Name should have the following languages: "+elem);
                $scope.tagError = true;
            }
            else if($scope.tagError == true) {
                $scope.result = railApi.post('tags', $scope.hgForm, 'tag')
            }
        });
    };
};

module.exports = tagEditCtrl;
