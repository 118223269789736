'use strict';



var Directive = function(railApi) {
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        template: "<span class=\"select\">\n    <hg-select placeholder=\"{{ 'Job.PlaceholderIndustry' | hgTranslate }}\"\n               ng-model=\"hgForm.industry.id\"\n               options-model=\"industries\"\n               options=\"{ maxItems: 1, returnInt: true}\">\n        <hg-option ng-repeat=\"industry in industries\" value=\"{{industry.id}}\">\n            {{industry.name | hgGetLang: currentLang}}\n        </hg-option>\n        <hg-option selected ng-if=\"hgForm.industry[0].id\" value=\"{{hgForm.industry[0].id}}\">\n            {{hgForm.industry[0].name | hgGetLang: currentLang}}\n        </hg-option>\n    </hg-select>\n</span>",

        link: function(scope, elem, attrs) {
            scope.industries = railApi.index('industries').data;

        }
    };
};

Directive.$inject = ['railApi'];

module.exports = Directive;