
var config = require('../config.js');
var serialize = require('../serialize.js');

var noteService = function($http, CacheFactory, conversions){

    var convert = function(changes,wrapper){
        var convertedChanges = conversions.convertData(changes);
        var wrappedChanges = {};
        wrappedChanges[wrapper] = convertedChanges;

        return wrappedChanges;
    }

    this.updateNote = function(note){
        return $http.put(config.apiIP+'/notes/'+note.id,convert(note,'note'));
    }

    this.addNote = function(note){
        return $http.post(config.apiIP+'/notes/',convert(note,'note'))
    }

    this.deleteNote = function(note){
        return $http.delete(config.apiIP+'/notes/'+note.id)
    }
}

module.exports = noteService;