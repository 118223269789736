'use strict';

var Directive = function () {
    return function (scope, elem) {
        var el = elem[0];

        el.screensize = true;

        $(window).resize(function() {
            var clientWidth = document.documentElement.clientWidth;

            if (clientWidth > 1600){
                elem.addClass('col col-md-7');
                elem.removeClass('col col-md-12');
                elem.removeClass('col col-md-10');
            } else if (clientWidth < 1600 && clientWidth > 1370) {
                elem.removeClass('col col-md-7');
                elem.removeClass('col col-md-12');
                elem.addClass('col col-md-10');
            } else if (clientWidth < 1370 && clientWidth > 990) {
                elem.removeClass('col col-md-10');
                elem.removeClass('col col-md-7');
                elem.addClass('col col-md-12');
            }
        });
    };
};

Directive.$inject = [];

module.exports = Directive;

