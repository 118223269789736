/**
 * Created by dylan on 18/12/14.
 */
'use strict';



var Directive = function ($window) {
    return {
        require: '^?navGroup',
        restrict: 'AE',
        controller: Controller,
        scope: {
            title: '@',
            view: '@',
            icon: '@',
            iconCaption: '@',
            href: '@',
            target: '@'
        },
        transclude: true,
        replace: true,
        template: "<li data-ng-class=\"{active: isActive(view)}\">\n    <a href=\"{{ getItemUrl(view) }}\" target=\"{{ getItemTarget() }}\" title=\"{{ title }}\">\n        <i data-ng-if=\"hasIcon\" class=\"{{ icon }}\"><em data-ng-if=\"hasIconCaption\"> {{ iconCaption }} </em></i>\n        <span ng-class=\"{'menu-item-parent': !isChild}\" data-localize=\"{{ title }}\"> {{ title }} </span>\n        <span data-ng-transclude=\"\"></span>\n    </a>\n</li>'",
        link: function (scope, element, attrs, navgroupCtrl) {
            scope.$watch('active', function (newVal) {
                if (newVal) {
                    if (null != navgroupCtrl) {
                        navgroupCtrl.setActive(true);
                    }
                    // $window.document.title = scope.title;  had to comment this one out because it forces the page title to change based on the nav:item name
                } else {
                    if (null != navgroupCtrl) {
                        navgroupCtrl.setActive(false);
                    }
                }
            });

            $(window).on('resize', function () {
                if( $(window).width() <= 979) {
                    if ($.root_.hasClass('minified')) {
                        $.root_.removeClass('minified');
                    }
                }
            }).trigger('resize');

            // this code is needed because on desktop mode, when hovering link second time the link tooltip does not appear
            $('nav>ul>li>a').bind('mouseover', function() {
                $(this).parent('li').removeClass('hide-submenu'); // show submenu
            });

            // clicking on menu's parent link will shows sub menu
            $('nav>ul>li>a').bind('click', function() {
                // if single link then hide the submenu
                if ($(this).attr('href')) {
                    $(this).parent('li').addClass('hide-submenu'); // hide submenu
                } else {
                    $(this).parent('li').removeClass('hide-submenu'); // show submenu
                }
            });

            // clicking on sub-menu's link will hide sub menu
            $('nav li>ul>li>a').bind('click', function() {
                $(this).parents('li').addClass('hide-submenu');
            });

            scope.openParents = scope.isActive(scope.view);
            scope.isChild = null != navgroupCtrl;

            element.on('click', 'a[href!="#"]', function () {
                if ($.root_.hasClass('mobile-view-activated')) {
                    $.root_.removeClass('hidden-menu');
                    $('html').removeClass('hidden-menu-mobile-lock');
                }
            });
        }
    };
};
Directive.$inject = ['$window'];

var Controller = function ($rootScope, $scope, $location) {
    $scope.isChild = false;
    $scope.active = false;
    $scope.isActive = function (viewLocation) {
        $scope.active = viewLocation === $location.path();

        return $scope.active;
    };

    $scope.hasIcon = null != $scope.icon;
    $scope.hasIconCaption = null != $scope.iconCaption;

    $scope.getItemUrl = function (view) {
        if (null != $scope.href) {
            return $scope.href;
        }

        if (null == view) {
            return '';
        }

        return '#' + view;
    };

    $scope.getItemTarget = function () {
        return null != $scope.target ? $scope.target : '_self';
    };
};
Controller.$inject = ['$rootScope', '$scope', '$location'];

module.exports = Directive;