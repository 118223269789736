'use strict';

var Directive = function () {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function (scope, elem, attr) {
            scope.$watch(attr.ngModel, function(newVal){
                if (null != newVal) {
                    if (null != attr.hgCondition) {
                        if (scope.$eval(attr.hgCondition)) {
                            scope.$eval(attr.hgChange);
                        }
                    } else {
                        scope.$eval(attr.hgChange);
                    }
                }
            }, true);
        }
    };
};

module.exports = Directive;