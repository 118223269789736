'use strict';
var config = require('../../config.js');

var questionTemplateAddCtrl = function ($scope, railApi, $state, $stateParams, $rootScope, $modal) {

    $scope.successfulQuestionTemplateCreation;
    $scope.questionCategories = railApi.index('question_categories').data;
    $scope.defaultIndex = -1;
    $scope.page = ""
    $scope.i = 0;
    $scope.hgForm;
    $scope.paramCategory = null;
    var preferred_language = $rootScope.preferred_language
    $scope.question_types = [
        { id: 'multiple-choice' },
        { id: 'multiple-answer' },
        { id: 'select-dropdown' },
        { id: 'long-answer' },
        { id: 'short-answer' }
    ];
    $scope.newAnswer = {
        en: "",
        fr: "",
        es: ""
    }
    if($stateParams.CategoryId){
        $scope.paramCategory = $stateParams.CategoryId;
    }


    $scope.companyDepartments = railApi.index('company_departments').data;

    $scope.hgForm = {
        name: {},
        question: {},
        question_category_ids: [],
        company_department_ids: [],
        question_type: $stateParams.Type,
        answer_templates_attributes: [],
        archived: false,
        required_languages: [preferred_language]
    };
    $scope.addAnswer = function () {

            $scope.hgForm.answer_templates_attributes.push({
                "text":{"en":"","fr":"","es":""},"order":$scope.hgForm.answer_templates_attributes.length,"weighting":0
            })
            //console.log($scope.newQuestion.answer_templates_attributes)

        // (!_.isEmpty($scope.newAnswer[lang])) ? i = i + 1 : '';
        // (!_.isEmpty($scope.newAnswer[lang])) ? $scope.hgForm.answer_templates_attributes.push({ text: _.cloneDeep($scope.newAnswer), order: i }) : '';
        // //railApi.put('questions/' + $stateParams.id, $scope.hgForm, 'question');;

        $scope.newAnswer = {
            en: "",
            fr: "",
            es: ""
        };
    }

    $scope.onSubmit = function() {
      railApi.post('question_templates/', $scope.hgForm, 'question_template').promise.then(function (data) {
        $scope.hgForm = data;

        $scope.successfulQuestionTemplateCreation = $modal.open({
                templateUrl: 'successfulQuestionTemplateCreation.ejs',
                scope: $scope,
                size: 'lg'
            });
      })
    }

    $scope.redirect = function() {
      $state.go('questionList', {categorySelected: $scope.hgForm.question_category_ids});
    }

    $scope.dismissModal = function() {
      $scope.successfulQuestionTemplateCreation.dismiss();
    }

    $scope.launchModal = function() {
      $scope.returnQuestionInfo();
    }

    $scope.removeAnswer = function (order) {
        $scope.hgForm.answer_templates_attributes = $scope.hgForm.answer_templates_attributes.filter(function (answer) { return answer.order != order })
        $scope.hgForm.answer_templates_attributes.sort(function (a, b) {
            return a.order - b.order;
        })
        _.forEach($scope.hgForm.answer_templates_attributes, function (answer, index) {

            answer.order = index
        });

    }

    $scope.reOrderAnswers = function(answer1, answer2) {
        console.log(answer1.text.en)
        console.log(answer2.text.en)

        var tempIndex = answer1.order;
        answer1.order = answer2.order;
        answer2.order = tempIndex;

        //$scope.hgForm.answer_templates_attributes.splice(newIndex, 0, $scope.hgForm.answer_templates_attributes.splice(oldIndex, 1)[0])
    }

    $scope.updateQuestion = function () {
        //railApi.put('questions/' + $stateParams.id, $scope.hgForm, 'question')
    }

    // $scope.onKeyPress = function ($event, lang, i) {

    //     $event.keyCode == 13 ? $event.preventDefault() : '';

    //     $event.keyCode == 13 && (!_.isEmpty($scope.newAnswer[lang])) ? i = i + 1 : '';

    //     if ($event.keyCode == 13 && (!_.isEmpty($scope.newAnswer[lang]))) {
    //         $scope.hgForm.answer_templates_attributes.push({ text: _.cloneDeep($scope.newAnswer), order: i })

    //         $scope.newAnswer = {
    //             en: "",
    //             fr: "",
    //             es: ""
    //         }
    //         if ($scope.page == "edit") {
    //             railApi.put('questions/' + $stateParams.id, $scope.hgForm, 'question');
    //         }
    //     }

    // }

    $scope.deleteQuestion = function (index) {
        if ($scope.hgForm.answers.length > 2) {
            $scope.hgForm.answers.splice(index, 1);
            railApi.put('questions/' + $stateParams.id, $scope.hgForm, 'question');
        } else {
            //it will trigger an answer edit instead of a delete automatically.
        }
    }

    $scope.getDefaultIndex = function () {
        //when a user wants to add a new question to a specific category.
        $scope.defaultIndex = _.findIndex($scope.questionCategories.question_categories, { "id": +$scope.paramCategory })
        //console.log(defaultIndex)

        return $scope.defaultIndex;
    }

    railApi.index('tags', {is_viewable: 'true'}).promise.then(function(tagData) {

        $scope.availableTags = tagData
    });

    $scope.addTagToQuestion = function(tag){
        $scope.answer.questionTag = []
        $scope.answer.questionTag.push(tag)

    }


};

module.exports = questionTemplateAddCtrl;
