'use strict';

var demoCtrl = function ($scope, $window, Restangular){
    $scope.name;
    $scope.company= '';
    $scope.phonenumber= '';
    $scope.email;
    $scope.usingATS;
    $scope.question= '';
    $scope.emailQuestion= '';
    $scope.registeremail;
    $scope.registercompany = '';
    $scope.registername;

    $scope.sendDemoRequest = function(){
        var email = {
            to: 'sales@hiregroundsoftware.com',
            from: $scope.email,
            subject: "ATS Demo Request",
            emailbody: "Name: " + $scope.name + "<br>Company: " + $scope.company + "<br>Phone Number: "
            + $scope.phonenumber + "<br>Email: " + $scope.email + "<br>Using an ATS: " + $scope.usingATS
        };

        Restangular.all('demo-request').post(email).then(function(data) {
            $window.location = 'thankyou.html#/';
        }, function (err) {
            $window.alert('Email was not sent');
        });

    };

    $scope.sendQuestion = function() {
        var emailAQuestion = {
            to: 'sales@hiregroundsoftware.com',
            from: $scope.emailQuestion,
            subject: "ATS Question",
            emailbody: "Question: " + $scope.question + "<br>Email: " + $scope.emailQuestion
        };
        Restangular.all('question-request').post(emailAQuestion).then(function(data) {
            $window.location = 'thankyou.html#/';
        }, function (err) {
            $window.alert('Email was not sent');
        });
    };

    $scope.sendRegistration = function() {
        var register = {
            to: 'sales@hiregroundsoftware.com',
            from: $scope.registeremail,
            subject: "White Paper Registration",
            emailbody: "Name: " + $scope.registername + "<br>Company: " + $scope.registercompany + "<br>Email: " + $scope.registeremail
        };
        Restangular.all('whitepaper-request').post(register).then(function(data) {
            $window.location = 'whitepaper.html#/';
        }, function (err) {
            $scope.error = 'Email was not sent';
        });
    };



};

module.exports = demoCtrl;