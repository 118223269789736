'use strict';

var Service = function (Upload, $rootScope) {
    var result = {
        attachDocsId: [],
        attachDocsInfo: [],
        parsedResume: {}
    };

    this.addNewFile = function (attachFiles) {
        var newItemNo = attachFiles.length + 1;
        attachFiles.push({'id' : 'attachFiles' + newItemNo});
    };

    this.removeFile = function (index, docFiles, attachFiles) {
        docFiles.splice(index,1);
        attachFiles.splice(index,1);
        result.attachDocsId.splice(index,1);
        result.attachDocsInfo[index] = {};
        if (0 === attachFiles.length) {
            this.addNewFile(attachFiles);
        }
    };

    this.convertIntoArray = function (docFiles, attachDocsInfo) {
        var files = [];
        _.map(docFiles, function (value, index) {
            if (-1 === _.findIndex(attachDocsInfo, { 'fileName': value.name })) {
                return files.push(value);
            }
        });
        return files;
    };

    this.upload = function (files, fileType, result) {
        result.errorResume = false;
        result.errorFile = false;
        if (files && files.length) {

            for (var i = 0; i < files.length; i+=1) {
                var file = files[i];
                Upload.upload({
                    url: '/api/v1/upload-file',
                    file: file,
                    fields: {type: fileType}

                }).progress(function (evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                }).success(function (data, status, headers, config) {
                    // completed, data contains the parsed resume and fileModel
                    result.fileModel = data.fileModel;
                    if ('resume' === fileType) {
                        result.parsedResume = data.parsedResume;
                    }

                    if (-1 !== result.fileModel.path.indexOf('/attachedDocuments/')) {
                        result.attachDocsInfo.push({
                            fileName: result.fileModel.originalFileName,
                            id: result.fileModel.id
                        });
                        result.attachDocsId.push(result.fileModel.id);
                    }

                }).error(function(data, status, headers, config) {
                    if (config.fields.type === 'resume') {
                        result.errorResume = true;
                    }
                    else {
                        result.errorFile = true;
                    }

                });

            }

        }
    }; // upload fx

};

Service.$inject = ['Upload', '$rootScope'];

module.exports = Service;