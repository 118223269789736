'use strict';
var helpCenterCtrl = function ($scope, $window, $location, $anchorScroll){
    
    $scope.hideElements = function() {
        var path = $window.location.href;
        path = path.substring((path.indexOf('#') + 1), path.length);
        if (path === '/HelpCenter' || path === '/HelpCenter#contactInfo') {
            return true;
        } else {
            return false;
        }
    };

  $scope.indexedContent = [];

  $scope.uploadSections = function (sectionList) {
      $scope.indexedSections = sectionList;
  };

  $scope.uploadContent = function (content) {
          $scope.contentList = content;
  };

  $scope.contentsToFilter = function () {
      indexedContent = [];
      return $scope.contentList;
  };

  $scope.sectionsToDisplay = function(){
      return $scope.indexedSections;
  };

  $scope.filteredSections = function(content){

    var categoryIsNew = indexedContent.indexOf(content.sectionId.name.en) == -1;
    if (categoryIsNew) {
        indexedContent.push(content.sectionId.name.en);
    }
      return categoryIsNew;
  }; 
  
  $scope.showTrackersSelector = function(){
    var nav = document.querySelector('#trackers')
    var count = nav.querySelector('ul').childElementCount
    if(count > 0){
        return true
    }else{
        return false
    }
  }

  var indexedContent = [];

  $scope.goToContactInfo = function(){
      $location.hash('contactInfo');
      $anchorScroll();
  };

};
module.exports = helpCenterCtrl;
