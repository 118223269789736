'use strict';

var Jobs = function (RailRestangular) {
    var jobs = RailRestangular.service('jobs/active?page=candidate_dashboard');

    return jobs;
};

Jobs.$inject = ['RailRestangular'];

module.exports = Jobs;
