'use strict';

var templateEmailCtrl = function ($rootScope, $scope, railApi, $state, $stateParams) {

    console.log("state:", $state)

    $scope.generalTemplates = [];
    $scope.offerTemplates = [];
    $scope.informationRequestTemplates = [];
    $scope.regretTemplates = [];
    $scope.selectedTemplates = [];
    $scope.generalCount;
    $scope.offerCount;
    $scope.regretCount;
    $scope.archivedCount;
    $scope.informationRequestCount;


    
    railApi.index('template_emails', {is_deleted: true}).promise.then(function(data){$scope.archivedTemplates = data; $scope.archivedCount = data.length;});
    railApi.index('template_emails', {is_deleted: false}).promise.then(function(data){
        data.forEach(function(template){
            if (template.email_type == "general") {
                $scope.generalTemplates.push(template)
            }
            if (template.email_type == "offer") {
                $scope.offerTemplates.push(template)
            }
            if (template.email_type == "RejectionEmailtype") {
                $scope.regretTemplates.push(template)
            }
            if (template.email_type == "informationRequest") {
                $scope.informationRequestTemplates.push(template)
            }
        })
        $scope.offerCount = $scope.offerTemplates.length;
        $scope.regretCount = $scope.regretTemplates.length;
        $scope.generalCount = $scope.generalTemplates.length;
        $scope.informationRequestCount = $scope.informationRequestTemplates.length;
        $scope.selectedTemplates = $scope.generalTemplates;

    })

    $scope.customEmailTemplatesQuery = railApi.index('template_emails', {
        is_system_default: 'false',
        is_deleted: false
    });
    $scope.customEmailTemplates = $scope.customEmailTemplatesQuery.data;
    var preferred_language = $rootScope.preferred_language
    $scope.companyDepartments = railApi.index('company_departments').data;

    $scope.hgForm = {
        name: {},
        subject: {},
        body: {},
        template_notes: '',
        required_languages: [preferred_language],
        custom_placeholder: []
    };
    if($state.current.url != '/template/email/add/:id'){
        $scope.hgForm.email_type = 'general'
    }


    $scope.hgForm.template_email_selected_languages = ['en'];
    

    $scope.label = {
        label: '',
        label_desc: ''
    }

    $scope.selectButton = "Job.Select"

    $scope.systemPlaceholders = [{type: 'jobPosting', label: 'jobName'} ,{type: 'jobPosting', label: 'jobNameFr'} ,{type: 'jobPosting', label: 'jobNameEs'} ,{type: 'jobPosting', label: 'clientName'} ,
                              {type: 'candidate', label: 'user.name.first'} ,{type: 'candidate', label: 'user.name.last'} ,{type: 'candidate', label: 'user.email'}
                             ,{type: 'jobPosting', label: 'job.name'} ,{type: 'jobPosting', label: 'job.name_fr'} ,{type: 'jobPosting', label: 'job.name_es'}
                             ,{type: 'jobPosting', label: 'organization.name'} ,{type: 'jobPosting', label: 'contactEmail'} ,{type: 'jobPosting', label: 'contactPhone'}
                             ,{type: 'jobPosting', label: 'global.url.protocol'} ,{type: 'jobPosting', label: 'global.url.hostname'}]

    $scope.existing = false;
    $scope.addCustom = false;

    $scope.showEditor = function(){
        $scope.label = {
            label: '',
            label_desc: ''
        }
        return $scope.addCustom = !$scope.addCustom
    }

    if ($stateParams.id) {

        railApi.get('template_emails/' + $stateParams.id).promise.then(function (data) {
            $scope.hgForm = data;
            $scope.hgForm.template_email_selected_languages = data.required_languages
        })
    }

    $scope.redirect = function() {
      $state.go('emailTemplateClone', {id: $scope.hgForm.id});
    }

    $scope.addCustomLabel = function(label, desc, hgForm){

        if(!$scope.systemPlaceholders.some(u => u.label == label ) && !hgForm.custom_placeholder.some(u => u.label == label )){ //<---- this searches the systemPlaceholder array and hgform.customplaceholder array if the label already exist

            hgForm.custom_placeholder.push({label: label, description: desc})
            $('#labelInput').val('');
            $('#labelDesc').val('');
            $scope.label.label = ''
            $scope.label.label_desc = ''
            $scope.addCustom = false;
            $scope.existing = false;
        }else{
            $scope.existing = true;
            $scope.addCustom = true;
        }

    }

    $scope.addCustomLabelEdit = function(label, desc, hgForm, id){
        if(_.isEmpty(hgForm.custom_placeholder)){
            hgForm.custom_placeholder = []
        }

        if(!$scope.systemPlaceholders.some(u => u.label == label ) && !hgForm.custom_placeholder.some(u => u.label == label )){ //<---- this searches the systemPlaceholder array and hgform.customplaceholder array if the label already exist

            hgForm.custom_placeholder.push({label: label, description: desc})
            railApi.put('template_emails/' + id, {custom_placeholder: hgForm.custom_placeholder}, 'template_email').promise.then(function(data){
                $('#labelInput').val('');
                $('#labelDesc').val('');
                $scope.label.label = ''
                $scope.label.label_desc = ''
                $scope.addCustom = false;
                $scope.existing = false;
            })

        }else{
            $scope.existing = true;
            $scope.addCustom = true;
        }
    }

    $scope.copyText = async function(labelText, divId){
        await navigator.clipboard.writeText('{{' + labelText + '}}').then(function(){
            // console.log("Copied " + labelText + " to clipboard")
            $('#'+divId).removeClass("invisibleCopied")
            setTimeout(function(){
                $('#'+divId).addClass('invisibleCopied')
                }, 1000);
        })
    }

    $scope.removeCustomLabel = function(labelIndex, hgForm){
        hgForm.custom_placeholder.splice(labelIndex, 1);
        // console.log($scope.hgForm)
    }

    $scope.removeCustomLabelEdit = function(labelIndex, hgForm, id){
        hgForm.custom_placeholder.splice(labelIndex, 1);
        railApi.put('template_emails/' + id, {custom_placeholder: hgForm.custom_placeholder}, 'template_email').promise.then(function(data){
            // console.log(data)
        })
    }

    $scope.setTab = function(tab) {
        if (tab == 'offer') {
            $scope.selectedTemplates = $scope.offerTemplates;

        }
        if (tab == 'regret') {
            $scope.selectedTemplates = $scope.regretTemplates;

        }
        if (tab == 'general') {
            $scope.selectedTemplates = $scope.generalTemplates;

        }
        if (tab == 'archived') {
            $scope.selectedTemplates = $scope.archivedTemplates;

        }
        if (tab == 'informationRequest') {
            $scope.selectedTemplates = $scope.informationRequestTemplates;

        }
    }
    
};

module.exports = templateEmailCtrl;
