
var config = require('../config.js');

var applicantService = function($http, CacheFactory){

    if (!CacheFactory.get('applicantCache')) {
        CacheFactory.createCache('applicantCache', {
          deleteOnExpire: 'aggressive',
          recycleFreq: 1 * 60 *1000
        });
      }
  
    var applicantCache = CacheFactory.get('applicantCache');
    this.getApplicantProfile = function(applicantId){
        return new Promise(function(resolve,reject){
            $http.get(config.apiIP+'/applicant_profiles/'+applicantId,{cache:applicantCache})
                .then(function(response){
                    resolve(response.data);
                })
                .catch(function(error){
                    reject(error);
                });
        });
    }

    this.flushCache = function(){
        applicantCache.destroyAll();
    }
}

module.exports = applicantService;