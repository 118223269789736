/**
 * Created by dylan on 21/01/15.
 */
'use strict';

var getProp = function get(obj, path) {
    if ('' === path) {
        return obj;
    }

    path = path.split('.');

    for (var i = 0, max = path.length; i < max; i += 1) {
        if (obj.hasOwnProperty(path[i])) {
            obj = obj[path[i]];
        } else {
            return undefined;
        }
    }

    return obj;
};

var setProp = function set(obj, path, val) {
    path = path.split('.');

    for (var i = 0, max = path.length - 1, next; i < max; i += 1) {
        if (isFinite(path[i + 1]) && 0 === Number(path[i + 1]) % 1) {
            next = obj[path[i]] || [];
        } else {
            next = obj[path[i]] || {};
        }
        obj = obj[path[i]] = next;
    }

    obj[path[i]] = val;
};

module.exports = function (app, injectables) {
    var Directive = function () {
        var resources = [].slice.call(arguments);

        return {
            restrict: 'E',
            scope: true,
            link: function (scope, elem, attr) {
                var requested = attr.inject;

                if (!requested) {
                    requested = [];
                } else {
                    requested = (requested || '').split(/ ?, ?/);
                }

                var resource;
                var ref1;
                var ref2;
                var inject;
                var prop;
                var alias;

                for (var i = 0; i < requested.length; i += 1) {
                    ref1 = requested[i].split(/ as /);
                    ref2 = ref1[0].split('.');
                    inject = ref2.shift();
                    prop = ref2.join('.');
                    alias = ref1[1];
                    resource = resources[Directive.$inject.indexOf(inject)];

                    if (null == resource) {
                        throw new Error('Unable to inject dependency: ' + inject);
                    }

                    setProp(scope, alias || inject,
                        getProp(resource, prop)
                    );
                }
            }
        };
    };

    Directive.$inject = app._invokeQueue.filter(function (mod) {
        return mod[1] === 'service' || mod[1] === 'factory';
    }).map(function (mod) {
        return mod[2][0];
    }).concat(injectables || []);

    app.directive('hg', Directive);
};